import React from "react";
import {Field, FormikProps, FormikValues} from "formik";
import 'react-phone-input-2/lib/material.css'
import PhoneInput from "react-phone-input-2";

interface FormikFieldProps {
    name: string;
    label: string;
    required?: boolean;
    error?: boolean;
    size: string;
    formik: FormikProps<FormikValues>;
}

const FormikMUIPhoneInput: React.FC<FormikFieldProps> =
    ({name, label, required = false, formik}) => (
        <>
            <Field
                // className="member-modifier__form-input"
                as={PhoneInput}
                country={"us"}
                prefix={"+"}
                specialLabel={""}
                name={name}
                inputProps={{name: name, autoComplete: "none"}}
                onChange={(value: FormikValues) => {
                    formik.setFieldValue(name, value, true)
                }}
                placeholder={"Contact phone (optional)"}
                onBlur={formik.handleBlur}
                containerClass={
                    formik.errors[name] && formik.touched[name]
                        ? "error"
                        : ""

                }
            />
            {formik.errors[name] && formik.touched[name] && (
                <div className="member-modifier__form-error">
                    {formik.errors[name]}
                </div>
            )}
        </>
    );

export default FormikMUIPhoneInput;

import React from "react";
import './NavigationTabs.scss'

type NavigationTab = {
    label: string,
    id: string
}

interface NavigationTabsProps {
    tabs: NavigationTab[],
    activeTab: string,
    changeTabHandler: (tabName: string) => void
}

const NavigationTabs = ({tabs, activeTab, changeTabHandler}: NavigationTabsProps) => {

    return <div className="navigation-tabs__tab-group">
        {
            tabs.slice(0, 3).map((tab, index) => {
                return <div
                    key={index}
                    onClick={() => {if (activeTab !== tab.id) changeTabHandler(tab.id)}}
                    className={`navigation-tabs__tab navigation-tabs__tab--margin ${(activeTab === tab.id ? "navigation-tabs__tab--active" : "")}`}>
                    {tab.label}
                </div>
            })
        }
    </div>
}

export default NavigationTabs;

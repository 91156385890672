import React from 'react';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {analyticsChartLineColors} from "../../constants";


interface Props {
  title?: string;
  subtitle?: string;
  data: Array<any>;
  wasEventRegistered(event: string, eventsData: Array<any>): boolean;
}


const MultiLineChart: React.FC<Props> = ({title, subtitle, data, wasEventRegistered}) => {
  return (
    <article>
      {title && <h1 className="engagement__title-props">{title}</h1>}
      {subtitle && <p className="engagement__subtitle-props">{subtitle}</p>}
      <ResponsiveContainer  width="100%" height={250}>
        <LineChart
          width={730}
          height={250}
          data={data}
          margin={{top: 5, right: 30, left: 20, bottom: 5}}
        >
          <CartesianGrid strokeDasharray="3 4" stroke="#818A91" vertical={false}/>
          <XAxis
            dataKey="date"
            axisLine={false}
            tick={{fill: "#555555"}}
            tickLine={false}
            style={{fontSize: 14, fontFamily: "Mulish", fontStyle: "italic"}}
          />
          <YAxis
            dataKey="total_count"
            stroke="#818A91"
            tick={{fill: "#555555"}}
            tickCount={4}
            tickLine={false}
            style={{fontSize: 14, fontFamily: "Mulish", fontStyle: "italic"}}
          />
          <Tooltip />
          <Legend verticalAlign="bottom"  height={36}/>
          {
            Object.keys(data[0]).map((key, index) => {
              if (!['date', 'total_count'].includes(key) && wasEventRegistered(key, data))
                return <Line
                  key={index}
                  type="linear"
                  dataKey={key}
                  stroke={analyticsChartLineColors.get(key)}
                  strokeWidth={3}
                />
            })
          }
        </LineChart>
      </ResponsiveContainer>
    </article>
  );
};

export default MultiLineChart;

import React, {Dispatch, useState} from "react";

import {Auth0UserNameProps} from "../../interfaces";

export type Auth0UserNameContextType = {
  auth0UserNameProps?: Auth0UserNameProps;
  setAuth0UserNameProps?: Dispatch<React.SetStateAction<Auth0UserNameProps | undefined>>;
}

export const Auth0UserNameContext = React.createContext<Auth0UserNameContextType>({});

export const Auth0UserNameContextProvider: React.FC = ({children}) => {
  const [auth0UserNameProps, setAuth0UserNameProps] = useState<Auth0UserNameProps>();

  return (
    < Auth0UserNameContext.Provider value={{auth0UserNameProps, setAuth0UserNameProps}}>{children}</ Auth0UserNameContext.Provider>
  )
}

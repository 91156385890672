import * as React from "react";
import {Button, InputLabel} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import LoadingSpinner from "../../../../../components/loading/LoadingSpinner";
import {Modal} from "../../../../../components/utils/Modal/Modal";
import AddTenantModal from "../AddTenant";
import './DropdownActions.scss'

export enum dropdownActionsTypes {
  deleteTenants = 'Delete selected Clients (Tenants)',
  exportEmailData = 'Email Data Export',
  exportNewScores = 'Changed Domain Scoring Export',
  exportData = 'Data Export'
}

type DropdownImportExportCsvType = {
  onChange: (type: string, tenants_ids: number[]) => void;
  isLoading: boolean;
  selectedTenantsIds: number[];
  tenantsCount: number
}

const DropdownActions = ({onChange, isLoading, selectedTenantsIds, tenantsCount}: DropdownImportExportCsvType) => {
  const [csvType, setCsvType] = React.useState('');
  const [tenantModelOpened, setTenantModelOpened] = React.useState(false)

  const handleTenantModalClose = () => {
    setTenantModelOpened(false);
  }

  return (
    <>
      <div className="dropdown-actions">
        <div className="dropdown-actions__title">
          <p className="m-0">Select tenant to change</p>
        </div>
        <div className="flex">
          <FormControl className="dropdown-actions__form-control">
            <InputLabel className="dropdown-actions__form-control-label">Export/Delete Tenants...</InputLabel>
            <Select
              value={csvType}
              disabled={isLoading}
              label='Export/Delete Tenants...'
              renderValue={(value) => (
                <div className="flex items-center">
                  <span className="text-sm text-dark-purple">{value}</span>
                  {isLoading && <div className="ml-2"><LoadingSpinner size={15}/></div>}
                </div>
              )}
              sx={{p: 0, m: 0, height: '2.5rem', borderRadius: '8px'}}
            >
              <MenuItem value={dropdownActionsTypes.deleteTenants}
                        onClick={() => setCsvType(dropdownActionsTypes.deleteTenants)}
              >
                <div className="flex items-center">
                  <span className="text-sm text-dark-purple">{dropdownActionsTypes.deleteTenants}</span>
                </div>
              </MenuItem>
              <MenuItem value={dropdownActionsTypes.exportEmailData} className="flex items-center"
                        onClick={() => setCsvType(dropdownActionsTypes.exportEmailData)}
              >
                <div className="flex items-center">
                  <span className="text-sm text-dark-purple">{dropdownActionsTypes.exportEmailData}</span>
                </div>
              </MenuItem>
              <MenuItem value={dropdownActionsTypes.exportData} className="flex items-center"
                        onClick={() => setCsvType(dropdownActionsTypes.exportData)}
              >
                <div className="flex items-center">
                  <span className="text-sm text-dark-purple">{dropdownActionsTypes.exportData}</span>
                </div>
              </MenuItem>
              <MenuItem value={dropdownActionsTypes.exportNewScores} className="flex items-center"
                        onClick={() => setCsvType(dropdownActionsTypes.exportNewScores)}
              >
                <div className="flex items-center">
                  <span className="text-sm text-dark-purple">{dropdownActionsTypes.exportNewScores}</span>
                </div>
              </MenuItem>
            </Select>
          </FormControl>
          <div className="mr-2">
            <Button className="dropdown-actions__buttons dropdown-actions__go-button"
                    disabled={(csvType === '' || selectedTenantsIds.length === 0)}
                    onClick={() => {
                      if (selectedTenantsIds.length > 0) {
                        onChange(csvType, selectedTenantsIds)
                      }
                    }}
                    size="large">
              <span>Go</span>
            </Button>
          </div>
          <div style={{paddingTop: "0.2rem"}}>
            <p
              className="dropdown-actions__selected-tenants-p">{selectedTenantsIds.length} of {tenantsCount} selected</p>
          </div>
          <div style={{marginLeft: "12rem"}}>
            <Button className="dropdown-actions__buttons dropdown-actions__add-tenant-button"
              onClick={() => setTenantModelOpened(true)}
                    size="large">
              <span>+ Add Tenant</span>
            </Button>
          </div>
          <Modal
            useFooter={false}
            onClose={handleTenantModalClose}
            show={tenantModelOpened}
            hideConfirmButton={true}
            hideCloseButton={true}
            haveTextArea={true}
            onDeny={handleTenantModalClose}
            size="xxl">
              <AddTenantModal handleTenantModalClose={handleTenantModalClose}/>
          </Modal>
        </div>
      </div>
    </>
  );

}

export default DropdownActions
import React from 'react';
import {
	Button,
	Checkbox,
	FormControl,
	FormHelperText,
	InputLabel,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select
} from "@mui/material";
import {MenuProps} from "../../CampaignManagement/CampaignManagement";
import {SelectedFlags} from "./FlagsSelect";

type DisplayFlagsType = {
	selectedFlagsNames: string[]
	selectedFlags: SelectedFlags
	isAllSelected: boolean
	handleAllFlagsSelected: (checked: boolean) => void
	handleSingleSelectedFlag: (new_state: boolean, id: number) => void
	indeterminate: boolean | undefined
}

const DisplayFlags = ({
						  selectedFlags,
						  selectedFlagsNames,
						  isAllSelected,
						  handleSingleSelectedFlag,
						  handleAllFlagsSelected,
						  indeterminate
					  }: DisplayFlagsType) => {
	return (
		<>
			<FormControl className="campaign-management__member-selection" size={"small"} fullWidth={false}
						 style={{width: 200, marginRight: 10}}>
				<InputLabel id="mutiple-select-label">Select flags</InputLabel>
				<Select
					labelId="mutiple-select-label"
					label="Select your flags"
					multiple
					value={selectedFlagsNames}
					renderValue={() => selectedFlagsNames!.join(", ")}
					MenuProps={MenuProps}
				>
					<MenuItem
						value="all"
						classes={{
							root: isAllSelected ? "selectedAll" : ""
						}}
					>
						<ListItemIcon>
							<Checkbox
								classes={{indeterminate: "indeterminateColor"}}
								checked={isAllSelected}
								onChange={(e) => handleAllFlagsSelected(e.target.checked)}
								indeterminate={indeterminate}
							/>
						</ListItemIcon>
						<ListItemText
							classes={{primary: 'selectAllText'}}
							primary="Select All"
						/>
					</MenuItem>
					{selectedFlags?.map(({id, name: flag}: { id: number, name: string }) => (
						<MenuItem key={id} value={flag}>
							<ListItemIcon>
								<Checkbox
									onChange={(e) => {
										handleSingleSelectedFlag(e.target.checked, id)
									}}
									checked={
										selectedFlags.findIndex(item => item.id === id && item.new_state) > -1
									}
								/>
							</ListItemIcon>
							<ListItemText primary={flag}/>
						</MenuItem>
					))}
				</Select>
				<FormHelperText>Please select flags</FormHelperText>
			</FormControl>
		</>
	);
};

export default DisplayFlags;
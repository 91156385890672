import React from "react";
import {Button} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";
import {useLocation} from "react-router-dom";

import './Header.scss';

type LayoutHeaderType = {
  buttons: {
    mainButtonsColor: string,
    actionButtonsColor: string
  },
  logo: string | undefined
}

const Header: React.FC<LayoutHeaderType> = ({buttons, logo}: LayoutHeaderType): JSX.Element => {
    const {user, logout} = useAuth0();
    const pathName = useLocation().pathname;
    const handleContactRedirect = () => {
        window.location.href = 'https://edisonmarks.com/contact-us/';
    }

    return (
        <header className="layout_top__header">
            <img className="layout_top__logo" src={logo} alt="Logo"/>
            <div>
                {pathName !== '/' &&
                <Button variant="contained"
                        size="large"
                        style={{backgroundColor: buttons?.mainButtonsColor}}
                        onClick={handleContactRedirect}
                >
                    Contact us
                </Button>
                }
                {user && (
                    <Button
                        className="layout_top__logout"
                        variant="contained"
                        size="large"
                        style={{backgroundColor: buttons?.mainButtonsColor}}
                        onClick={() => logout({
                            returnTo: window.location.origin
                        })}
                    >
                        Logout
                    </Button>
                )}
            </div>
        </header>
    )
}

export default Header;
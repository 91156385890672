import React, {useMemo} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";

import {withRoleBasedRedirect} from "./withRoleBasedRedirect";

type Props = {
    component: React.FC,
    requiredPermissions: string[]
}

const ProtectedRoute = ({component, requiredPermissions}: Props) => {
    // TODO: style ?
    // We use useMemo() because useMemo will only recompute the memoized value when one of the dependencies has changed.
    // This optimization helps to avoid expensive calculations on every render.
    const Component = useMemo(() => {
        return withAuthenticationRequired(
            withRoleBasedRedirect(component, requiredPermissions), {
                returnTo: window.location.search.includes('isInvited=true')
                  ? window.location.pathname + '?afterLogin=true&isInvited=true'
                  : window.location.pathname + '?afterLogin=true',
                onRedirecting: () => <div style={{textAlign: 'center'}}>Checking authentication</div>
            });
    }, [component]);

    return <Component/>
}

export default ProtectedRoute;

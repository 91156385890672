import React, {useContext} from "react";
import {useAuth0} from "@auth0/auth0-react";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import LoadingSpinner from "../loading/LoadingSpinner";
import {EmHero} from "../../assets";
import {ThemeContext} from "../../store/context/theme-context";
import './styles.scss';

const Layout = ({children}: { children: React.ReactNode }) => {
    const {isLoading} = useAuth0();
    const currentThemeProps = useContext(ThemeContext)

    return (
        <>
            {isLoading
                ? <LoadingSpinner/>
                : (
                    <>
                        <section className='layout'>
                            <Header buttons={currentThemeProps?.buttons} logo={currentThemeProps.logo}/>
                            <article className="layout__main">
                                {children}
                                <img className="layout__hero-img" src={EmHero} alt=''/>
                            </article>
                            <Footer footerText={currentThemeProps?.footerContent}/>
                        </section>
                    </>
                )
            }
        </>
    )
}

export default Layout;
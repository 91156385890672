import React from 'react';
import {TextareaAutosize, TextareaAutosizeProps} from "@mui/material";
import {ErrorMessage, Field} from "formik";

interface FormikFieldProps extends TextareaAutosizeProps {
	name: string;
	placeholder: string;
	type?: string;
	required?: boolean;
	error?: boolean;
	size: string;
	minRows?: number
}

const MuiTextarea: React.FC<FormikFieldProps> =
	({
		 name, placeholder, type = "text",
		 required = false, error, size, minRows = 3, ...props
	 }) => {
		return (
			<Field
				{...props}
				className="member-modifier__form-input"
				required={required}
				autoComplete="off"
				as={TextareaAutosize}
				placeholder={placeholder}
				name={name}
				fullWidth
				type={type}
				helperText={<ErrorMessage name={name}/>}
				error={error}
				size={size}
				minRows={minRows}
			/>
		);
	};

export default MuiTextarea;
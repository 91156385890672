import React, {useContext} from 'react';
import {Link, Navigate, Route, Routes, useLocation, useParams} from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PaymentIcon from '@mui/icons-material/Payment';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';

import TenantUserAccount from "./TenantUserAccount";
import Billing from "../../SelfServe/Settings/Billing";
import TenantAccount from "./TenantAccount";
import Users from "./Users";
import NonTrackedIPs from "./NonTrackedIPs";
import useNavSideBar from "../../../utils/hooks/useNavSideBar";
import {TenantUserPropsContext} from "../../../store/context/tenant-user-props-context";
import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import {ROUTES} from "../../../constants";


const settings = {
  'profile': {
    name: 'Profile',
    icon: AccountCircleIcon
  },
  'account': {
    name: 'Account',
    icon: ManageAccountsIcon
  },
  'billing': {
    name: 'Billing',
    icon: PaymentIcon
  },
  'users': {
    name: 'Users',
    icon: GroupAddIcon
  },
  'internal-ips': {
    name: 'Internal-IPs',
    icon: PlaylistAddCheckCircleIcon
  },
  // 'customization': {
  //   name: 'Customization',
  //   icon: PaletteIcon
  // }
}

const initialNavSideValues = Object.values(settings).map(({name: settingName}) => {
  return {
    name: settingName.toLowerCase(),
    isClicked: false
  }
})

interface Setting {
  name: string;
  icon: any
}

const Settings = ({setUserName, userName}: {
  setUserName: React.Dispatch<React.SetStateAction<string | undefined>>,
  userName: string | undefined
}) => {
  const {pathname} = useLocation();
  const activeSetting = React.useMemo(() => {
    return Object.values(settings).find(({name}) => pathname.includes(name.toLowerCase()))
  }, [pathname])

  const {tenant_id: tenantId} = useParams()
  const {
    filterNavButtons,
    updateNavButtons
  } = useNavSideBar(initialNavSideValues, activeSetting?.name?.toLowerCase()! || 'profile')
  const {tenantUserProps} = useContext(TenantUserPropsContext);

  return (
    !tenantUserProps ?
      <LoadingSpinner/>
      :
      <section className="app__ss">
        <aside className="app__ss-settings">
          <nav className="app__ss-s-paths">
            {
              Object.values(settings)
                .map(({name: settingName, icon: Icon}: Setting, index: number) => {
                  if (tenantUserProps.is_owner || settingName.includes('Profile') || settingName.includes('Users')
                    || settingName.includes('Internal-IPs')) {
                    return (
                      <Link key={index}
                            onClick={() => {
                              updateNavButtons(settingName.toLowerCase());
                            }}
                            to={settingName.includes('Profile')
                              ? `/tenants/${tenantId}/settings/${tenantUserProps?.id}/profile`
                              : `/tenants/${tenantId}/settings/${settingName.toLowerCase()}`
                            }
                            className={filterNavButtons(settingName.toLowerCase())
                              ? "nav-dash__clicked-path"
                              : ""
                            }
                      >
                        <Icon
                          className={filterNavButtons(settingName.toLowerCase()) ? "nav-dash__icon-clicked custom-icon-class" : "custom-icon-class"}
                        />
                        <div className="nav-dash__link-wrapper">
                          <p
                            className="nav-dash__headers">{settingName.includes("-") ? settingName.replace(/-/g, " ") : settingName}</p>
                        </div>
                      </Link>
                    )
                  }
                })}
          </nav>
        </aside>
        <article className="app__ss-settings-management flex justify-start">
          <Routes>
            <Route path={ROUTES.TENANT_SS.subroutes.SETTINGS.subroutes.PROFILE.route}
                   element={<TenantUserAccount userName={userName} setUserName={setUserName}/>}/>
            <Route path={ROUTES.TENANT_SS.subroutes.SETTINGS.subroutes.ACCOUNT.route} element={<TenantAccount/>}/>
            <Route path={ROUTES.TENANT_SS.subroutes.SETTINGS.subroutes.BILLING.route} element={<Billing/>}/>
            <Route path={ROUTES.TENANT_SS.subroutes.SETTINGS.subroutes.USERS.route} element={<Users/>}/>
            <Route path={ROUTES.TENANT_SS.subroutes.SETTINGS.subroutes.INTERNALIPS.route} element={<NonTrackedIPs/>}/>
            {/*<Route path="customization/" element={<Customization/>}/>*/}
            <Route index element={<TenantUserAccount userName={userName} setUserName={setUserName}/>}/>
            <Route path={ROUTES.ERROR_PAGE.route} element={<Navigate replace to={'/not-found'}/>}/>
          </Routes>
        </article>
      </section>
  );
};

export default Settings;
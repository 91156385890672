import {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useMutation} from "@tanstack/react-query";

import {ENDPOINTS} from "../../constants";
import {useAPI} from "../../utils/hooks/useAPI";

type Props = {
  children: JSX.Element,
}

const CreateTenantUserProvider = ({children}: Props) => {
  const api = useAPI()
  const {user, isLoading} = useAuth0();

  const {mutate: createTenantUser} = useMutation({
    mutationKey: ['createTenantUser'],
    mutationFn: createTenantUserFn
  })

  function createTenantUserFn() {
    return api.post(ENDPOINTS.TENANTS.tenant_user_create)
  }

  useEffect(() => {
    if (!isLoading && user) {
      createTenantUser()
    }
  }, [isLoading, user]);

  return children
}

export default CreateTenantUserProvider;
import React from 'react';

interface DownloaderProps {
    fileNames: string[],
    onComplete?: () => void
}

const Downloader: React.FC<DownloaderProps> = ({fileNames, onComplete}) => {
    return (
        <div style={{display: 'none'}}>
            {
                fileNames?.map((fileName) => <iframe src={fileName} title={fileName}/>)
            }
        </div>
    )
}

export default Downloader;
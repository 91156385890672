import {ComponentProps, FC} from 'react';

export const combineComponents = (...components: FC[]): FC => {
    return components.reduce(
        (AccumulatedComponents, CurrentComponent) => {
            return ({children}: ComponentProps<FC>): JSX.Element => {
                return (
                    <AccumulatedComponents>
                        <CurrentComponent>{children}</CurrentComponent>
                    </AccumulatedComponents>
                );
            };
        },
        ({children}) => <>{children}</>,
    );
};
import {useEffect, useRef, useState} from "react";
import {Scrollbars} from "react-custom-scrollbars-2";
import {useNavigate} from "react-router-dom";

import {MemberOnTenant} from "../../../../interfaces";
import './MemberList.scss'

type MemberListProps = {
    filteredMembers: MemberOnTenant[];
    pathName: string;
    onMouseOver: (id: number) => void
    onMouseOut: () => void
}

// TODO: any -> ?
const MembersList = ({filteredMembers, pathName, onMouseOver, onMouseOut}: MemberListProps) => {
    const id = pathName.split('/')[3];
    const [clickedButtonId, setClickedButtonId] = useState<number>(0);
    const [currentMembersIndex, setCurrentMembersIndex] = useState<number>(0);
    const ref = useRef<null | HTMLButtonElement>(null);
    const navigate = useNavigate();
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);


    //find initial active member
    useEffect(() => {
        if (id) {
            const currentMemberId = Number(id)
            setClickedButtonId(currentMemberId);
            setCurrentMembersIndex(() => filteredMembers.findIndex(member => member.id === currentMemberId) || 0);
        }
    }, [filteredMembers]) // eslint-disable-line react-hooks/exhaustive-deps

    // navigate to selected member
    useEffect(() => {
        const memberId = filteredMembers[currentMembersIndex]?.id;
        const timeout = setTimeout(() => {
            if (memberId) {
                navigate(`${memberId?.toString()}`);
            }
        }, 300)

        return () => clearTimeout(timeout)
    }, [currentMembersIndex]);

    // scroll to the selected member from the list
    // eslint-disable-next-line
    useEffect(() => {
        if (ref.current !== null && isInitialLoad) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest"
            });
            setIsInitialLoad(false)
        }
    }, [filteredMembers, ref.current]);

    const handleOnKeyDown = (event: React.KeyboardEvent<Scrollbars>) => {
        if (event.key === 'ArrowDown') {
            handleOnArrowDown()
        }

        if (event.key === 'ArrowUp') {
            handleOnArrowUp()
        }
    }

    const handleOnArrowUp = () => {
        const nextIndex = currentMembersIndex - 1;
        if (filteredMembers[nextIndex]?.id) {
            handleOnMemberSelect(filteredMembers[nextIndex].id)
        }
    }

    const handleOnArrowDown = () => {
        const nextIndex = currentMembersIndex + 1;
        if (filteredMembers[nextIndex]?.id) {
            handleOnMemberSelect(filteredMembers[nextIndex].id)
        }
    }

    const handleOnMemberSelect = (memberId: number) => {
        setClickedButtonId(memberId)
        setCurrentMembersIndex(() => filteredMembers.findIndex(m => m.id === memberId) || 0);
    }

    // TODO: style -> ?
    return (
        <div className="scroll-member">
            <Scrollbars id="containerElement"
                        className={"scroll-member"}
                        onKeyDown={handleOnKeyDown}
                        universal={true}
            >
                {filteredMembers.map((member: MemberOnTenant) => (
                    <button
                        ref={member.id === clickedButtonId ? ref : null}
                        key={"/" + member.id}
                        className={clickedButtonId === member.id
                            ? "scroll-member__button--clicked"
                            : "scroll-member__button"
                        }
                        // onMouseOver={() => member.id !== clickedButtonId && onMouseOver(member.id)}
                        // onMouseOut={() => onMouseOut()}
                        onMouseOver={() => handleOnMemberSelect(member.id)}
                    >
                        {member.company_name}
                    </button>
                ))}
            </Scrollbars>
        </div>
    )
}

export default MembersList;

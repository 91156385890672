import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";

import Layout from "../../components/Layout";
import {ENDPOINTS, permissionsCatalog} from "../../constants";
import {useAPI} from "../../utils/hooks/useAPI";
import {DomainListInterface} from "../../interfaces";
import {RedirectContext} from "../../store/context/redirect-context";
import {UserPermissionContext} from "../../store/context/user-permission-context";
import PathContext from "../../store/context/path-context";
import {SSMemberContext} from "../../store/context/self-serve-member-user";
import {ThemeContext} from "../../store/context/theme-context";
import {useTitle} from "../../utils/hooks/useTitle";
import jwt from "jwt-decode";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import {notifyError} from "../../components/utils/ToastNotifications/Notifier";
import useIsMobile from "../../utils/hooks/useIsMobile";

import './Main.scss';

const Main = () => {
    const api = useAPI();
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const {user, loginWithRedirect, isLoading, getAccessTokenSilently} = useAuth0();
    const {pathname} = useContext(RedirectContext);
    const {apiUrlProps: {tenant}} = useContext(PathContext);
    const isSSTenant = tenant === 'ss';
    const {userPermissions} = useContext(UserPermissionContext);
    const {memberIds} = useContext(SSMemberContext);
    const [domainList, setDomainList] = useState<DomainListInterface[]>();
    const [domainListLoading, setDomainListLoading] = useState<boolean>();
    const currentThemeProps = useContext(ThemeContext)

    useTitle(`Vulnerability IQ report by ${currentThemeProps?.name}`);

    useEffect(() => {
        (async () => {
            if (!isLoading && user) {
                if (await shouldRedirectToMemberOnboarding()) {
                    navigate('/members/onboarding/');
                    return;
                }

                if (memberIds.length) {
                    getDomainList(memberIds[0]);
                }
            }
        })();
    }, [api, isLoading, user, memberIds]);


    const shouldRedirectToMemberOnboarding = async () => {
        const accessToken = await getAccessTokenSilently();
        const decodedToken = jwt<{ "https://user.info/isOnboarded": string }>(accessToken);
        let isOnboarded = decodedToken?.['https://user.info/isOnboarded'];
        if (isOnboarded === undefined) {
            return false;
        }

        isOnboarded = JSON.parse(isOnboarded.toLowerCase());

        return isSSTenant && !isOnboarded;
    }

    const getDomainList = (memberId: number) => {
        api.get(ENDPOINTS.DOMAINS_SCORE.get_or_create_domain(memberId)).then(response => {
            setDomainList(response.data);
            setDomainListLoading(false);
        }).catch(() => {
            setDomainListLoading(false);
            notifyError('Something went wrong. Please try again later.')
        });
    }


    const dashboardRedirectHandler = () => {
        navigate('/admin/engagement/');
    }

    const domainRedirectHandler = (domain: string) => {
        if (memberIds.length && !userPermissions.includes(permissionsCatalog.read_dashboard)) {
            navigate(`members/${memberIds[0]}/reports/${domain}`);
            return;
        }

        navigate(`/reports/${domain}`);
    }

    return (
        <Layout>
            <article className="main_page">
                <div className='main_page__header'>
                    {currentThemeProps?.mainPageSettings.filter(obj => obj.element === 'title')[0]
                      .text.split('\n')
                      .map((item, i) =>
                      <p
                        style={{margin: '0.4rem'}}
                        key={i}
                      >
                          {item}
                      </p>
                    )}
                </div>
                <div className='main_page__text'>
                    {currentThemeProps?.mainPageSettings.filter(obj => obj.element === 'paragraph')[0]
                      .text.split('\n')
                      .map((item, i) =>
                      <p
                        style={{margin: '0.4rem'}}
                        key={i}
                      >
                          {item}
                      </p>
                    )}
                </div>
                <div className="main_page__auth">
                    {!user && (
                        <div className={`flex-auto ${isMobile && 'text-center'}`}>
                            <Button
                                className="main_page__login-button"
                                style={{marginRight: '10px', backgroundColor: currentThemeProps?.buttons.mainButtonsColor}}
                                variant="contained"
                                size="large"
                                onClick={() => loginWithRedirect(
                                    {
                                        appState: {
                                            returnTo: pathname
                                        }
                                    }
                                )}
                            >
                                Login
                            </Button>
                            {
                              isSSTenant &&
                              <Button
                                className="main_page__login-button"
                                style={{backgroundColor: currentThemeProps?.buttons.mainButtonsColor}}
                                variant="contained"
                                size="large"
                                onClick={() => loginWithRedirect(
                                  {
                                      appState: {
                                          returnTo: pathname
                                      }
                                  }
                                )}
                              >
                                  Sign up
                              </Button>
                            }
                        </div>
                    )}
                    {user && userPermissions.includes(permissionsCatalog.read_dashboard) && (
                        <Button
                            className="main_page__login-button"
                            style={{backgroundColor: currentThemeProps?.buttons.mainButtonsColor}}
                            variant="contained"
                            size="large"
                            onClick={dashboardRedirectHandler}
                        >
                            Dashboard
                        </Button>
                    )}
                    {user && !userPermissions.includes(permissionsCatalog.read_dashboard) && (
                      <div>
                          {
                              domainListLoading ?
                                <LoadingSpinner/>
                                :
                                <>
                                    <h3>Your domains:</h3>
                                    { !domainList?.length
                                      ? <p>Sorry, there are currently no domains associated with your account.</p>
                                      : (
                                        <>
                                            {domainList?.map((item) => {
                                                return <p key={item.id} className="main_page__domain"
                                                          onClick={domainRedirectHandler.bind(this, item.domain)}
                                                >
                                                    {item.domain}
                                                </p>
                                            })}
                                        </>
                                      )}
                                </>
                          }
                        </div>
                    )}
                </div>
            </article>
        </Layout>
    )
}

export default Main;

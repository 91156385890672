import React, {Dispatch, useState} from "react";
import {TenantUserProps} from "../../interfaces";

export type TenantUserPropsContextType = {
  tenantUserProps?: TenantUserProps;
  setTenantUserProps?: Dispatch<React.SetStateAction<TenantUserProps | undefined>>;
}

export const TenantUserPropsContext = React.createContext<TenantUserPropsContextType>({});

export const TenantUserPropsContextProvider: React.FC = ({children}) => {
  const [tenantUserProps, setTenantUserProps] = useState<TenantUserProps>();

  return (
    <TenantUserPropsContext.Provider value={{tenantUserProps, setTenantUserProps}}>
        {children}</TenantUserPropsContext.Provider>
  )
}

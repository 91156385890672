import React, {useContext, useEffect, useMemo} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import FlagIcon from '@mui/icons-material/Flag';

import {ColorNavPathsContext} from "../../../store/context/color-nav-paths-context";
import {MemberOnTenant, NavigationButtonsContextType} from "../../../interfaces";
import './DashboardNavigation.scss';
import {AddTask} from "@mui/icons-material";
import {UserPermissionContext} from "../../../store/context/user-permission-context";
import {useAPI} from "../../../utils/hooks/useAPI";
import {notifyError} from "../../../components/utils/ToastNotifications/Notifier";
import axios, {AxiosError} from "axios";
import Button from "@mui/material/Button";
import {ENDPOINTS, permissionsCatalog} from "../../../constants";
import {TenantPropsContext} from "../../../store/context/tenant-props-context";

// TODO: style -> ?
const DashboardNavigation = ({setIsDrawerToggled, isDrawerToggled}: {setIsDrawerToggled?: React.Dispatch<React.SetStateAction<boolean>>, isDrawerToggled?: boolean}) => {
	const {filterNavButtons, updateNavButtons} = useContext(ColorNavPathsContext) as NavigationButtonsContextType;
	const {userPermissions} = useContext(UserPermissionContext);
	const {tenantProps} = useContext(TenantPropsContext);
	const cancelSource = axios.CancelToken.source();
	const api = useAPI(cancelSource);
	const navigate = useNavigate()
	const {pathname} = useLocation();
	const [members, setMembers] = React.useState<MemberOnTenant[]>();
	const initialNavButton = useMemo(() => {
		if (pathname.split('/')[2]) {
			return pathname
				.split('/')[2]
				.split('-')
				.map(word => word.charAt(0).toUpperCase() + word.slice(1))
				.join(' ');
		}
		return 'Engagement';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const {logout} = useAuth0();

	useEffect(() => {
		//select initial navigation item
		updateNavButtons(initialNavButton);
		handleFirstMemberIdFetching()

		return () => {
			cancelSource.cancel('Cancelling in cleanup');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// handlers
	const handleFirstMemberIdFetching = async () => {
		await api.get(ENDPOINTS.MEMBERS.get_or_create_member)
			.then(data => setMembers(data.data))
			.catch((e: unknown) => {
					if ((e as AxiosError).response?.status === 403) {
						navigate('/access-denied');
						return;
					}
					notifyError("Something went wrong, please try again or contact us at support@edisonmarks.com")
				}
			)
	}

	const handleMemberManagementRedirect = async () => {
		if (members && members.length) {
			navigate(`/admin/member-management/${members[0].id}`, {state: members});
			return;
		}
		navigate('/admin/member-management/', {state: members});
	}

	// when the user click one of the navigation link, the context (ColorNavPathsContext) will update nav buttons and will change their styles.
	return (
		<nav className='nav-dash'>
			<div className='nav-dash__paths'>
				<Link onClick={
					() => {
						updateNavButtons("Engagement")
						setIsDrawerToggled && setIsDrawerToggled(false)
					}
				} to='/admin/engagement/' className={
					filterNavButtons("Engagement")
						? "nav-dash__clicked-path"
						: ""}>
					<QueryStatsIcon className={
						filterNavButtons("Engagement")
							? "nav-dash__icon-clicked"
							: ""}/>
					<div className="nav-dash__link-wrapper">
						<p className="nav-dash__headers">Engagement</p>
					</div>
				</Link>
				<Link onClick={
					() => {
						updateNavButtons("Ticket Tracking")
						setIsDrawerToggled && setIsDrawerToggled(false)
					}
				} to='/admin/ticket-tracking/' className={
					filterNavButtons("Ticket Tracking")
						? "nav-dash__clicked-path"
						: ""}>
					<AddTask className={
						filterNavButtons("Ticket Tracking")
							? "nav-dash__icon-clicked"
							: ""}/>
					<div className="nav-dash__link-wrapper">
						<p className="nav-dash__headers">Ticket Tracking</p>
					</div>
				</Link>
				<Link onClick={
					() => {
						updateNavButtons("Landing Page Stats")
						setIsDrawerToggled && setIsDrawerToggled(false)
					}
				} to='/admin/landing-page-stats/' className={
					filterNavButtons("Landing Page Stats")
						? "nav-dash__clicked-path"
						: ""}>
					<NoteAddIcon className={
						filterNavButtons("Landing Page Stats")
							? "nav-dash__icon-clicked"
							: ""}/>
					<div className="nav-dash__link-wrapper">
						<p className="nav-dash__headers">Landing Page Stats</p>
					</div>
				</Link>
				<Link onClick={
					() => {
						updateNavButtons("Member Response Stats")
						setIsDrawerToggled && setIsDrawerToggled(false)
					}
				} to='/admin/member-response-stats/' className={
					filterNavButtons("Member Response Stats")
						? "nav-dash__clicked-path"
						: ""}>
					<AssessmentOutlinedIcon className={
						filterNavButtons("Member Response Stats")
							? "nav-dash__icon-clicked"
							: ""}
					/>
					<div className="nav-dash__link-wrapper">
						<p className="nav-dash__headers">Member Response Stats</p>
					</div>
				</Link>
				{
					userPermissions.includes(permissionsCatalog.read_tenants) &&
					<Link onClick={
						() => {
							updateNavButtons("Campaign Management")
							setIsDrawerToggled && setIsDrawerToggled(false)
						}
					} to='/admin/campaign-management/' className={
						filterNavButtons("Campaign Management")
							? "nav-dash__clicked-path"
							: ""}>
						<MailOutlineIcon className={
							filterNavButtons("Campaign Management")
								? "nav-dash__icon-clicked"
								: ""}
						/>
						<div className="nav-dash__link-wrapper">
							<p className="nav-dash__headers">Campaign Management</p>
						</div>
					</Link>
				}
				{ tenantProps && !tenantProps?.is_self_serve &&
          <Link onClick={
            () => {
              updateNavButtons("Campaign Stats")
              setIsDrawerToggled && setIsDrawerToggled(false)
            }
          } to='/admin/campaign-stats/' className={
            filterNavButtons("Campaign Stats")
              ? "nav-dash__clicked-path"
              : ""}>
            <MailOutlineIcon className={
              filterNavButtons("Campaign Stats")
                ? "nav-dash__icon-clicked"
                : ""}
            />
            <div className="nav-dash__link-wrapper">
              <p className="nav-dash__headers">Campaign Stats</p>
            </div>
          </Link>
				}
				{
					userPermissions.includes(permissionsCatalog.read_tenants) &&
					<Link onClick={
						() => {
							updateNavButtons("Admin Campaign Stats")
							setIsDrawerToggled && setIsDrawerToggled(false)
						}
					} to='/admin/admin-campaign-stats/' className={
						filterNavButtons("Admin Campaign Stats")
							? "nav-dash__clicked-path"
							: ""}>
						<MailOutlineIcon className={
							filterNavButtons("Admin Campaign Stats")
								? "nav-dash__icon-clicked"
								: ""}
						/>
						<div className="nav-dash__link-wrapper">
							<p className="nav-dash__headers">Admin Campaign Stats</p>
						</div>
					</Link>
				}
				{
					userPermissions.includes(permissionsCatalog.read_tenants) &&
					<Link onClick={
						() => {
							updateNavButtons("Flags")
							setIsDrawerToggled && setIsDrawerToggled(false)
						}
					} to='/admin/flags/' className={
						filterNavButtons("Flags")
							? "nav-dash__clicked-path"
							: ""}>
						<FlagIcon className={
							filterNavButtons("Flags")
								? "nav-dash__icon-clicked"
								: ""}
						/>
						<div className="nav-dash__link-wrapper">
							<p className="nav-dash__headers">Flags</p>
						</div>
					</Link>
				}
			</div>
			<div className={filterNavButtons("Member Management")
				? "nav-dash__paths--active"
				: "nav-dash__paths--inactive"
			}>
				<div
					style={{textDecoration: "none", cursor: "pointer"}}
					//to="/admin/member-management/99"
					onClick={
						() => {
							handleMemberManagementRedirect()
							updateNavButtons("Member Management");
							setIsDrawerToggled && setIsDrawerToggled(false);
						}
					}>
					<div className="nav-dash__link-wrapper">
                        <span className={filterNavButtons("Member Management")
							? "nav-dash__paths--link-active"
							: "nav-dash__paths--link-inactive"
						}>
                            Member Management
                        </span>
					</div>
				</div>
			</div>
			<div className="nav-dash__menubar--margin">
				{isDrawerToggled && <Button
					variant="contained"
					size="large"
					className="header-rep__logout header-rep__logout--margin"
					onClick={() => logout({
						returnTo: window.location.origin
					})}
				>
					Logout
				</Button>}
			</div>
		</nav>
	)
}

export default DashboardNavigation;

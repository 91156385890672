import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import jwt from "jwt-decode";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import {UserPermissionsProps} from "../../interfaces";

export const UserPermissionContext = React.createContext<UserPermissionsProps>({
    userPermissions: [],
    isSetUp: false
})

export type JwtPayload = {
	permissions: string[],
	requiresRelogin: boolean,
	'https://*.edisonmarks.com/tenants': Array<string>
}

const audience = process.env.NODE_ENV === 'development'
	? process.env.REACT_APP_DEV_AUTH0_AUDIENCE
	: process.env.REACT_APP_PROD_AUTH0_AUDIENCE;

export const UserPermissionContextProvider: React.FC = ({children}) => {
	const {isLoading, getAccessTokenSilently, user} = useAuth0();
	const [permissions, setPermissions] = useState<string[]>([]);
	const [isInitialLoad, setIsInitialLoad] = useState(true)
	const [isSetUp, setIsSetUp] = useState(false)

	useEffect(() => {
		if (!isLoading) {
			setUserPermissionHandler();
		}
	}, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

	const setUserPermissionHandler = async () => {
		if (user) {
			const accessToken = await getAccessTokenSilently({
				ignoreCache: true,
				audience,
			})

			const data = jwt<JwtPayload>(accessToken);
			const permissions = data.permissions
			setPermissions(permissions);
		}
		if (isInitialLoad && !user) {
			setIsInitialLoad(false)
		}
		setIsSetUp(true)
	}

    return (
        isSetUp ?
            <UserPermissionContext.Provider value={{isSetUp: isSetUp, userPermissions: permissions}}>
                {children}
            </UserPermissionContext.Provider>
            : <LoadingSpinner/>
    )
}

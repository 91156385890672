import React from 'react';
import {CSSTransition, SwitchTransition} from "react-transition-group";
import cyberAttacks from "../../../assets/images/cyber-onboarding.png";
import './LoaderWithFacts.scss';
import YellowLoader from "../YellowLoader/YellowLoader";

const facts = [
  [
    '<b>95%</b> of cyber breaches are due to unpatched software.',
    '<b>80%</b> of data breaches involve weak passwords.',
    '<b>60%</b> of small businesses close within six months of a cyberattack.',
    '<b>70%</b> of Wi-Fi networks are vulnerable to attacks.',
    'Phishing attacks account for <b>90%</b> of data breaches.',
    'Firewalls can block <b>99%</b> of external threats.',
    'Cybersecurity training reduces risks by <b>50%</b>.',
    'Data encryption reduces breach costs by <b>70%</b>.',
    'Two-factor authentication stops <b>99.9%</b> of unauthorized logins.',
    '<b>89%</b> of malware is detected by reputable antivirus software.'
  ],
  [
    'Ctrl+Alt+Delete: Cyber panic button.',
    'Why do hackers prefer dark mode? Less visibility!',
    "Cybersecurity: Don't trust atoms, they make up everything!",
    "I told a cybersecurity joke, but you can't decrypt it!",
    '404 Error: Humor not found in cyberspace.',
    'Why was the computer cold? It left its Windows open!',
    'Why did the cybersecurity expert cross the road? To patch a vulnerability!',
    'Cybersecurity: The only place you get paid for stopping accidents.',
    'Cybercriminals: Hiding behind CTRL+ALT+DELusions!',
    'Why did the hacker break up? They lost all their passwords!'
  ]
];

type Props = {
  domain: string;
  title: string;
}

const LoaderWithFacts = ({domain, title}: Props) => {
  const [currentFact, setCurrentFacts] = React.useState<string>(facts[Math.floor(Math.random() * facts.length)][0]);

  const currentFactArrIndex = React.useMemo(() => {
    return facts.findIndex((factArr) => factArr.includes(currentFact))
  }, [currentFact]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      let currentFactArrIndex = facts.findIndex((factArr) => factArr.includes(currentFact));
      let nextFactSubArrIndex = facts[currentFactArrIndex].indexOf(currentFact) + 1;

      if (nextFactSubArrIndex >= facts[currentFactArrIndex].length) {
        currentFactArrIndex = currentFactArrIndex ? 0 : 1;
        nextFactSubArrIndex = 0
      }

      // take turns between did you know and cant be serious
      setCurrentFacts(currentFactArrIndex
        ? facts[currentFactArrIndex - 1][nextFactSubArrIndex]
        : facts[currentFactArrIndex + 1][nextFactSubArrIndex]
      );
    }, 10000);

    return () => clearTimeout(timer);
  }, [currentFact]);

  return (
    <section>
      <article>
        <h4 className="text-xl mb-0 font-normal quicksand">
          Analyzing...
        </h4>
        <p className="text-lg font-bold">
          {domain}
        </p>
        <p className="text-sm font-normal">
          {title}
        </p>
        <YellowLoader/>
        <p className="quicksand">loading...</p>
      </article>

      <article>
        <h4 className="mt-16 text-xl font-normal ">
          <SwitchTransition>
            <CSSTransition
              key={currentFactArrIndex}
              timeout={500}
              classNames="app_analyze__fact"
              unmountOnExit
            >
              <p className="quicksand">{currentFactArrIndex ? 'Cause it can\'t all be serious...' : 'Did you know ...'}</p>
            </CSSTransition>
          </SwitchTransition>
          <div className="flex flex-col items-center xl:flex-row xl:items-start">
            <img style={{height: '177px', maxWidth: '220px'}} src={cyberAttacks} alt="Cyberattack"/>
            <ul>
              <SwitchTransition>
                <CSSTransition
                  key={currentFact}
                  timeout={500}
                  classNames="app_analyze__fact"
                  unmountOnExit
                >
                  <li className="text-start text-base leading-5"
                      dangerouslySetInnerHTML={{__html: currentFact}}
                  />
                </CSSTransition>
              </SwitchTransition>
            </ul>
          </div>
        </h4>
      </article>
    </section>
  );
};

export default LoaderWithFacts;
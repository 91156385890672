import React from "react";
import {useNavigate} from "react-router-dom";
import {AppState, Auth0Provider} from "@auth0/auth0-react";

const Auth0ProviderWithHistory: React.FC = ({children}) => {

    const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
    const clientId = process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_DEV_AUTH0_CLIENTID
        : process.env.REACT_APP_PROD_AUTH0_CLIENTID;
    const audience = process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_DEV_AUTH0_AUDIENCE
        : process.env.REACT_APP_PROD_AUTH0_AUDIENCE;

    const navigate = useNavigate();

    const onRedirectCallback = (appState: AppState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={domain}
            useRefreshTokens={true}
            clientId={clientId!}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={audience}
            scope="offline_access"
        >
            {children}
        </Auth0Provider>
    )
}

export default Auth0ProviderWithHistory;
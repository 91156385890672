import React, {useContext} from 'react';
import {useParams} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {AxiosError} from "axios";
import {Form, Formik, FormikValues} from "formik";
import {Button, Tooltip, Typography} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {useMutation} from "@tanstack/react-query";

import FormikMUISimpleInput from "../../../components/Formik/MUISimpleInput/FormikMUISimpleInput";
import {ENDPOINTS} from "../../../constants";
import {notifyError, notifySuccess} from "../../../components/utils/ToastNotifications/Notifier";
import {Auth0UserNameContext} from "../../../store/context/auth0-user-name";
import {useAPI} from "../../../utils/hooks/useAPI";

const TenantUserAccount = ({setUserName, userName}: {
  setUserName: React.Dispatch<React.SetStateAction<string | undefined>>,
  userName: string | undefined
}) => {
  const {tenant_id: tenantId, tenant_user_id: tenantUserId} = useParams()
  const api = useAPI();
  const {user} = useAuth0();
  const userProvider = user?.sub?.split('|')[0]
  const {auth0UserNameProps} = useContext(Auth0UserNameContext)

  const {mutate: mutateAccProps} = useMutation({
    mutationFn: handleChangingTenantUser,
    onError: (error) => notifyError((error as AxiosError).response?.data?.message || 'Something went wrong please try again later or contact us.')
  })

  async function handleChangingTenantUser(values: FormikValues) {
    return api.patch(ENDPOINTS.TENANTS.edit_tenant_user(tenantId, tenantUserId), values)
      .then((response) => {
        if (response.status === 200) {
          setUserName(values.name)
          notifySuccess('You have successfully changed your data.');
        }
      });
  }

  return (
    <section className="w-1/2">
      <h3>Profile</h3>
      <p>Change your user details.</p>
      <Formik initialValues={{
        name: auth0UserNameProps ? auth0UserNameProps : userName ? userName : ''
      }}
              enableReinitialize
              onSubmit={(values: FormikValues, {}) => mutateAccProps(values)}
      >
        {({errors, touched, isValid}) => {
          return (
            <Form className="flex flex-col mt-6">
              <div className="mb-4 mt-1 w-11/12 items-center relative">
                <FormikMUISimpleInput error={!!errors.name} label="Name" name="name"
                                      size="small" disabled={userProvider !== "email"}/>
                {userProvider !== "email" &&
                    <Tooltip className="absolute mt-2 ml-2"
                             title={<Typography fontSize={"0.9rem"}>You cannot change your name due to being logged
                               in
                               via social account (ex: google).</Typography>}>
                        <InfoOutlinedIcon className="app__ss-account-icon"/>
                    </Tooltip>}
              </div>
              {userProvider === "email" &&
                  <div className="mb-16 flex justify-center">
                      <Button className="!font-bold !text-lg !px-7 !rounded-lg bg-orange mulish" type="submit"
                              size="large" variant="contained"
                              disabled={!isValid || (Object.keys(touched).length === 0 && touched.constructor === Object)}
                      >
                          submit changes
                      </Button>
                  </div>}
            </Form>
          )
        }}
      </Formik>
    </section>
  );
};

export default TenantUserAccount;

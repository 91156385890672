import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {Avatar, Box, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

import SideDrawer from "../../../components/Layout/SideDrawer/SideDrawer";
import {emUrl} from "../../../constants";
import EmAdminNavigation from "../EmAdminNavigation/EmAdminNavigation";
import {TenantPropsContext} from "../../../store/context/tenant-props-context";
import {TenantUserPropsContext} from "../../../store/context/tenant-user-props-context";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import "../../Dashboard/DashboardHeader/DashboardHeader.scss"

const EmAdminHeader = () => {
  const {user, logout} = useAuth0()
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const {tenantProps} = useContext(TenantPropsContext);
  const {tenantUserProps} = useContext(TenantUserPropsContext);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [isDrawerToggled, setIsDrawerToggled] = React.useState(false);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return <header className="dash-header">
    <div className="dash-header__img-wrapper">
      <img
        className="dash-header__img"
        src="https://media-exp1.licdn.com/dms/image/C4D0BAQHusJQncW_zFA/company-logo_200_200/0/1628534469879?e=2159024400&v=beta&t=lYhp_JbOYwycaneTIwWk4bT7XL0SWqsCneq755G92y4"
        alt="Edison Marks"
      />
      <h3 className="dash-header__title">Administration</h3>
    </div>
    <div className="dash-header__logout flex">
      <div className="flex items-center">
        <Box sx={{flexGrow: 0}}>
          <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
            <Avatar alt="Avatar"
                    src={user?.picture}/>
          </IconButton>
          <Menu
            sx={{mt: '45px'}}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {tenantProps && tenantProps.id && tenantUserProps && tenantUserProps.id &&
                <MenuItem onClick={() => {
                  handleCloseUserMenu()
                  navigate(`/tenants/${tenantProps?.id}/settings/${tenantUserProps?.id}/profile/`)
                }}
                          key="Settings"
                >
                    <SettingsIcon className="me-2"/>
                    <Typography textAlign="center">Settings</Typography>
                </MenuItem>
            }

            <MenuItem onClick={() => {
              handleCloseUserMenu()
              logout({returnTo: emUrl})
            }}
                      key="Sign out"
            >
              <ExitToAppIcon className="me-2"/>
              <Typography textAlign="center">Sign out</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </div>
      {
        isMobile &&
          <Button
              variant="outlined"
              style={{border: 0, minWidth: '45px', minHeight: '40px'}}
              sx={{padding: 1}}
              onClick={() => setIsDrawerToggled(prevState => !prevState)}
          >
              <MenuRoundedIcon sx={{color: '#2e3192'}}/>
          </Button>
      }
      <SideDrawer isDrawerToggled={isDrawerToggled} setIsDrawerToggled={setIsDrawerToggled}>
        <EmAdminNavigation setIsDrawerToggled={setIsDrawerToggled}/>
      </SideDrawer>
    </div>
  </header>
}

export default EmAdminHeader;
import React, {useContext, useEffect} from 'react';
import Downloader from "../utils/Downloader";
import {useAuth0} from "@auth0/auth0-react";
import {useAPI} from "../../utils/hooks/useAPI";
import {RedirectContext} from "../../store/context/redirect-context";
import {notifyError, notifySuccess} from "../utils/ToastNotifications/Notifier";
import {ENDPOINTS} from "../../constants";

type DownloadTechReportTypes = {
	domainId: number
	scrollId?: number
	onComplete?: () => void
	onError?: () => void
	setIsClickedTechReportButton: React.Dispatch<React.SetStateAction<boolean>>
}

const DownloadTechReport = ({
								domainId,
								onComplete,
								onError,
								scrollId,
								setIsClickedTechReportButton
							}: DownloadTechReportTypes) => {
	const [techReportLink, setTechReportLink] = React.useState(['']);
	const [isReportLinkQueried, setIsReportLinkQueried] = React.useState(false);

	const api = useAPI();
	const {user, loginWithRedirect} = useAuth0();
	const {pathname} = useContext(RedirectContext);

	useEffect(() => {
		(async () => {
			if (!user) {
				localStorage.setItem('modal', 'active');
				scrollId && localStorage.setItem('scrollId', scrollId.toString());

				await loginWithRedirect({
					appState: {
						returnTo: pathname
					}
				});
				return;
			}
			await handleDownloadingTechReport(domainId);
		})()
	}, [user])

	const handleDownloadingTechReport = async (domain_id: number) => {
		api.get(ENDPOINTS.DOMAINS_SCORE.get_pdf_report_link(domain_id))
			.then(response => {
				setTechReportLink([response.data.link]);
				setIsReportLinkQueried(prevState => !prevState);
				notifySuccess('Success.');

				onComplete && onComplete();

				//in case you click download tech report several times one after another
				setTimeout(() => {
					setIsClickedTechReportButton(prevState => !prevState);
					setIsReportLinkQueried(prevState => !prevState);
				}, 1000);
			})
			.catch((error) => {
				setIsClickedTechReportButton(prevState => !prevState);
				setIsReportLinkQueried(prevState => prevState ? !prevState : prevState);
				notifyError(error?.response?.data?.detail ? error?.response?.data?.detail : 'Something went wrong. Please try again later.');

				onError && onError();
			})
	}

	return (
		<>
			{isReportLinkQueried &&
				<Downloader
					fileNames={techReportLink}
				/>
			}
		</>
	);
};

export default DownloadTechReport;
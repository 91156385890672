import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import Layout from "../Layout";
import './ErrorPage.scss';


type ErrorsType = {
    errors: { className: string, text: string }[]
}

const ErrorPage = ({errors}: ErrorsType) => {
    // TODO: style ?
    return (
        <Layout>
            <article className="not_found_text-content">
                <SentimentVeryDissatisfiedIcon style={{fontSize: '10rem', color: '#555555', textAlign: 'center'}}/>
                {errors.map((message) => {
                    return <p className={message.className}>{message.text}</p>
                })}
            </article>
        </Layout>
    )
}

export default ErrorPage;
import React from 'react';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import GenerateTicket from "./GenerateTicket";
import {dataTestIds} from "../../constants/data-test-ids";

type ModalRecOverviewProps = {
	domain: string,
	ticketData?: {
		btnType: string,
		scrollId: string
	},
	fixedIssues: number,
	unResolvedIssues: number
}

const ModalRecOverview = ({domain, fixedIssues, ticketData, unResolvedIssues}: ModalRecOverviewProps) => {
	return (
		<section data-testid="viq-overview" className="modal_recommendations_bg">
			<article className="p-6">
				<h4 className="mb-6 font-normal text-xl leading-6 text-center">{fixedIssues ? 'Well done!' : ''}</h4>
				<p className="flex justify-center items-center mb-6 text-lg">
					{fixedIssues ? <TagFacesIcon className="text-orange me-1"/> : <SentimentVeryDissatisfiedIcon className="text-orange me-1"/>}
					<span>You fixed <b data-testid={dataTestIds.viq.resolvedIssues}>{fixedIssues}</b> {fixedIssues === 1 ? 'issue' : 'issues'}!</span>
				</p>
				<article className="text-center mb-6">
					<p className="mb-6">We are
						detecting <b data-testid={dataTestIds.viq.unresolvedIssues}>{unResolvedIssues}</b> more {unResolvedIssues === 1 ? 'issue' : 'issues'} that {unResolvedIssues === 1 ? 'needs' : 'need'} attention.
					</p>
					{/*<Button onClick={props.nextStep} variant="contained">SEND ME AN EMAIL TO REMIND ME</Button>*/}
				</article>
			</article>
			<GenerateTicket domain={domain} ticketData={ticketData}/>
		</section>
	);
};

export default ModalRecOverview;
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";

import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import DashboardComponent from "../../../components/DashboardComponent/DashboardComponent";
import {ENDPOINTS, permissionsCatalog} from "../../../constants";
import {Tenant} from "../CampaignStats/AdminCampaignStats";
import {UserPermissionContext} from "../../../store/context/user-permission-context";
import {useAPI} from "../../../utils/hooks/useAPI";
import './CampaignManagement.scss';

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MenuProps: any = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center"
    },
    variant: "menu"
};

const CampaignManagement: React.FC = () => {
    const {userPermissions} = useContext(UserPermissionContext);
    const api = useAPI();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [tenants, setTenants] = useState<Array<Tenant>>();
    const [templates, setTemplates] = useState<string[]>([]);
    const [selectedTenant, setSelectedTenant] = useState<string>("");
    const [selectedTemplate, setSelectedTemplate] = useState<string>("");
    const [campaignName, setCampaignName] = useState<string>("");
    const [isSending, setIsSending] = useState<boolean>(false)
    const [selectedMembers, setSelectedMembers] = useState<Array<string> | undefined>([]);
    const [members, setMembers] = useState<Array<{id: number, company_name: string}>>([])
    const isAllSelected = members.length > 0 && selectedMembers!.length === members.length;

    const handleSelectedMembersChange = async (event: SelectChangeEvent<string[]>) => {
        const value: any = event.target.value;
        console.log(value);
        if (value[value.length - 1] === "all") {
            setSelectedMembers(selectedMembers!.length === members.length ? [] : members.map(({ id, company_name}) => company_name));
            return;
        }
        setSelectedMembers([...value]);
    };

    useEffect(() => {
        if (!userPermissions.includes(permissionsCatalog.read_tenants)) {
            navigate("/access-denied");
            return;
        }
    }, [userPermissions]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!tenants) {
            initData()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedTenant !== "") {
            setSelectedMembers([])
            setSelectedTemplate("");
            updateMembers();
            updateTemplates();
        }
    }, [selectedTenant]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleTenantChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        await setSelectedTenant(event.target.value);
    };

    const handleTemplateChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        await setSelectedTemplate(event.target.value);
    };

    async function updateMembers() {
        const {data} = await api.get(ENDPOINTS.TENANTS.get_members_names_by_tenant(selectedTenant));
        setMembers(data);
    }

    async function updateTemplates() {
        const {data} = await api.get(ENDPOINTS.EMAILS.get_email_templates(selectedTenant));
        setTemplates(data);
    }

    async function initData() {
        setLoading(true);

        const {data} = await api.get(ENDPOINTS.TENANTS.get_all_tenants);

        setTenants(data);
        setLoading(false);
    }

    return (
        <DashboardComponent>
            <article className="campaign-management">
                {
                    loading || !tenants ?
                        <LoadingSpinner/>
                        :
                        <>
                        <div className="campaign-management__fields">
                            <TextField
                                select
                                label="Tenant"
                                helperText="Please select your tenant"
                                size={"small"}
                                value={selectedTenant}
                                onChange={handleTenantChange}
                                style={{marginRight: 10}}
                                sx={{ width: 200 }}
                            >
                                {tenants && tenants.map((tenant, index) => (
                                    <MenuItem key={index} value={tenant.name}>
                                        {tenant.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                label="Template"
                                helperText="Please select your template"
                                size={"small"}
                                value={selectedTemplate}
                                onChange={handleTemplateChange}
                                style={{marginRight: 10}}
                                sx={{ width: 200 }}
                            >
                                {templates.map((template, index) => (
                                    <MenuItem key={index} value={template}>
                                        {template}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <FormControl className="campaign-management__member-selection" size={"small"} fullWidth={false}
                                         style={{width: 200, marginRight: 10}}>
                                <InputLabel id="mutiple-select-label">Select members</InputLabel>
                                <Select
                                    labelId="mutiple-select-label"
                                    label={"Select members"}
                                    multiple
                                    value={selectedMembers}
                                    onChange={handleSelectedMembersChange}
                                    renderValue={() => selectedMembers!.join(", ")}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem
                                        value="all"
                                        classes={{
                                            root: isAllSelected ? "selectedAll" : ""
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                classes={{ indeterminate: "indeterminateColor" }}
                                                checked={isAllSelected}
                                                indeterminate={
                                                    selectedMembers!.length > 0 && selectedMembers!.length < members.length
                                                }
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: 'selectAllText' }}
                                            primary="Select All"
                                        />
                                    </MenuItem>
                                    {members.map(({id, company_name}) => (
                                        <MenuItem key={id} value={company_name}>
                                            <ListItemIcon>
                                                <Checkbox checked={selectedMembers!.indexOf(company_name) > -1} />
                                            </ListItemIcon>
                                            <ListItemText primary={company_name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>Please select members</FormHelperText>
                            </FormControl>
                            <TextField label={'Campaign name'} value={campaignName} size={"small"}
                                       helperText={"Please enter campaign name"} autoComplete={'off'}
                                       onChange={event => setCampaignName(event.target.value)}
                                       sx={{ width: 200 }}/>
                            </div>
                            <div>
                            <Button disabled={selectedTemplate === "" || campaignName === "" || selectedMembers!.length < 1 || isSending}
                                    onClick={async () => {
                                        setIsSending(true)
                                        await toast.promise(
                                            api.post('email-messaging/campaigns/', {
                                                'tenant': selectedTenant,
                                                'template': selectedTemplate,
                                                'campaign_name': campaignName,
                                            'selected_members': selectedMembers
                                        }),
                                            {
                                                pending: 'Sending emails...',
                                                success: "Emails sent successfully",
                                                error: "Something went wrong"
                                            }
                                        ).finally(() => setIsSending(false))
                                    }}
                                    variant={"text"} size="large" style={{marginTop: 12}}>
                                <span>Start Campaign</span>
                            </Button>
                        </div>
                        </>
                }
            </article>
        </DashboardComponent>
    )
}

export default CampaignManagement;

import React from 'react';
import {MenuItem, Select} from "@mui/material";

const Billing = () => {
	return (
		<section className="w-1/2">
			<h3>Plan</h3>
			<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value='free'
				label="Free"
				sx={{width: '100%'}}
			>
				<MenuItem value='free'>Free</MenuItem>
			</Select>
		</section>
	);
};

export default Billing;
import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PaymentIcon from '@mui/icons-material/Payment';
import {Link, Route, Routes, useLocation} from "react-router-dom";

import Account from "./Account";
import Alerts from "./Alerts";
import Billing from "./Billing";

import './styles.scss';
import useNavSideBar from "../../../utils/hooks/useNavSideBar";
import ErrorPage from "../../../components/ErrorPage/ErrorPage";
import {pageNotFoundConstants, ROUTES} from "../../../constants";

const settings = {
    'account': {
        name: 'Account',
        icon: AccountCircleIcon
    },
    'billing': {
        name: 'Billing',
        icon: PaymentIcon
    },
    'preferences': {
        name: 'Alerts',
        icon: SubscriptionsIcon
    }
}

const initialNavSideValues = Object.values(settings).map(({name: settingName}) => {
    return {
        name: settingName.toLowerCase(),
        isClicked: false
    }
})

interface Setting {
    name: string;
    icon: any
}

const Settings = ({setUserName, userName}: {
    setUserName: React.Dispatch<React.SetStateAction<string | undefined>>,
    userName: string | undefined
}) => {
    const {pathname} = useLocation();

    const [activeSetting, setActiveSetting] = React.useState<keyof typeof settings>(pathname.split('/')[4] as keyof typeof settings)
    const memberId = useLocation().pathname.split('/')[2];
    const {filterNavButtons, updateNavButtons} = useNavSideBar(initialNavSideValues, activeSetting)

    return (
        <section className="app__ss">
            <aside className="app__ss-settings">
                <nav className="app__ss-s-paths">
                    {Object.values(settings).map(({name: settingName, icon: Icon}: Setting, index: number) => {
                        return (
                            <Link key={index}
                                  onClick={() => {
                                      setActiveSetting(settingName.toLowerCase() as keyof typeof settings);
                                      updateNavButtons(settingName.toLowerCase());
                                  }}
                                  to={`/members/${memberId}/settings/${settingName.toLowerCase()}`}
                                  className={filterNavButtons(settingName.toLowerCase())
                                      ? "nav-dash__clicked-path"
                                      : ""
                                  }
                            >
                                <Icon
                                    className={filterNavButtons(settingName.toLowerCase()) ? "nav-dash__icon-clicked custom-icon-class" : "custom-icon-class"}
                                />
                                <div className="nav-dash__link-wrapper">
                                    <p className="nav-dash__headers">{settingName}</p>
                                </div>
                            </Link>
                        )
                    })}
                </nav>
            </aside>
            <article className="app__ss-settings-management flex justify-start">
                <Routes>
                    <Route path={ROUTES.MEMBER_SS.subroutes.SETTINGS.subroutes.ACCOUNT.route} element={<Account userName={userName} setUserName={setUserName}/>}/>
                    <Route path={ROUTES.MEMBER_SS.subroutes.SETTINGS.subroutes.BILLING.route} element={<Billing/>}/>
                    <Route path={ROUTES.MEMBER_SS.subroutes.SETTINGS.subroutes.ALERTS.route} element={<Alerts/>}/>
                    <Route index element={<Account userName={userName} setUserName={setUserName}/>}/>
                    <Route path={ROUTES.ERROR_PAGE.route} element={<ErrorPage errors={pageNotFoundConstants}/>}/>
                </Routes>
            </article>
        </section>
    );
};

export default Settings;
import React, {useContext, useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import moment from "moment/moment";

import {notifyError, notifySuccess} from "../../../../../components/utils/ToastNotifications/Notifier";
import {ENDPOINTS} from "../../../../../constants";
import {Tenant} from "../../../../Dashboard/CampaignStats/AdminCampaignStats";
import DropdownActions, {dropdownActionsTypes} from "./DropdownActions";
import TenantsTableBody from "./TableBody";
import PathContext from "../../../../../store/context/path-context";
import {useAPI} from "../../../../../utils/hooks/useAPI";
import './DropdownActions.scss'

export const tenantActions = {
  'delete': {
    name: 'Delete selected Clients (Tenants)',
    endpoint: ''
  },
  'emails': {
    name: 'Email Data Export',
    endpoint: ''
  },
  'scores': {
    name: 'Changed Domain Scoring Export',
    endpoint: ''
  },
  'members': {
    name: 'Data Export',
    endpoint: ''
  },
}

const TenantsTable = () => {
  const {apiUrlProps: {tenant}} = useContext(PathContext)
  const api = useAPI();
  const [selectedTenantsIds, setSelectedTenantsIds] = useState<number[]>([]);
  const [tenants, setTenants] = useState<Array<Tenant>>([]);
  const [tenantsCount, setTenantsCount] = useState<number>(0)

  const {data: response, error, refetch} = useQuery({
    queryKey: ['getTenants'],
    queryFn: getTenants,
    refetchOnWindowFocus: false
  })

  function getTenants() {
    return api.get(ENDPOINTS.TENANTS.get_all_tenants);
  }

  useEffect(() => {
    if (response?.data) {
      setTenants(response.data)
      setTenantsCount(response.data.length)
    }
  }, [response?.data, error])

  const {refetch: refetchExportedData, isFetching} = useQuery({
    queryKey: ['export-tenant-data'],
    queryFn: () => handleFetchingMembersData(),
    enabled: false
  })

  const {refetch: refetchEmailExportedData, isFetching: isEmailRefetching} = useQuery({
    queryKey: ['export-changed-tenant-data'],
    queryFn: () => handleFetchingEmailsData(),
    enabled: false
  })

  const {refetch: refetchChangedExportedData, isFetching: isChangedRefetching} = useQuery({
    queryKey: ['export-changed-tenant-data'],
    queryFn: () => handleFetchingChangedScoresData(),
    enabled: false
  })

  const {mutate: deleteTenants} = useMutation({
    mutationFn: handleDeleteTenants,
    onSuccess: async () => {
      notifySuccess("You successfully deleted selected tenants.")
      await refetch()
    },
    onError: (error) => notifyError((error as AxiosError).response?.data?.message || 'Something went wrong please try again later or contact us.')
  })

  async function handleDeleteTenants() {
    return api.delete(ENDPOINTS.TENANTS.delete_tenants(selectedTenantsIds.toString()));
  }

  const handleFetchingMembersData = () => {
    return api.get(ENDPOINTS.TENANTS.get_tenants_members(selectedTenantsIds.toString()));
  }

  const handleFetchingEmailsData = () => {
    return api.get(ENDPOINTS.TENANTS.get_tenant_email_export(selectedTenantsIds.toString()))
  }

  const handleFetchingChangedScoresData = () => {
    return api.get(ENDPOINTS.TENANTS.get_tenant_members_changed_scores(selectedTenantsIds.toString()))
  }

  const handleDropdownChange = (type: string, tenants: number[]) => {
    if (type === dropdownActionsTypes.deleteTenants) {
      deleteTenants()
    }

    if (type === dropdownActionsTypes.exportEmailData) {
      handleDownloadFile(type, tenants)
    }

    if (type === dropdownActionsTypes.exportData) {
      handleDownloadFile(type, tenants)
    }

    if (type === dropdownActionsTypes.exportNewScores) {
      handleDownloadFile(type, tenants)
    }
  }

  const handleDownloadFile = async (type: dropdownActionsTypes, tenants: number[]) => {
    try {
      const {data} = type === dropdownActionsTypes.exportData
        ? await refetchExportedData()
        : type === dropdownActionsTypes.exportEmailData
        ? await refetchEmailExportedData()
        : await refetchChangedExportedData();


      if (data) {
        let blob = new Blob([(data as any).data], {
          type: "application/pdf"
        });
        const fileURL = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = fileURL;

        downloadLink.download = type === dropdownActionsTypes.exportData
          ? `${tenant}_members_${moment().format('YYYY-MM-DD')}.csv`
          : `${tenant}_members_changed_${moment().format('YYYY-MM-DD')}.csv`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      notifyError('Something went wrong.');
    }
  };

  return (
    <>
      <DropdownActions isLoading={isChangedRefetching || isFetching || isEmailRefetching}
                       onChange={handleDropdownChange} selectedTenantsIds={selectedTenantsIds}
                       tenantsCount={tenantsCount}/>
      <TenantsTableBody
        tenants={tenants}
        selectedTenantsIds={selectedTenantsIds}
        setSelectedTenantsIds={setSelectedTenantsIds}/>
      <div style={{paddingTop: "0.2rem"}}>
        <p
          className="dropdown-actions__selected-tenants-p" style={{margin: 0}}>{tenantsCount} Clients (Tenants)</p>
      </div>
    </>

  )
}

export default TenantsTable;
import {useEffect} from 'react';

export const useTitle = (title: string) => {
	useEffect(() => {
		const prevTitle = document.title;
		document.title = title;

		return () => {
			document.title = prevTitle;
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
};
import moment from "moment";

export function replaceEmptyStringsWithNullsInJSON(values: object): object {
	const jsonObject: any = {...values}
	Object.keys(jsonObject).forEach(
		(key) => {
			if (jsonObject[key] === "") {
				jsonObject[key] = null;
			}
		}
	);
	return jsonObject;
}

export const dateFormatToUniversal = (data: Array<any>) => {
	return data.map((single: any) => {
		const date = moment(single.date, 'DD/MM').format('DD MMM');
		return {...single, date};
	});
}


export const capitalizeFirstLetter = (string: string) => {
	if (string && string.length) {
		return string.replace(/^./, string[0].toUpperCase());
	}

	return string;
}

export const mapBackendErrorToUserFriendlyMessage = (backendError: string) => {
	const errorMap: { [key: string]: string } = {
		'Domain already exists': 'Bummer, looks like this domain is already registered on our platform. Please try again, or contact us at support@edisonmark.com.',
        'Email already registered': 'Bummer, looks like this email is already registered on your tenant. Please try again, or contact us at support@edisonmark.com.'
		// Add more mappings as needed
	};

	return errorMap[backendError] || 'An unexpected error occurred. Please try again later, or contact us at support@edisonmark.com.';
}

export const getRangeFromNumbers = () => {
	// Define the range and number of parts
	const startRange = 300;
	const endRange = 850;
	const numParts = 25;

	// Calculate the step size
	const stepSize = (endRange - startRange) / numParts;

	// Initialize an array to store the objects
	const rangeObjects: {start: number, end: number}[] = [];

	// Iterate over the range and create objects for each part
	for (let i = 0; i < numParts; i++) {
		// Calculate the start and end values for the current part
		const partStart = startRange + i * stepSize;
		const partEnd = i < numParts - 1 ? partStart + stepSize : endRange;

		// Create the object for the current part
		const rangeObject = {
			start: partStart,
			end: partEnd
		};

		// Add the object to the array
		rangeObjects.push(rangeObject);
	}

	return rangeObjects
}
import {forwardRef, HTMLAttributes} from "react";
import {CircularProgress, Grid} from "@mui/material";

interface LoadingSpinnerProps extends HTMLAttributes<HTMLElement> {
    size?: number
}


const LoadingSpinner = forwardRef<any, LoadingSpinnerProps>(({size}, ref) => {
    // TODO -> style ?
    return (
        <Grid
            ref={ref}
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{verticalAlign: 'center'}}
        >
            <Grid item xs={1}>
                <CircularProgress size={size}/>
            </Grid>
        </Grid>
    )
})

export default LoadingSpinner;

import React from 'react';
import { MenuItem, TextField } from "@mui/material";

type MemberSelectProps = {
	selectedMember: {id: number, company_name: string};
	handleMembersChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	membersResponse: { data: {id: number, company_name: string}[] } | undefined;
};


const MemberSelect: React.FC<MemberSelectProps> = ({ selectedMember, handleMembersChange, membersResponse }) => {
	return (
		<TextField
			select
			label="Member"
			helperText="Please select your member"
			size="small"
			value={selectedMember.company_name}
			onChange={handleMembersChange}
			style={{marginRight: 10}}
			sx={{width: 200}}
		>
			{membersResponse ?
				membersResponse.data.map(({id, company_name}) => (
					<MenuItem key={id} value={company_name}>
						{company_name}
					</MenuItem>
				))
				: <span className="ms-2">{selectedMember.company_name}</span>
			}
		</TextField>
	);
};

export default MemberSelect;
import React, {useContext, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import customLocale from 'date-fns/locale/en-US'
import moment from "moment";

import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import {useAPI} from "../../../utils/hooks/useAPI";
import {NavigationButtonsContextType} from "../../../interfaces";
import {ColorNavPathsContext} from "../../../store/context/color-nav-paths-context";
import './MemberResponseStats.scss';
import DashboardComponent from "../../../components/DashboardComponent/DashboardComponent";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import {ENDPOINTS, extraSmallScreen, largeScreen} from "../../../constants";
import DateRangePicker from "../../../components/DatePickers/DateRangePicker";

const DAY_IN_SECONDS = 24 * 3600 * 1000

interface GATableReportEntity {
	member_id: number;
	member: string;
	domain: string;
	page_views: number;
	email_opens: number;
	cta_clicks: number;
	email_clicks: number
}

interface TableSortState {
	criteria: string;
	orderIsDesc: boolean;
}

const MemberResponseStats = () => {
	const isLargeScreen = useIsMobile(largeScreen);
	const api = useAPI();
	const [loading, setLoading] = useState<boolean>();
	const [pageViewsTableData, setPageViewsTableData] = useState<Omit<GATableReportEntity[], "cta_clicks" | "email_clicks">>([]);
	const [pageViewsTableSorting, setPageViewsTableSorting] = useState<TableSortState>({
		criteria: "report_visits",
		orderIsDesc: true
	})
	const [filteredPageViewsData, setFilteredPageViewsData] = useState<GATableReportEntity[]>([]);
	const [ctaTableData, setCTATableData] = useState<Omit<GATableReportEntity[], "page_views" | "email_opens">>([]);
	const [ctaTableSorting, setCTATableSorting] = useState<TableSortState>({
		criteria: "report_ctas",
		orderIsDesc: true
	})
	const [filteredCTATableData, setFilteredCTAData] = useState<GATableReportEntity[]>([]);
	const [inputFilter, setInputFilter] = React.useState('')
	const [startDate, setStartDate] = useState<Date | null>(new Date(Date.now() - 14 * DAY_IN_SECONDS))
	const [endDate, setEndDate] = useState<Date | null>(new Date())
	const {updateNavButtons} = useContext(ColorNavPathsContext) as NavigationButtonsContextType;
	customLocale.options!.weekStartsOn = 1

	useEffect(() => {
		if (!pageViewsTableData.length) {
			initReportData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setFilteredCTAData(ctaTableData);
	}, [ctaTableData])

	useEffect(() => {
		setFilteredPageViewsData(pageViewsTableData);
	}, [pageViewsTableData])


	const initReportData = async () => {
		setLoading(true);
		const {data} = await api.post(ENDPOINTS.GOOGLE_ANALYTICS.get_tables_data);
		await setTablesData(data.page_view_reports, data.cta_reports);
		setLoading(false);
	}

	function onPageViewsTableSortAction(criteria: string) {
		if (pageViewsTableSorting.criteria === criteria) {
			setPageViewsTableSorting(current => {
				return {
					...current,
					orderIsDesc: !current.orderIsDesc
				}
			})
		} else {
			setPageViewsTableSorting({criteria: criteria, orderIsDesc: true})
		}
	}

	useEffect(() => {
		switch (pageViewsTableSorting.criteria) {
			case "report_visits":
				const sortedByPageViews = pageViewsTableData.sort((a, b) => {
					if (pageViewsTableSorting.orderIsDesc) {
						return b.page_views - a.page_views
					}
					return a.page_views - b.page_views
				})
				setPageViewsTableData([...sortedByPageViews])
				break;
			case "email_opens":
				const sortedByEmailOpens = pageViewsTableData.sort((a, b) => {
					if (pageViewsTableSorting.orderIsDesc) {
						return b.email_opens - a.email_opens
					}
					return a.email_opens - b.email_opens
				})
				setPageViewsTableData([...sortedByEmailOpens])
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageViewsTableSorting])

	function onCTATableSortAction(criteria: string) {
		if (ctaTableSorting.criteria === criteria) {
			setCTATableSorting(current => {
				return {
					...current,
					orderIsDesc: !current.orderIsDesc
				}
			})
		} else {
			setCTATableSorting({criteria: criteria, orderIsDesc: true})
		}
	}

	useEffect(() => {
		switch (ctaTableSorting.criteria) {
			case "report_ctas":
				const sortedByCTAClicks = ctaTableData.sort((a, b) => {
					if (ctaTableSorting.orderIsDesc) {
						return b.cta_clicks - a.cta_clicks
					}
					return a.cta_clicks - b.cta_clicks
				})
				setCTATableData([...sortedByCTAClicks])
				break;
			case "email_clicks":
				const sortedByEmailClicks = ctaTableData.sort((a, b) => {
					if (ctaTableSorting.orderIsDesc) {
						return b.email_clicks - a.email_clicks
					}
					return a.email_clicks - b.email_clicks
				})
				setCTATableData([...sortedByEmailClicks])
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ctaTableSorting])

	async function setTablesData(pageViewsData: GATableReportEntity[], ctaData: GATableReportEntity[]) {
		await setPageViewsTableData(pageViewsData);
		await setCTATableData(ctaData);
	}

	async function onDateFilterButtonClicked() {
		setLoading(true);
		const {data} = await api.post(ENDPOINTS.GOOGLE_ANALYTICS.get_tables_data, {
			start_date: moment(startDate).format("YYYY-MM-DD"),
			end_date: moment(endDate).format("YYYY-MM-DD")
		});
		await setTablesData(data.page_view_reports, data.cta_reports);
		setLoading(false);
	}

	function filterTablesByMemberOrDomain(searchValue: string) {
		searchValue = searchValue.toLowerCase()
		const filteredPageViews = pageViewsTableData.filter(function (entry) {
			return entry.member.toLowerCase().includes(searchValue) || entry.domain.toLowerCase().includes(searchValue)
		});
		const filteredCTA = ctaTableData.filter(function (entry) {
			return entry.member.toLowerCase().includes(searchValue) || entry.domain.toLowerCase().includes(searchValue)
		});
		setFilteredPageViewsData(filteredPageViews);
		setFilteredCTAData(filteredCTA);
	}

	// TODO: style -> ?
	return (
		<DashboardComponent>
			<section className="urs">
				<div
					className={`${isLargeScreen ? "urs__mobile_search" : "urs__desktop_search"}`}
				>
					<div className="urs__search">
						<input
							className="urs__search-input urs__search-input--focus"
							type="text" value={inputFilter}
							placeholder="Enter company or domain to filter..."
							data-testid="urs__search-input-testId"
							disabled={loading}
							onChange={e => {
								setInputFilter(e.target.value);
								filterTablesByMemberOrDomain(e.target.value);
							   }}/>
					</div>
					<div style={{display: "flex", marginTop: 8}}>
					<DateRangePicker startDate={startDate} endDate={endDate} disabled={loading}
													 setStartDate={setStartDate} setEndDate={setEndDate}
													 onDateFilterChartData={onDateFilterButtonClicked}/>
					</div>
				</div>
				{
					loading ?
						<LoadingSpinner/>
						:
						<>
							{/*<AnalyticsPdfReports/>*/}
							<article className="urs__content">
								<h4 className="urs__content_title">Engagement</h4>
								<p className="engagement__subtitle-props">the number of report or email opens within a
									timeframe</p>
								{
									filteredPageViewsData.length ?
										<div className={"urs__table_wrapper"}>
										<table className="urs__table">
											<thead className="urs__table_headers">
											<tr>
												<td>Member</td>
												<td>Domain</td>
												<td>
													<article className={"urs__table-headers-icon-wrapper"}
															 data-testid="urs__report-visits"
															 onClick={() => onPageViewsTableSortAction("report_visits")}
															 style={{cursor: "pointer"}}>
														<div>
															Report<br/>Visits
														</div>
														<div className={"urs__table-headers-icon"}>
															{pageViewsTableSorting.criteria === "report_visits" && (pageViewsTableSorting.orderIsDesc ?
																<ArrowDropDownIcon/> :
																<ArrowDropUpIcon/>)}
														</div>
													</article>
												</td>
												<td>
													<article className={"urs__table-headers-icon-wrapper"}
															 data-testid="urs__email-opens"
															 onClick={() => onPageViewsTableSortAction("email_opens")}
															 style={{cursor: "pointer"}}>
														<div>
															Email<br/>Opens
														</div>
														<div className={"urs__table-headers-icon"}>
															{pageViewsTableSorting.criteria === "email_opens" && (pageViewsTableSorting.orderIsDesc ?
																<ArrowDropDownIcon/> :
																<ArrowDropUpIcon/>)}
														</div>
													</article>
												</td>
											</tr>
											</thead>
											<tbody data-testid="page_views">
											{
												filteredPageViewsData.map((entry) => {
													return <tr className="urs__table_rows" key={entry.member_id}>
														<td>
															<NavLink onClick={() => {
																updateNavButtons("Member Management");
															}
															} to={`/admin/member-management/${entry.member_id}`}
																	 className={"urs__content-member-link"}
																	 state={{memberID: entry.member_id}}
																	 data-testid="urs__domain-link"
															>
																{entry.member}
															</NavLink>
														</td>
														<td>{entry.domain}</td>
														<td data-testid="urs__reports-visits-page-views">{entry.page_views}</td>
														<td data-testid="urs__reports-visits-email-opens">{entry.email_opens}</td>
													</tr>
												})
											}
											</tbody>
										</table>
										</div> :
										<span>There is no data for the selected period.</span>
								}
							</article>
							<article className="urs__content">
								<h4 className="urs__content_title">Conversion</h4>
								<p className="engagement__subtitle-props">the number of report or email clicks within a
									timeframe</p>
								{
									filteredCTATableData.length ?
										<div className="urs__table_wrapper">
										<table className="urs__table">
											<thead className="urs__table_headers">
											<tr>
												<td>Member</td>
												<td>Domain</td>
												<td>
													<article className={"urs__table-headers-icon-wrapper"}
															 data-testid="urs__report-ctas"
															 onClick={() => onCTATableSortAction("report_ctas")}
															 style={{cursor: "pointer"}}>
														<div>
															Report<br/>CTAs
														</div>
														<div className={"urs__table-headers-icon"}>
															{ctaTableSorting.criteria === "report_ctas" && (ctaTableSorting.orderIsDesc ?
																<ArrowDropDownIcon/> :
																<ArrowDropUpIcon/>)}
														</div>
													</article>
												</td>
												<td>
													<article className={"urs__table-headers-icon-wrapper"}
															 data-testid="urs__report-ctas-clicks"
															 onClick={() => onCTATableSortAction("email_clicks")}
															 style={{cursor: "pointer"}}>
														<div>
															Email<br/>Clicks
														</div>
														<div className={"urs__table-headers-icon"}>
															{ctaTableSorting.criteria === "email_clicks" && (ctaTableSorting.orderIsDesc ?
																<ArrowDropDownIcon/> :
																<ArrowDropUpIcon/>)}
														</div>
													</article>
												</td>
											</tr>
											</thead>
											<tbody data-testid="page_clicks">
											{
												filteredCTATableData.map((entry) => {
													return <tr className="urs__table_rows" key={entry.member_id}>
														<td><NavLink onClick={() => {
															updateNavButtons("Member Management");
														}
														} to={`/admin/member-management/${entry.member_id}`}
																	 className={"urs__content-member-link"}
																	 state={{memberID: entry.member_id}}>
															{entry.member}
														</NavLink></td>
														<td>{entry.domain}</td>
														<td data-testid="urs__report-cta-clicks">{entry.cta_clicks}</td>
														<td data-testid="urs__report-cta-email-clicks">{entry.email_clicks}</td>
													</tr>
												})
											}
											</tbody>
										</table>
										</div>:
										<span>There is no data for the selected period.</span>
								}
							</article>
						</>
				}
			</section>
		</DashboardComponent>
	)
}

export default MemberResponseStats;

import React, {useState} from "react";
import {SSActiveDomainContextType} from "../../interfaces";

export const SSActiveDomainContext = React.createContext<SSActiveDomainContextType>({});

export const SSActiveDomainContextProvider: React.FC = ({children}) => {
  const [activeDomain, setActiveDomain] = useState<string>();

  return (
    <SSActiveDomainContext.Provider value={{activeDomain, setActiveDomain}}>{children}</SSActiveDomainContext.Provider>
  )
}

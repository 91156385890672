import React from "react";

import './FooterReports.scss';


type FooterReportsTypes = {
    logo: string | undefined,
    footer: string | undefined
}

const FooterReports: React.FC<FooterReportsTypes> = ({logo, footer}: FooterReportsTypes): JSX.Element => {
    return (
        <section className="footer">
            <article className="footer__wrapper">
                <img className={"footer__img"}
                     src={logo} alt='Edison Marks'/>
                <div className="footer__headings">
                    <p className="footer__heading footer__heading--alignment">{footer}</p>
                </div>
            </article>
        </section>
    )
}

export default FooterReports;
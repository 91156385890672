import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import MoodIcon from '@mui/icons-material/Mood';
import moment from "moment";

import {howRUDoing, initialBarChartData, MIN_DOMAIN_SCORE, permissionsCatalog} from '../../../constants';
import {EndUserData} from '../../../interfaces';
import {ReferenceBoardType} from "../SubheaderReport/types";
import {ScoreDial} from "../../../components/Charts/ScoreDial/ScoreDial";
import {ReportModal} from "../ReportModal/ReportModal";

import './ReferenceBoard.scss';

export const ReferenceBoard: React.FC<EndUserData & ReferenceBoardType> =
	(props: EndUserData & ReferenceBoardType): JSX.Element => {
		const {
			id,
			timestamp,
			low_risk_score,
			total_avg_score,
			score_history,
			risk_status,
			domain,
			domain_id,
			member_id,
			permissions,
			font,
			buttonColor,
			borderLineColor,
			arrowColor
		} = props
		const [barChartData, setBarChartData] = useState<{ name: string, score: number | string }[]>(initialBarChartData);
		const [reportBeginningDate, setReportBeginningDate] = useState('');
		const [ticketData, setTicketData] = useState<{ btnType: string, scrollId: string }>();

		//when we get the timestamp from the db we need one month ago from that date.
		useEffect(() => {
			setReportBeginningDate(() => {
				return moment(timestamp, 'DD MMM YYYY').subtract(1, 'months').format(('DD MMM YYYY'));
			})
		}, [timestamp])

		//when we receive the scores from the db we set the bar chart data in the useEffect
		useEffect(() => {
			setBarChartData(prevState => {
				return prevState.map((barInfo) => {
					switch (barInfo.name) {
						case initialBarChartData[0].name:
							barInfo.score = low_risk_score;
							break;
						case initialBarChartData[1].name:
							barInfo.score = total_avg_score;
							break;
						default:
							barInfo.score = score_history ? score_history.filter(score => score.You !== null).at(-1)!.You : '';
							break;
					}
					return barInfo;
				})
			});
		}, [low_risk_score, total_avg_score, domain, score_history])
		// TODO: style ?
		return (
			<section className="referenceValues">
				<fieldset style={{border: `5px solid ${borderLineColor}`}} className="referenceValues__bar-fieldset">
					<legend data-testid="over-vulnerability" className="referenceValues__bar-legend">Overall Vulnerability: How do you compare?</legend>
					<article className="referenceValues__overallVulnerability">
						<article className="referenceValues__graphValues">
							{/*<ResponsiveContainer className="referenceValues__responsive-container">*/}
							{/*	<BarChart*/}
							{/*		width={350}*/}
							{/*		height={350}*/}
							{/*		data={barChartData}*/}
							{/*		barCategoryGap={2}*/}
							{/*		margin={{top: 15, right: 30, left: 20, bottom: 5}}*/}
							{/*	>*/}
							{/*		<XAxis dataKey="name" axisLine={false} width={150} tick={<CustomizedAxisTick/>}*/}
							{/*			   tickSize={-1}/>*/}
							{/*		<Bar dataKey="score" fill="#8884d8" radius={[10, 10, 0, 0]}>*/}
							{/*			<LabelList dataKey="score" position="top" fill="black"/>*/}
							{/*			{*/}
							{/*				barChartData.map((entry, index) => (*/}
							{/*					<Cell key={`cell-${index}`} fill={barColors[index % 20]}/>*/}
							{/*				))*/}
							{/*			}*/}
							{/*		</Bar>*/}
							{/*	</BarChart>*/}
							{/*</ResponsiveContainer>*/}
							<ScoreDial
								score={score_history?.filter(score => score.You !== null).at(-1)!.You || MIN_DOMAIN_SCORE}
								averageScore={total_avg_score}
								domain={domain}
							/>
							{/*<ScoreDial*/}
							{/*	score={550}*/}
							{/*	averageScore={550}*/}
							{/*	domain={domain}*/}
							{/*/>*/}
						</article>
						<article className="referenceValues__userResult">
							<fieldset style={{border: '0'}}
									  className="referenceValues__riskScaleFieldSet">
								<legend className="referenceValues__legend">How are you doing?</legend>
								<article className="referenceValues__howRUDoing">
									<div className=
											 {`${'referenceValues__arrowContainer'}
                                    ${risk_status === howRUDoing.atRisk
												 ? 'referenceValues__positionArrowAtEnd'
												 : risk_status === howRUDoing.good
													 ? 'referenceValues__positionArrowMiddle'
													 : 'referenceValues__positionArrowOnTop'}`
											 }>
										<p style={{borderLeft: `23px solid ${arrowColor}`}}
										   className="referenceValues__arrow"/>
									</div>
									<div style={{borderLeft: `3px solid ${arrowColor}`}}
										 className="referenceValues__scale">
										<p
										   className={risk_status === howRUDoing.great ? 'referenceValues__activeResult referenceValues__great-activeResult' : 'referenceValues__notActiveResult'}>
											<b data-testid={howRUDoing.great} style={{color: risk_status === howRUDoing.great ? arrowColor : 'lightgray'}}>
												{howRUDoing.great}!&nbsp;
											</b>
											<MoodIcon/>
											<MoodIcon/>
										</p>
										<p
										   className={risk_status === howRUDoing.good ? 'referenceValues__activeResult' : 'referenceValues__notActiveResult'}>
											<b data-testid={howRUDoing.good} style={{color: risk_status === howRUDoing.good ? arrowColor : 'lightgray'}}>
												{howRUDoing.good}!&nbsp;
											</b>
											<MoodIcon/>
										</p>
										<p
										   className={risk_status === howRUDoing.atRisk ? 'referenceValues__activeResult' : 'referenceValues__notActiveResult'}>
											<b data-testid={howRUDoing.atRisk} style={{color: risk_status === howRUDoing.atRisk ? arrowColor : 'lightgray'}}>
												{howRUDoing.atRisk}!
											</b>
										</p>
									</div>
								</article>
								<article className="referenceValues__riskScaleFooter">
									{risk_status === howRUDoing.atRisk
										? <>
											<p data-testid={`${howRUDoing.atRisk}-paragraph`}>You are at <b>significantly more risk</b> than other
											businesses in your area!</p>
										</>
										: risk_status === howRUDoing.good
											? <>
												<p data-testid={`${howRUDoing.good}-paragraph`}>You are <b>much better</b> than other
												businesses in your area!</p>
											</>

											: <>
												<p data-testid={`${howRUDoing.great}-paragraph`}>You are <b>significantly better</b> than other
												businesses in your area!</p>
											</>
									}
								</article>
							</fieldset>
						</article>
					</article>
					{!permissions.includes(permissionsCatalog.read_tenants) && (
						<div className="header-rep__tell_me_more_div">
							<Button
								id="score"
								data-testid="score"
								role='button'
								variant="contained"
								size="large"
								className="header-rep__tell_me_more_btn"
								style={{marginBottom: '0.8rem', backgroundColor: buttonColor}}
								onClick={(e) => {
									setTicketData({btnType: (e.target as HTMLElement).id, scrollId: '1'})
								}}
							>
								Learn more
							</Button>
						</div>
					)}
					<p className="referenceValues__reference-board-footer" style={{fontFamily: font}}>
						✧ Based on a
						passive scan of 100 nearby
						similar
						businesses
						and {domain}'s digital presence performed
						between {reportBeginningDate} - {timestamp}.
						Our score ranges from 300 to 850.
					</p>
				</fieldset>
				<ReportModal domain={domain} ticketData={ticketData} domainId={domain_id} domainScoreId={id} memberId={member_id}/>
			</section>
		)
	}

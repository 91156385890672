import React from 'react';

import {NavigationButton, NavigationButtonsContextType} from "../../interfaces";
import {initialNavButtonsContext} from "../../constants";

export const ColorNavPathsContext = React.createContext<NavigationButtonsContextType | null>(null);

export const ColorNavPathsContextProvider: React.FC = ({children}) => {
    const [navButtons, setNavButtons] = React.useState<NavigationButton[]>(initialNavButtonsContext)

    // The context has a initial state - array of NavigationButton (nav buttons properties - name, isClicked).
    // When the user click one of the navigation link, updateNavButtons will execute - the method will set the property
    // isClicked to true on the button with given name and then will update navButtons with the new values.
    const updateNavButtons = (name: string) => {
        navButtons.filter((navButton: NavigationButton) => {
            navButton.isClicked = navButton.name === name;
            return setNavButtons([...navButtons])
        })
    }

    // this method will return isClicked property by a given button name
    const filterNavButtons = (name: string) => {
        return navButtons.filter((navButton: NavigationButton) => navButton.name === name)[0].isClicked
    }


    return (
        <ColorNavPathsContext.Provider
            value={{navButtons, filterNavButtons, updateNavButtons}}>{children}</ ColorNavPathsContext.Provider>
    )
}

import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import {AxiosError} from "axios";
import {Button} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import {useMutation} from "@tanstack/react-query";
import * as yup from "yup";

import {useAPI} from "../../../utils/hooks/useAPI";
import {notifyError, notifySuccess} from "../../../components/utils/ToastNotifications/Notifier";
import FormikMUISimpleInput from "../../../components/Formik/MUISimpleInput/FormikMUISimpleInput";
import {ENDPOINTS} from "../../../constants";


export const userValidation = yup.object({
  name: yup.string()
    .notRequired(),
  email: yup.string()
    .email("You must enter a valid email.")
    .max(254, "This field must contain at most 254 characters.")
    .required("This field is required."),
})

const Users = () => {
  const api = useAPI();
  const [formValues, setFormValues] = useState<any>({
    name: '',
    email: '',
  });
  const {tenant_id: tenantId} = useParams()

  const handleFormSubmit = (values: { name?: string, email: string }) => {
    return api.post(ENDPOINTS.TENANTS.invite_tenant_user(tenantId), values);
  }

  const {mutate: inviteTenantUser, isLoading} = useMutation({
    mutationFn: handleFormSubmit,
    onSuccess: async () => {
      notifySuccess('Your invitation has been successfully send.');
      formik.resetForm()
    },
    onError: (error) => notifyError((error as AxiosError).response?.data?.message || 'Something went wrong please try again later or contact us.'),
    onSettled: () => {
      setFormValues(formik.values)
    }
  })

  const formik = useFormik({
    initialValues: {
      name: formValues.name,
      email: formValues.email
    },
    enableReinitialize: true,
    validationSchema: userValidation,
    onSubmit: (values) => {
      inviteTenantUser(values);
    }
  })

  return (
    <section className="w-1/2">
      <h3>Users</h3>
      <p>Invite a new user to your tenant.</p>
      <FormikProvider value={formik}>
        <Form className="flex flex-col items-center mt-6">
          <div className="mb-4 w-full">
            <FormikMUISimpleInput error={!!formik.errors.name} label="Name" name="name"
                                  size="small" placeholder={'Name'}/>
          </div>
          <div className="mb-5 w-full">
            <FormikMUISimpleInput error={!!formik.errors.email} label="Email" name="email"
                                  size="small" placeholder={'email'} required/>
          </div>
          <div className="mb-16 flex justify-center">
            <Button className="!font-bold !text-lg !px-7 !rounded-lg bg-orange mulish" type="submit"
                    size="large" variant="contained"
                    disabled={formik.isSubmitting || !formik.isValid || !formik.dirty || !formik.touched}>
              submit changes
            </Button>
          </div>
        </Form>
      </FormikProvider>
    </section>
  );
};

export default Users;

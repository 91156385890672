import {RedirectContextProvider} from "./context/redirect-context";
import {ColorNavPathsContextProvider} from "./context/color-nav-paths-context";
import {CurrentThemeContextProvider} from "./context/current-theme-context";
import {combineComponents} from "./combine-components";
import {FeatureFlagProvider} from "./context/feature-flag-context";
import {SSMemberUserContextProvider} from "./context/self-serve-member-user";
import {ThemeContextProvider} from "./context/theme-context";


const providers = [
    RedirectContextProvider,
    ColorNavPathsContextProvider,
    CurrentThemeContextProvider,
    FeatureFlagProvider,
    SSMemberUserContextProvider,
    ThemeContextProvider,
]
export const AppContextProvider = combineComponents(...providers);
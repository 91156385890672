import './Structure.scss';

const Structure = () => {
    return (
        <section className="sg-structure">
            <article>
                <h2 className="concepts__center-headers">Structure</h2>
                <h2>Folder Structure</h2>
                <p>A good folder structure depends on the size of your application and your team.</p>
                <p>But in this project the following folder-structure is followed:</p>
                <p className="sg-structure__example">
                    - src/ <br/>
                    --- assets/ <br/>
                    ---- images/ <br/>
                    ---- scss/<br/>
                    --- components/ <br/>
                    ---- component.tsx <br/>
                    ---- style.css <br/>
                    --- constants/ <br/>
                    ----- index.tsx <br/>
                    --- hooks/ <br/>
                    ---- index.tsx <br/>
                    ---interfaces/ <br/>
                    ----- index.tsx <br/>
                    --- pages/ <br/>
                    ---- page.tsx <br/>
                    ---- page.scss <br/>
                    --- utils/ <br/>
                    ---- index.tsx
                </p>
            </article>
            <article>
                <h2>Maintain a structured import order</h2>
                <p>
                    If you've already got some experience in React, you might have seen files
                    that are bloated with a lot of import statements. They might also be mixed
                    up with external imports from third-party packages and internal imports like
                    other components, util functions, styles and many more.
                </p>
                <h4>Real World Example (cut):</h4>
                <p className="sg-structure__example">
                    import React from "react";<br/>
                    import Typography from "@material-ui/core/Typography";<br/>
                    import Divider from "@material-ui/core/Divider";<br/>
                    import Title from "../components/Title";<br/>
                    import Navigation from "../components/Navigation";<br/>
                    import DialogActions from "@material-ui/core/DialogActions"<br/>
                    import Grid from "@material-ui/core/Grid";<br/>
                    import Paragraph from "../components/Paragprah";<br/>
                    import Box from "@material-ui/core/Box";<br/>
                    import axios from 'axios';<br/>
                    import CustomButton from "../components/CustomButton";<br/>
                    ...
                </p>
                <p>
                    You probably recognize the deal here. It's difficult to distinguish what are all the third-party
                    and the local (internal) imports. They are not grouped and seem to be all over the place.
                </p>
                <h4>Better Version:</h4>
                <p className="sg-structure__example">
                    import React from "react";<br/>
                    import axios from 'axios';<br/>
                    import Typography from "@material-ui/core/Typography";<br/>
                    import Divider from "@material-ui/core/Divider";<br/>
                    import Box from "@material-ui/core/Box";<br/>
                    import DialogActions from "@material-ui/core/DialogActions";<br/>
                    import Grid from "@material-ui/core/Grid";<br/><br/>

                    import CustomButton from "../components/CustomButton";<br/>
                    import Title from "../components/Title";<br/>
                    import Navigation from "../components/Navigation";<br/>
                    import Paragraph from "../components/Paragraph";<br/>
                    ...
                </p>
                <p>
                    The structure is clearer and it's very easy to distinguish where the external and internal imports
                    are.
                </p>
            </article>
            <article>
                <h2>Organize file structure properly</h2>
                <p>Keep in mind to follow this structure when writing your components:</p>
                <ul>
                    <li>Correctly positioned imports as mentions above.</li>
                    <li>Keep your variables, custom hooks, etc. at top lvl of the component.</li>
                    <li>useEffect, useMemo and other hooks after the variables.</li>
                    <li>handlers and functions after the hooks.</li>
                    <li>last but not least the jsx.</li>
                </ul>
                <p>Other useful tips to keep the maintainability of the projects are:</p>
                <ul>
                    <li>use functional components (like arrow-functions).</li>
                    <li>don't use inline-styles.</li>
                    <li>format your code before committing. Also use Tab size: 4, Indents: 4,
                        Continuation indents: 4
                    </li>
                </ul>
            </article>
            <article>
                <h2>Linter</h2>
                <p>Follow the configured linter settings by not leaving unused imports, unused variables,
                    missing dependencies and etc.</p>
            </article>
        </section>
    );
};

export default Structure;
import React, {useContext, useEffect, useState} from "react";
import QRCode from "qrcode.react";
import {useAuth0} from "@auth0/auth0-react";
import Button from "@mui/material/Button";

import {useAPI} from "../../../utils/hooks/useAPI";
import {HeaderReportTypes} from "./types";
import {RedirectContext} from "../../../store/context/redirect-context";
import PathContext from "../../../store/context/path-context";
import './HeaderReport.scss';
import {ENDPOINTS, permissionsCatalog} from "../../../constants";
import jwt from "jwt-decode";
import {JwtPayload} from "../../../store/context/user-permission-context";

// in this case we use the redirect url just to avoid repeating code, we need the host in production
const host = process.env.REACT_APP_PROD_REDIRECT_URL;

const HeaderReport: React.FC<HeaderReportTypes> = ({
                                                     permissions,
                                                     company_info,
                                                     timestamp,
                                                     domain,
                                                     fromSelfServe,
																										 logo,
                                                     font,
                                                     buttonColor,
																										 borderLineColor
                                                   }: HeaderReportTypes): JSX.Element => {
  const {loginWithRedirect, isLoading, logout, user, isAuthenticated, getAccessTokenSilently} = useAuth0();
  const {pathname} = useContext(RedirectContext);
  const {apiUrlProps: {protocol, tenant}} = useContext(PathContext);
  const api = useAPI();
  const [hasTenantMembership, setHasTenantMembership] = useState<boolean>(false)

  useEffect(() => {
    (async () => {
      const isTenantMember = await checkTenantMembership()
      setHasTenantMembership(isTenantMember)
      if (isAuthenticated && tenant !== 'ss') {
        await api.post(ENDPOINTS.MEMBERS.activate_member, {domain: domain});
      }
    })();
  }, [isAuthenticated, getAccessTokenSilently, api, domain]);

  const redirectDashboardHandler = (): void => {
    window.open('/admin/engagement/', '_blank');
  }

  async function checkTenantMembership() {
    if (!permissions.includes(permissionsCatalog.read_dashboard)) {
      return false;
    }
    const token = await getAccessTokenSilently()
    const {'https://*.edisonmarks.com/tenants': authorisedTenants} = jwt<JwtPayload>(token);
    return authorisedTenants.includes(tenant)
  }

  return (
    <header className="header-rep">
      <div>
        {!fromSelfServe && <img className={"header-rep__img"}
                                src={logo}
                                alt="Edison Marks"
        />}
        {permissions.includes(permissionsCatalog.read_tenants) && company_info &&
            <div className="header-rep__address" style={{fontFamily: font}}>
                <p className="header-rep__address_info">{company_info?.company_name || ''}</p>
                <p className="header-rep__address_info">ATTN: {company_info?.contact_name || ''}</p>
                <p className="header-rep__address_info">{company_info?.mailing_address || ''}</p>
                <p className="header-rep__address_info">{company_info?.mailing_address_2 || ''}</p>
                <p className="header-rep__address_info">{company_info?.city || ""}, {company_info?.state || ""} {company_info?.zip || ''}</p>
            </div>}
      </div>
      <div className="header-rep__auth">
        {/*Super admin visual*/}
        {permissions.includes(permissionsCatalog.read_tenants) &&
            <div className="subheader-rep__business-security-report">
                <div className="subheader-rep__security-rep">
                    <h4 className="text-xl mb-5">Vulnerability IQ Report</h4>
                    <h5 className="subheader-rep__timestamp"
                        style={{borderBottom: `4px solid ${borderLineColor}`}}>
                      {timestamp}
                    </h5>
                    <div className="subheader-rep__content" style={{fontFamily: font}}>
                        <p>We've put together this personalized report to help</p>
                        <p>you understand your cybersecurity risk and what</p>
                        <p>you can do to better defend your business.</p>
                    </div>
                </div>
                <div className="subheader-rep__qr-reports-code">
                    <QRCode
                        className="subheader-rep_qr-code"
                        value={`${protocol}${tenant}${host}${pathname}?utm_source=em&utm_medium=qr&utm_campaign=[t1]`}
                        size={100}
                        fgColor={buttonColor}
                    />
                    <div className="subheader-rep__qr_info_wrapper"
												 style={{fontFamily: font}}>
                        <p className="subheader-rep__qr_info">This information and more is</p>
                        <p className="subheader-rep__qr_info">available by scanning the QR code</p>
                        <p className="subheader-rep__qr_info">or visiting {`${protocol}${tenant}${host}/`}</p>
                        <p className="subheader-rep__qr_info">{`${pathname.substring(1)}`}</p>
                    </div>
                </div>
            </div>}
        {/*Tenant Admin*/}
        {!isLoading && user && !fromSelfServe && hasTenantMembership &&
          !permissions.includes(permissionsCatalog.read_tenants) && (
            <Button
              variant="contained"
              size="large"
              className="header-rep__buttons"
              style={{backgroundColor: buttonColor}}
              onClick={redirectDashboardHandler}
            >
              Dashboard
            </Button>
          )}
        {/*Tenant Admin or regular user*/}
        {!isLoading && user && !permissions.includes(permissionsCatalog.read_tenants) && !fromSelfServe && (
        	<>
        		<Button
        			variant="contained"
        			size="large"
        			className="header-rep__buttons"
        			style={{marginLeft: '1rem', backgroundColor: buttonColor}}
        			onClick={() => logout({
        				returnTo: window.location.origin
        			})}
        		>
        			Logout
        		</Button>
        	</>
        )}
        {!isLoading && !user && !permissions.includes(permissionsCatalog.read_tenants) && !fromSelfServe && (
        	<Button
        		id="login"
        		variant="contained"
        		className="header-rep__buttons"
						style={{backgroundColor: buttonColor}}
        		onClick={() => loginWithRedirect(
        			{
        				appState: {
        					returnTo: pathname
        				}
        			}
        		)}
        	>
        		Login
        	</Button>
        )}
      </div>
    </header>
  )
}

export default HeaderReport;

import React from "react";
import {ErrorMessage, Field} from "formik";
import {TextField} from "@mui/material";

interface FormikFieldProps {
    name: string;
    label: string;
    type?: string;
    required?: boolean;
    error?: boolean;
    size: string;
    disabled?: boolean;
    placeholder?: string;
}

const FormikMUISimpleInput: React.FC<FormikFieldProps> =
    ({
         name, label, type = "text", placeholder,
         required = false, error, size, disabled
     }) => (
        <>
            <Field
                className="member-modifier__form-input"
                required={required}
                autoComplete="off"
                as={TextField}
                label={label}
                placeholder={placeholder}
                name={name}
                fullWidth
                type={type}
                helperText={<ErrorMessage className="bg-transparent" name={name}/>}
                error={error}
                size={size}
                InputProps={{
                    classes: {
                        root: 'text-field-root',
                    },
                }}
                disabled={disabled}
            />
        </>
    );

export default FormikMUISimpleInput;

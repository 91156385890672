import React, {useContext} from 'react';
import {Form, Formik, FormikValues} from "formik";
import {useAPI} from "../../utils/hooks/useAPI";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Button} from "@mui/material";
import * as yup from "yup";
import {AxiosError} from "axios";
import {useLocation, useNavigate} from "react-router-dom";


import FormikMUISimpleInput from "../../components/Formik/MUISimpleInput/FormikMUISimpleInput";
import {notifyError, notifySuccess} from "../../components/utils/ToastNotifications/Notifier";
import {SSActiveDomainContext} from "../../store/context/self-serve-active-domain";
import {ENDPOINTS} from "../../constants";

const NewDomain = ({setAddDomainModal}: { setAddDomainModal: React.Dispatch<React.SetStateAction<boolean>>}) => {
	const currentPath = useLocation().pathname;
	const [_, , memberId, , domainName] = currentPath.split('/');
	const api = useAPI();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const {setActiveDomain} = useContext(SSActiveDomainContext);

	const {mutate: addNewDomain, isLoading} = useMutation({
		mutationFn: handleCreatingNewDomain,
		onSuccess: async ( {data: {domain: domainName, created_id: domain_id}}) => {
			notifySuccess('Your domain have been successfully created.');
			setAddDomainModal(prevState => !prevState);
			if (currentPath.includes(`/members/${memberId}/analyze/`)) {
				await queryClient.invalidateQueries(['fetch-ss-domains']);
				setActiveDomain && setActiveDomain(domainName)
			}
			navigate(`/members/${memberId}/analyze/${domain_id}`);
		},
		onError: (error) => notifyError((error as AxiosError).response?.data?.detail || 'Something went wrong please try again later or contact us.')
	})

	function handleCreatingNewDomain(values: FormikValues) {
		return api.post(ENDPOINTS.DOMAINS_SCORE.get_or_create_domain(memberId), values)
	}

	return (
		<section className="px-10">
			<h3>
				<Formik initialValues={{domain: ''}}
						validationSchema={yup.object({
							domain: yup.string()
								.required("This field is required.")
								.matches(/^[\w-]+([.]{1}[\w-]{0,61})*\.[\w-]{2,}$/,
									"You must enter a valid domain."),
						})}
						onSubmit={(values: FormikValues) => addNewDomain(values)}>
					{({errors, touched}) => {
						return (
							<Form className="flex flex-col items-center">
								<h4 className="font-normal text-xl font-normal quicksand">Add your new domain</h4>
								<div className="mb-5 mt-1 w-full">
									<FormikMUISimpleInput error={!!errors.domain} label="example.com*" name="domain"
														  size="small"
									/>
								</div>

								<div className="mb-16">
									<Button className="!font-semibold !px-7 !rounded-lg !text-lg bg-orange"
											type="submit"
											size="large" variant="contained"
											disabled={isLoading}
									>
										Add
									</Button>
								</div>
							</Form>
						)
					}}
				</Formik>
			</h3>
		</section>
	);
};

export default NewDomain;

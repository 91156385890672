import React, {useEffect, useState} from 'react';
import {AxiosResponse} from "axios";
import BoltIcon from '@mui/icons-material/Bolt';
import SpaIcon from '@mui/icons-material/Spa';
import {useQuery} from "@tanstack/react-query";

import {DOMAIN_SCORES_RISK_STATUS, ENDPOINTS, RISK_STATUS_DISTRIBUTION_COLORS} from "../../constants";
import LoadingSpinner from "../loading/LoadingSpinner";
import {getRangeFromNumbers} from "../../utils/helpers";
import {useAPI} from "../../utils/hooks/useAPI";
import {notifyError} from "../utils/ToastNotifications/Notifier";

export type MemberDistChartType = {
    [key: string]: {
        scores: number[]
    }
}

export type MembersStatsType = {
    averageScore: string,
    membersScoreDistribution: number[],
    newlyAddedMembers: string[],
    topEngaging: {
        [key: string]: {
            domain: string,
            count: number
        }
    },
    moversUp: {
        score: number,
        domain: string,
        difference: number
    }[],
    moversDown: {
        score: number,
        domain: string,
        difference: number
    }[],
    highestScoreMembers: {
        "score": number,
        "member": string
    }[],
    lowestScoreMembers: {
        "score": number,
        "member": string
    }[]
}

const MemberStats = () => {
    const [memberDistChartData, setMemberDistChartData] = useState<MemberDistChartType>();
    const [membersStats, setMembersStats] = useState<MembersStatsType>();
    const api = useAPI();

    const {data: membersStatsData, isError } = useQuery({
        queryKey: ['member-stats-data'],
        queryFn: handleGettingMembersStatsData,
        refetchOnWindowFocus: false,
        enabled: !membersStats
    })

    useEffect(() => {
        if(membersStatsData) {
            setMembersStats(membersStatsData.data)
        }
    }, [membersStatsData?.data]);

    useEffect(() => {
        if(isError) {
            notifyError('The application, could not load Member stats data, please try again later.')
        }
    }, [isError]);

    useEffect(() => {
        if (membersStatsData?.data && membersStatsData.data.membersScoreDistribution && membersStatsData.data.membersScoreDistribution.length > 0) {
            const ranges = getRangeFromNumbers()

            const periodsData: { [key: string]: { scores: number[] } } = {}

            membersStatsData.data.membersScoreDistribution.forEach(score => {
                const findPeriod = ranges.find(({start, end}) => start < score && end > score)

                if (findPeriod) {
                    const period = `${findPeriod.start}-${findPeriod.end}`

                    if (periodsData[period]) {
                        periodsData[period].scores.push(score)
                        return;
                    }

                    periodsData[period] = {scores: [score]}
                }
            })

            setMemberDistChartData(periodsData)
        }
    }, [membersStatsData?.data]);

    function handleGettingMembersStatsData(): Promise<AxiosResponse<MembersStatsType>> {
        return api.get(ENDPOINTS.MEMBERS.get_members_stats)
    }

    function getTextClass(text: string | undefined) {
        if (text && text.length > 17) {
            return 'truncate ';
        } else {
            return '';
        }
    }

    function figureDistributionColorByScore(score: number) {
        if (score <= DOMAIN_SCORES_RISK_STATUS.AT_RISK) {
            return RISK_STATUS_DISTRIBUTION_COLORS.RED
        }

        if (score <= DOMAIN_SCORES_RISK_STATUS.GOOD) {
            return RISK_STATUS_DISTRIBUTION_COLORS.YELLOW
        }

        if (score > DOMAIN_SCORES_RISK_STATUS.GOOD) {
            return RISK_STATUS_DISTRIBUTION_COLORS.GREEN
        }
    }

    const getMarginTopProperty = (scores: number[]) => {
        if (scores.length === 5) {
            return "mt-10"
        }
        if (scores.length === 4) {
            return "mt-8"
        }
        if (scores.length === 3) {
            return "mt-5"
        }
        if (scores.length === 2) {
            return "mt-3"
        }
    }

    return (
        <section className="mt-3">
            {!membersStats
                ? (
                    <div className="mt-3 m-auto">

                        <LoadingSpinner/>
                    </div>
                )
                : (
                membersStats && (
                        <div className="flex flex-wrap">
                            <div className="flex flex-col max-w-[300px] mr-2 xl:mr-10">
                                <div>
                                    <h3 className="mb-0 text-lg text-dark-grey">Average Member Score</h3>
                                    <div className="flex flex-col md:flex-row md:items-center">
                                        <h2 className="mr-2 my-1 text-orange text-4xl font-bold">{membersStats.averageScore}</h2>
                                        <p className="sm-ml-3 my-3 w-[220px] text-xs italic">
                                            Short description of method of calculation and how often is it updated.
                                        </p>
                                    </div>
                                </div>
                                <div className="mr-2 mb-3">
                                    <h4 className="mt-0 text-dark-grey text-sm">Member Score Distribution</h4>
                                    <div className="flex">
                                        {
                                            memberDistChartData && Object.values(memberDistChartData).length > 0
                                                ? (
                                                    Object.values(memberDistChartData).reverse().map(({scores}, indexValues) => {
                                                        return (
                                                            <div key={indexValues} className="flex flex-col-reverse" >
                                                                {
                                                                    scores.slice(0, 5).map((item, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <div>
                                                                                    {scores.length - 1 === index && Object.values(memberDistChartData).length - 1 === indexValues && (
                                                                                        <div className="relative">
                                                                                            <p className={`absolute m-0.5 text-xs ${getMarginTopProperty(scores)}`}>
                                                                                                {Number(item.toString()[0]) + 1 > 8 ? 850 : Number(item.toString()[0]) + 1 + '00'}
                                                                                            </p>
                                                                                        </div>
                                                                                    )}

                                                                                    {!index && !indexValues &&
                                                                                        <div className="relative">
                                                                                            <p className="absolute m-0.5 text-xs">
                                                                                                {item.toString()[0]}00
                                                                                            </p>
                                                                                        </div>
                                                                                    }
                                                                                </div>

                                                                                <div
                                                                                    className={`w-[6px] h-[6px] rounded-full flex justify-center items-center m-0.5 ${figureDistributionColorByScore(item)}`}
                                                                                />
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                )
                                                : (
                                                    <div>
                                                        <p>No members distribution data.</p>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="mr-2 xl:mr-10">
                                <h4 className="flex items-center mb-0 mt-4">
                                    <BoltIcon className="text-orange"/>
                                    <span className="text-lg text-dark-grey mb-0.5">Top Engaging</span>
                                </h4>
                                {membersStats.topEngaging && Object.values(membersStats.topEngaging).length > 0 ?
                                    Object.values(membersStats.topEngaging).slice(0, 5).map((member, index) => {
                                        return (
                                            <div key={index} className="max-w-[220px]">
                                                <p className={`max-w-[220px] my-1.5 text-dark-grey font-semibold text-base mr-2 ${getTextClass(member.domain)}`}>
                                                    {member.domain}
                                                </p>
                                            </div>
                                        )
                                    })
                                    : (
                                        <p className="w-[220px] text-xs italic">
                                            No engaging members.
                                        </p>
                                    )
                                }
                            </div>

                            <div className="mr-2 xl:mr-10">
                                <h4 className="flex items-center mb-0 mt-4">
                                    <SpaIcon className="text-green mr-0.5"/>
                                    <span className="text-lg text-dark-grey mb-0.5">New</span>
                                </h4>
                                {membersStats.newlyAddedMembers && membersStats.newlyAddedMembers.length > 0 ?
                                  membersStats.newlyAddedMembers.slice(0, 5).map((member, index) => {
                                        return (
                                            <div key={index} className="max-w-[220px]">
                                                <p className={`max-w-[220px] my-1.5 text-dark-grey font-semibold text-base mr-2 ${getTextClass(member)}`}>
                                                    {member}
                                                </p>
                                            </div>
                                        )
                                    })
                                    : (
                                        <p className="w-[240px] text-xs italic">
                                            No new members.
                                        </p>
                                    )
                                }
                            </div>

                            <div className="flex flex-wrap">
                                <div className="mr-2 xl:mr-8">
                                    <h4 className="text-dark-grey text-lg mb-0 mt-4">Highest Scoring</h4>
                                    {membersStats.highestScoreMembers && membersStats.highestScoreMembers.length > 0 ?
                                      membersStats.highestScoreMembers.slice(0, 5).map((item, index) => {
                                            return (
                                                <div key={index}
                                                    className="flex items-center justify-between max-w-[180px] my-0 mr-3">
                                                    <p className={`max-w-[150px] text-dark-grey text-sm my-1.5 mr-2 ${getTextClass(item.member)}`}>
                                                        {item.member}
                                                    </p>
                                                    <p className="text-green my-1 text-sm">{item.score}</p>
                                                </div>
                                            )
                                        })
                                        : (
                                            <p className="w-[200px] text-xs italic">
                                                No highest scoring members.
                                            </p>
                                        )
                                    }
                                </div>

                                <div className="mr-2 xl:mr-8">
                                    <h4 className="text-dark-grey text-lg mb-0 mt-4">Movers Up</h4>
                                    {membersStats.moversUp && membersStats.moversUp.length > 0 ?
                                      membersStats.moversUp.slice(0, 5).map((item, index) => {
                                            return (
                                                <div key={index}
                                                    className="flex items-center justify-between max-w-[180px] my-0 mr-3">
                                                    <p className={`max-w-[150px] text-dark-grey text-sm my-1.5 mr-2 ${getTextClass(item.domain)}`}>
                                                        {item.domain}
                                                    </p>

                                                    <div className="flex items-center">
                                                        <p className="text-green my-1.5 mr-1.5 text-sm">{item.score}</p>
                                                        <p className="text-dark-grey my-1.5 text-xs italic font-light">+{item.difference}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : (
                                            <p className="w-[200px] text-xs italic">
                                                No movers up.
                                            </p>
                                        )
                                    }
                                </div>

                                <div className="mr-2 xl:mr-8">
                                    <h4 className="text-dark-grey text-lg mb-0 mt-4">Lowest Scoring</h4>
                                    {membersStats.lowestScoreMembers && membersStats.lowestScoreMembers.length > 0 ?
                                      membersStats.lowestScoreMembers.slice(0, 5).map((item, index) => {
                                            return (
                                                <div key={index}
                                                    className="flex items-center justify-between max-w-[180px] my-0 mr-3">
                                                    <p className={`max-w-[150px] text-dark-grey text-sm my-1.5 mr-2 ${getTextClass(item.member)}`}>
                                                        {item.member}
                                                    </p>
                                                    <p className="text-alert-red my-1.5 text-sm">{item.score}</p>
                                                </div>
                                            )
                                        })
                                        : (
                                            <p className="w-[200px] text-xs italic">
                                                No lowest scoring members.
                                            </p>
                                        )
                                    }
                                </div>

                                <div className="mr-2 xl:mr-8">
                                    <h4 className="text-dark-grey text-lg mb-0 mt-4">Movers Down</h4>
                                    {membersStats.moversDown && membersStats.moversDown.length > 0 ?
                                      membersStats.moversDown.slice(0, 5).map((item, index) => {
                                            return (
                                                <div key={index}
                                                    className="flex items-center justify-between max-w-[180px] my-0 mr-3">
                                                    <p className={`max-w-[150px] text-dark-grey text-sm my-1.5 mr-2 ${getTextClass(item.domain)}`}>
                                                        {item.domain}
                                                    </p>
                                                    <div className="flex items-center">
                                                        <p className="mr-1 text-alert-red my-1.5 text-sm">{item.score}</p>
                                                        <p className="text-dark-grey my-1.5 text-xs italic font-light">-{item.difference}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : (
                                            <p className="w-[200px] text-xs italic">
                                                No movers down.
                                            </p>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    )
                )
            }
        </section>
    );
};

export default MemberStats;

import React, {useState} from "react";
import {useAPI} from "../../utils/hooks/useAPI";
import NewTicketForm from "./NewTicketForm";
import {Modal} from "../utils/Modal/Modal";
import {useMutation} from "@tanstack/react-query";
import {ENDPOINTS} from "../../constants";

type TicketGeneratorType = {
	domain: string,
	btnType: string | undefined
}

// This component is used when we want to create a ticket and the member does not have the VIQ flag
export const NewTicket: React.FC<TicketGeneratorType> = ({
															 domain, btnType
														 }): JSX.Element => {
	const api = useAPI();
	const [ticketModalOpened, setTicketModalOpened] = useState<boolean>(false);
	const [ticketSource] = useState<string>(btnType || '');
	const [loading, setLoading] = useState<boolean>(false);
	const [confirmationModalOpened, setConfirmationModalOpened] = useState<boolean>(false);
	const [modalType, setModalType] = useState<string>("");

	const {mutate: submitMutation} = useMutation(submitTicket);

	function submitTicket({
							  ticketSource,
							  ticketDescription,
							  email
						  }: { ticketSource: string, ticketDescription: string, email: string }) {
		return api.post(ENDPOINTS.TICKET_TRACKING.create_ticket, {
			ticket_source: ticketSource,
			ticket_description: ticketDescription,
			domain,
			email: email
		})
	}

	function handleTicketModalClose(result: string): void {
		handleModalClose()
		setModalType(result)
		setConfirmationModalOpened(true)
	}

	function handleModalClose(): void {
		if (ticketModalOpened) {
			setTicketModalOpened(false)
			localStorage.removeItem('isInputChanged')
		}
		if (confirmationModalOpened) {
			setConfirmationModalOpened(false);
			localStorage.removeItem('isInputChanged')
		}
	}

	return (
		<>
			<NewTicketForm
				onConfirm={(email: string, ticketDescription: string) => {
					submitMutation({ticketSource, ticketDescription, email}, {
						onSuccess: (response) => {
							setLoading(false)
							if (response.status === 201) {
								handleTicketModalClose("success")
							} else {
								handleTicketModalClose("failure")
							}
						},
						onError: () => {
							setLoading(false)
							handleTicketModalClose("failure")
						}
					})
					setLoading(true);
				}}
				loading={loading}
			/>
			{modalType === "success" ?
				<Modal
					onClose={handleModalClose}
					show={confirmationModalOpened}
					hideConfirmButton={true}
					hideCloseButton={true}
					onDeny={handleModalClose}
					size="lg"
				>
					<div className="modal-message">
						<h3>Thank you for submitting your request.</h3>
						<p>We will contact you shortly.</p>
					</div>
				</Modal>
				:
				<Modal
					onClose={handleModalClose}
					show={confirmationModalOpened}
					hideConfirmButton={true}
					hideCloseButton={true}
					onDeny={handleModalClose}
					size="lg"
				>
					<div className="modal-message">
						<h3>Something went wrong with your request.</h3>
						<p>Please try again later.</p>
					</div>
				</Modal>
			}
		</>
	)
}
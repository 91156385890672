import {TooltipProps} from "recharts";
import '../../../pages/Dashboard/styles.scss';

const CampaignStatsCustomChartTooltip = ({active, payload}: TooltipProps<any, any>) => {
    const MAX_ELEMENTS_IN_TOOLTIP = 5;
    if (active && payload && payload.length) {
        const payloadEvents = payload[0].payload.users;
        return (
            <div className="custom-tooltip">
                {
                    payloadEvents.length >= 1 ?
                        <>
                            <p>{`Date: ${payload[0].payload.date}`}</p>
                            <p>{`Events count: ${payload[0].value}`}</p>
                            <p>{`Users engaged: ${payloadEvents.length}`}</p>
                            <div style={{
                                borderStyle: "solid",
                                borderWidth: "thin",
                                borderBottom: "white",
                                borderLeft: "white",
                                borderRight: "white"
                            }}>
                                {
                                    payloadEvents.length > MAX_ELEMENTS_IN_TOOLTIP ?
                                        <>
                                            {
                                                payloadEvents.slice(0, MAX_ELEMENTS_IN_TOOLTIP).map((user: string, index: number) =>
                                                    // <p>{`${event.page_path.replace('/reports/', '')}: ${event.event_count}`}</p>
                                                    <p key={index}>{user}</p>
                                                )
                                            }
                                            <p style={{textAlign: "center"}}>...</p>
                                        </>
                                        :
                                        payloadEvents.map((user: string, index: number) =>
                                            <p key={index}>{user}</p>
                                        )
                                }
                            </div>
                        </>
                        :
                        (
                            <>
                                <p>{`Date: ${payload[0].payload.date}`}</p>
                                <p>{'No events'}</p>
                            </>
                        )
                }
            </div>
        );
    }

    return null;
};

export default CampaignStatsCustomChartTooltip;
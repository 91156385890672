import {Route, Routes} from 'react-router-dom';

import ProtectedRoute from "./components/auth/ProtectedRoute";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import Main from "./pages/Main/Main";
import Reports from "./pages/Reports/";
import AdminDashboard from "./pages/Dashboard";
import EmAdmin from "./pages/EmAdmin";
import StyleGuide from "./pages/StyleGuide";
import SelfServe from "./pages/SelfServe";
import TenantSelfServe from "./pages/TenantSS";
import AcceptInvitation from "./pages/AcceptInvitation/AcceptInvitation";
import {TenantPropsContextProvider} from './store/context/tenant-props-context';
import {
  accessDeniedConstants,
  expiredInvitationLinkConstants,
  invalidInvitationLinkConstants,
  pageNotFoundConstants,
  permissionsCatalog,
  ROUTES
} from "./constants";
import './App.css';

function App() {
  return (
    <>
      <Routes>
        <Route path={ROUTES.MAIN.route} element={<Main/>}/>
        <Route path={ROUTES.ACCEPT_INVITATION_PAGE.route} element={<AcceptInvitation/>}/>
        <Route path={ROUTES.REPORTS.route} element={<Reports/>}/>
        <Route path={ROUTES.MEMBER_SS.route} element={
          <ProtectedRoute component={SelfServe} requiredPermissions={[permissionsCatalog.read_ss_report]}/>
        }/>
        <Route path={ROUTES.TENANT_SS.route} element={
          <ProtectedRoute component={TenantSelfServe} requiredPermissions={[permissionsCatalog.create_tenants,
            permissionsCatalog.update_tenant_users]}/>
        }/>
        <Route path={ROUTES.ADMIN.route} element={
          <TenantPropsContextProvider>
            <ProtectedRoute component={AdminDashboard} requiredPermissions={[permissionsCatalog.read_dashboard]}/>
          </TenantPropsContextProvider>
        }/>
        <Route path={ROUTES.EM_ADMIN.route} element={
          <TenantPropsContextProvider>
            <ProtectedRoute component={EmAdmin} requiredPermissions={[permissionsCatalog.read_tenants]}/>
          </TenantPropsContextProvider>
        }/>
        <Route path={ROUTES.STYLE_GUIDE.route} element={<StyleGuide/>}/>
        <Route path={ROUTES.ACCESS_DENIED_PAGE.route} element={<ErrorPage errors={accessDeniedConstants}/>}/>
        <Route path={ROUTES.EXPIRED_LINK_PAGE.route} element={<ErrorPage errors={expiredInvitationLinkConstants}/>}/>
        <Route path={ROUTES.INVALID_LINK_PAGE.route} element={<ErrorPage errors={invalidInvitationLinkConstants}/>}/>
        <Route path={ROUTES.ERROR_PAGE.route} element={<ErrorPage errors={pageNotFoundConstants}/>}/>
      </Routes>
    </>
  );
}

export default App;

import React, {useEffect, useState} from 'react';
import {Button} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {AxiosResponse} from "axios";
import {useMutation, useQuery} from "@tanstack/react-query";

import GenerateTicket from "./GenerateTicket";
import AIRecommendation from "./AIRecommendation";
import {ENDPOINTS, REC_RECORDS, REC_RISKS, REC_STATUTES} from "../../constants";
import {useAPI} from "../../utils/hooks/useAPI";
import LoadingSpinner from "../loading/LoadingSpinner";
import {notifyError, notifySuccess} from "../utils/ToastNotifications/Notifier";
import {ModalRecommendationsProps, RecommendationsType} from "./types";
import './styles.scss';
import {dataTestIds} from "../../constants/data-test-ids";
import LoaderWithFacts from "../loading/LoaderWithFacts/LoaderWithFacts";

const ModalRecommendations = ({
								  ticketData,
								  domainScoreId,
								  domain,
								  nextStep,
								  previousStep,
								  setFixedIssues,
								  setUnResolvedIssues,
								  isLoadingSpinnerFunc
							  }: ModalRecommendationsProps) => {
	const [recommendations, setRecommendations] = React.useState<RecommendationsType>();
	const [step, setStep] = React.useState<number>(1);
	const [activatedPrevSteps, setActivatedPrevSteps] = useState<number>(0);

	const api = useAPI();

	const {
		isLoading,
		isFetching,
		data: response,
		error
	} = useQuery(['rec', step], () => handleGetRec(step), {
		refetchOnWindowFocus: false,
		retryDelay: 3000,
		retry: 60,
	})

	const {mutate: mutateRec, isLoading: isMarkingLoading} = useMutation(handleMarkedAsDonePatch)

	React.useEffect(() => {
		if (response?.data) {
			const {data} = response;

			let mappedRecs: RecommendationsType['issue']['items'] = [];

			const scanTypes = REC_RECORDS[data.issue.type as keyof typeof REC_RECORDS];
			if (scanTypes) {
				// Find missing resolved scan types and add them with the recommendations
				// The importance here is that we want to add them in the order of each scanType
				scanTypes.forEach((scanType) => {
					const isScanTypeInResponse = data.issue.items.some(item => item.scan_type === scanType);

					if (!isScanTypeInResponse) {
						mappedRecs.push({
							//The code below generates an id between 50 and 100
							id: Math.floor(Math.random() * (101 - 50)) + 50 + '+',
							scan_type: scanType,
							risk: REC_STATUTES.ALL_GOOD,
							details: "",
							recommendation: "",
							status: REC_STATUTES.ALL_GOOD,
						})
						return;
					}
					mappedRecs = [...mappedRecs, handleEnrichingSingleRec(mappedRecs, data.issue.items, scanType)];
				})
			} else {
				mappedRecs = [...handleEnrichingRecs(data.issue.items)]
			}

			const enrichedResponseData = {
				...data,
				issue: {
					...data.issue,
					items: mappedRecs
				}
			};

			setRecommendations(enrichedResponseData);
		}
	}, [response])

	useEffect(() => {
		isLoadingSpinnerFunc && isLoadingSpinnerFunc((isLoading || isFetching || isMarkingLoading), 'facts');
	}, [isLoading || isFetching || isMarkingLoading]);

	//handlers
	const handleEnrichingRecs = (response: RecommendationsType['issue']['items']): RecommendationsType['issue']['items'] => {
		const needsWorkItem = response.find((item) => item.resolved_on === null);

		return response.map((rec, index) => {
			return {
				...rec,
				status: rec.resolved_on
					? REC_STATUTES.MARKED_AS_DONE
					: needsWorkItem?.id === rec.id
						? REC_STATUTES.WORK_NEEDED
						: REC_STATUTES.POSTPONED
			}
		})
	}

	const handleEnrichingSingleRec = (mappedRecs: RecommendationsType['issue']['items'], response: RecommendationsType['issue']['items'], rec: string) => {
		const workNeededRec = mappedRecs.some(r => r.status === REC_STATUTES.WORK_NEEDED);
		const item = response.find(r => r.scan_type === rec)

		return {
			...item!,
			status: item!.resolved_on
				? REC_STATUTES.MARKED_AS_DONE
				: workNeededRec
					? REC_STATUTES.POSTPONED
					: REC_STATUTES.WORK_NEEDED
		}
	}

	const handleMarkedAsDone = (id: number | string) => {
		mutateRec({viqRecId: id}, {
			onError: () => notifyError('Something wrong happened, please try again later'),
			onSuccess: async () => {
				notifySuccess('Successfully edited recommendation.');
				setRecommendations((prevState) => {
					if (prevState) {
						prevState = handleActiveRecommendation(prevState, id, REC_STATUTES.MARKED_AS_DONE)
					}
					return prevState;
				});
			}
		})

	}

	const handleMarkedAsSkipped = (id: number | string) => {
		setRecommendations((prevState) => {
			if (!prevState) {
				return prevState;
			}

			return handleActiveRecommendation(prevState, id, REC_STATUTES.MARKED_AS_SKIPPED);
		})
	}

	const handleActiveRecommendation = (prevState: RecommendationsType, id: number | string, newStatus: REC_STATUTES.MARKED_AS_SKIPPED | REC_STATUTES.MARKED_AS_DONE): RecommendationsType => {
		const nextOnTheQueue = prevState.issue.items.find((item) => item.status === REC_STATUTES.POSTPONED);

		return {
			...prevState,
			issue: {
				...prevState.issue,
				items: prevState.issue.items.map((rec) => ({
					...rec,
					status:
						rec.id == id
							// Collapse the marked one
							? newStatus
							// Expand the next in the queue
							: rec.id === nextOnTheQueue?.id
								? REC_STATUTES.WORK_NEEDED
								: rec.status
				}))
			}
		}
	}

	async function handleGetRec(step: number): Promise<AxiosResponse<RecommendationsType>> {
		return await api.get(ENDPOINTS.VIQ.get_viq_recommendations_payload(domainScoreId, step));
	}

	async function handleMarkedAsDonePatch({viqRecId}: { viqRecId: number | string }): Promise<AxiosResponse<RecommendationsType>> {
		return await api.patch(ENDPOINTS.VIQ.update_viq_recommendation(viqRecId));
	}

	const handleMovingToTheNextStep = () => {
		// We need to be able to keep track of the unresolved issues, if the user goes back and forward
		!activatedPrevSteps && handleAddingTheUnresolvedIssues();

		activatedPrevSteps && setActivatedPrevSteps(previousStep => previousStep - 1);

		if (recommendations?.total_steps && recommendations?.total_steps < step + 1) {
			nextStep && nextStep();
			return;
		}

		setStep(prevState => prevState + 1);
	}

	const handleMovingToThePreviousStep = () => {
		if (step === 1) {
			setFixedIssues(0);
			setUnResolvedIssues(0);
			previousStep && previousStep();
			return;
		}

		setActivatedPrevSteps(previousStep => previousStep + 1);
		setStep(prevState => prevState - 1);
	}

	const handleAddingTheUnresolvedIssues = () => {
		if (!recommendations) {
			return;
		}

		setUnResolvedIssues(prevState => prevState + recommendations?.issue.items
			.filter(rec => rec.status === REC_STATUTES.WORK_NEEDED
				|| rec.status === REC_STATUTES.POSTPONED
				|| rec.status === REC_STATUTES.MARKED_AS_SKIPPED)
			.length);
	}

	if (error) {
		notifyError('Something went wrong please try again later');
		return <LoadingSpinner/>;
	}

	return (
		<>
			{
				isLoading || isFetching || isMarkingLoading
					? <LoaderWithFacts domain={domain} title={"Gathering recommendations can take between 15sec and 2 min."}/>
					: (
						<section className={"modal_recommendations_bg"}>
							<h4 data-testid={dataTestIds.viq.pageTitle} className="pt-6 mt-0 mb-2 font-normal text-lg sm:text-xl leading-6 text-center">{recommendations?.issue.title}</h4>
							<div className="max-sm:p-4 p-6 modal_recommendations_body">
								{
									recommendations?.issue.items.map((rec, index) => {
										return (
											<div key={rec.id}>
												<AIRecommendation
													id={rec.id}
													status={rec && rec.status !== REC_STATUTES.WORK_NEEDED
														? rec.status === REC_STATUTES.POSTPONED
															? REC_STATUTES.POSTPONED
															: rec.status
														: rec.status
													}
													title={rec.scan_type.toUpperCase()}
													risk={rec.risk as REC_RISKS}
													details={rec.details}
													recommendation={rec.recommendation}
													onDoneClick={(id: number | string) => {
														setFixedIssues(prevState => prevState + 1);
														handleMarkedAsDone(id);
													}}
													onSkipClick={(id: number | string) => handleMarkedAsSkipped(id)}
												/>
											</div>
										)
									})
								}
								<div className="text-center mb-1 relative">
									<Button
										onClick={handleMovingToTheNextStep}
										data-testid={dataTestIds.viq.nextRecBtn}
										disabled={isLoading || isFetching || isMarkingLoading}
										sx={{paddingX: '27px !important', color: 'white'}}
										className="bg-light-blue-3 hover:btn-box-shadow">
										Next
									</Button>
									<div className="ms-3 text-dark-purple flex items-center absolute bottom-0.5">
										<ArrowBackIcon className="text-dark-purple"/>
										<Button onClick={handleMovingToThePreviousStep}
												disabled={isLoading || isFetching || isMarkingLoading}
												className="ms-2 font-semibold cursor-pointer text-dark-purple"
												data-testid={dataTestIds.viq.backBtn}
											>
											Back
										</Button>
									</div>
								</div>
							</div>
							<div>
							<GenerateTicket domain={domain} ticketData={ticketData}/>
							</div>
						</section>
					)
			}
		</>
	);
};

export default ModalRecommendations;
import React, {useEffect, useState} from 'react';

import {useQuery} from "@tanstack/react-query";
import {useAPI} from "../../utils/hooks/useAPI";
import {defaultEmTheme} from "../../utils/theme";
import {DomainScoreRecommendations} from "../../interfaces";

export interface TenantThemeProps {
  name: string,
  logo: string,
  fontSize: string,
  fonts: {
    mainFont: string,
    subFont: string,
    all: string[]
  },
  footerContent: string,
  borderLineColor: string,
  borderCircle: {
    urgentColor: string,
    importantColor: string
  },
  buttons: {
    mainButtonsColor: string,
    actionButtonsColor: string
  },
  mainPageSettings: {
    element: string,
    text: string
  }[],
  recommendations?: Array<DomainScoreRecommendations>
}
export const ThemeContext = React.createContext<TenantThemeProps>(defaultEmTheme);

export const ThemeContextProvider: React.FC = ({children}) => {
  const [currentThemeProps, setCurrentThemeProps] = useState<TenantThemeProps>(defaultEmTheme);
  const [isThemeSet, setIsThemeSet] = useState<boolean>();
  const api = useAPI();

  const {data: response} = useQuery({
    queryKey: ['fetch-tenant-info'],
    queryFn: fetchTenantInfo,
    enabled: !isThemeSet,
    refetchOnWindowFocus: false
  })

  function fetchTenantInfo() {
    return api.get(`/tenants/current-tenant/theme`);
  }

  useEffect(() => {
    if (response && Object.keys(response.data.theme).length !== 0) {
      setCurrentThemeProps(response.data.theme)
      setIsThemeSet(true)
    }
  }, [response])

  return (
    <ThemeContext.Provider value={currentThemeProps}>{children}</ThemeContext.Provider>
  )
}
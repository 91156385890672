import React, {Dispatch, useState} from "react";
import {TenantProps} from "../../interfaces";

export type TenantPropsContextType = {
  tenantProps?: TenantProps;
  setTenantProps?: Dispatch<React.SetStateAction<TenantProps | undefined>>;
}

export const TenantPropsContext = React.createContext<TenantPropsContextType>({});

export const TenantPropsContextProvider: React.FC = ({children}) => {
  const [tenantProps, setTenantProps] = useState<TenantProps>();

  return (
    <TenantPropsContext.Provider value={{tenantProps, setTenantProps}}>{children}</TenantPropsContext.Provider>
  )
}

import React, {forwardRef, useEffect, useState} from 'react';
import {AxiosError} from "axios";
import {FormikErrors, FormikProvider, FormikTouched, FormikValues, useFormik} from 'formik';
import {Button} from "@mui/material";
import * as Yup from "yup";

import FormikMUISimpleInput from "../../../../../components/Formik/MUISimpleInput/FormikMUISimpleInput";
import FormikMUISelect, {FormikSelectItem} from "../../../../../components/Formik/MUISelect/FormikMUISelect";
import FormikMUIPhoneInput from "../../../../../components/Formik/MUIPhoneInput/FormikMUIPhoneInput";
import {Modal} from "../../../../../components/utils/Modal/Modal";
import {
    businessRelationships,
    countries,
    employeesCounts, ENDPOINTS,
    industries,
    revenues,
    usaStates
} from "../../../../../constants";
import {useAPI} from "../../../../../utils/hooks/useAPI";
import {replaceEmptyStringsWithNullsInJSON} from "../../../../../utils/helpers";
import './AddMember.scss'

// TODO: any ?
interface FormElementInputProps {
    name: string;
    label: string;
    type?: string;
    required?: boolean;
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    size?: "small" | "medium";
    isPhone?: boolean;
    formik?: any;
}

interface FormElementSelectProps extends FormElementInputProps {
    items: Array<FormikSelectItem>
}

export const FormElementInputField: React.FC<FormElementInputProps> = ({
                                                                    name, label, required,
                                                                    type, errors,
                                                                    touched, size = "small",
                                                                    isPhone, formik
                                                                }) => (
    <>
        {isPhone ?
            <div className="member-modifier__form-control member-modifier__form-control--phone">
                <FormikMUIPhoneInput
                    name={name}
                    label={label}
                    size={size}
                    required={required}
                    error={!!(errors[name] && touched[name])}
                    formik={formik}
                />
            </div>
            :
            <div className="member-modifier__form-control">
                <FormikMUISimpleInput
                    name={name}
                    label={label}
                    required={required}
                    type={type}
                    error={!!(errors[name] && touched[name])}
                    size={size}
                />
            </div>
        }
    </>
)


export const FormElementSelectField: React.FC<FormElementSelectProps> = ({
                                                                      name, label, items,
                                                                      required, errors,
                                                                      touched, size = "small"
                                                                  }) => (
    <div className="member-modifier__form-control">
        <FormikMUISelect
            name={name}
            items={items}
            label={label}
            required={required}
            error={!!(errors[name] && touched[name])}
            size={size}
        />
    </div>
)

interface componentProps {
    handleSubmitResult: Function;
    title: string;
}


const AddMember: React.FC<componentProps> = forwardRef(({handleSubmitResult, title}, ref) => {
    const api = useAPI();
    const [selectedCountry, setSelectedCountry] = useState<string>("United States");
    const [simpleModalOpened, setSimpleModalOpened] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const formik = useFormik({
        initialValues: {
            contact_email: "",
            contact_name: "",
            company_name: "",
            contact_position: "",
            contact_phone: "",
            mailing_address: "",
            industry: "",
            revenue: "",
            mailing_address_2: "",
            number_employees: "",
            business_relationship: "",
            country: "United States",
            state: "",
            city: "",
            zip: "",
            domain: "",
        },
        validationSchema: Yup.object().shape({
            contact_email: Yup.string()
                    .email("You must enter a valid email.")
                    .max(254, "This field must contain at most 254 characters.")
                    .required("This field is required."),
            contact_name: Yup.string()
                .max(255, "This field must contain at most 255 characters.")
                .required("This field is required."),
            company_name: Yup.string()
                .max(255, "This field must contain at most 255 characters.")
                .required("This field is required."),
            contact_position: Yup.string()
                .max(100, "This field must contain at most 100 characters.")
                .notRequired(),
            contact_phone: Yup.string()
                .notRequired()
                .min(8, "This field must contain at least 8 characters.")
                .max(16, "This field must contain at most 16 characters."),
            mailing_address: Yup.string()
                .max(255, "This field must contain at most 255 characters.")
                .required("This field is required."),
            industry: Yup.string()
                .notRequired(),
            revenue: Yup.string()
                .notRequired(),
            mailing_address_2: Yup.string()
                .max(50, "This field must contain at most 50 characters.")
                .notRequired(),
            number_employees: Yup.string()
                .notRequired(),
            business_relationship: Yup.string()
                .required("This field is required."),
            country: Yup.string()
                .required("This field is required."),
            state: selectedCountry === "United States" ?
                Yup.string()
                    .required("This field is required.")
                    .max(50, "This field must contain at most 50 characters.")
                : Yup.string()
                    .notRequired()
                    .max(50, "This field must contain at most 50 characters."),
            city: Yup.string()
                .max(30, "This field must contain at most 30 characters.")
                .required("This field is required."),
            zip: selectedCountry === "United States" ?
                Yup.string()
                    .required("This field is required.")
                    .matches(/\b\d{5}\b/g, "You must enter a 5 digit ZIP code.") :
                Yup.string()
                    .required("This field is required.")
                    .max(10, "This field must contain at most 16 characters."),
            domain: Yup.string()
                    .required("This field is required.")
                    .matches(/^[\w-]+([.]{1}[\w-]{0,61})*\.[\w-]{2,}$/,
                        "You must enter a valid domain."),
        }),
        onSubmit: (formData, {setSubmitting}) => {
            const values = replaceEmptyStringsWithNullsInJSON(formData)
            const response = api.post(ENDPOINTS.MEMBERS.get_or_create_member, {values});
            response.then((result) => {
                handleSubmitResult(result.data.created_member_id);
            }).catch((error) => {
                setErrorMessage((error as AxiosError).response?.data[0])
                setSimpleModalOpened(true);
            })
            setSubmitting(false);
        },
    });

    useEffect(() => {
        localStorage.setItem('userModalInput', `${formik.dirty}`)
    }, [formik.dirty])

    useEffect(() => {
        setSelectedCountry((prevValues) => {
            if (prevValues !== formik.values.country) {
                formik.values.state = "";
            }
            return formik.values.country
        });
    }, [formik.values, formik.values.country])

    // TODO: any -> object

    function handleModalClose() {
        setSimpleModalOpened(false);
    }

    // TODO: style -> ?
    return (
        <section className="member-modifier" onClick={e => {
            e.stopPropagation()
        }}>
            <div className="member-modifier__main">
                <FormikProvider value={formik}>
                    <h4 className="member-modifier__modal-title" style={{textAlign: 'center'}}>{title}</h4>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="member-modifier__form">
                            <div className="member-modifier__section">
                                <h4 className="member-modifier__header">
                                    Company information
                                </h4>
                                <FormElementInputField name="company_name" label="Company name"
                                                       errors={formik.errors} touched={formik.touched}
                                                       required/>
                                <FormElementInputField name="contact_name" label="Contact name"
                                                       errors={formik.errors} touched={formik.touched}
                                                       required/>
                                <FormElementInputField name="contact_email"
                                                       label="Contact email"
                                                       errors={formik.errors}
                                                       touched={formik.touched}
                                                       required/>
                                <FormElementInputField name="domain" label="Domain"
                                                       errors={formik.errors}
                                                       touched={formik.touched} required/>
                                <FormElementSelectField items={businessRelationships}
                                                        name="business_relationship"
                                                        label="Business relationship"
                                                        errors={formik.errors}
                                                        touched={formik.touched} required/>
                            </div>
                            <div className="member-modifier__section">
                                <h4 className="member-modifier__header">
                                    Optional details
                                </h4>
                                <FormElementInputField name="contact_phone"
                                                       label="Contact phone (optional)"
                                                       errors={formik.errors} touched={formik.touched}
                                                       isPhone={true}
                                                       formik={formik}/>
                                <FormElementInputField name="contact_position"
                                                       label="Contact position (optional)"
                                                       errors={formik.errors} touched={formik.touched}/>
                                <FormElementSelectField items={industries} name="industry"
                                                        label="Industry (optional)"
                                                        errors={formik.errors} touched={formik.touched}/>
                                <FormElementSelectField items={revenues} name="revenue"
                                                        label="Revenue (optional)"
                                                        errors={formik.errors}
                                                        touched={formik.touched}/>
                                <FormElementSelectField items={employeesCounts} name="number_employees"
                                                        label="Employee count (optional)"
                                                        errors={formik.errors} touched={formik.touched}/>
                            </div>
                        </div>
                        <div>
                            <div className="member-modifier__mailing-wrapper">
                                <h4 className="member-modifier__address-header">
                                    Mailing address
                                </h4>
                                <div className={"member-modifier__mailing-info"}>
                                    <div className="member-modifier__section">
                                        <FormElementInputField name="mailing_address" label="Mailing address"
                                                               errors={formik.errors} touched={formik.touched}
                                                               required/>
                                        <FormElementInputField name="mailing_address_2"
                                                               label="Mailing address 2 (optional)"
                                                               errors={formik.errors} touched={formik.touched}/>
                                        <FormElementInputField name="zip" label="ZIP code"
                                                               errors={formik.errors} touched={formik.touched}
                                                               required/>
                                    </div>
                                    <div className="member-modifier__section">
                                        <FormElementInputField name="city" label="City" errors={formik.errors}
                                                               touched={formik.touched} required/>
                                        {formik.values.country !== "United States" ?
                                            <FormElementInputField name="state" label="State"
                                                                   errors={formik.errors} touched={formik.touched}/>
                                            :
                                            <FormElementSelectField items={usaStates} name="state" label="State"
                                                                    errors={formik.errors} touched={formik.touched}
                                                                    required/>
                                        }
                                        <FormElementSelectField items={countries} name="country" label="Country"
                                                                errors={formik.errors} touched={formik.touched}
                                                                required/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"member-modifier__submit-wrapper"}>
                            <Button variant="contained"
                                    className="member-modifier__submit"
                                    type="submit"
                                    disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                </FormikProvider>
            </div>
            <Modal
                useFooter={false}
                onClose={handleModalClose}
                show={simpleModalOpened}
                hideConfirmButton={true}
                hideCloseButton={true}
                onDeny={handleModalClose}
                size="lg"
            >
                <div className="member-modifier__modal">
                    <h3 className="member-modifier__title">Something went wrong with your request</h3>
                    <p className="member-modifier__content">{errorMessage}</p>
                </div>
            </Modal>
        </section>
    )
})

export default AddMember;

import {useEffect, useState} from "react";

import {GoogleAnalyticsChartData} from "../../../components/chart/types";
import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import {useAPI} from "../../../utils/hooks/useAPI";
import {Ticket} from "../TicketTracking/TicketTracking";
import ReportsChart from "../../../components/Charts/ReportsChart";
import DashboardComponent from "../../../components/DashboardComponent/DashboardComponent";
import {dateFormatToUniversal} from "../../../utils/helpers";
import {ENDPOINTS} from "../../../constants";
import './Engagement.scss';
import MemberStats from "../../../components/MemberStats/MemberStats";

const Engagement = () => {
	const api = useAPI();
	const [landingPageChartData, setLandingPageChartData] = useState<GoogleAnalyticsChartData[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [pageCount, setPageCount] = useState<number>(1)
	const [shownTickets, setShownTickets] = useState<Array<Ticket>>([]);
	const [shownTicketsStatus, setShownTicketsStatus] = useState<string>("new");
	const [loading, setLoading] = useState<boolean>();
	const [ticketsLoaded, setTicketsLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (!landingPageChartData.length && !shownTickets.length) {
			initReportData();
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!ticketsLoaded) {
			return;
		}
		if (shownTicketsStatus !== "closed") {
			if (!shownTickets.length) {
				const nextStatus = getNewTicketStatus(shownTicketsStatus)
				setShownTicketsStatus(nextStatus)
				if (nextStatus !== 'closed') {
					getTickets(nextStatus)
				}
			}
		}
	}, [shownTickets]) // eslint-disable-line react-hooks/exhaustive-deps

	const initReportData = async () => {
		setLoading(true);
		const {data} = await api.post(ENDPOINTS.GOOGLE_ANALYTICS.get_overview_charts_data);

		const chartData = dateFormatToUniversal(data.overview_report_visits);
		setLandingPageChartData(chartData);
		getTickets(shownTicketsStatus);
		setLoading(false);
	}

	async function getTickets(status: string, searchValue: string = "", page: number = 1) {
		const {data} = await api.get(ENDPOINTS.TICKET_TRACKING.get_tickets(status, page))
		await setTicketsLoaded(true)
		setShownTickets(data.tickets);
		setCurrentPage(data.current_page)
		setPageCount(data.total_pages)
	}


	function getNewTicketStatus(currentStatus: string): string {
		if (currentStatus === "new")
			return "in progress"
		else if (currentStatus === "in progress")
			return "closed"
		return ""
	}

	return (
		<DashboardComponent>
			<article className="engagement app__test__engagement">
				{
					loading ?
						<LoadingSpinner/>
						:
						<div>
							<div className="engagement__linechart-container">
								<ReportsChart title="Report Views"
											  subtitle="the number of members engaging with a report"
											  data={landingPageChartData}
											  xAxisData={'date'}
											  yAxisData={'event_count'}
											  lineData={'event_count'}
								/>
							</div>

							<MemberStats />
						</div>
				}
			</article>
		</DashboardComponent>
	)

}

export default Engagement;

import React, {useEffect, useState} from "react";
import {MenuItem, SelectChangeEvent} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {useQuery} from "@tanstack/react-query";

import {ENDPOINTS} from "../../../../constants";
import {Tenant} from "../../../Dashboard/CampaignStats/AdminCampaignStats";
import {useAPI} from "../../../../utils/hooks/useAPI";
import '../../Users/Tenants/Table/DropdownActions.scss';
import './Filtesrs.scss'


const vulnerabilitiesFilters = [
  'All',
  'Empty',
  'No DNS',
  'No SSL',
  'No TLS',
  'No SSL Scan',
  'No Vulnerabilities',
  'No Vulnerabilities Scan'
]

const dateFilters = [
  'All',
  'Today',
  'Yesterday'
]

export interface RiskStatus {
  id: number,
  name: string,
  range: string
}

type FiltersProps = {
  riskStatus: string,
  setRiskStatus: React.Dispatch<React.SetStateAction<string>>,
  vulnerabilitiesFilterValue: string,
  setVulnerabilitiesFilterValue: React.Dispatch<React.SetStateAction<string>>,
  dateFilterValue: string,
  setDateFilterValue: React.Dispatch<React.SetStateAction<string>>,
  tenantFilterValue: string,
  setTenantFilterValue: React.Dispatch<React.SetStateAction<string>>,
}

const Filters = ({
                   riskStatus, setRiskStatus, vulnerabilitiesFilterValue, setVulnerabilitiesFilterValue,
                   dateFilterValue, setDateFilterValue, tenantFilterValue, setTenantFilterValue
                 }: FiltersProps) => {
  const api = useAPI();
  const [riskStatuses, setRiskStatuses] = useState<Array<RiskStatus>>([{
    id: 0,
    name: 'All',
    range: '350-850'
  }]);
  const [tenants, setTenants] = useState<Array<Tenant>>([{
    id: 0,
    name: 'All',
    subdomain_prefix: 'all',
    active: true
  }]);

  const {data: riskStatusesData,} = useQuery({
    queryKey: ['getRiskStatusesData'],
    queryFn: getRiskStatusesData,
    refetchOnWindowFocus: false,
  })

  const {data: tenantsData,} = useQuery({
    queryKey: ['getTenantsData'],
    queryFn: getTenantsData,
    refetchOnWindowFocus: false,
  })

  function getRiskStatusesData() {
    return api.get(ENDPOINTS.DOMAINS_SCORE.get_all_risk_statuses)
  }

  function getTenantsData() {
    return api.get(ENDPOINTS.TENANTS.get_all_tenants)
  }

  useEffect(() => {
    if (riskStatusesData) {
      setRiskStatuses([...riskStatuses, ...riskStatusesData.data])
    }
  }, [riskStatusesData?.data])

  useEffect(() => {
    if (tenantsData) {
      setTenants([...tenants, ...tenantsData.data])
    }
  }, [tenantsData?.data])

  const handleChangeRiskStatus = (event: SelectChangeEvent) => {
    setRiskStatus(event.target.value);
  };

  const handleChangeVulnerabilities = (event: SelectChangeEvent) => {
    setVulnerabilitiesFilterValue(event.target.value);
  };

  const handleChangeDate = (event: SelectChangeEvent) => {
    setDateFilterValue(event.target.value);
  };

  const handleChangeTenant = (event: SelectChangeEvent) => {
    setTenantFilterValue(event.target.value);
  };

  return (
    <>
      <div className="filters">
        <div>
          <p className="filters__title">Filter by:</p>
        </div>
        <div>
          <FormControl variant="standard" sx={{m: 1, minWidth: 120, marginLeft: "1.5rem"}}>
            <InputLabel id="demo-simple-select-label">Score Range:</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={riskStatus}
              onChange={handleChangeRiskStatus}
              label="Select Option"
              renderValue={(selected) => selected}
            >
              {riskStatuses.map((riskStatusObj) => (
                <MenuItem key={riskStatusObj.id} value={riskStatusObj.name}>
                  <Checkbox checked={riskStatus === riskStatusObj.name}/>
                  {riskStatusObj.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl variant="standard" sx={{m: 1, minWidth: 120, marginLeft: "1.5rem"}}>
            <InputLabel id="demo-simple-select-label">Vulnerabilities:</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={vulnerabilitiesFilterValue}
              onChange={handleChangeVulnerabilities}
              label="Select Option"
              renderValue={(selected) => selected}
            >
              {vulnerabilitiesFilters.map((filterName) => (
                <MenuItem key={filterName} value={filterName}>
                  <Checkbox checked={vulnerabilitiesFilterValue === filterName}/>
                  {filterName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl variant="standard" sx={{m: 1, minWidth: 120, marginLeft: "1.5rem"}}>
            <InputLabel id="demo-simple-select-label">Date:</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dateFilterValue}
              onChange={handleChangeDate}
              label="Select Option"
              renderValue={(selected) => selected}
            >
              {dateFilters.map((dateFilter) => (
                <MenuItem key={dateFilter} value={dateFilter}>
                  <Checkbox checked={dateFilter === dateFilterValue}/>
                  {dateFilter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl variant="standard" sx={{m: 1, minWidth: 120, marginLeft: "1.5rem"}}>
            <InputLabel id="demo-simple-select-label">Tenant:</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tenantFilterValue}
              onChange={handleChangeTenant}
              label="Select Option"
              renderValue={(selected) => selected}
            >
              {tenants.map((tenant) => (
                <MenuItem key={tenant.id} value={tenant.name}>
                  <Checkbox checked={tenant.name === tenantFilterValue}/>
                  {tenant.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  );
}

export default Filters;
import React, {useEffect, useRef, useState} from "react";
import {Button} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import './AnalyticsPDFReports.scss';
import {CampaignHistoryEntry} from "../../interfaces";
import useIsMobile from "../../utils/hooks/useIsMobile";
import {largeScreen} from "../../constants";

interface AnalyticsPdfReportsProps {
    initialCampaignId: number | undefined,
    campaignHistory: Array<Array<CampaignHistoryEntry>>,
    handleCampaignChange: Function
}

const blueColor = '#2DAAE2'

const AnalyticsPdfReports: React.FC<AnalyticsPdfReportsProps> = ({
                                                                     initialCampaignId,
                                                                     campaignHistory,
                                                                     handleCampaignChange
                                                                 }) => {
    const isLargeScreen = useIsMobile(largeScreen);
    const [arrowVisible, setArrowVisible] = useState<boolean>(false);
    const [arrowPosition, setArrowPosition] = useState<string>('0');
    const [selectionColor, setSelectionColor] = useState<typeof blueColor | 'green'>();
    const [scrolled, setScrolled] = useState<boolean>(false);
    const [selectedEntry, setSelectedEntry] = useState<CampaignHistoryEntry>();
    const [screenWidth, setWidth] = useState(window.innerWidth);
    const selectedCampaignRef = useRef<null | HTMLDivElement>(null);
    const navRef = useRef<null | HTMLElement>(null);

    useEffect(() => {
        const flatHistory = campaignHistory.flat()
        const el = flatHistory.find(entry => entry.campaign_id === initialCampaignId)
        if (el && screenWidth < 1900) {
            if (flatHistory.indexOf(el) >= 9) {
                setScrolled(true)
            }
        }
        setSelectedEntry(el)
        setSelectionColor(el?.in_progress ? blueColor : 'green')
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        (async () => {
            await changeArrowPosition()
        })()
            .then(() => {
                setSelectionColor(selectedEntry?.in_progress ? blueColor : "green");
                if (selectedCampaignRef.current?.offsetLeft && !isLargeScreen) {
                    setArrowVisible(true);
                }
            })
    }, [selectedCampaignRef.current?.offsetLeft]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedCampaignRef.current?.offsetLeft && !isLargeScreen) {
            setArrowVisible(true)
        } else {
            setArrowVisible(false)
        }
        changeArrowPosition()
    }, [scrolled])

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }

    const changeArrowPosition = async () => {
        if (selectedCampaignRef.current && navRef.current) {
            const currentOffsetFromNavStart = selectedCampaignRef.current.offsetLeft - navRef.current.offsetLeft;
            setArrowPosition(`${currentOffsetFromNavStart + 27}`)
        }
    }

    const scrollToQuarterHandler = () => {
        setScrolled((prevScroll) => !prevScroll);
    }

    function getElementColor(campaignEntry: CampaignHistoryEntry) {
        if (campaignEntry.is_scheduled) {
            return "secondary"
        } else if (campaignEntry.in_progress) {
            return "primary"
        } else if (!(campaignEntry.campaign_id || campaignEntry.is_scheduled)) {
            return "disabled"
        } else {
            return "success"
        }
    }

    function getElementText(color: String) {
        switch (color) {
            case "success":
                return "Sent";
            case "disabled":
                return "N/A";
            case "primary":
                return "Ongoing";
            case "secondary":
                return "Scheduled"
        }
    }

    function handleNavClick(campaignEntry: CampaignHistoryEntry) {
        const color = getElementColor(campaignEntry)
        if (color === "disabled" || color === "secondary") return;
        handleCampaignChange(campaignEntry)
        setSelectedEntry(campaignEntry)
    }

    // TODO: style ?
    return (campaignHistory &&
        <fieldset className="urs__quarters" style={{borderColor: selectionColor}}>
            <legend style={{marginLeft: `${arrowPosition}px`}} className="urs__quarters-legend">
                {arrowVisible && !isLargeScreen &&
                    <ArrowBackIosNewOutlinedIcon className="urs__legend-arrow"
                                                 style={{
                                                     transform: "rotate(90deg)",
                                                     color: selectionColor,
                                                     marginLeft: "-5px",
                                                     fontSize: "1.15rem"
                                                 }}
                    />
                }
            </legend>
            <nav ref={navRef} className="urs__quarters-body">
                {scrolled && !isLargeScreen && <div className="urs__scroller">
                    <div className="urs__scroller--right_border">
                        <h4>Q1</h4>
                        <Button onClick={scrollToQuarterHandler}>
                            <ArrowBackIosIcon fontSize={"large"}/>
                        </Button>
                    </div>
                </div>}
                {!scrolled && <article>
                    <h4 className="urs__quarter-title">Q1</h4>
                    <article className="urs__quarter">
                        {campaignHistory[0] && campaignHistory[0].map((campaignEntry: CampaignHistoryEntry, index: number) => {
                            const color = getElementColor(campaignEntry);
                            return (
                                <div className="urs__status_wrapper"
                                     key={index}
                                     ref={campaignEntry.campaign_id === initialCampaignId
                                         ? selectedCampaignRef
                                         : null
                                     }
                                     style={color === "success" || color === "primary" ? {cursor: "pointer"} : {}}
                                     onClick={() => handleNavClick(campaignEntry)}>
                                    <PictureAsPdfIcon
                                        className="urs__pdf_icon"
                                        fontSize={"large"}
                                        color={color}
                                    />
                                    <p className="urs__status">{getElementText(color)}</p>
                                </div>
                            )
                        })}
                    </article>
                </article>}
                <article>
                    <h4 style={{marginLeft: "2rem"}}>Q2</h4>
                    <article className="urs__quarter">
                        <div className="urs__quarter-divider"></div>
                        {campaignHistory[1] && campaignHistory[1].map((campaignEntry: CampaignHistoryEntry, index: number) => {
                            const color = getElementColor(campaignEntry);
                            return (
                                <div className="urs__status_wrapper"
                                     key={index}
                                     ref={campaignEntry.campaign_id === initialCampaignId
                                         ? selectedCampaignRef
                                         : null
                                     }
                                     style={color === "success" || color === "primary" ? {cursor: "pointer"} : {}}
                                     onClick={() => handleNavClick(campaignEntry)}>
                                    <div className={index === 0
                                        ? "urs__left-border"
                                        : index === 2
                                            ? "urs__right-border"
                                            : ""}>
                                        <PictureAsPdfIcon
                                            className="urs__pdf_icon"
                                            fontSize={"large"}
                                            color={color}
                                        />
                                    </div>
                                    <p className={index === 0 ? "urs__status urs__status--padding-left"
                                        : index === 2
                                            ? "urs__status urs__status--padding-right"
                                            : "urs__status"}>
                                        {getElementText(color)}
                                    </p>
                                </div>
                            )
                        })}
                        <div className="urs__quarter-divider"></div>
                    </article>
                </article>
                <article>
                    <div>
                        <h4 className="urs__quarter-title">Q3</h4>
                        <article className="urs__quarter">
                            {campaignHistory[2] && campaignHistory[2].map((campaignEntry: CampaignHistoryEntry, index: number) => {
                                const color = getElementColor(campaignEntry);
                                return (
                                    <div className="urs__status_wrapper" key={index}
                                         ref={campaignEntry.campaign_id === initialCampaignId
                                             ? selectedCampaignRef
                                             : null
                                         }
                                         style={color === "success" || color === "primary" ? {cursor: "pointer"} : {}}
                                         onClick={() => handleNavClick(campaignEntry)}>
                                        <div className={index === 2 ? "urs__right-border" : ""}>
                                            <PictureAsPdfIcon
                                                className="urs__pdf_icon"
                                                fontSize={"large"}
                                                color={color}
                                            />
                                        </div>
                                        <p className={index === 2 ? "urs__status urs__status--padding" : "urs__status"}>{getElementText(color)}</p>
                                    </div>
                                )
                            })}
                            <div className="urs__quarter-divider"></div>
                        </article>
                    </div>
                </article>
                {(scrolled || screenWidth > 1900 || screenWidth < 992) && <article className="urs__q4">
                    <h4 className="urs__quarter-title">Q4</h4>
                    <article className="urs__quarter">
                        {campaignHistory[3] && campaignHistory[3].map((campaignEntry: CampaignHistoryEntry, index: number) => {
                            const color = getElementColor(campaignEntry);
                            return (
                                <div className="urs__status_wrapper" key={index}
                                     ref={campaignEntry.campaign_id === initialCampaignId
                                         ? selectedCampaignRef
                                         : null
                                     }
                                     style={color === "success" || color === "primary" ? {cursor: "pointer"} : {}}
                                     onClick={() => handleNavClick(campaignEntry)}>
                                    <PictureAsPdfIcon
                                        className="urs__pdf_icon"
                                        fontSize={"large"}
                                        color={color}
                                    />
                                    <p className="urs__status">{getElementText(color)}</p>
                                </div>
                            )
                        })}
                    </article>
                </article>}
                {(!scrolled && screenWidth < 1900 && !isLargeScreen) && <div className="urs__scroller">
                    <div className="urs__scroller--left_border">
                        <h4>Q4</h4>
                        <Button onClick={scrollToQuarterHandler}>
                            <ArrowForwardIosIcon fontSize={"large"}/>
                        </Button>
                    </div>
                </div>}
            </nav>
        </fieldset>
    )
}

export default AnalyticsPdfReports;

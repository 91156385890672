import React, {useEffect} from "react";
import {useMutation} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {useNavigate, useSearchParams} from "react-router-dom";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import {useAPI} from "../../utils/hooks/useAPI";
import {ENDPOINTS} from "../../constants";


const AcceptInvitation = () => {
  const api = useAPI()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();

  const {mutate: validateInvitation} = useMutation({
    mutationFn: validateInvitationFn,
    onSuccess: () => {
      navigate(`/admin?isInvited=true`);
    },
    onError: (error) => {
      if ((error as AxiosError).response?.status === 404) {
        navigate(`/invalid-link`);
      }
      if ((error as AxiosError).response?.status === 400) {
        navigate(`/expired-link`);
      }
    },
  })

  function validateInvitationFn() {
    return api.post(ENDPOINTS.TENANTS.validate_invitation, {
      'token': searchParams.get("token"),
      'expiration_time': searchParams.get("expires")
    })
  }

  useEffect(() => {
    if (searchParams && searchParams.get("token") && searchParams.get("expires")) {
      validateInvitation()
    } else {
      navigate(`/not-found`);
    }
  }, [])

  return (
    <LoadingSpinner/>
  )
}

export default AcceptInvitation
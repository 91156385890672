import React from 'react';
import './YellowLoader.scss';

const YellowLoader = () => {
  return (
    <div className="w-auto bg-red px-5 py-0.5 rounded-full bg-[#7b61ff0d]">
      <p className="yellow-loader"/>
    </div>
  );
};

export default YellowLoader;
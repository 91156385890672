import React, {ReactNode} from 'react';
import {SwipeableDrawer} from "@mui/material";

type SideDrawerTypes = {
    isDrawerToggled: boolean;
    setIsDrawerToggled: React.Dispatch<React.SetStateAction<boolean>>;
    children: ReactNode
}

const SideDrawer = ({isDrawerToggled, setIsDrawerToggled, children}: SideDrawerTypes) => {
    return (
        <SwipeableDrawer
            anchor={"right"}
            open={isDrawerToggled}
            onClose={() => setIsDrawerToggled(false)}
            onOpen={() => setIsDrawerToggled(true)}
            BackdropProps={{ invisible: true }}
        >
            {children}
        </SwipeableDrawer>
    );
};

export default SideDrawer;
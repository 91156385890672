import React, {useState} from "react";

import DashboardComponent from "../../../components/DashboardComponent/DashboardComponent";
import NavigationTabs from "../../../components/NavigationTabs/NavigationTabs";
import Overview from "./Overview";
import Details from "./Details";
import {useQuery} from "@tanstack/react-query";
import {useAPI} from "../../../utils/hooks/useAPI";
import {notifyError} from "../../../components/utils/ToastNotifications/Notifier";
import LoadingSpinner from "../../../components/loading/LoadingSpinner";

const LandingPageStats = () => {
	const api = useAPI();
	const OVERVIEW = 'overview';
	const DETAILS = 'details';
	const tabs = [{id: OVERVIEW, label: 'Overview'}, {id: DETAILS, label: 'Details'}]
	const [activeTab, setActiveTab] = useState<string>(OVERVIEW);

	const query = useQuery({
		queryKey: ['get-tenant-domains'],
		queryFn: () => getTenantDomains(),
		refetchOnWindowFocus: false,
	});

	const getTenantDomains = async () => {
	  const response =  await api.get('tenants/domains/');
		if (response.status !== 200) {
			notifyError("Something went wrong, please try again or contact us at support@edisonmarks.com");
			return;
		}

		return response;
	}

	return (
		<DashboardComponent>
			<NavigationTabs tabs={tabs} activeTab={activeTab} changeTabHandler={setActiveTab}/>
			<article className="campaign-stats">
				{
					query.isFetching || !query.data?.data ?
						<LoadingSpinner/> :
						(activeTab === OVERVIEW ? <Overview/> : <Details/>)
				}
			</article>
		</DashboardComponent>
	)
}


export default LandingPageStats;

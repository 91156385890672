import {useEffect, useState} from "react";
import * as React from "react";
import {AxiosResponse} from "axios";
import TextField from "@mui/material/TextField/TextField";
import {useQuery} from "@tanstack/react-query";

import DashboardComponent from "../../../../components/DashboardComponent/DashboardComponent";
import {ENDPOINTS} from "../../../../constants";
import LoginsTable, {User} from "./LoginsTable";
import {useAPI} from "../../../../utils/hooks/useAPI";
import {useDebounce} from "../../../../utils/hooks/useDebounce";
import '../Tenants/Table/DropdownActions.scss'
import './UserLogins.scss';

export type UserLoginsTableType = {
  current_page: number,
  total_pages: number,
  users: User[]
}

const UserLogins = () => {
  const api = useAPI();
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageCount, setPageCount] = useState<number>(1)
  const [users, setUsers] = useState<Array<User>>([])
  const [searchValue, setSearchValue] = useState('')
  const debounce = useDebounce(searchValue, 1000)

  const {data: userLoginsData} = useQuery({
    queryKey: ['getUserLoginsData', currentPage, debounce],
    queryFn: getUserLoginsData,
    refetchOnWindowFocus: false,
  })

  function getUserLoginsData(): Promise<AxiosResponse<UserLoginsTableType>> {
    return api.get(ENDPOINTS.ADMIN.get_user_logins(currentPage, debounce))
  }

  useEffect(() => {
    if (userLoginsData) {
      setUsers(userLoginsData.data.users)
      setCurrentPage(userLoginsData.data.current_page)
      setPageCount(userLoginsData.data.total_pages)
    }
  }, [userLoginsData?.data, currentPage])

  return (
    <DashboardComponent>
      <article className="user-logins">
        <div className="dropdown-actions">
          <div className="dropdown-actions__title">
            <p className="m-0">User Logins</p>
          </div>
          <div className="user-logins__search-field">
            <TextField className="user-logins__search-label"
                       id="outlined-basic" label="Enter email or name to search" variant="outlined"
                       value={searchValue} size="small"
                       onChange={e => {
                         setSearchValue(e.target.value)
                       }}/>
          </div>
        </div>
        <LoginsTable
          users={users}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageCount={pageCount}
        />
      </article>
    </DashboardComponent>
  )
}

export default UserLogins;
import React, {useState} from 'react';
import {Form, FormikProvider, useFormik} from "formik";
import FormikMUISimpleInput from "../../components/Formik/MUISimpleInput/FormikMUISimpleInput";
import {Alert, AlertTitle, Button, Tooltip, Typography} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {useAuth0} from "@auth0/auth0-react";
import {useAPI} from "../../utils/hooks/useAPI";
import * as yup from "yup";
import {useMutation} from "@tanstack/react-query";
import {notifyError, notifySuccess} from "../../components/utils/ToastNotifications/Notifier";
import {AxiosError} from "axios";
import {ENDPOINTS} from "../../constants";

const onboardingValidation = yup.object({
  name: yup.string().required('This field is required'),
  company_name: yup.string().required('This field is required'),
  subdomain_prefix: yup.string()
    .required('This field is required')
	.matches(/^[a-zA-Z0-9-]*$/, 'You must enter a valid subdomain - subdomain contains alphanumeric characters as well as hyphens(-)')
})

const Onboarding = () => {
  const {user} = useAuth0()
  const api = useAPI();
  const userProvider = user?.sub?.split('|')[0];
  const [formValues, setFormValues] = useState<any>({
    name: user?.name && userProvider !== 'email' ? user.name : '',
    company_name: '',
    subdomain_prefix: '',
  });
  const [tenantsLimitReached, setTenantsLimitReached] = useState(false)

  const handleFormSubmit = (values: {name?: string, company_name: string, subdomain_prefix: string}) => {
    const requestData = {...values, subdomain_prefix: values.subdomain_prefix.toLowerCase()}
    if (requestData['name'] === user?.name) {
      delete requestData['name'];
    }
    return api.post(ENDPOINTS.TENANTS.create_tenant, requestData);
  }

  const {mutate: createTenant, isLoading} = useMutation({
    mutationFn: handleFormSubmit,
    onSuccess: async ({data: {subdomain_prefix}}) => {
      notifySuccess('Your tenant has been successfully added.');
      window.open(`https://${subdomain_prefix}.edisonmarks.com/admin/member-management`, "_self")
    },
    onError: (error) => {
      if ((error as AxiosError).response?.data?.['error_code'] === 'tenants_limit_reached') {
        setTenantsLimitReached(true)
      } else {
        const customErrMessage = (error as AxiosError).response?.data?.subdomain_prefix && 'Looks like this subdomain is already taken. Please pick another one and try again.'

        notifyError(customErrMessage || 'Something went wrong please try again later or contact us.');
      }
    },
    onSettled: () => {
      setFormValues(formik.values)
    }
  })

  const formik = useFormik({
    initialValues: {
      name: formValues.name,
      company_name: formValues.company_name,
      subdomain_prefix: formValues.subdomain_prefix,
    },
    enableReinitialize: true,
    validationSchema: onboardingValidation,
    onSubmit: (values) => {
      createTenant(values);
    }
  })

  return (
    !tenantsLimitReached ?
      <section className="mt-10 lg:w-1/4">
        <FormikProvider value={formik}>
          <Form className="flex flex-col items-center">
            <h4 className="text-xl font-normal quicksand">Your Details</h4>
            <div className="mb-4 mt-1 w-full relative">
              <FormikMUISimpleInput error={!!formik.errors.name} label="Name" name="name"
                                    placeholder={'First and Last Name'}
                                    size="small"
                                    disabled={userProvider !== "email"}
                                    required/>
              {userProvider !== "email" &&
                  <Tooltip className="absolute mt-2 ml-2"
                           title={<Typography fontSize={"0.9rem"}>You cannot change your name due to being logged in via
                             social account (ex: google).</Typography>}>
                      <InfoOutlinedIcon className="app__ss-account-icon"/>
                  </Tooltip>}
            </div>
            <div className="mb-4 w-full">
              <FormikMUISimpleInput error={!!formik.errors.company_name} label="Company name" name="company_name"
                                    size="small" placeholder={'Company INC'} required/>
            </div>
            <div className="mb-5 w-full">
              <FormikMUISimpleInput error={!!formik.errors.subdomain_prefix} label="Subdomain" name="subdomain_prefix"
                                    size="small" placeholder={'subdomain (.edisonmarks.com)'} required/>
            </div>
            <div className="mb-16">
              <Button className="!font-bold !text-lg !px-7 !rounded-lg bg-orange mulish" type="submit"
                      size="large" variant="contained"
                      disabled={formik.isSubmitting || !formik.isValid || !formik.dirty || !formik.touched}>
                submit
              </Button>
            </div>
          </Form>
        </FormikProvider>
      </section>
      :
      <article>
        <Alert severity="error" className="mt-10 mb-24 "
               style={{fontFamily: '\'Mulish\', \'Quicksand\', sans-serif', fontSize: '1.1rem'}}>
          <AlertTitle style={{fontFamily: '\'Mulish\', \'Quicksand\', sans-serif', fontSize: '1.1rem'}}>
            You've reached the maximum number of tenants that you can own.
          </AlertTitle>
          If you still want to proceed with your request, please <a href="mailto:{support@edisonmarks.com}">contact the
          support team</a>
        </Alert>
      </article>
  );
};

export default Onboarding;

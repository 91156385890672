import React, {useContext, useEffect, useState} from 'react';
import {Form, Formik, FormikValues} from "formik";
import {Button} from "@mui/material";

import FormikMUISimpleInput from "../../../components/Formik/MUISimpleInput/FormikMUISimpleInput";
import {useNavigate, useParams} from "react-router-dom";
import {TenantUserPropsContext} from "../../../store/context/tenant-user-props-context";
import {useMutation, useQuery} from "@tanstack/react-query";
import {notifyError, notifySuccess} from "../../../components/utils/ToastNotifications/Notifier";
import {AxiosError} from "axios";
import {useAPI} from "../../../utils/hooks/useAPI";
import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import {ENDPOINTS} from "../../../constants";

const TenantAccount = () => {
  const {tenantUserProps} = useContext(TenantUserPropsContext);
  const {tenant_id: tenantId} = useParams()
  const navigate = useNavigate()
  const api = useAPI();
  const [tenantName, setTenantName] = useState<{ name: string }>()

  const {data: tenantData, isFetching: tenantDataLoading} = useQuery({
    queryKey: ['ssTenantInfo'],
    queryFn: fetchSSTenantInfo,
    refetchOnWindowFocus: false,
  })

  const {mutate: mutateTenantName} = useMutation({
    mutationFn: handleChangingTenantName,
    onError: (error) => notifyError((error as AxiosError).response?.data?.message || 'Something went wrong please try again later or contact us.')
  })

  useEffect(() => {
    if (!tenantUserProps?.is_owner) {
      navigate('/access-denied');
      return;
    }
  }, [tenantUserProps, tenantUserProps?.is_owner])

  useEffect(() => {
    if (tenantData?.data) {
      setTenantName({name: tenantData.data.name})
    }
  }, [tenantData?.data])

  async function handleChangingTenantName(values: FormikValues) {
    return api.patch(ENDPOINTS.TENANTS.edit_tenant(tenantId), values)
      .then((response) => {
        if (response.status === 200) {
          setTenantName({name: response.data.name})
          notifySuccess('You have successfully changed tenant data.');
        }
      });
  }

  function fetchSSTenantInfo() {
    return api.get(ENDPOINTS.TENANTS.get_current_tenant);
  }

  return (
    <section className="w-1/2">
      <h3>Account</h3>
      <p>Change your tenant details.</p>
      {tenantDataLoading ? <LoadingSpinner/> :
        <div>
          <Formik initialValues={{
            name: tenantName ? tenantName?.name : '',
          }}
                  enableReinitialize
                  onSubmit={(values: FormikValues, {}) => mutateTenantName(values)}
          >
            {({errors, touched, isValid}) => {
              return (
                <Form className="flex flex-col mt-6">
                  <div className="mb-4 mt-1 w-11/12 items-center relative">
                    <FormikMUISimpleInput error={!!errors.name} label="Name*" name="name"
                                          size="small"
                    />
                  </div>
                  {/*<div className="mb-4 mt-1 w-3/4 flex items-center">*/}
                  {/*  <FormikMUISimpleInput error={!!errors.name} label="Logo*" name="logo"*/}
                  {/*                        size="small"*/}
                  {/*  />*/}
                  {/*</div>*/}
                  <div className="mb-16 flex justify-center">
                    <Button className="!font-bold !text-lg !px-7 !rounded-lg bg-orange mulish" type="submit"
                            size="large" variant="contained"
                            disabled={!isValid || (Object.keys(touched).length === 0 && touched.constructor === Object)}
                    >
                      submit changes
                    </Button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>}
    </section>
  );
};

export default TenantAccount;

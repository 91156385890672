import React from "react";
import {Box, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import {Tenant} from "../../../../Dashboard/CampaignStats/AdminCampaignStats";
import TenantsTableHead from "./TableHead";

type TenantsTableBodyProps = {
  tenants: Tenant[],
  selectedTenantsIds: number[],
  setSelectedTenantsIds: React.Dispatch<React.SetStateAction<number[]>>
}

const TenantsTableBody = ({tenants, selectedTenantsIds, setSelectedTenantsIds}: TenantsTableBodyProps) => {
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = tenants.map((tenant) => tenant.id)
      setSelectedTenantsIds(newSelected);
      return;
    }
    setSelectedTenantsIds([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selectedTenantsIds.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTenantsIds, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTenantsIds.slice(1));
    } else if (selectedIndex === selectedTenantsIds.length - 1) {
      newSelected = newSelected.concat(selectedTenantsIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTenantsIds.slice(0, selectedIndex),
        selectedTenantsIds.slice(selectedIndex + 1),
      );
    }
    setSelectedTenantsIds(newSelected);
  };

  const isSelected = (id: number) => selectedTenantsIds.indexOf(id) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
          >
            <TenantsTableHead
              numSelected={selectedTenantsIds.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={tenants.length}
            />
            <TableBody>
              {tenants.map((tenant, index) => {
                const isItemSelected = isSelected(tenant.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, tenant.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={tenant.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {tenant.name}
                    </TableCell>
                    <TableCell style={{padding: '12px 12px 12px 0'}}>{tenant.subdomain_prefix}</TableCell>
                    <TableCell style={{padding: '12px 12px 12px 0'}}>
                      {tenant.active
                        ? <CheckCircleIcon className="text-green-600"/>
                        : <CancelIcon className="text-red-600"/>}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
    </Box>
  );
}

export default TenantsTableBody;
import React from "react";
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import moment from "moment";

import LoadingSpinner from "../../../../components/loading/LoadingSpinner";
import {Modal} from "../../../../components/utils/Modal/Modal";
import '../../../Dashboard/MemberManagement/SingleMember/SingleMemberInfo.scss'
import './LoginsTable.scss'

export interface User {
  name: string
  email: string
  connection: string
  logins: number
  latest_login: string
  app_metadata: {
    tenants: string[]
  }
}

type LoginTableProps = {
  users: User[] | undefined,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  currentPage: number,
  pageCount: number,
}

const LoginsTable = ({users, setCurrentPage, currentPage, pageCount}: LoginTableProps) => {
  const [userInfo, setUserInfo] = React.useState<User>()
  const [userInfoModal, setUserInfoModal] = React.useState<boolean>(false)

  if (!users) {
    return <LoadingSpinner/>
  }

  const handlePaginationOnChange = (event: React.ChangeEvent<unknown>, page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page)
    }
  }

  const handleModalClose = () => {
    setUserInfoModal(false);
  }

  function showInfoModal(user: User) {
    if (user) {
      setUserInfo(user)
      setUserInfoModal(true)
    }
  }

  return (
    <>
      <TableContainer>
        <Table sx={{minWidth: 650}} aria-label="simple table" className="table-fixed">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Connection</TableCell>
              <TableCell align="center">Logins</TableCell>
              <TableCell align="center">Latest Login</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, i) => (
              <TableRow
                hover
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                onClick={(e) => showInfoModal(user)}
              >
                <TableCell component="th" scope="row" className="overflow-hidden text-ellipsis login-table__button">
                  {user.name}
                </TableCell>
                <TableCell align="center" className="overflow-hidden text-ellipsis login-table__button">
                  {user.email}
                </TableCell>
                <TableCell align="center">
                  {user.connection}
                </TableCell>
                <TableCell align="center">
                  {user.logins}
                </TableCell>
                <TableCell align="center">
                  {moment(user.latest_login).format('YYYY/MM/DD')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pageCount > 1 &&
          <article className="tickets-tab__pagination">
              <Pagination
                  size="large"
                  count={pageCount}
                  color="primary"
                  onChange={handlePaginationOnChange}
                  page={currentPage}
              />
          </article>
      }
      <Modal
        useFooter={false}
        onClose={handleModalClose}
        show={userInfoModal}
        hideConfirmButton={true}
        hideCloseButton={true}
        onDeny={handleModalClose}
        size="lg"
      >
        <div className="member-info__contacts-card m-0 pt-0">
          <div className="member-info__contact-header">
            <h4 className="member-info__contacts-name">
              {userInfo?.name}
            </h4>
          </div>
          <div className="member-info__contacts-headers text-base">
            <div className="member-info__card-row">
              <p className="login-table__user-info member-info__p">email</p>
              <p className="member-info__p">
                {userInfo?.email}
              </p>
            </div>
            <div className="member-info__card-row">
              <p className="login-table__user-info member-info__p">connection</p>
              <p className="member-info__p ">
                {userInfo?.connection}
              </p>
            </div>
            <div className="member-info__card-row">
              <p className="login-table__user-info member-info__p">logins</p>
              <p className="member-info__h4">
                {userInfo?.logins}
              </p>
            </div>
            <div className="member-info__card-row">
              <p className="login-table__user-info member-info__p">app metadata</p>
              <p className="member-info__h4 text-left break-all">
                {JSON.stringify(userInfo?.app_metadata ? userInfo?.app_metadata : {})}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default LoginsTable
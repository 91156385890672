import React, {useContext, useEffect} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';

import DashboardHeader from "../Dashboard/DashboardHeader/DashboardHeader";
import Reports from "../Reports";
import DashboardFooter from "../Dashboard/DashboardFooter/DashboardFooter";
import Onboarding from "./Onboarding";
import Analyze from "./Analyze/Analyze";
import Settings from "./Settings/";

import {Modal} from "../../components/utils/Modal/Modal";
import NewDomain from "./NewDomain";
import {useAuth0} from "@auth0/auth0-react";
import jwt from "jwt-decode";
import ProtectedSSRoute from "./ProtectedSSRoute";
import {SSActiveDomainContextProvider} from "../../store/context/self-serve-active-domain";
import {SSMemberUserContextProvider} from "../../store/context/self-serve-member-user";
import PathContext from "../../store/context/path-context";
import {ROUTES} from "../../constants";
import './styles.scss';

const SelfServe = () => {
    const [addDomainModal, setAddDomainModal] = React.useState<boolean>(false);
    const [isOnboarded, setIsOnboarded] = React.useState<boolean>()
    const {user, getAccessTokenSilently} = useAuth0();
    const [userName, setUserName] = React.useState(user?.name)
    const navigate = useNavigate()
    const {apiUrlProps: {tenant}} = useContext(PathContext);

    useEffect(() => {
        if (!['localhost', 'ss', 'stg'].includes(tenant)) {
            navigate('/not-found');
        }
    }, []);

    React.useEffect(() => {
        (async () => {
            const accessToken = await getAccessTokenSilently();
            const decodedToken = jwt<{ "https://user.info/isOnboarded": string }>(accessToken);
            const isOnboarded = JSON.parse(decodedToken?.['https://user.info/isOnboarded'].toLowerCase());
            setIsOnboarded(isOnboarded)
        })()

    }, [getAccessTokenSilently]);

    return (
        <SSMemberUserContextProvider>
            <SSActiveDomainContextProvider>
                <section className="app__self-serve">
                    <DashboardHeader fromSelfServe
                                     setAddDomainModal={setAddDomainModal}
                                     isUserOnboarded={isOnboarded}
                    />
                    <article className="app__self-serve-main">
                        <Routes>
                            <Route path={ROUTES.MEMBER_SS.subroutes.ONBOARDING.route} element={
                                <ProtectedSSRoute isOnboarded={isOnboarded} isOnboardedAllowed={false}>
                                    <Onboarding isOnboarded={isOnboarded!} setIsOnboarded={setIsOnboarded}
                                            setUserName={setUserName}/>
                            </ProtectedSSRoute>
                        }/>
                        <Route path={ROUTES.MEMBER_SS.subroutes.ANALYZE.route} element={
                                 <ProtectedSSRoute isOnboarded={isOnboarded} isOnboardedAllowed>
                                     <Analyze/>
                                 </ProtectedSSRoute>
                            }/>
                            <Route path={ROUTES.MEMBER_SS.subroutes.REPORTS.route} element={
                                <ProtectedSSRoute isOnboarded={isOnboarded} isOnboardedAllowed>
                                    <article className="app__self-serve-card">
                                        <Reports fromSelfServe/>
                                    </article>
                                </ProtectedSSRoute>
                            }/>
                            <Route path={ROUTES.MEMBER_SS.subroutes.SETTINGS.route} element={
                                <ProtectedSSRoute isOnboarded={isOnboarded} isOnboardedAllowed>
                                     <Settings userName={userName} setUserName={setUserName}/>
                                </ProtectedSSRoute>
                            }/>
                            <Route path={ROUTES.ERROR_PAGE.route} element={<Navigate replace to={'/not-found'}/>} />
                        </Routes>

                        <Modal show={addDomainModal}
                               size="lg"
                               onClose={() => setAddDomainModal(prevState => !prevState)}
                               onDeny={() => setAddDomainModal(prevState => !prevState)}
                               useFooter={false}
                        >
                            <NewDomain setAddDomainModal={setAddDomainModal}/>
                        </Modal>
                    </article>
                    <DashboardFooter/>
                </section>
            </SSActiveDomainContextProvider>
        </SSMemberUserContextProvider>
    );
};

export default SelfServe;

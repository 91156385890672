import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

import {CurrentYearScoreBoardType} from "./types";
import './CurrentYearScoreBoard.scss';


const CurrentYearScoreBoard = ({
                                 scoreHistory,
                                 underlineColor,
                                 borderLineColor
                               }: CurrentYearScoreBoardType) => {
  return (
    <fieldset style={{border: `5px solid ${borderLineColor}`}} className="yearly">
      <legend className="yearly__score-legend">Current Year Business Comparison</legend>
      <article className="yearly__comparison-wrapper">
        <article className="yearly-comparison-scale">
          <p style={{borderBottom: `4px solid ${underlineColor}`}}>Key</p>
          <p className="yearly__comparison--item">
            <span className="yearly__circle-comparison yearly__circle-comparison--purple"/>
            You
          </p>
          <p className="yearly__comparison--item">
            <span className="yearly__circle-comparison yearly__circle-comparison--grey"/>
            Average
          </p>
          <p className="yearly__comparison--item">
            <span className="yearly__circle-comparison yearly__circle-comparison--lightblue"/>
            Low Risk
          </p>
        </article>
        <article className="yearly__score-chart">
          <ResponsiveContainer className="yearly__responsive-container">
            <LineChart
              data={scoreHistory!}
              margin={{
                top: 20,
                right: 35,
                left: 1,
                bottom: 20
              }}
              className={"yearly__line-chart"}
            >
              <CartesianGrid strokeDasharray="1, 1" vertical={false}/>
              <XAxis dataKey="Month" padding={{left: 20, right: 20}}/>
              <YAxis dataKey='Low Risk' ticks={[400, 550, 700, 850]}
                     domain={[300, 850]} padding={{bottom: 20}}/>
              <Tooltip active={false}/>
              <Line
                type="monotone"
                dataKey="Average"
                stroke="#A6A6A6"
                strokeWidth='4.5px'
                isAnimationActive={false}
              />
              <Line type="monotone" dataKey="Low Risk" stroke="#6CE5E8" strokeWidth='4.5px'
                    isAnimationActive={false}/>
              <Line type="monotone" dataKey="You" stroke="#2F3292" strokeWidth='4.5px'
                    isAnimationActive={false}/>
            </LineChart>
          </ResponsiveContainer>
        </article>
      </article>
    </fieldset>
  )
}
export default CurrentYearScoreBoard;

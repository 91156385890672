import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AxiosError} from "axios";
import EditIcon from '@mui/icons-material/Edit';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SyncIcon from '@mui/icons-material/Sync';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from "@mui/material";
import moment from "moment";
import {useMutation} from "@tanstack/react-query";

import {parseBool} from "../../../../components/utils/parsers";
import {notifyError, notifySuccess} from "../../../../components/utils/ToastNotifications/Notifier";
import {Modal} from "../../../../components/utils/Modal/Modal";
import LoadingSpinner from "../../../../components/loading/LoadingSpinner";
import DownloadTechReport from "../../../../components/DownloadTechReport/DownloadTechReport";
import {ENDPOINTS, permissionsCatalog} from "../../../../constants";
import {MemberInfo, MemberUsers} from "../../../../interfaces";
import {icon_style} from "../MemberManagement";
import EditAssets from "../AddOrModifyMember/EditAssets/EditAssets";
import CreateEditContact from "../AddOrModifyMember/CreateEditContact/CreateEditContact";
import EditMemberInfo from "../AddOrModifyMember/EditMemberInfo/EditMemberInfo";
import ScanData from "../ScanData/ScanData";
import {UserPermissionContext} from "../../../../store/context/user-permission-context";
import {useAPI} from "../../../../utils/hooks/useAPI";
import {useMember} from "../../../../utils/hooks/useMemberPreview";
import './SingleMemberInfo.scss';


type modalModeType = "create-contact" | "edit-contact" | "edit-member-info" | "edit-assets" | undefined

const SingleMember = () => {
  const [member, setMember] = React.useState<MemberInfo>();
  const [isMemberActive, setIsMemberActive] = React.useState<boolean>();
  const [memberRelationship, setMemberRelationship] = React.useState<String>();
  const [domainId, setDomainId] = React.useState<number>();
  const [rescanDomainId, setRescanDomainId] = useState<number>()
  const api = useAPI();
  const [changesMade, setChangesMade] = useState<boolean>(false)
  const memberPreview = useMember()
  const {member_id: memberParamId} = useParams();
  const navigate = useNavigate();
  const [scanDataModalOpened, setScanDataModalOpened] = useState<boolean>(false);
  const [scanDataDomainId, setScanDataDomainId] = useState<number | null>(null);
  const [isClickedTechReportButton, setIsClickedTechReportButton] = React.useState(false);

  const [modalMode, setModalMode] = useState<modalModeType>();
  const [editedContact, setEditedContact] = useState<MemberUsers>()
  const {userPermissions} = useContext(UserPermissionContext);
  const [member_id, setMemberId] = useState(() => (memberPreview ? memberPreview.id : Number(memberParamId)));
  const currentDateTimeStr = moment().format('YYYY-MM-DD');
  const [monthlyDomainScanDateStr, setMonthlyDomainScanDateStr] = useState<string>('');
  const [isMonthlyScanFinished, setIsMonthlyScanFinished] = useState<boolean>(false);
  const [rescanInfo, setRescanInfo] = useState({
    domainId: 0,
    isRescanStarted: false
  })

  useEffect(() => {
    if (!memberPreview) {
      setMemberId(Number(memberParamId));
    }
  }, [memberParamId])

  useEffect(() => {
    getMemberById(member_id!)
  }, [member_id, memberPreview]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (member && member.active !== undefined) {
      setIsMemberActive(member.active);
      setMemberRelationship(member.business_relationship);
    }
  }, [member]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (changesMade) {
      getMemberById(Number(member_id));
      setChangesMade(false);
    }
  }, [changesMade]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const currentDateTime = new Date(currentDateTimeStr);
    const monthlyDomainScanDate = new Date(monthlyDomainScanDateStr);
    if (currentDateTime >= monthlyDomainScanDate) {
      setIsMonthlyScanFinished(true)
    }
  }, [currentDateTimeStr, monthlyDomainScanDateStr])

  useEffect(() => {
    if (rescanDomainId) {
      rescanDomain()
      setRescanInfo({
        domainId: rescanDomainId,
        isRescanStarted: true
      })
    }
  }, [rescanDomainId])

  async function handleRescanDomain() {
    return api.post(ENDPOINTS.DOMAINS_SCORE.rescan_domain(rescanDomainId))
  }

  const {mutate: rescanDomain} = useMutation({
    mutationFn: handleRescanDomain,
    onSuccess: (response) => {
      notifySuccess(response.data.message)
    },
    onError: (error) => notifyError((error as AxiosError).response?.data?.message || 'Something went wrong please try again later or contact us.')
  })

  const onActiveMemberToggle = async (event: React.MouseEvent<HTMLElement>, value: string) => {
    if (value) {
      const isActive = parseBool(value);
      await api.patch(ENDPOINTS.MEMBERS.edit_member_active(String(member_id)), {active: isActive})
        .then(() => {
          member!.active = isActive
          setIsMemberActive(isActive)
        })
        .catch(() => notifyError("Something went wrong, please try again or contact us at support@edisonmarks.com"));
    }
  }

  const onBusinessRelToggle = async (event: React.MouseEvent<HTMLElement>, value: string) => {
    if (value) {
      await api.patch(ENDPOINTS.MEMBERS.edit_member_relationship(String(member_id)), {relationship: value})
        .then(() => {
          member!.business_relationship = value;
          setMemberRelationship(value);
        })
        .catch(() => notifyError("Something went wrong, please try again or contact us at support@edisonmarks.com"));
    }
  }

  const handleEditBtnClick = (modalMode: modalModeType, editedContact?: MemberUsers) => {
    setModalMode(modalMode)
    if (modalMode === "edit-contact") {
      setEditedContact(editedContact)
    }
  }

  const renderModal = () => {
    switch (modalMode) {
      case "create-contact":
        return <CreateEditContact setChangesMade={setChangesMade} memberId={member!.id}
                                  closeModal={handleModalClose}/>
      case "edit-contact":
        return <CreateEditContact setChangesMade={setChangesMade} closeModal={handleModalClose}
                                  memberId={member!.id} editedContact={editedContact}/>
      case "edit-member-info":
        return <EditMemberInfo setChangesMade={setChangesMade} member={member!}
                               closeModal={handleModalClose}/>
      case "edit-assets":
        return <EditAssets setChangesMade={setChangesMade} memberId={member!.id}
                           domains={member!.domains}/>
    }
  }

  const handleModalClose = () => {
    setModalMode(undefined)
  }

  const getMemberById = (id: number) => {
    api.get(ENDPOINTS.MEMBERS.get_or_update_member(id))
      .then(response => {
        console.log(response.data)
        setMember(response.data)
        setMonthlyDomainScanDateStr(getThirdMondayOfMonth())
      })
      .catch(e => {
        if ((e as AxiosError).response?.status === 403) {
          navigate('/access-denied');
          return;
        }
        if ((e as AxiosError).response?.status === 404) {
          navigate('/not-found');
          return;
        }
        notifyError("Something went wrong, please try again or contact us at support@edisonmarks.com");
      })
  }

  const redirectDashboardHandler = (domain: string): void => {
    window.open(`/reports/${domain}`, '_blank');
  }

  const handleEmailCopy = async (email: string) => {
    navigator.clipboard.writeText(email)
      .then(() => notifySuccess('Email copied to clipboard.'))
      .catch(() => notifyError('Copying email to clipboard failed.'))
  }

  const handleScanDataButtonClicked = (selectedDomainId: number) => {
    if (scanDataDomainId !== selectedDomainId) {
      setScanDataDomainId(selectedDomainId);
    }
    setScanDataModalOpened(true);
  }

  function getThirdMondayOfMonth(): string {
    const currentDate = moment();
    const firstDayOfMonth = moment([currentDate.year(), currentDate.month(), 1]);
    let firstMonday = firstDayOfMonth.clone().startOf('month');
    while (firstMonday.day() !== 1) {
      firstMonday.add(1, 'day');
    }
    const thirdMonday = firstMonday.add(17, 'days');
    return thirdMonday.format('YYYY-MM-DD');
  }

  return <div className="member-info">
    <div className="member-info__padding_wrapper">
      {
        member === undefined ? <LoadingSpinner/> :
          <>
            <div className="member-info__header">
              <h4 className="member-info__h4">{member.company_name}</h4>
              {/*<button className="member-info__button>Delete member</button>*/}
            </div>
            <div className="member-info__score-board">
              <div className="member-info__score">
                <div className="member-info__score-area">
                  <p className="member-info__score-title">Member Score</p>
                  <Tooltip
                    title={<Typography fontSize={"0.9rem"}>The Member Score represents an average
                      number
                      of the current score for all assets.</Typography>}>
                    <InfoOutlinedIcon className="member-info__score-icon"
                                      style={{fontSize: "1.3rem"}}/>
                  </Tooltip>
                </div>
                <p
                  className={member.current_score ? "member-info__score-main member-info__score-value" : "member-info__score-main member-info__score--pending"}>
                  {member.current_score ? member.current_score : "Pending..."}
                </p>
              </div>
              <div className={"member-info__button_group_wrapper"}>
                <div>
                  <ToggleButtonGroup
                    color="primary"
                    value={isMemberActive !== undefined && isMemberActive.toString()}
                    exclusive
                    onChange={onActiveMemberToggle}
                  >
                    <ToggleButton value={"true"}>Active</ToggleButton>
                    <ToggleButton value={"false"}>Inactive</ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <div>
                  <ToggleButtonGroup
                    color="primary"
                    value={memberRelationship !== undefined && memberRelationship}
                    exclusive
                    onChange={onBusinessRelToggle}
                    // style={{marginLeft: 20}}
                  >
                    <ToggleButton value={"Prospect"}>Prospect</ToggleButton>
                    <ToggleButton value={"Customer"}>Customer</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            </div>
            <div className="member-info__details-headers" style={{justifyContent: "space-between"}}>
              <h4 className="member-info__h4">Contacts</h4>
              <button className="member-info__add-contact-btn"
                      onClick={() => {
                        handleEditBtnClick("create-contact")
                      }}>
                <AddCircleOutlineIcon
                  style={icon_style}
                />
                <span>Add New Contact</span>
              </button>
            </div>
            <article className="member-info__card-wrapper">
              {member.contacts?.map((contact, index) => {
                return <article className="member-info__contacts-cards" key={index}>
                  <div className="member-info__contacts-card">
                    <div className="member-info__contact-header">
                      <h4 className="member-info__contacts-name">
                        {contact.contact_name ? contact.contact_name : contact.email}
                      </h4>
                      <EditIcon
                        className="member-info__details-button"
                        onClick={() => handleEditBtnClick("edit-contact", contact)}
                      />
                    </div>
                    <div className="member-info__contacts-headers">
                      <div className="member-info__card-row">
                        <p className="member-info__row-header member-info__p">position</p>
                        <p className="member-info__p">
                          {contact.contact_position || 'n/a'}
                        </p>
                      </div>
                      <div className="member-info__card-row">
                        <p className="member-info__row-header member-info__p">email</p>
                        <p className="member-info__p ">
                          {contact.is_primary && <span className="member-info__primary">
													Primary
												</span>}
                          <Tooltip title={contact.email}>
													<span onClick={() => handleEmailCopy(contact.email)}
                                className="member-info__card-content">
														{contact.email}
													</span>
                          </Tooltip>
                        </p>
                      </div>
                      <div className="member-info__card-row">
                        <p className="member-info__row-header member-info__p">phone</p>
                        <p className="member-info__h4">
                          {contact.contact_phone || 'n/a'}
                        </p>
                      </div>
                    </div>
                  </div>
                </article>
              })}
            </article>

            <div className="member-info__details">
              <div className="member-info__details-headers">
                <h4 className="member-info__h4">Information</h4>
                <EditIcon
                  className="member-info__details-button"
                  onClick={() => handleEditBtnClick("edit-member-info")}
                />
              </div>
              <article className="member-info__details-content">
                <div className="member-info__content-section">
                  <p className="member-info__content-headers">industry</p>
                  <p>{member.industry || 'n/a'}</p>
                  <p className="member-info__content-headers">added on</p>
                  <p>{moment(member.added_on, 'DD/MM/YYYY').format('DD MMM YYYY')}</p>
                </div>
                <div className="member-info__content-section-middle">
                  <p className="member-info__content-headers">revenue</p>
                  <p>{member.revenue || 'n/a'}</p>
                  <p className="member-info__content-headers">employees</p>
                  <p>{member.number_employees || 'n/a'}</p>
                </div>
                <div className="member-info__content-section">
                  <p className="member-info__content-headers">address</p>
                  <p className="member-info__address">{member.postal_address.contact_name}</p>
                  <p
                    className="member-info__address">{member.postal_address.mailing_address} {member.postal_address.mailing_address_2}</p>
                  <p
                    className="member-info__address">{member.postal_address.city}, {member.postal_address.state}, {member.postal_address.zip}</p>
                  <p className="member-info__address">{member.postal_address.country}</p>
                </div>
              </article>
            </div>
            <article className="member-info__assets">
              <div className="member-info__details-headers">
                <h4 className="member-info__h4">Assets</h4>
                <EditIcon
                  className="member-info__details-button"
                  onClick={() => handleEditBtnClick("edit-assets")}
                />
              </div>
              {member.domains.map((domain, index) => {
                const isPending = (!domain.timestamp && !domain.is_domain_reprocess) || (rescanInfo.domainId === domain.id && rescanInfo.isRescanStarted) || (!domain.timestamp && domain.is_domain_reprocess && domain.reprocess_retry !== 3);
                const isCurrentMonthScanned = domain.score && isMonthlyScanFinished && moment(domain.timestamp).format('M') === moment(currentDateTimeStr).format('M');
                const isPastMonthScanned = domain.score && !isMonthlyScanFinished;

                return (
                  <article key={index} className="member-info__content-wrapper">
                    <h4
                      className="member-info__h4"
                      style={{display: "flex", justifyContent: "space-between"}}
                    >
										<span
                      onClick={() => redirectDashboardHandler(domain.domain)}
                      className="member-info--pointer">
											{domain.domain}
										</span>
                      <span>
                                            {
                                              domain.score > 0 &&
                                                <Button variant="text" className='member-info__tech-report-btn'
                                                        style={{width: "inherit"}}
                                                        onClick={() => {
                                                          setDomainId(domain?.id)
                                                          setIsClickedTechReportButton(prevState => !prevState)
                                                        }}
                                                >
                                                    Tech Report
                                                </Button>
                                            }
                        {
                          (domain.score > 0 && userPermissions.includes(permissionsCatalog.read_tenants)) &&
                            <Button variant="text" className='member-info__scan-data-btn'
                                    style={{width: "inherit"}}
                                    onClick={() => handleScanDataButtonClicked(domain.id)}>
                                Scan Data
                            </Button>
                        }
                                        </span>
                    </h4>
                    <article className="member-info__assets-content">
                      <article className="member-info__assets-score">
                        <p className="member-info__score-title">Current score</p>
                        <div className="member-info__score-area">
                          <p className={isPending ? "member-info__score-main member-info__score--pending"
                            : isCurrentMonthScanned ? "member-info__score-main member-info__score-value"
                              : isPastMonthScanned ? "member-info__score-main member-info__score-value"
                                : "member-info__score-main member-info__score--failure"}
                          >{isPending ? "Pending..." : isCurrentMonthScanned ? domain.score : isPastMonthScanned ? domain.score : "Failure"}</p>
                          {!isPending && !isCurrentMonthScanned && !isPastMonthScanned &&
                              <Tooltip
                                  title={<Typography fontSize={"0.9rem"}>Rescan Domain</Typography>}>
                                  <SyncIcon className="member-info__rescan-icon" style={{fontSize: "1.7rem"}}
                                            onClick={() => {
                                              setRescanDomainId(domain?.id)
                                            }}/>
                              </Tooltip>}
                        </div>
                      </article>
                      <TableContainer>
                        <Table size="small" aria-label="a domains table">
                          <TableHead>
                            <TableRow style={{
                              borderTop: '1.5px solid white',
                            }}>
                              <TableCell>
																	<span className="member-info__content-headers">
																		Technology
																	</span>
                              </TableCell>
                              <TableCell
                                style={{padding: '6px 10px'}}
                                align="left"
                              >
                                <ErrorOutlineIcon className="member-info__table-icons"/>
                                <span className="member-info__content-headers">
																	Urgent
															</span>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{padding: '6px 10px'}}
                              >
                                <HealthAndSafetyIcon
                                  className="member-info__table-icons"/>
                                <span className="member-info__content-headers">
																	Important
															</span>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {domain.scanners_opportunities.map((domain, index) => (
                              <React.Fragment key={index}>
                                <TableRow
                                  key={index}
                                  style={{borderTop: "1.5px solid white"}}
                                  sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                  <TableCell component="th" scope="row">
																		<span className="member-info__content-headers">
																			{domain.scanner === 'vulnerabilities'
                                        ? domain.scanner.charAt(0).toUpperCase() + domain.scanner.slice(1)
                                        : domain.scanner.toUpperCase()
                                      }
																		</span>
                                  </TableCell>
                                  <TableCell align="left">
																		<span
                                      style={{
                                        fontWeight: domain.critical ? 'bold' : '',
                                        color: domain.critical ? '' : '#BDC7CF',
                                        fontSize: '18px',
                                      }}
                                    >
																			{domain.critical}
																		</span>
                                  </TableCell>
                                  <TableCell align="left">
																		<span
                                      style={{
                                        fontWeight: domain.important ? 'bold' : '',
                                        color: domain.important ? '' : '#BDC7CF',
                                        fontSize: '18px',
                                        borderBottom: 'none',
                                      }}
                                    >
																			{domain.important}
																		</span>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </article>
                  </article>
                )
              })}
              {isClickedTechReportButton &&
                  <DownloadTechReport
                      domainId={domainId || 0}
                      setIsClickedTechReportButton={setIsClickedTechReportButton}
                  />
              }
            </article>
            <Modal
              useFooter={false}
              onClose={handleModalClose}
              show={modalMode !== undefined}
              haveTextArea={true}
              size="lg">
              {renderModal()}
            </Modal>
            <Modal show={scanDataModalOpened} size='xxl' hideConfirmButton={true} hideCloseButton={true}
                   onClose={() => setScanDataModalOpened(false)}>
              <ScanData domainId={scanDataDomainId}/>
            </Modal>
          </>
      }
    </div>
  </div>
}


export default SingleMember
import React from 'react';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import customLocale from "date-fns/locale/en-US";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {Button, TextField} from "@mui/material";
import useIsMobile from "../../utils/hooks/useIsMobile";
import {extraSmallScreen} from "../../constants";

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate(date: Date | null): void;
  setEndDate(date: Date | null): void;
  onDateFilterChartData(): void;
  disabled?: boolean;
}

const DateRangePicker: React.FC<Props> = (
  {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    onDateFilterChartData,
    disabled,
  }
) => {
  const isExtraSmallScreen = useIsMobile(extraSmallScreen);

  return (
    <div style={{display: "flex", justifyContent: "flex-end"}}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={customLocale}>
        <div className={"urs__datepicker"}>
          <DatePicker
            disabled={disabled}
            minDate={moment().subtract(6, 'months').toDate()}
            maxDate={endDate!}
            inputFormat={isExtraSmallScreen ? "dd MMM yy" : "dd MMM yyyy"}
            disableMaskedInput={true}
            label="Start date"
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            renderInput={(params) =>
              <TextField disabled={true} onKeyDown={(event) => {
                event.preventDefault()
              }} size={"small"} {...params} />}
          />
        </div>
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={customLocale}>
        <div className={"urs__datepicker ms-2"}>
          <DatePicker
            disabled={disabled}
            minDate={startDate!}
            maxDate={new Date()}
            inputFormat={isExtraSmallScreen ? "dd MMM yy" : "dd MMM yyyy"}
            disableMaskedInput={true}
            label="End date"
            value={endDate}
            onChange={setEndDate}
            renderInput={(params) =>
              <TextField onKeyDown={(event) => {
                event.preventDefault()
              }} disabled={true} size={"small"} {...params} />}
          />
        </div>
      </LocalizationProvider>
      <Button
        disabled={disabled}
        style={{boxShadow: "none", marginLeft: 20}}
        size={"medium"}
        variant={"contained"}
        onClick={onDateFilterChartData}>
        Filter
      </Button>
    </div>
  );
};

export default DateRangePicker;

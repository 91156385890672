import {useEffect, useState} from "react";

export const useDebounce = (value: any, delay: number | undefined) => {
    const [debounceValue, setDebounceValue] = useState(value)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceValue(value)
        }, delay);

        return () => {
            clearTimeout(handler)
        };
    }, [delay, value])

    return debounceValue
}
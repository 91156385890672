import React from "react";
import {useReactToPrint} from "react-to-print";
import {useAuth0} from "@auth0/auth0-react";
import Button from '@mui/material/Button';

import {PrintVersionTypes} from "./types";
import './PrintVersion.scss';

const PrintVersion: React.FC<PrintVersionTypes> = ({font, printRef, buttonColor}: PrintVersionTypes): JSX.Element => {
    const {logout} = useAuth0();
    const handlePrint = useReactToPrint({
        content: () => printRef!.current
    })

    const redirectDashboardHandler = (): void => {
        window.open('/admin/engagement/', '_blank');
    }

    const buttonStyle = {
        backgroundColor: buttonColor,
        fontFamily: font,
    }

    return (
        <section className="print">
            <Button
                variant="contained"
                size="large"
                className="header-rep__buttons"
                style={buttonStyle}
                onClick={handlePrint}
            >
                Print Report</Button>
            <div className="print__dash_logout">
                <p className="print__dashboard">
                    <Button
                        variant="contained"
                        size="large"
                        className="header-rep__buttons header-rep__dashboard_btn--margin"
                        style={buttonStyle}
                        onClick={redirectDashboardHandler}
                    >
                        Dashboard
                    </Button>
                </p>
                <Button
                    variant="contained"
                    size="large"
                    className="header-rep__buttons"
                    style={buttonStyle}
                    onClick={() => logout({
                        returnTo: window.location.origin
                    })}
                >
                    Logout
                </Button>
            </div>
        </section>
    )
}

export default PrintVersion;
import React, {useEffect, useState} from "react";
import {AxiosError, AxiosResponse} from "axios";
import {Form, FormikProvider, useFormik} from "formik";
import {Button, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {useMutation, useQuery} from "@tanstack/react-query";
import * as yup from "yup";

import FormikMUISimpleInput from "../../../components/Formik/MUISimpleInput/FormikMUISimpleInput";
import {notifyError, notifySuccess} from "../../../components/utils/ToastNotifications/Notifier";
import {ENDPOINTS} from "../../../constants";
import {useAPI} from "../../../utils/hooks/useAPI";
import IPsTable, {ipActionsTypes, IpAddress} from "./IPsTable";
import '../../Reports/HeaderReport/HeaderReport.scss';
import './NonTrackedIPs.scss';

export const ipAddressV4Validation = yup.object({
  ip_address: yup.string()
    .required("This field is required.")
    .matches(/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/, "You must enter a valid IPv4 address.")
})

export interface IpValuesEditAction {
  ipId: number;
  ip_address: string;
}

const NonTrackedIPs = () => {
  const api = useAPI();
  const [validationSchema, setValidationSchema] = useState(ipAddressV4Validation);
  const [inputLabel, setInputLabel] = useState('IP Address v4');
  const [ips, setIps] = useState<Array<IpAddress>>([]);
  const [ipId, setIpId] = useState<number>();

  const {data: ipAddressesData, refetch} = useQuery({
    queryKey: ['getIpAddressesData'],
    queryFn: getIpAddressesData,
    refetchOnWindowFocus: false,
  })

  function getIpAddressesData(): Promise<AxiosResponse<Array<IpAddress>>> {
    return api.get(ENDPOINTS.GOOGLE_ANALYTICS.get_or_create_blacklisted_ip)
  }

  useEffect(() => {
    if (ipAddressesData) {
      setIps(ipAddressesData.data)
    }
  }, [ipAddressesData?.data])

  async function handleDeleteIp(selectedIpId: number | undefined) {
    return api.delete(ENDPOINTS.GOOGLE_ANALYTICS.update_or_delete_blacklisted_ip(selectedIpId));
  }

  const {mutate: deleteIpAddress} = useMutation({
    mutationFn: handleDeleteIp,
    onSuccess: async () => {
      notifySuccess("IP Address deleted successfully.")
      await refetch()
    },
    onError: (error) => notifyError((error as AxiosError).response?.data?.message || 'There was a problem deleting your IP Address. Please try again later.')
  })

  const handleEditIp = (values: { ipId: number, ip_address: string }) => {
    return api.patch(ENDPOINTS.GOOGLE_ANALYTICS.update_or_delete_blacklisted_ip(ipId), values);
  }

  const {mutate: editIpAddress} = useMutation({
    mutationFn: handleEditIp,
    onSuccess: async () => {
      notifySuccess("IP Address changed successfully.")
      await refetch()
    },
    onError: (error) => notifyError((error as AxiosError).response?.data?.message || 'There was a problem changing your IP Address. Please try again later.')
  })

  const handleFormSubmit = (values: { ip_address: string, ipVersion: string }) => {
    return api.post(ENDPOINTS.GOOGLE_ANALYTICS.get_or_create_blacklisted_ip, values);
  }

  const {mutate: addBlacklistedIP} = useMutation({
    mutationFn: handleFormSubmit,
    onSuccess: async () => {
      notifySuccess('Your IP Address has been successfully added.');
      formik.resetForm();
      setInputLabel('IP Address v4');
      setValidationSchema(ipAddressV4Validation);
      await refetch()
    },
    onError: (error) =>  {
      notifyError((error as AxiosError).response?.data?.message || 'There was a problem adding your IP Address. Please try again later.');
      formik.resetForm();
      setInputLabel('IP Address v4');
      setValidationSchema(ipAddressV4Validation);
    },
  })

  const formik = useFormik({
    initialValues: {
      ip_address: "",
      ipVersion: "ipv4"
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      addBlacklistedIP(values);
    }
  })

  useEffect(() => {
    convertIp()
  }, [formik.values.ipVersion]);

  const convertIp = () => {
    const {ip_address, ipVersion} = formik.values;

    if (ip_address && ipVersion === "ipv6") {
      const octets: string[] = ip_address.split(".");
      const octetBytes: number[] = new Array(4);
      for (let i = 0; i < 4; ++i) {
        octetBytes[i] = parseInt(octets[i], 10);
      }
      const ipv4asIpV6addr: number[] = new Array(16);
      ipv4asIpV6addr[10] = 0xff;
      ipv4asIpV6addr[11] = 0xff;
      ipv4asIpV6addr[12] = octetBytes[0];
      ipv4asIpV6addr[13] = octetBytes[1];
      ipv4asIpV6addr[14] = octetBytes[2];
      ipv4asIpV6addr[15] = octetBytes[3];

      const ipv6: string = `::ffff:${ipv4asIpV6addr[12].toString(16).padStart(2, '0')}${ipv4asIpV6addr[13].toString(16).padStart(2, '0')}:${ipv4asIpV6addr[14].toString(16).padStart(2, '0')}${ipv4asIpV6addr[15].toString(16).padStart(2, '0')}`;
      formik.setFieldValue('ip_address', ipv6);
    }

    if (ip_address && ipVersion === "ipv4") {
      const match = ip_address.match(/::ffff:(\w{1,4}):(\w{1,4})/);
      if (!match) {
        formik.setFieldValue('ip_address', ip_address);
        return null;
      }

      const [, high, low] = match;
      const parts: number[] = [
        parseInt(high.substring(0, 2), 16),
        parseInt(high.substring(2), 16),
        parseInt(low.substring(0, 2), 16),
        parseInt(low.substring(2), 16),
      ];

      const ipv4: string = `${parts[0]}.${parts[1]}.${parts[2]}.${parts[3]}`
      formik.setFieldValue('ip_address', ipv4);
    }
  };

  const handleActionChange = (type: string, values?: IpValuesEditAction, selectedIpId?: number) => {
    if (type === ipActionsTypes.editIp && values) {
      editIpAddress(values)
    }
    if (type === ipActionsTypes.deleteIp) {
      deleteIpAddress(selectedIpId)
    }
  }

  return (
    <section className="w-full">
      <h3>Internal IPs</h3>
      <p>Add an IP address to be excluded from tracking stats.</p>
      <FormikProvider value={formik}>
        <Form className="flex flex-col items-start mt-6">
          <div className="mb-4">
            <ToggleButtonGroup
              color="primary"
              value={formik.values.ipVersion}
              exclusive
            >
              <ToggleButton
                value={'ipv4'}
                onClick={() => {
                  setValidationSchema(ipAddressV4Validation);
                  setInputLabel('IP Address v4');
                  formik.setFieldValue('ipVersion', 'ipv4');
                }}
                disabled={!formik.isValid && formik.values.ip_address !== ''}>
                IPv4
              </ToggleButton>
              <ToggleButton
                value={'ipv6'}
                onClick={() => {
                  setValidationSchema(yup.object({
                    ip_address: yup.string()
                      .required('This field is required.')
                      .matches(/^::(?:ffff:)?([0-9a-fA-F]{1,4}:){1,7}[0-9a-fA-F]{1,4}$/, 'You must enter a valid IPv6 address'),
                  }));
                  setInputLabel('IP Address v6');
                  formik.setFieldValue('ipVersion', 'ipv6');
                }}
                disabled={!formik.isValid && formik.values.ip_address !== ''}>
                IPv6
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="mb-4 flex w-full">
              <FormikMUISimpleInput error={!!formik.errors.ip_address} label={inputLabel} name="ip_address"
                                    size="small" placeholder={inputLabel}/>
          </div>
          <div className="mb-8">
            <Button
              className={formik.isSubmitting || !formik.isValid || !formik.dirty || !formik.touched ? "!font-bold !text-base !px-7 !rounded-lg header-rep__buttons non-tracked-ips__convert-btn-disabled"
                : "!font-bold !text-base !px-7 !rounded-lg bg-orange header-rep__buttons"}
              type="submit"
              size="large" variant="contained"
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty || !formik.touched}
            >
              submit changes
            </Button>
          </div>
        </Form>
      </FormikProvider>
      <IPsTable ips={ips} setIpId={setIpId} onChange={handleActionChange}/>
    </section>
  )
}

export default NonTrackedIPs;
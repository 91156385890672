import React, {useEffect} from "react";
import {AxiosResponse} from "axios";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useQuery} from "@tanstack/react-query";

import LoadingSpinner from "../../../../components/loading/LoadingSpinner";
import {ENDPOINTS} from "../../../../constants";
import '../../../Dashboard/MemberManagement/SingleMember/SingleMemberInfo.scss'
import {useAPI} from "../../../../utils/hooks/useAPI";

export interface DomainScoreDataInterface {
  member_id: number,
  domain_id: number,
  domain: string,
  score: number,
  dns: string,
  ssl: string,
  tls: string,
  vulnerabilities: string,
  ssl_scan: string,
  vulnerabilities_scan: string,
  critical: number,
  important: number,
  scanners_opportunities: string,
  report_name: string,
  risk_status: string,
  reason: string,
  timestamp: string,
  tenant: string | undefined
}

type DomainScoreDataProps = {
  domainId: number | null;
}

const DomainScoreData = ({domainId}: DomainScoreDataProps) => {
  const api = useAPI();
  const [domainScoreInfo, setDomainScoreInfo] = React.useState<DomainScoreDataInterface>();

  const {data: domainScoreData} = useQuery({
    queryKey: ['getDomainScoreData'],
    queryFn: getDomainScoreData,
    refetchOnWindowFocus: false,
  })

  function getDomainScoreData(): Promise<AxiosResponse<DomainScoreDataInterface>> {
    return api.get(ENDPOINTS.DOMAINS_SCORE.get_domain_scan(domainId))
  }

  useEffect(() => {
    if (domainScoreData && domainScoreData.data.domain_id === domainId) {
      setDomainScoreInfo(domainScoreData.data)
    }
  }, [domainScoreData?.data]);


  return <section>
    {!domainScoreInfo ? <LoadingSpinner/> :
      <TableContainer component={Paper} className={"scan-data-wrapper"}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong className={"scan-data-table-cell-align"}>Field</strong></TableCell>
              <TableCell>
                <strong style={{verticalAlign: "middle", whiteSpace: 'nowrap'}}>Value</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(domainScoreInfo!).map((entry, index) => {
              return <TableRow key={index}>
                <TableCell component="th" scope="row" className={"scan-data-table-row-width"}>
                  <span>{entry[0]}</span>
                </TableCell>
                <TableCell component="th" scope="row">
                  {JSON.stringify(entry[1])}
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    }
  </section>
}

export default DomainScoreData;
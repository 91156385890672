import React, {useEffect, useState} from "react";
import moment from "moment";
import Button from "@mui/material/Button";

import {permissionsCatalog} from "../../../constants";
import {OpportunitiesBoardType} from "./types";
import {ReportModal} from "../ReportModal/ReportModal";
import {defaultEmTheme} from "../../../utils/theme";

import './OpportunitiesBoard.scss';

const OpportunitiesBoard: React.FC<OpportunitiesBoardType> =
  ({
     id,
     domain,
     domain_id,
     member_id,
     timestamp,
     critical,
     important,
     permissions,
     font,
     buttonColor,
     borderLineColor,
     borderCircleUrgentColor,
     borderCircleImportantColor
   }: OpportunitiesBoardType): JSX.Element => {
    const [reportBeginningDate, setReportBeginningDate] = useState('');
    const [ticketData, setTicketData] = useState<{
      btnType: string,
      scrollId: string
    }>();

    useEffect(() => {
      setReportBeginningDate(() => {
        return moment(timestamp, 'DD MMM YYYY').subtract(1, 'months').format(('DD MMM YYYY'));

      })
    }, [timestamp])

    return (
      <>
        <section className="opportunities">
          <fieldset style={{border: `5px solid ${borderLineColor}`}}
                    className="opportunities__fieldset opportunities__fieldset--border">
            <legend className="opportunities__legend">By The Numbers: Your Opportunities</legend>
            <article className="opportunities__values">
              <div style={{border: `6px solid ${borderCircleUrgentColor}`}}
                   className="opportunities__update">
                <h4
                  className="opportunities__value"
                  data-testid="critical"
                >
                  {critical ? `+${critical}` : '+0'}
                </h4>
                <p style={{color: borderCircleUrgentColor}} className="opportunities__name">URGENT</p>
              </div>
              <div style={{border: `6px solid ${borderCircleImportantColor !== borderCircleUrgentColor ?
                                                borderCircleImportantColor
                                                : defaultEmTheme.buttons.mainButtonsColor}`}}
                   className="opportunities__update">
                <h4
                  className="opportunities__value"
                  data-testid="important"
                >
                  {important ? `+${important}` : '+0'}
                </h4>
                <p style={{color: `${borderCircleImportantColor !== borderCircleUrgentColor ?
                                     borderCircleImportantColor
                                     : defaultEmTheme.buttons.mainButtonsColor}`}}
                   className="opportunities__name">IMPORTANT</p>
              </div>
              <div className="opportunities__summary">
                <p>You have numerous opportunities to increase your score!</p>
                {!permissions.includes(permissionsCatalog.read_tenants) && (
                  <div className="opportunities__getStartedButton">
                    <Button
                      id="opport"
                      data-testid="opport"
                      variant="contained"
                      size="large"
                      className="header-rep__tell_me_more_btn"
                      style={{
                        borderRadius: '8px', backgroundColor: buttonColor
                      }}
                      onClick={(e) => {
                        setTicketData({btnType: (e.target as HTMLElement).id, scrollId: '2'})
                      }}
                    >
                      Get Started
                    </Button>
                  </div>
                )}
              </div>
            </article>
            <footer className="opportunities__footer" style={{fontFamily: font}}>
              ✧ Based on a passive scan of {domain} digital presence
              performed by {reportBeginningDate} - {timestamp}.
            </footer>
          </fieldset>
          <ReportModal domain={domain} ticketData={ticketData} domainId={domain_id} domainScoreId={id}
                       memberId={member_id}/>
        </section>
      </>
    )
  }

export default OpportunitiesBoard;

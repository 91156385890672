import React from 'react';

const Background = () => {
  return (
    <div className={'score-dial__background'}>
      <svg width="386" height="289" viewBox="0 0 386 289" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_dd_1236_982)">
          <path d="M362.458 270C367.17 270 370.999 266.209 371 261.508C371.004 248.118 370.856 208.738 370.856 188.526C370.856 89.7104 291.195 11 192.928 11C94.6612 11 15.0002 90.4812 15.0002 188.526C15.0002 208.39 14.9875 247.845 15.1459 261.609C15.1995 266.271 19.013 270 23.6854 270H362.458Z" fill="#2DAAE2" fillOpacity="0.05" shapeRendering="crispEdges"/>
        </g>
        <defs>
          <filter id="filter0_dd_1236_982" x="0" y="0" width="386" height="289" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="7.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1236_982"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
            <feBlend mode="normal" in2="effect1_dropShadow_1236_982" result="effect2_dropShadow_1236_982"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1236_982" result="shape"/>
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default Background;
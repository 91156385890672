import React from 'react';
import {Cell, Pie, PieChart} from 'recharts';
import './ScoreDial.scss';
import Background from "./Items/Background";
import MinScoreCircle from "./Items/MinScoreCircle";
import MaxScoreCircle from "./Items/MaxScoreCircle";
import {styled, Tooltip, tooltipClasses, TooltipProps, Typography} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {MAX_DOMAIN_SCORE, MIN_DOMAIN_SCORE} from "../../../constants";

type Props = {
  score?: number | null;
  averageScore: number;
  domain: string;
}

type LinearGradientProps = {
  id: string;
  minOffset: string;
  maxOffset: string;
  startColor: string;
  endColor: string;
}

type DialChartItemProperties = {
  iR: number | (() => number),
  oR: number,
  r: number,
  value: number | ((type: string) => number),
  x0: number,
  y0: number,
  divisor: number,
}

const startAngle = 180;
const RADIAN = Math.PI / startAngle;
const cx = 150;
const cy = 200;
const innerRadius = 130;
const outerRadius = 150;
const blocks = [
  {value: 190},
  {value: 170},
  {value: 190},
];
const total = blocks.reduce((a, b) => a + b.value, 0);
const linearGradientProps: Array<LinearGradientProps> = [
  {id: 'minRange', minOffset: '-4.38%', startColor: '#EA3D2F', endColor: '#FF7052', maxOffset: '91.58%'},
  {id: 'midRange', minOffset: '-112.42%', startColor: '#FF7052', endColor: '#FEEFC8', maxOffset: '123.69%'},
  {id: 'maxRange', minOffset: '-9.73%', startColor: '#C7FAC3', endColor: '#03B733', maxOffset: '106.53%'},
];

const VIQScoreDialTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} leaveTouchDelay={10000} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#555555',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    border: '1px solid #000000',
    borderRadius: '5px',
  },
}));

export const ScoreDial = ({score, averageScore, domain}: Props) => {
  const atRiskRange = [450, 649];
  enum ScoreDialProperty {
    scoreNeedle = 'scoreNeedle',
    averageScorePoint = 'averageScorePoint',
  }

  const scorePropertyMap: { [key: string]: number } = {
    [ScoreDialProperty.scoreNeedle]: score || 0,
    [ScoreDialProperty.averageScorePoint]: averageScore
  }
  const determinePosition = (type: string) => {
    const concreteScore = scorePropertyMap[type];
    const atRiskModifiers = buildRangePositions(
      atRiskRange[0], atRiskRange[1], 30, 50, 8, concreteScore
    );
    for (let scoreRanges of atRiskModifiers) {
      if (concreteScore >= scoreRanges.range[0] && concreteScore <= scoreRanges.range[1]) {
        return concreteScore - MIN_DOMAIN_SCORE - scoreRanges.modifier;
      }
    }

    return concreteScore - MIN_DOMAIN_SCORE;
  }

  const buildRangePositions = (
    minRange: number, maxRange: number, rangeStep: number, modifierMin: number,
    modifierStep: number, score: number, modifierStepIncreasing: boolean = false,
  ) => {
    let rangePositions = [];
    const isAtRisk = score >= atRiskRange[0] && score <= atRiskRange[1];
    const mid = Math.round((minRange + maxRange) / 2);
    const leftMid = Math.round((minRange + mid) / 2);
    const rightMid = Math.round((maxRange + mid) / 2);
    let modStep = modifierStep;
    let modifier = modifierMin;

    for (let i = minRange; i <= maxRange; i += rangeStep) {
      let end = i + rangeStep;
      if (end >= maxRange) {
        end = maxRange;
      }

      rangePositions.push({
        range: [i, end],
        modifier: modifier,
      });

      if (modifierStepIncreasing && !isAtRisk) {
        if (leftMid >= i && leftMid <= i + rangeStep) {
          modStep = modStep * 2;
        } else if (mid >= i && mid <= i + rangeStep) {
          modStep = modStep * 4;
        } else if (rightMid >= i && rightMid <= i + rangeStep && score <= 720) {
          modStep = modStep * 5;
        }
      }

      modifier = modifier + modStep;
    }

    return rangePositions;
  }

  const propertiesMap: { [key: string]: DialChartItemProperties } = {
    [ScoreDialProperty.averageScorePoint]: {
      iR: function () {
        const innerRadiusRanges = buildRangePositions(
          MIN_DOMAIN_SCORE, MAX_DOMAIN_SCORE, 15, 35, 0.2, averageScore, true
        );

        let iR = innerRadius;
        for (let scoreRanges of innerRadiusRanges) {
          if (averageScore >= scoreRanges.range[0] && averageScore <= scoreRanges.range[1]) {
            iR += scoreRanges.modifier;
            break;
          }
        }

        return iR;
      },
      oR: outerRadius,
      r: 5,
      value: determinePosition,
      x0: cx,
      y0: cy,
      divisor: 3.5,
    },
    [ScoreDialProperty.scoreNeedle]: {
      iR: 50,
      oR: 100,
      r: 5,
      value: determinePosition,
      x0: cx + 5,
      y0: cy + 15,
      divisor: 1.7,
    },
  }
  const getProperties = (type: ScoreDialProperty) => {
    const props = propertiesMap[type as keyof object];
    const value = typeof props.value === 'number' ? props.value : props.value(type)
    const ang = startAngle * (1 - value / total);
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const innerRadius = typeof props.iR === 'number' ? props.iR : props.iR();
    const length = (innerRadius + 2 * props.oR) / props.divisor;


    return {
      sin: sin,
      cos: cos,
      xp: props.x0 + length * cos,
      yp: props.y0 + length * sin,
      ...props
    }
  }
  const averageScorePoint = () => {
    const {yp, xp, r} = getProperties(ScoreDialProperty.averageScorePoint);
    let scoreTextXPosition = xp;
    if (averageScore >= 650) {
      const scoreTextXPositionValues = buildRangePositions(
        650, MAX_DOMAIN_SCORE, 100, 20, 10, averageScore
      );

      for (let x of scoreTextXPositionValues) {
        if (averageScore <= x.range[0]) {
          scoreTextXPosition = xp - x.modifier;
          break;
        }
      }
    }

    return [
      <circle cx={xp} cy={yp} r={r} fill={'#F4F4F4'} stroke="none" key={'average-score-point'}
              className={'score-dial__avg-score-circle'}/>,
      <text x={scoreTextXPosition} y={yp + 20} fill={'#A3A4A5'} className={'score-dial__avg-score'}
            key={'average-score'}>{averageScore}</text>,
      <text x={scoreTextXPosition} y={yp + 35} fill={'#A3A4A5'} className={'score-dial__avg-score'}
            key={'average-score-text'}>Average</text>
    ];
  };

  const scoreNeedle = () => {
    const {yp, xp, cos, sin, x0, y0, r} = getProperties(ScoreDialProperty.scoreNeedle);
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;

    return [
      <circle cx={x0} cy={y0} r={r} fill={'#323595'} stroke="none" key={'score-needle-circle'}/>,
      <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={'#323595'}
            key={'score-needle-path'}/>,
    ];
  };

  return (
    <div style={{}} className={'score-dial'}>
      <PieChart width={350} height={230} className={'score-dial__chart'}>
        <defs>
          {
            linearGradientProps.map((gradientProps, index) =>
              <linearGradient id={gradientProps.id} x1='0' y1='0' x2='100%' y2='0' key={`gradient-${index}`}>
                <stop offset={gradientProps.minOffset} stopColor={gradientProps.startColor}/>
                <stop offset={gradientProps.maxOffset} stopColor={gradientProps.endColor}/>
              </linearGradient>
            )
          }
        </defs>
        <Pie
          data={blocks}
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={0}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          paddingAngle={0.5}
          cornerRadius={40}
          dataKey="value"
        >
          {
            linearGradientProps.map((gradientProps, index) =>
              <Cell key={`cell-${index}`} fill={`url(#${gradientProps.id})`}/>
            )
          }
        </Pie>
        {averageScorePoint()}
        {scoreNeedle()}
      </PieChart>
      <div className={'score-dial__footer'}>
        <div className={'score-dial__at-risk-text'}>At Risk!</div>
        <div className={'score-dial__score-text'}>Your score: {score}</div>
        <div className={'score-dial__great-text'}>Great!</div>
      </div>
      <div className="score-dial__viq-score">
        <div className="score-dial__viq-score-text">VIQ Score</div>
        <VIQScoreDialTooltip title={<Typography fontSize={"0.9rem"} className={'score-dial__viq-score-tooltip'}>
          Your Vulnerability IQ score (“VIQ score”), is the result of a scan of more than 60 cybersecurity signals,
          weighted by level of risk. It’s like a credit score, but for cybersecurity risk.
        </Typography>}>
          <InfoOutlinedIcon className={'text-light-blue-3 cursor-pointer'} style={{fontSize: "1.2rem", zIndex: 777}}/>
        </VIQScoreDialTooltip>
      </div>
      <Background/>
      <MinScoreCircle/>
      <MaxScoreCircle/>
      <div className={'score-dial__domain'}>
        {domain}
      </div>
    </div>
  );
}

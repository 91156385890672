import React from 'react';
import * as yup from 'yup';
import MuiTextarea from "../Formik/MUITextarea/MUITextarea";
import {Form, Formik, FormikValues} from "formik";
import {Button} from "@mui/material";

import FormikMUISimpleInput from "../Formik/MUISimpleInput/FormikMUISimpleInput";
import {useAuth0} from "@auth0/auth0-react";
import {dataTestIds} from "../../constants/data-test-ids";

const ticketValidation = yup.object().shape({
	email: yup.string().email('Wrong email format.').required('Email is required.'),
})

type NewTicketFormType = {
	onConfirm: (email: string, ticketDescription: string) => void,
	loading: boolean
}

const NewTicketForm = ({onConfirm, loading}: NewTicketFormType) => {
	const {user} = useAuth0();
	const handleCreatingATicket = (values: FormikValues) => {
		onConfirm(values.email, values.description ? values.description : '');
	}

	return (
		<article className="mb-4">
			<p data-testid={dataTestIds.viq.ticketCreationHead} className="w-3/4 mb-3 m-auto text-xl text-center font-normal">
				Do you need help starting your cybersecurity journey? Please, reach out:
			</p>
			<Formik onSubmit={handleCreatingATicket}
					initialValues={{email: user?.email ? user.email : '', description: ''}}
					validationSchema={ticketValidation}>
				{({errors}) => {
					return <Form>
						<div className="mb-3">
							<FormikMUISimpleInput error={!!errors.email} name="email" size="small"
												  label="Your email *"/>
						</div>
						<div className="max-w-full min-w-0 w-auto">
							<MuiTextarea name="description" placeholder="How can we help you?" size="medium"
										 style={{width: '97.5%'}}/>
						</div>

						<div className="modal__footer">
							<Button
								id="ticketsubmission"
								className="modal__btn-submit"
								type='submit'
								style={{
									backgroundColor: "inherit"
								}}
								disabled={loading}
								variant="contained">
								<span className="modal__btn-label">Submit</span>
							</Button>
						</div>
					</Form>
				}}
			</Formik>
		</article>
	);
};

export default NewTicketForm;
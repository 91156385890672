import React from "react";

import {ApiUrlProps} from "../../interfaces";

const host = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
    ? process.env.REACT_APP_DEV_API_URL
    : process.env.REACT_APP_PROD_API_URL;
const tenant = window.location.hostname.split('.')[0];
const protocol = `${window.location.protocol}//`;

const apiUrlProps: ApiUrlProps = {
    host,
    tenant,
    protocol
}

const PathContext = React.createContext({
    apiUrlProps
})

export default PathContext;
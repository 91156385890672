import React, {useEffect, useState} from "react";
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useAPI} from "../../../../utils/hooks/useAPI";
import LoadingSpinner from "../../../../components/loading/LoadingSpinner";
import {CSVLink} from "react-csv";
import {LabelKeyObject} from "react-csv/components/CommonPropTypes";
import '../SingleMember/SingleMemberInfo.scss'
import {ENDPOINTS} from "../../../../constants";

interface ScanDataProps {
    domainId: number | null;
}

interface ScanDataInterface {
    id: number
    domain: string;
    timestamp: string;
    score: number;
    dns: Object;
    ssl: Object;
    tls: Object;
    vulnerabilities: Array<Object>;
    ssl_scan: Object;
    vulnerabilities_scan: Array<Object>;
    critical: number;
    important: number;
    scanners_opportunities: Array<Object>;
    tenant: string | undefined;
}

const ScanData: React.FC<ScanDataProps> = ({domainId}) => {
    const api = useAPI();
    const [data, setData] = useState<ScanDataInterface>();
    const [errorOccurred, setErrorOccurred] = useState<boolean>(false);

    useEffect(() => {
        api.get(ENDPOINTS.DOMAINS_SCORE.get_domain_scan(domainId))
            .then(responseData => setData(responseData.data))
            .catch(() => {
                setErrorOccurred(true)
            })
    }, [])

    const getDataAsCSV = (): Array<object> => {
        if (!data) return [];
        return Object.entries(data).map(value => {
            if (typeof value[1] === "number") {
                return {'field': value[0], 'value': value[1]}
            }
            return {'field': value[0], 'value': JSON.stringify(value[1]).replaceAll("\"", "\"\"")}
        })
    }

    const getHeadersOfCSV = (): LabelKeyObject[] => {
        return [{label: "Field", key: "field"}, {label: "Value", key: "value"}]
    }

    return <section>
        {!data && !errorOccurred ? <LoadingSpinner/> :
            errorOccurred ? <h3>Sorry, there was a problem fetching your data.</h3> :
                // <TableContainer style={{maxHeight: "40rem", wordBreak: "break-word"}} component={Paper}>
                <TableContainer component={Paper} className={"scan-data-wrapper"}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <strong className={"scan-data-table-cell-align"}>Field</strong></TableCell>
                                {/*<TableCell style={{display: 'flex'}} className={"scan-data-export-btn"}>*/}
                                <TableCell style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <strong style={{verticalAlign: "middle", whiteSpace: 'nowrap'}}>Value</strong>
                                    {/*<CSVLink style={{textDecoration: "none", marginLeft: '70%'}}*/}
                                    <CSVLink style={{textDecoration: "none", marginLeft: '12px'}}
                                             filename={`${data?.domain}_scan-data.csv`}
                                             headers={getHeadersOfCSV()}
                                             data={getDataAsCSV()}>
                                        <Button size={"small"} style={{backgroundColor: 'orange', color: 'white', whiteSpace: 'nowrap'}}>
                                            Export to CSV
                                        </Button>
                                    </CSVLink>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(data!).map((entry, index) => {
                                return <TableRow key={index}>
                                    <TableCell component="th" scope="row" className={"scan-data-table-row-width"}>
                                        <span>{entry[0]}</span>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {JSON.stringify(entry[1])}
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>}
    </section>
}

export default ScanData;

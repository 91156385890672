import * as React from "react";
import {useState} from "react";
import {AxiosError} from "axios";
import {Field, FormikProvider, useFormik} from "formik";
import {Button, Checkbox} from "@mui/material";
import * as Yup from "yup";
import {useMutation} from "@tanstack/react-query";

import {notifyError, notifySuccess} from "../../../../components/utils/ToastNotifications/Notifier";
import {ENDPOINTS} from "../../../../constants";
import {FormElementInputField} from "../../../Dashboard/MemberManagement/AddOrModifyMember/AddMember/AddMember";
import {useAPI} from "../../../../utils/hooks/useAPI";
import './Table/DropdownActions.scss'
import './AddTenant.scss'

type AddTenantModalProps = {
  handleTenantModalClose: Function
}

const AddTenantModal = ({handleTenantModalClose}: AddTenantModalProps) => {
  const api = useAPI();
  const [formValues, setFormValues] = useState<any>({
    name: '',
    subdomain_prefix: '',
    logo: '',
    is_self_serve: false,
    theme: '',
    active: true
  });

  const handleFormSubmit = (values: {
    name: string, subdomain_prefix: string, logo: string,
    is_self_serve: boolean, theme: string, active: boolean
  }) => {
    return api.post(ENDPOINTS.TENANTS.create_tenant_admin, values);
  }

  const {mutate: createTenant} = useMutation({
    mutationFn: handleFormSubmit,
    onSuccess: async () => {
      handleTenantModalClose()
      notifySuccess('Your tenant has been successfully added.');
    },
    onError: (error) => notifyError((error as AxiosError).response?.data?.message || 'Something went wrong please try again later or contact us.'),
    onSettled: () => {
      setFormValues(formik.values)
    }
  })

  const formik = useFormik({
    initialValues: {
      name: formValues.name,
      subdomain_prefix: formValues.subdomain_prefix,
      logo: formValues.logo,
      is_self_serve: formValues.is_self_serve,
      theme: formValues.theme,
      active: formValues.active
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .max(255, "This field must contain at most 255 characters.")
        .required("This field is required."),
      subdomain_prefix: Yup.string()
        .max(63, "This field must contain at most 63 characters.")
        .required("This field is required."),
      logo: Yup.string()
        .max(255, "This field must contain at most 255 characters.")
        .notRequired(),
      is_self_serve: Yup.boolean()
        .required("This field is required."),
      theme: Yup.string()
        .notRequired(),
      active: Yup.boolean()
        .required("This field is required."),
    }),
    onSubmit: (formData, {setSubmitting}) => {
      createTenant(formData)
    },
  });

  return (
    <section className="add-tenant">
      <div className="add-tenant__title">Add Tenant</div>
      <div className="add-tenant__form">
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <FormElementInputField name="name" label="Name"
                                   errors={formik.errors} touched={formik.touched}
                                   required/>
            <FormElementInputField name="subdomain_prefix" label="Subdomain Prefix"
                                   errors={formik.errors} touched={formik.touched}
                                   required/>
            <FormElementInputField name="logo" label="Logo"
                                   errors={formik.errors}
                                   touched={formik.touched}/>
            <div className="add-tenant__checkbox-div">
              <Field as={Checkbox} className="add-tenant__checkbox-styles"
                     name="is_self_serve"
                     checked={formik.values.is_self_serve}/>
              <span className="add-tenant__checkbox-label">Is Self Serve</span>
            </div>
            <FormElementInputField name="theme" label="Theme"
                                   errors={formik.errors}
                                   touched={formik.touched}/>
            <div className="add-tenant__checkbox-div">
              <Field as={Checkbox} className="add-tenant__checkbox-styles"
                     name="active"
                     checked={formik.values.active}
              />
              <span className="add-tenant__checkbox-label">Active</span>
            </div>
            <div className="float-right">
              <Button variant="contained"
                      className="dropdown-actions__buttons dropdown-actions__go-button"
                      type="submit"
                      disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              >
                Save
              </Button>
            </div>
          </form>
        </FormikProvider>
      </div>
    </section>
  )
}

export default AddTenantModal
import React from "react";

import './SubheaderReports.scss';

type SubheaderReportTypes = {
    font: string | undefined,
    timestamp: string
}

const SubheaderReport: React.FC<SubheaderReportTypes> = ({
                                                             font,
                                                             timestamp
                                                         }: SubheaderReportTypes): JSX.Element => {
    return (
        <section className="subheader-rep">
            <div className="subheader-rep__business-security-report">
                <div className="subheader-rep__security-rep">
                    <h1 className="text-xl mb-5">Vulnerability IQ Report</h1>
                    <h5 className="subheader-rep__timestamp">
                        Report period: {timestamp}
                    </h5>
                    <div className="subheader-rep__content" style={{fontFamily: font}}>
                        <p>We've put together this personalized report to help you understand your</p>
                        <p>cybersecurity risk and what you can do to better defend your business.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SubheaderReport;
import {useState, ComponentType, FC, useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from "react-router-dom";
import jwt from "jwt-decode";

type JwtPayload = {
    permissions: string[]
}

const audience = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_AUTH0_AUDIENCE
    : process.env.REACT_APP_PROD_AUTH0_AUDIENCE;

export const withRoleBasedRedirect = <P extends object>(
    Component: ComponentType<P>,
    requiredPermissions: string[]
): FC<P> => (props: P): JSX.Element => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const {getAccessTokenSilently} = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        const checkUserRoles = async () => {
            const accessToken = await getAccessTokenSilently({
                audience
            })

            const data = jwt<JwtPayload>(accessToken);
            if (!data.permissions.some(permission => requiredPermissions.includes(permission))) {
                navigate('/access-denied');
            }

            setIsAuthorized(true);
        }

        checkUserRoles();
    })

    return isAuthorized ? <Component {...props} /> : <div/>;
}

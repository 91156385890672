import React, {useContext, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import jwt from "jwt-decode";
import {useAuth0} from "@auth0/auth0-react";
import {SSMemberContext} from "../../store/context/self-serve-member-user";
import {useAPI} from "../../utils/hooks/useAPI";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import {ENDPOINTS} from "../../constants";

type ProtectedSSRouteType = {
    children: ReactJSXElement,
    isOnboarded: boolean | undefined,
    isOnboardedAllowed: boolean
}

const ProtectedSSRoute = ({children, isOnboarded, isOnboardedAllowed}: ProtectedSSRouteType) => {
    const navigate = useNavigate();
    const {getAccessTokenSilently} = useAuth0();
    const {member_id} = useParams()
    const {memberIds, setMemberIds} = useContext(SSMemberContext);
    const api = useAPI();
    const [isRequestProcessing, setIsRequestProcessing] = useState(false)

    React.useEffect(() => {
        (async () => {
            const accessToken = await getAccessTokenSilently();
            const decodedToken = jwt<{ "https://user.info/isOnboarded": string }>(accessToken);
            const isOnboardedToken = JSON.parse(decodedToken?.['https://user.info/isOnboarded'].toLowerCase());
            if ((isOnboarded === false || !isOnboardedToken) && isOnboardedAllowed) {
                navigate('/members/onboarding');
                return;
            }

            if ((isOnboarded || isOnboardedToken) && !isOnboardedAllowed) {
                navigate('/access-denied');
                return;
            }

            if (member_id && memberIds && !memberIds.includes(Number(member_id))) {
                setIsRequestProcessing(true)
                api.get(ENDPOINTS.MEMBERS.get_member_users).then((response) => {
                    if (response.data.length < 1 || !response.data.includes(Number(member_id))) {
                        navigate('/access-denied');
                        return;
                    }
                    setMemberIds(response.data);
                    setIsRequestProcessing(false);
                })
            }
        })()
    }, [])

    return isRequestProcessing ? <LoadingSpinner/> : children;
};

export default ProtectedSSRoute;

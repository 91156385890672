import React, {useContext, useEffect, useState} from 'react';
import {Checkbox, FormControlLabel} from "@mui/material";
import {AxiosError, AxiosResponse} from "axios";
import {useAPI} from "../../../utils/hooks/useAPI";
import {useLocation} from "react-router-dom";
import {FeatureFlagContext} from "../../../store/context/feature-flag-context";
import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import {ENDPOINTS, FEATURE_FLAGS} from "../../../constants";
import {notifyError, notifySuccess} from "../../../components/utils/ToastNotifications/Notifier";
import {FeatureFlag} from "../../../interfaces";

const Alerts = () => {
  const api = useAPI();
  const memberId = useLocation().pathname.split('/')[2];
  const {memberFlags, setMemberFlags} = useContext(FeatureFlagContext);
  const [areFlagsDisabled, setAreFlagsDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (memberFlags && memberFlags.length > 0) {
      return;
    }
    setIsLoading(true)
    getFeatureFlagsByMember()
      .then(response => {
        setMemberFlags(response.data.flags)
      })
      .catch(() => notifyError('Failed to fetch alerts'))
      .finally(() => setIsLoading(false))
  }, [])

  function getFeatureFlagsByMember(): Promise<AxiosResponse<{ flags: Array<FeatureFlag> }>> {
    return api.get(ENDPOINTS.FEATURE_FLAGS.get_or_update_member_feature_flags(memberId));
  }

  function fetchAllFlags(): Promise<AxiosResponse<{ flags: Array<FeatureFlag> }>> {
    return api.get(ENDPOINTS.FEATURE_FLAGS.get_all_flags);
  }

  function modifyFeatureFlagsForMember(flag: FeatureFlag, desiredState: boolean): Promise<void> {
    return api.post(ENDPOINTS.FEATURE_FLAGS.get_or_update_member_feature_flags(memberId), [{id: flag.id, new_state: desiredState}])
      .then((response: AxiosResponse) => {
        notifySuccess('Your alerts were updated.');
        desiredState ? memberFlags!.push(flag) : memberFlags?.splice(memberFlags?.indexOf(flag), 1);
        setMemberFlags([...memberFlags!])
      })
      .catch((error) => notifyError((error as AxiosError).response?.data?.detail || 'Unable to update your alerts.'));
  }

  const onFeatureFlagChanged = (flagName: string) => {
    setAreFlagsDisabled(true);
    const flag = memberFlags?.find(flag => flag.name === flagName)
    if (!flag) {
      fetchAllFlags()
        .then(response => {
          const flag = response.data.flags.find(flag => flag.name === flagName)
          if (!flag) {
            notifyError('Unable to update your alerts.');
            setAreFlagsDisabled(false);
            return;
          }
          modifyFeatureFlagsForMember(flag, true)
        })
        .catch((error) => notifyError((error as AxiosError).response?.data?.detail || 'Unable to update your alerts.'))
        .finally(() => setAreFlagsDisabled(false));
      return;
    }
    modifyFeatureFlagsForMember(flag, false).finally(() => setAreFlagsDisabled(false));
  }

  return (
    <section>
      <h3>Alerts</h3>
      {isLoading ? <LoadingSpinner/> :
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              disabled={areFlagsDisabled}
              checked={memberFlags && memberFlags.find(flag => flag.name === FEATURE_FLAGS.SCORE_EMAIL_ALERTS) != null}
              onChange={() => onFeatureFlagChanged(FEATURE_FLAGS.SCORE_EMAIL_ALERTS)}
            />
          }
          label="We'll send you a monthly email update on the current status of your score."
          labelPlacement="end"
        />
      }

    </section>
  );
};

export default Alerts;

import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Button from "@mui/material/Button";
import {useAPI} from "../../../utils/hooks/useAPI";

import TicketTrackingTab from '../../../components/TicketTracking';
import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import {notifyError, notifySuccess} from "../../../components/utils/ToastNotifications/Notifier";
import './TicketTracking.scss';
import NavigationTabs from "../../../components/NavigationTabs/NavigationTabs";
import DashboardComponent from "../../../components/DashboardComponent/DashboardComponent";
import {ENDPOINTS} from "../../../constants";

export interface Ticket {
	ticketID: number;
	companyName: string;
	memberID: number;
	domain: string;
	desc: string;
	phone: string;
	email: {
		email: string;
		is_primary: boolean;
	};
	src: string;
	score: number;
	status: string;
	date: Date;
}

type LocationState = {
	tab: string
}

const tabs = [{id: 'new', label: 'New tickets'}, {id: 'in progress', label: 'In Progress'}, {id: 'closed', label: 'Closed'}]

const TicketTracking = () => {
	const state = useLocation().state as LocationState;
	const api = useAPI();
	const [initialLoading, setInitialLoading] = useState<boolean>();
	const [loadingModal, setLoadingModal] = useState<boolean>(false)
	const [activeTab, setActiveTab] = useState<string>(state ? state.tab : 'new')
	const [inputFilter, setInputFilter] = React.useState<string>('')
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [tickets, setTickets] = useState<Array<Ticket>>([])
	const [pageCount, setPageCount] = useState<number>(1)
	const [searchDisabled, setSearchDisabled] = useState<boolean>(true)
	const [searchInProgress, setSearchInProgress] = useState<boolean>(false)

	useEffect(() => {
		setInitialLoading(true);
		getTickets(activeTab, undefined, pageCount).then(() => setInitialLoading(false));
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (inputFilter === "") {
			setSearchDisabled(true)
			if (initialLoading === false && searchInProgress) {
				getTickets(activeTab, inputFilter).then(() => setSearchInProgress(false))
			}
			return
		}
		setSearchDisabled(false)
	}, [inputFilter]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (initialLoading !== undefined) {
			changeTab(activeTab)
		}
	}, [activeTab]) // eslint-disable-line react-hooks/exhaustive-deps

	async function getTickets(status: string, searchValue: string = "", page: number = 1) {
		const {data} = await api.get(ENDPOINTS.TICKET_TRACKING.get_tickets_by_search_value(status, String(searchValue), page))
		setTickets(data.tickets);
		setCurrentPage(data.current_page)
		setPageCount(data.total_pages)
	}

	function changeTab(name: string) {
		setInitialLoading(true)
		setActiveTab(name);
		setInputFilter("");
		getTickets(name).then(() => setInitialLoading(false))
	}

	function getTicketsByFilter() {
		setSearchDisabled(true)
		setSearchInProgress(true)
		getTickets(activeTab, inputFilter)
	}

	function getNewTicketStatus(currentStatus: string): string {
		switch (currentStatus) {
			case "new":
				return "in progress";
			case "in progress":
				return "closed";
			default:
				return "new"
		}
	}

	function handleButtonClick(clickedTicket: Ticket) {
		const newTicketStatus = getNewTicketStatus(clickedTicket.status);
		setLoadingModal(true)
		api.patch(ENDPOINTS.TICKET_TRACKING.change_ticket_status(clickedTicket.ticketID), {status: newTicketStatus})
			.then((response) => {
				setLoadingModal(false);
				if (response.status === 200) {
					const message: string = newTicketStatus === "closed" ?
						"Ticket successfully closed." :
						"Ticket placed in progress"
					getTickets(activeTab, inputFilter, currentPage).then(() => notifySuccess(message))
				} else {
					notifyError("Something went wrong, please try again or contact us at support@edisonmarks.com")
				}
			})
			.catch(() => {
				setLoadingModal(false);
				notifyError("Something went wrong, please try again or contact us at support@edisonmarks.com")
			});
	}

	// TODO: style -> ?
	return (
		<DashboardComponent>
			<NavigationTabs tabs={tabs} activeTab={activeTab} changeTabHandler={setActiveTab}/>
			<article className="ticket-tracking member-management">
				{
					initialLoading ?
						<LoadingSpinner/>
						:
						<div>
							{
								(tickets.length > 0 || inputFilter.length > 0 || searchInProgress) && <div
									className="ticket-tracking__srch-member">
									<input
										className="ticket-tracking__srch-input ticket-tracking__srch-input--focus"
										type="text"
										value={inputFilter}
										placeholder="Enter source or domain to filter..."
										onChange={e => {
											setInputFilter(e.target.value)
										}}
										onKeyUp={event => {
											if (event.key === 'Enter') {
												if (!searchDisabled) {
													getTicketsByFilter()
												}
											}
										}}
									/>
									<Button style={{marginLeft: 3}} onClick={getTicketsByFilter} disabled={searchDisabled}>
										<span>Search</span>
									</Button>
								</div>
							}
							<TicketTrackingTab
								tickets={tickets}
								activeTab={activeTab}
								searchValue={inputFilter}
								emitHandleButtonClick={handleButtonClick}
								getTickets={getTickets}
								currentPage={currentPage}
								pageCount={pageCount}
								loadingModal={loadingModal}
							/>
							{
								tickets.length < 1 && initialLoading === false &&
								<h4 className="ticket-tracking__no-tickets">
									{	searchInProgress ?
										<span>
											There are no {activeTab === "new" ? activeTab + " tickets" : "tickets " + activeTab} matching your search.
										</span>
										:
										<span>
											There are no {activeTab === "new" ? activeTab + " tickets" : "tickets " + activeTab}.
										</span>
									}
								</h4>
							}
						</div>
				}
			</article>
		</DashboardComponent>
	)
}

export default TicketTracking

import {
  TSAChoiceLogo,
  EdisonMarksLogo,
  TrNetwork,
  TopSec,
  Mimecast,
  c3,
  scw,
  capstone,
  ofofo,
  iba,
  microsec,
  clearsailingits, redsift
} from "../../assets";
import {TenantsThemeInterface} from "../../interfaces";

const topSecDmarkOwned = [
  'mccowngordon.com',
  'superiorbowen.com',
  'backlotcars.com',
  'bbbskc.org',
  'UltraSourceUSA.com',
]

const domain = window.location.pathname.split('/')[2];
const isDomeinOwnerOfDmark = topSecDmarkOwned.includes(domain);

export const tenantsThemes: TenantsThemeInterface = {
  localhost: {
    name: 'Edison Marks',
    logo: EdisonMarksLogo,
    qrCode: {
      color: '#428DD1'
    },
    fontSize: '1.35rem',
    fonts: '-apple-system, BlinkMacSystemFont, \'Mulish\', \'Lato\', \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at em@edisonmarks.com',
    borderLine: {
      color: 'rgb(171,229,255,0.3)'
    },
    borderCircle: {
      color: 'rgb(255, 112, 82)',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'primary',
      headerButtonsColor: '',
      reportButtonsColor: ''
    },
    mainPage: [
      {
        text: `We use behavioral science\nto make small businesses\nmore secure.`,
        className: 'main_page__header'
      },
      {
        text: `Up to 80% of the costs attributed to cyber-attacks are the result of simple
                       human error. In other words, the ‘basics’. Edison Marks provides visibility into
                       vulnerabilities and simplifies the action items into plain english for us all.`,
        className: 'main_page__text'
      }
    ],
  },
  stg: {
    name: 'Edison Marks',
    logo: EdisonMarksLogo,
    qrCode: {
      color: '#428DD1'
    },
    fontSize: '1.35rem',
    fonts: '-apple-system, BlinkMacSystemFont, \'Mulish\', \'Lato\', \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at em@edisonmarks.com',
    borderLine: {
      color: 'rgb(171,229,255,0.3)'
    },
    borderCircle: {
      color: 'rgb(255, 112, 82)',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'primary',
      headerButtonsColor: '',
      reportButtonsColor: ''
    },
    mainPage: [
      {
        text: `We use behavioral science\nto make small businesses\nmore secure.`,
        className: 'main_page__header'
      },
      {
        text: `Up to 80% of the costs attributed to cyber-attacks are the result of simple
                       human error. In other words, the ‘basics’. Edison Marks provides visibility into
                       vulnerabilities and simplifies the action items into plain english for us all.`,
        className: 'main_page__text'
      }
    ],
  },
  em: {
    name: 'Edison Marks',
    logo: EdisonMarksLogo,
    qrCode: {
      color: '#428DD1'
    },
    fontSize: '1.35rem',
    fonts: '-apple-system, BlinkMacSystemFont, \'Mulish\', \'Lato\', \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at em@edisonmarks.com',
    borderLine: {
      color: 'rgb(171,229,255,0.3)'
    },
    borderCircle: {
      color: 'rgb(255, 112, 82)',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'primary',
      headerButtonsColor: '',
      reportButtonsColor: ''
    },
    mainPage: [
      {
        text: `We use behavioral science\nto make small businesses\nmore secure.`,
        className: 'main_page__header'
      },
      {
        text: `Up to 80% of the costs attributed to cyber-attacks are the result of simple
                       human error. In other words, the ‘basics’. Edison Marks provides visibility into
                       vulnerabilities and simplifies the action items into plain english for us all.`,
        className: 'main_page__text'
      }
    ],
  },
  tsachoice: {
    name: 'TSAChoice',
    logo: TSAChoiceLogo,
    qrCode: {
      color: '#ce0e2d'
    },
    fontSize: '1.30rem',
    fonts: '-apple-system, Gill Sans, Cabin Source, Sans Pro',
    footer: 'Questions? Suggestions? Reach out at tsachoice@edisonmarks.com',
    borderLine: {
      color: '#54565b'
    },
    borderCircle: {
      color: '#D32F2F',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'error',
      headerButtonsColor: 'header-rep__error-btn',
      reportButtonsColor: 'header-rep__error-btn'
    },
    mainPage: [
      {
        text: `Connect your business to the most\npowerful enterprise technology`,
        className: 'main_page__header'
      },
      {
        text: `We offer customized information technology solutions to empower your
                 business to meet its goals. Our turn-key service plans include equipment,
                 management and support from our knowledgeable partner solutions team.
                 Based in Greenville, S.C., and Asheville, N.C., TSA Choice delivers a
                 full spectrum of business IT solutions to businesses throughout the Southeast.`,
        className: 'main_page__text'
      }
    ],
  },
  trnetwork: {
    name: 'TrNetwork',
    logo: TrNetwork,
    qrCode: {
      color: '#b5121b'
    },
    fonts: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at trnetwork@edisonmarks.com',
    borderLine: {
      color: '#54585A'
    },
    borderCircle: {
      color: '#b5121b',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'error',
      headerButtonsColor: 'header-rep__error-btn',
      reportButtonsColor: 'header-rep__error-btn'
    },
    mainPage: [
      {
        text: `TR Network Consulting: trusted technology\n experts for businesses throughout Tampa Bay\n – Let us earn your trust today!`,
        className: 'main_page__header'
      },
      {
        text: `Maybe you’re concerned with the current rising costs of your IT services, 
                or maybe you are just running short on time because of your expanding business
                and need to hand over the reins of some services to someone else. Whatever your reason,
                we can help you with quality IT services today.`,
        className: 'main_page__text'
      }
    ],
  },
  mimecast: {
    name: 'Mimecast',
    logo: Mimecast,
    qrCode: {
      color: 'rgb(248,63,87)'
    },
    fonts: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at em@edisonmarks.com',
    borderLine: {
      color: 'rgb(171,229,255,0.3)'
    },
    borderCircle: {
      color: 'rgb(248,63,87)',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'primary',
      headerButtonsColor: '',
      reportButtonsColor: ''
    },
    mainPage: [
      {
        text: `The security partner of choice`,
        className: 'main_page__header'
      },
      {
        text: `As enterprise security leaders continuously work to mitigate risk 
				and look to replace legacy point solutions with more robust cloud-centric
				 security architectures, their partnership choices become more critical 
				 to success. Mimecast’s value begins with the most effective cloud-native 
				 platform for the #1 threat vector: email.`,
        className: 'main_page__text'
      }
    ],
    recommendations: [
      {
        recommendation_title: 'DMARC',
        title: 'BIG IMPACT',
        recommendation_subtitle: 'Easy does it',
        recommendation: "Implement DMARC and email authentication. \n\nThis sounds more complicated than it is " +
          "(like most things).\n\n Adding email authentication helps limit the bad folks from “spoofing” your domain " +
          "which can impact your customers, partners, and also your employees."
      },
      {
        recommendation_title: 'STP',
        title: 'GREAT INVESTMENT',
        recommendation_subtitle: 'Low cost, high return',
        recommendation: "Add a security awareness training platform. \n\nGood employees can make simple mistakes that have " +
          "serious consequences. \n\nAdding a security awareness training platform can help your employees be " +
          "good stewards of all you hold dear at your organization."
      },
      {
        recommendation_title: 'MFA',
        title: 'QUICK FIX',
        recommendation_subtitle: 'Smart consideration',
        recommendation: "Consider adding multi-factor authentication (MFA). \n\nThis sounds more complicated " +
          "than it is (like most things). \n\nAdding MFA means that you're using multiple forms of " +
          "authentication to sign-in to your accounts."
      }
    ],
  },
  topsec: {
    name: 'TopSec',
    logo: TopSec,
    qrCode: {
      color: '#68ade0'
    },
    fonts: 'Maven Pro, Digital-Serial Bold, sans-serif',
    footer: 'Questions? Suggestions? Reach out at em@edisonmarks.com',
    borderLine: {
      color: 'rgb(171,229,255,0.3)'
    },
    borderCircle: {
      color: '#F47950',
      importantColor: '#2DAAE2',
    },
    fontSize: '1.1rem',
    button: {
      color: 'primary',
      headerButtonsColor: '',
      reportButtonsColor: ''
    },
    mainPage: [
      {
        text: `We take pride in being the only fully managed concierge\n service for all your email security requirements`,
        className: 'main_page__header'
      },
      {
        text: `Topsec Cloud Solutions is a major provider of managed email and web security 
				solutions in the cloud. For almost two decades we have served a diverse range of 
				customers in Government, Healthcare, Engineering, Information Technology and other 
				industries.`,
        className: 'main_page__text'
      }
    ],
    recommendations: [
      {
        recommendation_title: 'STP',
        title: 'GREAT INVESTMENT',
        recommendation_subtitle: 'Low cost, high return',
        recommendation: "Add Email Security. \n\nEmail authentication is a great way to ensure genuine emails are " +
          "landing in your inbox. \n\nIt can be used for both harmful or fraudulent messages, helping block them " +
          "before they have time to get through any filters."
      },
      {
        recommendation_title: 'DMARC',
        title: 'BIG IMPACT',
        recommendation_subtitle: 'Easy does it',
        recommendation: `${isDomeinOwnerOfDmark ? 'Add Attachment Sandboxing.' : 'Implement DMARC.'} \n\n${isDomeinOwnerOfDmark
          ? 'Don\'t leave it up to your most important assets (your people) to make a simple mistake by downloading an attachment with malicious content.'
          : 'This sounds more complicated than it is (like most things).'} \n\n ${isDomeinOwnerOfDmark
          ? 'Application sandboxing helps protect your good intentioned employees.'
          : `Adding email authentication helps limit the bad folks 
					 from “spoofing” your domain which can impact your customers, partners,
					 and also your employees.`
        }`
      },
      {
        recommendation_title: 'MFA',
        title: 'QUICK FIX',
        recommendation_subtitle: 'Smart consideration',
        recommendation: "Add Secure File Exchange. \n\nLike most companies, you probably have files that " +
          "contain sensitive information. \n\n You don't want to transport your sensitive data in an unprotected " +
          "vehicle, do you? With a secure file exchange application, " +
          "you can rest easy knowing your most sensitive data is always protected."
      }
    ],
  },
  c3: {
    name: 'Carolina Cyber Center',
    logo: c3,
    qrCode: {
      color: '#428DD1'
    },
    fontSize: '1.35rem',
    fonts: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at c3@edisonmarks.com',
    borderLine: {
      color: 'rgb(171,229,255,0.3)'
    },
    borderCircle: {
      color: '#FF7052',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'primary',
      headerButtonsColor: '',
      reportButtonsColor: ''
    },
    mainPage: [
      {
        text: `A CORE OF EMPOWERMENT`,
        className: 'main_page__header'
      },
      {
        text: `Our passion is to empower our student’s learning journey. Founded by Montreat College,
				 	   Carolina Cyber Center (C3) has access to some of the best, brightest, 
				 	   and most apt minds working to forge the next-generation workforce,
				 	   the best cyber education in the region.`,
        className: 'main_page__text'
      }
    ],
  },
  'c3-msp': {
    name: 'Carolina Cyber Center',
    logo: c3,
    qrCode: {
      color: '#428DD1'
    },
    fontSize: '1.35rem',
    fonts: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at c3@edisonmarks.com',
    borderLine: {
      color: 'rgb(171,229,255,0.3)'
    },
    borderCircle: {
      color: '#FF7052',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'primary',
      headerButtonsColor: '',
      reportButtonsColor: ''
    },
    mainPage: [
      {
        text: `A CORE OF EMPOWERMENT`,
        className: 'main_page__header'
      },
      {
        text: `Our passion is to empower our student’s learning journey. Founded by Montreat College,
				 	   Carolina Cyber Center (C3) has access to some of the best, brightest, 
				 	   and most apt minds working to forge the next-generation workforce,
				 	   the best cyber education in the region.`,
        className: 'main_page__text'
      }
    ],
  },
  'c3-nclm': {
    name: 'Carolina Cyber Center',
    logo: c3,
    qrCode: {
      color: '#428DD1'
    },
    fontSize: '1.35rem',
    fonts: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at c3@edisonmarks.com',
    borderLine: {
      color: 'rgb(171,229,255,0.3)'
    },
    borderCircle: {
      color: '#FF7052',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'primary',
      headerButtonsColor: '',
      reportButtonsColor: ''
    },
    mainPage: [
      {
        text: `A CORE OF EMPOWERMENT`,
        className: 'main_page__header'
      },
      {
        text: `Our passion is to empower our student’s learning journey. Founded by Montreat College,
				 	   Carolina Cyber Center (C3) has access to some of the best, brightest, 
				 	   and most apt minds working to forge the next-generation workforce,
				 	   the best cyber education in the region.`,
        className: 'main_page__text'
      }
    ],
  },
  'c3-ced': {
    name: 'Carolina Cyber Center',
    logo: c3,
    qrCode: {
      color: '#428DD1'
    },
    fontSize: '1.35rem',
    fonts: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at c3@edisonmarks.com',
    borderLine: {
      color: 'rgb(171,229,255,0.3)'
    },
    borderCircle: {
      color: '#FF7052',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'primary',
      headerButtonsColor: '',
      reportButtonsColor: ''
    },
    mainPage: [
      {
        text: `A CORE OF EMPOWERMENT`,
        className: 'main_page__header'
      },
      {
        text: `Our passion is to empower our student’s learning journey. Founded by Montreat College,
				 	   Carolina Cyber Center (C3) has access to some of the best, brightest, 
				 	   and most apt minds working to forge the next-generation workforce,
				 	   the best cyber education in the region.`,
        className: 'main_page__text'
      }
    ],
  },
  ofofo: {
    name: 'Ofofo',
    logo: ofofo,
    qrCode: {
      color: '#428DD1'
    },
    fontSize: '1.35rem',
    fonts: '-apple-system, BlinkMacSystemFont, \'Mulish\', \'Lato\', \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at ofofo@edisonmarks.com',
    borderLine: {
      color: 'rgb(171,229,255,0.3)'
    },
    borderCircle: {
      color: '#FF7052',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'primary',
      headerButtonsColor: '',
      reportButtonsColor: ''
    },
    mainPage: [
      {
        text: `Begin your cyber resilience journey with Ofofo`,
        className: 'main_page__header'
      },
      {
        text: `Be proactive about your cybersecurity. Whether you have a limited budget or are a well-funded
				 	   company, Ofofo's Cyber resilience model starts with the same first steps. Free offerings and
				 	   guidance on implementing our model can help you start your cyber resilience journey today.`,
        className: 'main_page__text'
      }
    ],
  },
  iba: {
    name: 'IBA',
    logo: iba,
    qrCode: {
      color: '#428DD1'
    },
    fontSize: '1.35rem',
    fonts: '-apple-system, BlinkMacSystemFont, \'Mulish\', \'Lato\', \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at iba@edisonmarks.com',
    borderLine: {
      color: 'rgb(171,229,255,0.3)'
    },
    borderCircle: {
      color: '#FF7052',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'primary',
      headerButtonsColor: '',
      reportButtonsColor: ''
    },
    mainPage: [
      {
        text: `Offering creative and tailored solutions to help\n small and mid-size employers enhance\n
				 	   their employee benefits packages`,
        className: 'main_page__header'
      },
      {
        text: `We offer all the personalized service of a boutique shop with the tools and resources of national
				 	   agencies. We pride ourselves on finding creative, alternative funding solutions that beat generic
				 	   "off the shelf" options and are highly tailored to our clients' specific needs.`,
        className: 'main_page__text'
      }
    ],
  },
  microsec: {
    name: 'MicroCyberSec',
    logo: microsec,
    qrCode: {
      color: 'rgb(248,63,87)'
    },
    fontSize: '1.35rem',
    fonts: '-apple-system, BlinkMacSystemFont, \'Mulish\', \'Lato\', \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at MicroSec@edisonmarks.com',
    borderLine: {
      color: 'rgb(67,20,199,0.3)'
    },
    borderCircle: {
      color: 'rgb(248,63,87)',
      importantColor: '#4314C7'
    },
    button: {
      color: 'primary',
      headerButtonsColor: 'header-rep__dark-btn',
      reportButtonsColor: 'header-rep__microsec-btn'
    },
    mainPage: [
      {
        text: `Connect With a Reliable IT Support Company`,
        className: 'main_page__header'
      },
      {
        text: `Troubleshoot IT issues accurately from the comfort of your home. MicroSec is an IT support company
				 	   based in Waterford, NY. We provide a wide range of remote IT support services to residential clients
				 	   and small business owners in Waterford, NY and the surrounding areas.`,
        className: 'main_page__text'
      }
    ],
  },
  scw: {
    name: 'Security Camera Warehouse',
    logo: scw,
    qrCode: {
      color: '#428DD1'
    },
    fontSize: '1.35rem',
    fonts: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at scw@edisonmarks.com',
    borderLine: {
      color: 'rgb(171,229,255,0.3)'
    },
    borderCircle: {
      color: '#FF7052',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'primary',
      headerButtonsColor: '',
      reportButtonsColor: ''
    },
    mainPage: [
      {
        text: `From SMBs to Multi-Site Enterprise`,
        className: 'main_page__header'
      },
      {
        text: `SCW is a trusted partner to government agencies, major companies,\n
				 and large and small enterprises based on our expertise in security, surveillance,\n
				 and protection of property, people, and inventory. The company’s decade-plus of \n
				 technical know-how and five-star reviews are the backbone of our integrated security\n 
				 solutions and services.`,

        className: 'main_page__text'
      }
    ],
  },
  scw2: {
    name: 'Security Camera Warehouse',
    logo: scw,
    qrCode: {
      color: '#428DD1'
    },
    fontSize: '1.35rem',
    fonts: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at scw2@edisonmarks.com',
    borderLine: {
      color: 'rgb(171,229,255,0.3)'
    },
    borderCircle: {
      color: '#FF7052',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'primary',
      headerButtonsColor: '',
      reportButtonsColor: ''
    },
    mainPage: [
      {
        text: `From SMBs to Multi-Site Enterprise`,
        className: 'main_page__header'
      },
      {
        text: `SCW is a trusted partner to government agencies, major companies,\n
				 and large and small enterprises based on our expertise in security, surveillance,\n
				 and protection of property, people, and inventory. The company’s decade-plus of \n
				 technical know-how and five-star reviews are the backbone of our integrated security\n 
				 solutions and services.`,

        className: 'main_page__text'
      }
    ],
  },
  capstone: {
    name: 'Capstone Healthcare',
    logo: capstone,
    qrCode: {
      color: '#428DD1'
    },
    fontSize: '1.35rem',
    fonts: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at capstone@edisonmarks.com',
    borderLine: {
      color: '#2E3192'
    },
    borderCircle: {
      color: '#03B646',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'primary',
      headerButtonsColor: '',
      reportButtonsColor: ''
    },
    mainPage: [
      {
        text: `A CORE OF EMPOWERMENT`,
        className: 'main_page__header'
      },
      {
        text: `Our passion is to empower our student’s learning journey. Founded by Montreat College,
				 	   Carolina Cyber Center (C3) has access to some of the best, brightest, 
				 	   and most apt minds working to forge the next-generation workforce,
				 	   the best cyber education in the region.`,
        className: 'main_page__text'
      }
    ],
  },
  clearsailingits: {
    name: 'Clear Sailing IT Solutions',
    logo: clearsailingits,
    qrCode: {
      color: '#00c2cc'
    },
    fontSize: '1.35rem',
    fonts: '-apple-system, BlinkMacSystemFont, \'Mulish\', \'Lato\', \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at clearsailingits@edisonmarks.com',
    borderLine: {
      color: '#00c2cc'
    },
    borderCircle: {
      color: '#006166',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'primary',
      headerButtonsColor: 'header-rep__clearsailingits-btn',
      reportButtonsColor: 'header-rep__clearsailingits-btn'
    },
    mainPage: [
      {
        text: `Your Partner for Managed Services & IT Support`,
        className: 'main_page__header'
      },
      {
        text: `Clear Sailing IT Solutions has been serving the Illinois, Wisconsin, and Florida area since 2021,
				providing IT Support such as technical helpdesk support, computer support, and consulting to
				small and medium-sized businesses.`,
        className: 'main_page__text'
      }
    ],
  },
  redsift: {
    name: 'Red Sift',
    logo: redsift,
    qrCode: {
      color: '#428DD1'
    },
    fontSize: '1.35rem',
    fonts: '-apple-system, BlinkMacSystemFont, \'Mulish\', \'Lato\', \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
      '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
      '    sans-serif',
    footer: 'Questions? Suggestions? Reach out at redsift@edisonmarks.com',
    borderLine: {
      color: 'rgb(171,229,255,0.3)'
    },
    borderCircle: {
      color: 'rgb(255, 112, 82)',
      importantColor: '#2DAAE2',
    },
    button: {
      color: 'primary',
      headerButtonsColor: 'header-rep__redsift-btn',
      reportButtonsColor: 'header-rep__redsift-btn'
    },
    mainPage: [
      {
        text: `SEE. SOLVE. SECURE.`,
        className: 'main_page__header'
      },
      {
        text: `Red Sift's Digital Resilience Platform solves for the greatest vulnerabilities across your complete \n
				attack surface, including email, domains, brand, and your network perimeter.`,
        className: 'main_page__text'
      }
    ],
  }
}

export const defaultEmTheme = {
  "name": "Edison Marks",
  "logo": EdisonMarksLogo,
  "fontSize": "1.35rem",
  "fonts": {
    "mainFont": "Mulish, sans-serif",
    "subFont": "Lato, sans-serif",
    "all": ["-apple-system", "BlinkMacSystemFont", "Mulish", "Lato", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
            "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"]
  },
  "footerContent": "Questions? Suggestions? Reach out at em@edisonmarks.com",
  "borderLineColor": "rgb(171,229,255,0.3)",
  "borderCircle": {
    "urgentColor": "#ff7052",
    "importantColor": "#2DAAE2"
  },
  "buttons": {
    "mainButtonsColor": "#2DAAE2",
    "actionButtonsColor": "#ff7052"
  },
  "mainPageSettings": [
    {
      "element": "title",
			"text": "We use behavioral science\nto make small businesses\nmore secure."
    },
    {
      "element": "paragraph",
      "text": `Up to 80% of the costs attributed to cyber-attacks are the result of simple\nhuman error. In other words, the ‘basics’. Edison Marks provides visibility into\nvulnerabilities and simplifies the action items into plain english for us all.`,
    }
  ]
}
import React, {useContext, useEffect, useMemo} from 'react';
import {Button} from "@mui/material";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import DownloadIcon from '@mui/icons-material/Download';

import {useAuth0} from "@auth0/auth0-react";
import DownloadTechReport from "../DownloadTechReport/DownloadTechReport";
import GenerateTicket from "../ModalRecommendations/GenerateTicket";
import {useAPI} from "../../utils/hooks/useAPI";
import {notifyError} from "../utils/ToastNotifications/Notifier";
import {useMutation, useQuery} from "@tanstack/react-query";
import LoadingSpinner from "../loading/LoadingSpinner";
import {ENDPOINTS, FEATURE_FLAGS} from "../../constants";
import {FeatureFlagContext} from "../../store/context/feature-flag-context";
import {NewTicket} from "../NewTicket";
import "./VIQ.scss"
import {AxiosResponse} from "axios";
import {dataTestIds} from "../../constants/data-test-ids";
import {FeatureFlag} from "../../interfaces";
import {LoadingType} from "../loading/types";

type NewTicketProps = {
	domainScoreId: number,
	domainId: number,
	memberId: number,
	scrollID: number,
	domain: string,
	ticketData?: {
		btnType: string,
		scrollId: string
	},
	isClickedTechReportButton: boolean
	setIsClickedTechReportButton: React.Dispatch<React.SetStateAction<boolean>>
	nextStep?: () => void,
	isLoadingSpinnerFunc?: (isLoading: boolean, type: LoadingType) => void,
}

const VIQ = ({
				 domainScoreId,
				 domainId,
				 memberId,
				 scrollID,
				 nextStep,
				 domain,
				 ticketData,
				 isClickedTechReportButton,
				 setIsClickedTechReportButton,
				 isLoadingSpinnerFunc
			 }: NewTicketProps) => {
	const [totalRec, setTotalRec] = React.useState<number>(0);
	const [isRecCountLoading, setIsRecCountLoading] = React.useState<boolean>(true);
	const {memberFlags, setMemberFlags} = useContext(FeatureFlagContext);
	const {user} = useAuth0();
	const api = useAPI();

	const isViqEnabled = useMemo(() => {
		if (totalRec && memberFlags && memberFlags?.length > 0) {
			return totalRec > 0 && !!memberFlags?.find(flag => flag.name === FEATURE_FLAGS.VIQ)
		}
	}, [totalRec, memberFlags])


	const {data: countData, error: isRecCountError} = useQuery({
		queryKey: ['recCount'],
		queryFn: () => handleRecommendationCount(domainScoreId),
	})

	const {isLoading: featureFlagsLoading, data: flagsData, error: flagsError} = useQuery({
		queryKey: ['featureFlags'],
		queryFn: getFeatureFlagsByMember
	})

	const {
		isLoading: initiateRecLoading,
		mutate: initiateRecs,
		error: initiateRecError
	} = useMutation(handleInitiatingRec)

	React.useEffect(() => {
		if (countData && flagsData) {
			setTotalRec(countData?.data.all_recommendations_count - countData?.data.resolved_recommendations_count);
			setMemberFlags(flagsData.data.flags);
		}
	}, [countData, flagsData])

	useEffect(() => {
		isViqEnabled && isLoadingSpinnerFunc && isLoadingSpinnerFunc((isRecCountLoading || initiateRecLoading || featureFlagsLoading), 'spinner');
	}, [isRecCountLoading, initiateRecLoading, featureFlagsLoading, isViqEnabled]);

	function getFeatureFlagsByMember(): Promise<AxiosResponse<{ flags: Array<FeatureFlag> }>> {
		return api.get(ENDPOINTS.FEATURE_FLAGS.get_or_update_member_feature_flags(memberId));
	}

	async function handleRecommendationCount(domainScoreId: number): Promise<AxiosResponse<{ all_recommendations_count: number, resolved_recommendations_count: number }>> {
		const promise = api.get(ENDPOINTS.VIQ.get_recommendations_count(domainScoreId));
		promise.then(() => setIsRecCountLoading(false));

		return promise;
	}

	async function handleInitiatingRec({domainScoreId}: { domainScoreId: number }): Promise<void | undefined> {
		return api.post(ENDPOINTS.VIQ.trigger_recommendations_generation(domainScoreId))
			.then(() => nextStep && nextStep())
			.catch((e) => notifyError(e.message))
	}

	if (initiateRecError || isRecCountError || flagsError) {
		notifyError('Something wrong happened, please try again later')
		return <LoadingSpinner/>
	}

	return (
		<>
			{
				isRecCountLoading || initiateRecLoading || featureFlagsLoading
					? <div className={'align-middle justify-center flex py-60 px-80'}><LoadingSpinner/></div>
					: (
						<section className="modal_viq_bg">
							<article className="pt-6">
								<p className="px-6 text-lg sm:text-xl text-center font-normal mt-0 pointer-events-none">About your
									Vulnerability IQ Score</p>
								<article className="px-4 sm:px-6 pb-3 modal_viq_body">
								<article className="mb-3">
									<div className="flex items-start">
										<FactCheckIcon className="mt-1 me-4 ps-2 text-dark-purple"
													   style={{fontSize: '30.5px'}}/>
										<p className="mt-0 text-sm text-start italic">
											We've put together this personalized report to help you understand your
											cybersecurity risk
											and
											how to better defend your business.
										</p>
									</div>
									<div className="flex items-start">
										<TipsAndUpdatesIcon className="mt-1 me-4 ps-2 text-xl text-light-orange"
															style={{fontSize: '30.5px'}}/>
										<p className="mt-0 text-sm text-start italic">
											Your Vulnerability IQ score (“VIQ score”), is the result of a scan of more than
											60
											cybersecurity
											signals, weighted by level of risk. It’s like a credit score, but for
											cybersecurity risk.
										</p>
									</div>
									<div className="flex items-start">
										<QueryStatsIcon className="mt-1 me-4 ps-2 text-light-blue-3"
														style={{fontSize: '30.5px'}}/>
										<p className="mt-0 text-sm text-start italic">
											Comparisons are based on similar businesses to your own and may change over
											time.
										</p>
									</div>
								</article>

								<article className="text-center">
									{isViqEnabled
										? (
											<>
												<p className="font-normal text-xl">We’ve detected {totalRec} vulnerability
													issues on your website.</p>
												<Button
													className="py-1 bg-orange hover:btn-box-shadow"
													data-testid={dataTestIds.viq.execute}
													sx={{
														fontSize: '16px',
														fontWeight: '700',
														paddingX: '27px !important',
														color: 'white',
														borderRadius: '8px'
													}}
													onClick={() => initiateRecs({domainScoreId})}
													disabled={isRecCountLoading || initiateRecLoading || featureFlagsLoading}
												>
													GUIDE ME THROUGH FIXING THEM
												</Button>
											</>
										)
										: <NewTicket domain={domain} btnType={ticketData?.btnType}/>
									}
								</article>
								<article className="mb-7">
									<p className="w-3/4 mt-7 mb-4 m-auto text-xl text-center font-normal">
										Or if you feel confident in your tech chops:
									</p>
									<div className="m-auto w-80 cursor-pointer">
										<div
											id="download-tech-report"
											data-testid={dataTestIds.techReport.downloadBtn}
											onClick={() => setIsClickedTechReportButton(prevState => !prevState)}
											className="flex items-center justify-center rounded-lg p-1 bg-light-blue1 hover:btn-box-shadow">
											<DownloadIcon className="me-2" style={{fontSize: '18.5px'}}/>
											<p className="m-0 text-base font-thin">
												Download your Full Technical Report
											</p>
										</div>
									</div>
									{!user &&
										<p className="mt-1 text-center text-13">Please note: Before downloading you will be
											asked to Log
											In
											or Sign Up.
										</p>
									}
								</article>
								</article>
							</article>
							{ isViqEnabled ?
								<GenerateTicket domain={domain} ticketData={ticketData}/> :
								<div style={{height: "32px"}}></div>
							}
							{isClickedTechReportButton &&
								<DownloadTechReport
									domainId={domainId}
									scrollId={scrollID}
									setIsClickedTechReportButton={setIsClickedTechReportButton}
								/>}
						</section>
					)
			}
		</>

	);
};

export default VIQ;

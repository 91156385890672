import QueryStatsIcon from '@mui/icons-material/QueryStats';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import PublishIcon from "@mui/icons-material/Publish";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MoodIcon from '@mui/icons-material/Mood';
import {Button} from "@mui/material";

import './Styles.scss';

const Styles = () => {
    return (
        <section className="sg-styles">
            <article>
                <h2 className="concepts__center-headers">Styles</h2>
                <h2>Colors</h2>
                <ul>
                    <li>
                        Colors for text, links, and backgrounds.
                    </li>
                    <li>
                        Be sure to follow color usage guidelines.
                    </li>
                </ul>
                <table className="sg-styles__table">
                    <tr>
                        <td className="sg-styles__table-row">
                            white:
                        </td>
                        <td className="sg-styles__table-row">
                            <span>#FFFFFF</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            grey:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#818A91'}}>#818A91</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            light blue:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#ABE5FF'}}>#ABE5FF</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            pink:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#EBB0E7'}}>#EBB0E7</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            alert red:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#EA3D2F'}}>#EA3D2F</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            light gray:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#F7F7F7'}}>#F7F7F7</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            dark gray:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#555555'}}>#555555</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            light blue:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#ABE5FF4D'}}>#ABE5FF4D</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            orange:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#FF7052'}}>#FF7052</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            light red:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#EA3D2F33'}}>#EA3D2F33</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            light gray:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#F4F4F4'}}>#F4F4F4</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            dark gray:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#333333'}}>#333333</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            light blue:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#2DAAE2'}}>#2DAAE2</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            mauve:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#CC3366'}}>#CC3366</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            alert yellow:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#FFC90B'}}>#FFC90B</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            light gray:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#BDC7CF'}}>#BDC7CF</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            dark gray:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#353030'}}>#353030</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            light purple:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#7B61FF'}}>#7B61FF</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            light orange:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#F3AA18'}}>#F3AA18</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            alert green:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#58D14E'}}>#58D14E</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            black:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#000000'}}>#000000</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            dark purple:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#323595'}}>#323595</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            light yellow:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#FFE7AB'}}>#FFE7AB</span>
                        </td>
                    </tr>
                    <tr className="sg-styles__table-row">
                        <td className="sg-styles__table-row">
                            green:
                        </td>
                        <td className="sg-styles__table-row">
                            <span style={{color: '#2FA84F'}}>#2FA84F</span>
                        </td>
                    </tr>
                </table>
            </article>
            <article className="sg-styles__typography">
                <h2>Typography</h2>
                <div className="sg-styles__fonts">
                    <p>Mulish</p>
                    <p>Lorem Ipsum Dolor Amet</p>
                    <p>Bold / 24px</p>
                </div>
                <div className="sg-styles__fonts">
                    <p>Mulish</p>
                    <p>Lorem Ipsum Dolor Amet</p>
                    <p>Bold / 18px</p>
                </div>
                <div className="sg-styles__fonts">
                    <p>Mulish</p>
                    <p>Lorem Ipsum Dolor Amet</p>
                    <p>Light / 18px</p>
                </div>
                <div className="sg-styles__fonts">
                    <p>Mulish</p>
                    <p>Lorem Ipsum Dolor Amet</p>
                    <p>Semibold / 18px</p>
                </div>
                <div className="sg-styles__fonts">
                    <p>Mulish</p>
                    <p>Lorem Ipsum Dolor Amet</p>
                    <p>Semibold / 16px</p>
                </div>
                <div className="sg-styles__fonts">
                    <p>Mulish</p>
                    <p>Lorem Ipsum Dolor Amet</p>
                    <p>Regular / 16px</p>
                </div>
                <div className="sg-styles__fonts">
                    <p>Mulish</p>
                    <p>Lorem Ipsum Dolor Amet</p>
                    <p>Regular / 14px</p>
                </div>
                <div className="sg-styles__fonts">
                    <p>Mulish</p>
                    <p>Lorem Ipsum Dolor Amet</p>
                    <p>Light Italic / 14px</p>
                </div>
                <div className="sg-styles__fonts">
                    <p>Quicksand</p>
                    <p>Lorem Ipsum Dolor Amet</p>
                    <p>Regular / 20px</p>
                </div>
                <div className="sg-styles__fonts">
                    <p>Quicksand</p>
                    <p>Lorem Ipsum Dolor Amet</p>
                    <p>Light / 16px</p>
                </div>
            </article>
            <article>
                <h2>Library</h2>
                <p>In this project we use material ui library - <a href="https://mui.com/">https://mui.com/</a></p>
                <p>For icons you can reference - <a href="https://mui.com/material-ui/material-icons/">
                    https://mui.com/material-ui/material-icons/</a>
                </p>
            </article>
            <article>
                <h2>Iconography</h2>
                <p><QueryStatsIcon/> - import QueryStatsIcon from '@mui/icons-material/QueryStats';</p>
                <p><MailOutlineIcon/> - import MailOutlineIcon from '@mui/icons-material/MailOutline';</p>
                <p><NoteAddIcon/> - import NoteAddIcon from '@mui/icons-material/NoteAdd';</p>
                <p><AssessmentOutlinedIcon/> - import AssessmentOutlinedIcon from
                    '@mui/icons-material/AssessmentOutlined';</p>
                <p><ErrorIcon/> - import ErrorIcon from '@mui/icons-material/Error';</p>
                <p><OfflineBoltIcon/> - import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';</p>
                <p><PublishIcon/> - import PublishIcon from "@mui/icons-material/Publish";</p>
                <p><AddCircleOutlineIcon/> - import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
                </p>
                <p><FileDownloadOutlinedIcon/> - import FileDownloadOutlinedIcon from
                    '@mui/icons-material/FileDownloadOutlined';</p>
                <p><FileUploadOutlinedIcon/> - import FileUploadOutlinedIcon from
                    '@mui/icons-material/FileUploadOutlined';</p>
                <p><ArrowDropDownIcon/> - import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';</p>
                <p><ArrowDropUpIcon/> - import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';</p>
                <p><MoodIcon/> - import MoodIcon from '@mui/icons-material/Mood';</p>
            </article>
            <article>
                <h2>Sections</h2>
                <p>Make sure you start every components with section tag.</p>
                <p className="concepts__block-example">&lt;section /&gt; <br/>
                    &emsp;...content <br/>
                    &lt;/ section&gt; <br/>
                </p>
            </article>
            <article>
                <h2>Buttons</h2>
                <p>Make sure to use mui buttons <a href="https://mui.com/material-ui/react-button/">
                    https://mui.com/material-ui/react-button/</a>
                </p>
                <Button variant="contained">Contained</Button>
                <p className="concepts__block-example">&lt;Button variant="contained"&gt; <br/>
                    &emsp;Contained <br/>
                    &lt;/ Button&gt; <br/>
                </p>
                <p>Refer to their variant or implement your styles to the buttons, depending on what you need.</p>
                <h4>Submit Button</h4>
                <Button
                    className="sg-styles__btn-submit"
                    type='submit'>
                    Submit
                </Button>
                <h4>Cancel Button</h4>
                <Button
                    className="sg-styles__btn-cancel">
                    Cancel
                </Button>
                <h3>Disabled Buttons</h3>
                <p>Use disabled buttons when content is still loading and you don't want your users to go around the
                    pages.</p>
                <Button variant="contained" disabled>
                    Disabled
                </Button>
                <p className="concepts__block-example">&lt;Button variant="contained" disabled&gt; <br/>
                    &emsp;Disabled <br/>
                    &lt;/ Button&gt; <br/>
                </p>
            </article>
            <article>
                <h2>Sizing</h2>
                <p>Use rem units for sizing.</p>
            </article>
            <article>
                <h2>Loading Spinner</h2>
                <p>Use mui Loading spinner when waiting for content to be rendered.</p>
                <p className="concepts__block-example">
                    import LoadingSpinner from "../../../components/loading/LoadingSpinner"; <br/>
                    &lt;LoadingSpinner/&gt;
                </p>
            </article>
            <article>
                <h2>SASS</h2>
                <p>What is Sass?</p>
                <p>Sass is an extension to CSS. Sass is a CSS pre-processor.
                    Sass is completely compatible with all versions of CSS.
                    Sass reduces repetition of CSS and therefore saves time.
                </p>
                <p>The main sass features we use in this project are:</p>
                <ul>
                    <li>Nested Code</li>
                    <p className="concepts__block-example">
                        .sg-styles &#123; <br/>
                        &emsp;&__table &#123; <br/>
                        &emsp;width: 100%; <br/>
                        &emsp;margin-top: 3rem; <br/><br/>

                        &emsp;&-row &#123;<br/>
                        &emsp;&emsp;padding: 10px;<br/>
                        &emsp;&emsp;border-bottom: 1px solid lightgray;<br/>
                        &emsp;&#125;<br/>
                        &#125;
                    </p>
                    <p>& is for parent element</p>
                    <li>Variables</li>
                    <p>Use variables to avoid repeating css</p>
                    <p className="concepts__block-example">
                        declare in _varibles.scss file like white: $white;<br/>
                        and use in file like @import '../../../assets/scss/variables';<br/>
                        $white
                    </p>
                    <li>Mixins</li>
                    <p>Use mixins to avoid repeating sass code</p>
                    <p>For example:</p>
                    <p className="concepts__block-example">
                        declare in _mixins.scss file like: <br/>
                        @mixin flexCenter($horPosition, $verPosition ) &#123; <br/>
                        &emsp;display: flex; <br/>
                        &emsp;justify-content: $horPosition; <br/>
                        &emsp;align-items: $verPosition; <br/>
                        &#125;<br/><br/>
                        and use in file like: <br/>
                        @import "src/assets/scss/mixins";<br/>
                        @include flexCenter(space-between, center);
                    </p>
                </ul>
            </article>
        </section>
    );
};

export default Styles;
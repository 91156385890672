import {TooltipProps} from "recharts";
import {NameType, ValueType} from "recharts/types/component/DefaultTooltipContent";

import {GoogleAnalyticsEvent} from "../types";
import './../styles.scss';

const GoogleAnalyticsChartCustomTooltip = ({active, payload}: TooltipProps<ValueType, NameType>) => {
    const MAX_ELEMENTS_IN_TOOLTIP = 5;
    if (active && payload && payload.length) {
        const payloadEvents = payload[0].payload.events;
        // TODO: style ?
        return (
            <div className="custom-tooltip">
                {
                    payloadEvents.length > 1 ?
                        <>
                            <p>{`Total Count: ${payload[0].value}`}</p>
                            <div style={{
                                borderStyle: "solid",
                                borderWidth: "thin",
                                borderBottom: "white",
                                borderLeft: "white",
                                borderRight: "white"
                            }}>
                                {
                                    payloadEvents.length > MAX_ELEMENTS_IN_TOOLTIP ?
                                        <>
                                            {
                                                payloadEvents.slice(0, MAX_ELEMENTS_IN_TOOLTIP).map((event: GoogleAnalyticsEvent) =>
                                                    <p>{`${event.page_path.replace('/reports/', '')}: ${event.event_count}`}</p>
                                                )
                                            }
                                            <p style={{textAlign: "center"}}>...</p>
                                        </>
                                        :
                                        payloadEvents.map((event: GoogleAnalyticsEvent) =>
                                            <p>{`${event.page_path.replace('/reports/', '')}: ${event.event_count}`}</p>
                                        )
                                }
                            </div>
                        </>
                        :
                        (
                            payloadEvents.length === 1 ?
                                <>
                                    <p>{`Count: ${payload[0].value}`}</p>
                                    <p>{`Page: ${payloadEvents[0].page_path.replace('/reports/', '')}`}</p>
                                </>
                                :
                                <p>{`Count: ${payload[0].value}`}</p>
                        )
                }
            </div>
        );
    }

    return null;
};

export default GoogleAnalyticsChartCustomTooltip;

import React, {useContext, useEffect} from 'react';
import DashboardHeader from "../Dashboard/DashboardHeader/DashboardHeader";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import DashboardFooter from "../Dashboard/DashboardFooter/DashboardFooter";
import Onboarding from './Onboarding';
import Settings from "./SettingsSS";
import {useAuth0} from "@auth0/auth0-react";
import {TenantUserPropsContext} from "../../store/context/tenant-user-props-context";
import {useAPI} from "../../utils/hooks/useAPI";
import {useQuery} from "@tanstack/react-query";
import {ENDPOINTS, ROUTES, permissionsCatalog} from "../../constants";
import ProtectedRoute from "../../components/auth/ProtectedRoute";
import './styles.scss'

const TenantSelfServe = () => {
  const navigate = useNavigate();
  const {user} = useAuth0();
  const [userName, setUserName] = React.useState(user?.name);
  const api = useAPI();
  const {tenantUserProps, setTenantUserProps} = useContext(TenantUserPropsContext);
  const {pathname} = useLocation();

  const {data: response, error} = useQuery({
    queryKey: ['fetch-tenant-user-info'],
    queryFn: fetchTenantUserInfo,
    enabled: !(tenantUserProps || pathname.includes('/tenants/onboarding')),
    refetchOnWindowFocus: false
  })

  function fetchTenantUserInfo() {
      return api.get(ENDPOINTS.TENANTS.get_current_tenant_user);
  }

  useEffect(() => {
    if (error != null && (error as any).response.status === 404) {
      navigate('/access-denied');
    }
    if (response?.data) {
      setTenantUserProps && setTenantUserProps(response.data)
    }
  }, [response?.data, error])

  return (
    <section className="app__admin-dashboard">
      <DashboardHeader/>
      <article className="app__admin-dashboard-main app__tenant-self-serve-main">
        <Routes>
          <Route path={ROUTES.TENANT_SS.subroutes.ONBOARDING.route} element={
            <ProtectedRoute component={Onboarding} requiredPermissions={[permissionsCatalog.create_tenants]}/>
          }/>
          <Route path={ROUTES.TENANT_SS.subroutes.SETTINGS.route} element={
              <Settings userName={userName} setUserName={setUserName}/>
          }/>
          <Route path={ROUTES.ERROR_PAGE.route} element={<Navigate replace to={'/not-found'}/>} />
        </Routes>
      </article>
      <DashboardFooter/>
    </section>
  );
};

export default TenantSelfServe;

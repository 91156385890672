import React from "react";
import {ErrorMessage, Field} from "formik";
import {MenuItem, TextField} from "@mui/material";

interface FormikFieldProps {
    name: string;
    label: string;
    required?: boolean;
    error?: boolean;
    items: FormikSelectItem[];
    size: string;
}

export interface FormikSelectItem {
    label: string;
    value: string;
}

const FormikMUISelect: React.FC<FormikFieldProps> = ({
                                                         name, label, required = false,
                                                         error, items, size
                                                     }) => (
    <>
        <Field
            select
            required={required}
            autoComplete="off"
            as={TextField}
            label={label}
            name={name}
            fullWidth
            helperText={<ErrorMessage name={name}/>}
            error={error}
            size={size}
            className="member-modifier__form-select"
            InputProps={{
                classes: {
                    root: 'text-field-root',
                },
            }}
        >
            {items.map(item => (
                <MenuItem key={item.value} value={item.value}>
                    {item.label}
                </MenuItem>
            ))}
        </Field>
    </>
);

export default FormikMUISelect;

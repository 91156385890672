import React, {useContext} from 'react';
import {Form, Formik, FormikValues} from "formik";
import {Button, Tooltip, Typography} from "@mui/material";
import * as yup from "yup";

import FormikMUISimpleInput from "../../components/Formik/MUISimpleInput/FormikMUISimpleInput";
import {ENDPOINTS, industries} from "../../constants";
import {FormElementSelectField} from "../Dashboard/MemberManagement/AddOrModifyMember/AddMember/AddMember";
import {useMutation} from "@tanstack/react-query";
import {useAPI} from "../../utils/hooks/useAPI";
import {useNavigate} from "react-router-dom";
import {mapBackendErrorToUserFriendlyMessage, replaceEmptyStringsWithNullsInJSON} from "../../utils/helpers";
import {AxiosError, AxiosResponse} from "axios";
import {notifyError, notifySuccess} from "../../components/utils/ToastNotifications/Notifier";
import {useAuth0} from "@auth0/auth0-react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {SSMemberContext} from "../../store/context/self-serve-member-user";


export const domainRegex = /^[\w-]+([.]{1}[\w-]{0,61})*\.[\w-]{2,}$/;
export const onboardingValidation = yup.object({
	domain: yup.string()
		.required("This field is required.")
		.matches(domainRegex,
			"You must enter a valid domain."),
	name: yup.string()
		.required('This field is required'),
	company_name: yup.string()
		.notRequired(),
	industry: yup.string()
		.notRequired(),
})

type MemberResponseType = {
	member_id: number
	domain: string
	company_name: string | null
	domain_id: number
	industry: string | null
	name: string
	surname: string,
	primary_contact_email_id: number,
}

const Onboarding = ({isOnboarded, setIsOnboarded, setUserName}: {
	isOnboarded: boolean;
	setIsOnboarded: React.Dispatch<React.SetStateAction<boolean | undefined>>;
	setUserName: React.Dispatch<React.SetStateAction<string | undefined>>,
}) => {
	const [error, setError] = React.useState<string>('')
	const {user} = useAuth0()
	const api = useAPI();
	const navigate = useNavigate()
	const userProvider = user?.sub?.split('|')[0]
	const {memberIds, setMemberIds} = useContext(SSMemberContext)

	const {mutate, isLoading} = useMutation({
		mutationFn: handleCreatingMember,
		onSuccess: ({data: {member_id, domain_id, primary_contact_email_id}}, values) => {
			notifySuccess('Your domain have been successfully added.');
			memberIds?.push(member_id)
			setMemberIds && setMemberIds([...memberIds!])
			if (values.name !== user?.name) {
				api.patch(`/members/${member_id}/users/${primary_contact_email_id}/account`, {name: values.name})
					.then((response) => {
						if (response.status === 200) {
							setUserName(values.name)
						}
					})
			}
			api.get(`/members/${member_id}/users/onboard`)
				.then(() => {
					setIsOnboarded(true);
					navigate(`/members/${member_id}/analyze/${domain_id}`);
				})
				.catch((error) => notifyError((error as AxiosError).response?.data?.detail))
		},
		onError: (error) => setError(mapBackendErrorToUserFriendlyMessage((error as AxiosError).response?.data?.detail))
	})

	function handleCreatingMember(values: FormikValues): Promise<AxiosResponse<MemberResponseType>> {
		if (!values.company_name) {
			delete values.company_name
		}
		return api.post(ENDPOINTS.MEMBERS.create_member_public_api, replaceEmptyStringsWithNullsInJSON(values));
	}

	return (
		<section className="mt-10 lg:w-1/4">
			<Formik initialValues={{domain: '', name: user?.name ? user.name : '', company_name: '', industry: '',
				primary_contact_email: user?.email ? user.email : ''}}
					validationSchema={onboardingValidation}
					onSubmit={(values: FormikValues) => mutate(values)}>
				{({errors, touched}) => {
					return (
						<Form className="flex flex-col items-center">
							<h4 className="font-normal text-xl quicksand">Your website’s domain name:</h4>
							{error && <p className="text-red-500">{error}</p>}
							<div className="mb-5 mt-1 w-full">
								<FormikMUISimpleInput error={!!errors.domain} label="example.com" name="domain"
													  size="small" required
								/>
							</div>
							<h4 className="text-xl font-normal quicksand">Your Details</h4>
							<div className="mb-4 mt-1 w-full relative">
								<FormikMUISimpleInput error={!!errors.name} label="Name" name="name" required
													  size="small" disabled={userProvider !== "email"}
								/>
								{userProvider !== "email" &&
									<Tooltip className="absolute mt-2 ml-2"
													 title={<Typography fontSize={"0.9rem"}>You cannot change your name due to being logged in via social account (ex: google).</Typography>}>
										<InfoOutlinedIcon className="app__ss-account-icon"/>
									</Tooltip>}
							</div>
							<div className="mb-5 w-full">
								<FormikMUISimpleInput error={!!errors.industry} label="Company name" name="company_name"
													  size="small"
								/>
							</div>
							<div className="mb-5 w-full">
								<FormElementSelectField items={industries} name="industry"
														label="Industry"
														errors={errors} touched={touched}
								/>
							</div>
							<div className="mb-16">
								<Button className="!font-bold !text-lg !px-7 !rounded-lg bg-orange mulish" type="submit"
										size="large" variant="contained"
										disabled={isLoading}>
									analyze domain
								</Button>
							</div>
						</Form>
					)
				}}
			</Formik>
		</section>
	);
};

export default Onboarding;

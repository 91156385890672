import React, {CSSProperties, useContext, useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useEffectOnceWhen} from "rooks";
import StepWizard from "react-step-wizard";

import {Modal} from "../../../components/utils/Modal/Modal";
import VIQ from "../../../components/VIQ/VIQ";
import ModalRecommendations from "../../../components/ModalRecommendations";
import ModalRecOverview from "../../../components/ModalRecommendations/ModalRecOverview";
import {RedirectContext} from "../../../store/context/redirect-context";
import {LoadingType} from "../../../components/loading/types";

type TicketGeneratorType = {
	domainScoreId: number,
	domainId: number,
	memberId: number,
	domain: string
	ticketData?: {
		btnType: string,
		scrollId: string
	}
}

const customTransitions = {
	enterRight: 'animated fadeIn',
	enterLeft: 'animated fadeIn',
	exitRight: 'animated fadeOut',
	exitLeft: 'animated fadeOut'
}


export const ReportModal: React.FC<TicketGeneratorType> = ({
															   domainScoreId,
															   ticketData,
															   domain,
															   domainId,
															   memberId
														   }: TicketGeneratorType): JSX.Element => {
	const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
	const [isClickedTechReportButton, setIsClickedTechReportButton] = React.useState<boolean>(false);

	const [fixedIssues, setFixedIssues] = React.useState<number>(0);
	const [unResolvedIssues, setUnResolvedIssues] = React.useState<number>(0);

	const {pathname} = useContext(RedirectContext);
	const {user} = useAuth0();

	const [isLoadingSpinner, setIsLoadingSpinner] = useState<boolean>(false);
	const [disableModalPadding, setDisableModalPadding] = useState<boolean>(true);
	const [modalContainerCSSProps, setModalContainerCSSProps] = useState<CSSProperties>();

	const onLoading = (isLoading: boolean, type: LoadingType) => {
		setIsLoadingSpinner(isLoading);
		if (!isLoading) {
			setDisableModalPadding(true);
			return;
		}

		if (isLoading && type == 'spinner') {
			setModalContainerCSSProps({
					width: 752,
					height: 550,
					display: "flex",
					justifyContent: "center",
					alignItems: "center"
				}
			);
		} else if (isLoading && type == 'facts') {
			setModalContainerCSSProps({width: 700, paddingTop: 0, paddingBottom: 0});
			setDisableModalPadding(false);
		}
	}

	useEffect(() => {
		ticketData && setIsModalOpened(prevState => !prevState)
		// eslint-disable-next-line
	}, [user, pathname, ticketData])

	// when page is first rendered check if someone wants to open a ticket modal
	useEffectOnceWhen(() => {
		const isModalSet = localStorage.getItem('modal');

		if (user && isModalSet === 'active') {
			setIsModalOpened(true);
			setIsClickedTechReportButton(prevState => !prevState);
			localStorage.removeItem('modal');
		}
	}, !!user)

	return (
		<>
			{
				<Modal
					onDeny={() => {
						setFixedIssues(0);
						setUnResolvedIssues(0);
						setIsModalOpened(prevState => !prevState)}
					}
					show={isModalOpened}
					hideConfirmButton={false}
					hideCloseButton={false}
					haveTextArea={true}
					confirmBtnText={"Submit"}
					size="xxl"
					useFooter={false}
					disablePadding={disableModalPadding}
					hideClearIcon={isLoadingSpinner}
					containerStyle={modalContainerCSSProps}
				>
					<StepWizard transitions={customTransitions} isLazyMount>
						<VIQ
							domainScoreId={domainScoreId}
							domainId={domainId}
							memberId={memberId}
							scrollID={ticketData ? Number(ticketData.scrollId) : 1}
							domain={domain}
							ticketData={ticketData}
							isClickedTechReportButton={isClickedTechReportButton}
							setIsClickedTechReportButton={setIsClickedTechReportButton}
							isLoadingSpinnerFunc={onLoading}
						/>
						<ModalRecommendations
							domainScoreId={domainScoreId}
							domain={domain}
							ticketData={ticketData}
							setFixedIssues={setFixedIssues}
							setUnResolvedIssues={setUnResolvedIssues}
							isLoadingSpinnerFunc={onLoading}
						/>
						<ModalRecOverview
							fixedIssues={fixedIssues}
							unResolvedIssues={unResolvedIssues}
							domain={domain}
							ticketData={ticketData}
						/>
					</StepWizard>
				</Modal>
			}
		</>
	)
}

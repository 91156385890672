import React, {useContext, useEffect, useRef, useState} from "react";
import QRCode from "qrcode.react";
import Button from '@mui/material/Button';

import {DOMAIN_SCORE_RECOMMENDATION_MAX_CHARS_VISIBLE, permissionsCatalog} from "../../../constants";
import {SuggestionsBoardType} from "./types";
import PathContext from "../../../store/context/path-context";
import {RedirectContext} from "../../../store/context/redirect-context";
import {ReportModal} from "../ReportModal/ReportModal";
import './SuggestionsBoards.scss';

// in this case we use the redirect url just to avoid repeating code, we need the host in production
const host = process.env.REACT_APP_PROD_REDIRECT_URL;

const SuggestionsBoards: React.FC<SuggestionsBoardType> =
  ({
     id,
     domain,
     domain_id,
     member_id,
     permissions,
     font,
     qrColor,
     buttonColor,
     underlineColor,
     borderLineColor,
     recommendations
   }: SuggestionsBoardType): JSX.Element => {
    const {apiUrlProps: {protocol, tenant}} = useContext(PathContext)
    const {pathname} = useContext(RedirectContext);
    const selectedSuggestion: React.Ref<HTMLButtonElement> = useRef(null);
    const [ticketData, setTicketData] = useState<{
      btnType: string,
      scrollId: string
    }>();

    useEffect(() => {
      const scrollId = localStorage.getItem('scrollId');

      //scroll down if opportunities button is selected(which stands for scrollId = 2), or suggestionsBoard buttons is selected which stands for scrollId = 3
      if (scrollId === '2' || scrollId === '3') {
        window.scrollTo(0, document.body.scrollHeight);
      }
      localStorage.removeItem('scrollId');
    }, [])

    return (
      <section className="suggestions ">
        <h4 className="suggestions__main-header">
          Action Steps: {tenant === 'topsec'
          ? 'Personalized tips based on your vulnerabilities'
          : 'Tips and Recommendations to better secure your organization'}
        </h4>
        <article className={'suggestions__sections'}>
          {
            recommendations?.map((item, index) =>
              <article style={{border: `5px solid ${borderLineColor}`}} className="suggestions__section">
                <article className="suggestions__section-wrapper">
                  <h4 className="suggestions__title suggestions__header-margin">
                    {item.title}
                  </h4>
                  <p className="suggestions__subheaders suggestions__subheaders--width"
                     style={{borderBottom: `4px solid ${underlineColor}`, width: "40%"}}
                  >
                    {item.recommendation_subtitle ? item.recommendation_subtitle : ''}
                  </p>
                  <p className="suggestions__content" style={{fontWeight: 'bold'}}>{item.recommendation_title}</p>
                  <p className="suggestions__content">
                    {item.recommendation} {item.recommendation_link && item.recommendation?.length >= DOMAIN_SCORE_RECOMMENDATION_MAX_CHARS_VISIBLE &&
                      <a href={item.recommendation_link} target={'_blank'}>more</a>
                  }
                  </p>
                </article>
                <p className="suggestions__score-points">INCREASE YOUR SCORE</p>
                <p className="suggestions__score-points suggestions__qr-or-button--center">
                  {!permissions.includes(permissionsCatalog.read_tenants)
                    ? <Button ref={selectedSuggestion}
                              id={`button_${index + 1}`}
                              data-testid={`button_${index + 1}`}
                              variant="contained"
                              className="header-rep__buttons"
                              style={{backgroundColor: buttonColor, fontFamily: font}}
                              size="large"
                              title={item.recommendation_title}
                              onClick={e => {
                                setTicketData({btnType: (e.target as HTMLElement).id, scrollId: '3'});
                              }}
                    >
                      now
                    </Button>
                    : <QRCode
                      className="suggestions__qr-code"
                      title={`${item.recommendation_title} QR`}
                      value={`${protocol}${tenant}${host}${pathname}?utm_source=em&utm_medium=qr&utm_campaign=[r${index + 1}]`}
                      size={100}
                      fgColor={qrColor}
                    />
                  }
                </p>
              </article>)
          }
        </article>
        {
          permissions.includes(permissionsCatalog.read_tenants) && (
            <p style={{
              textAlign: 'center',
              wordWrap: 'break-word'
            }}
               className="suggestions__qr-direction"
            >{`Scan the QR code or visit ${protocol}${tenant}${host}${pathname}`}</p>
          )
        }
        <ReportModal domain={domain} ticketData={ticketData} domainId={domain_id} domainScoreId={id}
                     memberId={member_id}/>
      </section>
    )
  }

export default SuggestionsBoards;

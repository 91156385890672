import React, {useContext, useEffect, useState} from "react";
import {SSMemberContextType} from "../../interfaces";
import {useAPI} from "../../utils/hooks/useAPI";
import {useAuth0} from "@auth0/auth0-react";
import PathContext from "./path-context";
import {UserPermissionContext} from "./user-permission-context";
import {ENDPOINTS, permissionsCatalog} from "../../constants";

export const SSMemberContext = React.createContext<SSMemberContextType>({
  memberIds: [],
  setMemberIds: () => {},
});

export const SSMemberUserContextProvider: React.FC = ({children}) => {
  const api = useAPI();
  const [memberIds, setSSMemberIds] = useState<Array<number>>([]);
  const {apiUrlProps: {tenant}} = useContext(PathContext);
  const {isAuthenticated} = useAuth0();
  const {userPermissions} = useContext(UserPermissionContext);
  const [areMembersLoaded, setAreMembersLoaded] = useState<boolean>(false);
  const setMemberIds = (memberIds?: Array<number>) => {
    if (memberIds) {
      setSSMemberIds(memberIds)
      return;
    }

    api.get(ENDPOINTS.MEMBERS.get_member_users_by_tenant(tenant)).then((response) => {
      setSSMemberIds(response.data);
      setAreMembersLoaded(true);
    });
  }

  useEffect(() => {
    if (isAuthenticated &&
      !userPermissions.includes(permissionsCatalog.read_dashboard) &&
      ['localhost', 'ss', 'stg'].includes(tenant) &&
      !memberIds.length &&
      !areMembersLoaded) {
      setMemberIds();
    }
  }, [isAuthenticated, userPermissions]);

    return (
        <SSMemberContext.Provider value={{
            memberIds, setMemberIds
        }}>{children}</SSMemberContext.Provider>
    )
}
import React, {useContext, useEffect, useState} from "react";
import {useAPI} from "../../../utils/hooks/useAPI";
import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import {Bar, BarChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import AnalyticsPdfReports from "../../../components/AnalyticsPdfReports/AnalyticsPdfReports";
import "./CampaignStats.scss"
import CampaignStatsCustomChartTooltip from "../../../components/chart/tooltip/CampaignStatsCustomChartTooltip";
import {notifyError} from "../../../components/utils/ToastNotifications/Notifier";
import {CampaignHistoryEntry} from "../../../interfaces";
import DashboardComponent from "../../../components/DashboardComponent/DashboardComponent";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import {Scrollbars} from "react-custom-scrollbars-2";
import {ENDPOINTS, largeScreen} from "../../../constants";
import {useNavigate} from "react-router-dom";
import {TenantPropsContext} from "../../../store/context/tenant-props-context";

export interface CampaignStatsData {
    campaign_id: number;
    sent_count: number;
    clicked: Array<EmailEventData>;
    bounced: number;
    opened: Array<EmailEventData>;
    deliveries: number;
    rejects: number;
    campaign_start: string;
}

interface EmailEventData {
    date: string;
    event_count: number;
    users: Array<string>;
}

const CampaignStats: React.FC = () => {
    const navigate = useNavigate()
    const api = useAPI();
    const isLargeScreen = useIsMobile(largeScreen);
    const [loading, setLoading] = useState<boolean>(false);
    const [campaignChanging, setCampaignChanging] = useState<boolean>(false);
    const [campaignStats, setCampaignStats] = useState<CampaignStatsData>();
    const [campaignHistory, setCampaignHistory] = useState<Array<Array<CampaignHistoryEntry>>>([]);
    const {tenantProps} = useContext(TenantPropsContext);

    useEffect(() => {
        console.log(tenantProps)
        if (tenantProps?.is_self_serve) {
            navigate('/access-denied');
            return;
        }

        if (!campaignStats) {
            initData()
        }
    }, [tenantProps, tenantProps?.is_self_serve]) // eslint-disable-line react-hooks/exhaustive-deps

    async function initData() {
        setLoading(true);
        const campaignHistoryPromise = api.get(ENDPOINTS.EMAILS.get_campaign_history)
            .then(response => {
                setCampaignHistory(response.data)
            })
            .catch(error => {
                notifyError(error.response.data.error)
            })

        const campaignStatsPromise = api.get(ENDPOINTS.EMAILS.get_campaign_stats)
            .then(response => {
                setCampaignStats(response.data)
            })
            .catch(error => {
                notifyError(error.response.data.error);
            });
        await Promise.all([campaignHistoryPromise, campaignStatsPromise]).finally(() => setLoading(false))
    }

    function handleCampaignChange(campaignEntry: CampaignHistoryEntry) {
        console.log(campaignEntry)
        setCampaignChanging(true)
        api.get(ENDPOINTS.EMAILS.get_campaign_stats_by_id(campaignEntry.campaign_id))
            .then(async response => {
                setCampaignStats(response.data);
            })
            .catch(error => {
                setCampaignStats(undefined)
                notifyError(error.response.data.error);
            })
            .finally(() => {
                setCampaignChanging(false);
            });
    }

    return (
        <DashboardComponent>
            <article className="campaign-stats">
                {
                    loading || !campaignHistory ?
                        <LoadingSpinner/>
                        :
                        <section>
                            {isLargeScreen ?
                                <Scrollbars id="containerElement"
                                            universal={true}
                                            className={"campaign-stats__pdf_reports_wrapper"} >
                                       <AnalyticsPdfReports initialCampaignId={campaignStats?.campaign_id}
                                                         campaignHistory={campaignHistory}
                                                         handleCampaignChange={handleCampaignChange}/>
                                </Scrollbars>
                                :
                                <AnalyticsPdfReports initialCampaignId={campaignStats?.campaign_id}
                                                     campaignHistory={campaignHistory}
                                                     handleCampaignChange={handleCampaignChange}/>
                            }
                            {campaignChanging ?
                                <LoadingSpinner/>
                                :
                                campaignStats &&
                                <>
                                    <div className="campaign-stats__list-wrapper">
                                        <div className="campaign-stats__list-stats">
                                            <div className="campaign-stats__stats-entry">
                                                <div className="campaign-stats__stats-title">
                                                    Sent to users
                                                </div>
                                                <div className="campaign-stats__stats-value">
                                                    {campaignStats?.sent_count}
                                                </div>
                                            </div>
                                            <div className="campaign-stats__stats-entry">
                                                <div className="campaign-stats__stats-title">
                                                    Delivered
                                                </div>
                                                <div className="campaign-stats__stats-value">
                                                    {campaignStats?.deliveries}
                                                </div>
                                            </div>
                                            <div className="campaign-stats__stats-entry">
                                                <div className="campaign-stats__stats-title">
                                                    Bounced
                                                </div>
                                                <div className="campaign-stats__stats-value">
                                                    {campaignStats?.bounced}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="campaign-stats__list-stats">
                                            <div className="campaign-stats__stats-entry">
                                                <div className="campaign-stats__stats-title">
                                                    Opened
                                                </div>
                                                <div className="campaign-stats__stats-value">
                                                    {campaignStats?.opened.reduce((previousValue, currentValue) =>
                                                        previousValue + currentValue.event_count, 0
                                                    )}
                                                </div>
                                            </div>
                                            <div className="campaign-stats__stats-entry">
                                                <div className="campaign-stats__stats-title">
                                                    Clicked
                                                </div>
                                                <div className="campaign-stats__stats-value">
                                                    {campaignStats?.clicked.reduce((previousValue, currentValue) =>
                                                        previousValue + currentValue.event_count, 0
                                                    )}
                                                </div>
                                            </div>
                                            <div className={`${isLargeScreen ? "campaign-stats__stats-entry" : "campaign-stats__stats-entry--start"}`}>
                                                <div className="campaign-stats__stats-title">
                                                    Campaign start
                                                </div>
                                                <div className="campaign-stats__stats-value" >
                                                    {campaignStats?.campaign_start}
                                                </div>
                                            </div>
                                         </div>
                                    </div>

                                    <h4 className="engagement__title-props">Email link clicks per day</h4>
                                    <p className="engagement__subtitle-props">the number of members who opened an email</p>

                                    {
                                        <div className="campaign-stats__linechart-container">
                                            <ResponsiveContainer width="100%" height={250}>
                                                <BarChart
                                                    // width={730}
                                                    // height={250}
                                                    data={campaignStats?.clicked}
                                                    margin={{
                                                        top: 20,
                                                        right: 20
                                                    }}
                                                    barSize={15}
                                                >
                                                    <CartesianGrid strokeDasharray="3 4" stroke="#818A91" vertical={false}/>
                                                    <XAxis
                                                        dataKey="date"
                                                        axisLine={false}
                                                        tick={{fill: "#555555"}}
                                                        tickLine={false}
                                                        style={{fontSize: 14, fontFamily: "Mulish", fontStyle: "italic"}}
                                                    />
                                                    <YAxis
                                                        dataKey="event_count"
                                                        stroke="#818A91"
                                                        tick={{fill: "#555555"}}
                                                        allowDecimals={false}
                                                        tickCount={4}
                                                        tickLine={false}
                                                        style={{fontSize: 14, fontFamily: "Mulish", fontStyle: "italic"}}
                                                    />
                                                    <Tooltip
                                                        isAnimationActive={false}
                                                        allowEscapeViewBox={{x: true, y: true}}
                                                        cursor={{fill: "transparent"}}
                                                        labelStyle={{display: "none"}}
                                                        content={<CampaignStatsCustomChartTooltip/>}
                                                    />
                                                    <Bar dataKey="event_count" fill="#F3AA18" radius={[10, 0, 10, 0]}/>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    }

                                    <h4 className="engagement__title-props">Email opens per day</h4>
                                    <p className="engagement__subtitle-props">number of members who clicked on the report link</p>
                                    <div className="campaign-stats__linechart-container">
                                        <ResponsiveContainer width="100%" height={250}>
                                            <LineChart
                                                // width={730}
                                                // height={250}
                                                data={campaignStats?.opened}
                                                margin={{
                                                    top: 20,
                                                    right: 20
                                                }}
                                                barSize={20}
                                            >
                                                <CartesianGrid strokeDasharray="3 4" stroke="#818A91" vertical={false}/>
                                                <XAxis
                                                    dataKey="date"
                                                    axisLine={false}
                                                    tick={{fill: "#555555"}}
                                                    tickLine={false}
                                                    style={{fontSize: 14, fontFamily: "Mulish", fontStyle: "italic"}}
                                                />
                                                <YAxis
                                                    dataKey="event_count"
                                                    stroke="#818A91"
                                                    tick={{fill: "#555555"}}
                                                    allowDecimals={false}
                                                    tickCount={4}
                                                    tickLine={false}
                                                    style={{fontSize: 14, fontFamily: "Mulish", fontStyle: "italic"}}
                                                />
                                                <Tooltip
                                                    isAnimationActive={false}
                                                    allowEscapeViewBox={{x: true, y: true}}
                                                    cursor={{fill: "transparent"}}
                                                    labelStyle={{display: "none"}}
                                                    content={<CampaignStatsCustomChartTooltip/>}
                                                />
                                                <Line
                                                    type="linear"
                                                    dataKey="event_count"
                                                    stroke="#2daae2"
                                                    strokeWidth={3}
                                                    activeDot={{r: 8}}
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </>
                            }
                        </section>
                }
            </article>
        </DashboardComponent>
    )
}

export default CampaignStats;

import React from "react";

import "./DashboardComponent.scss"


const DashboardComponent: React.FC = ({children}) => (
    <section className="dashboard-wrapper">
        {children}
    </section>
)


export default DashboardComponent;

import {EdisonMarksLogo} from "../../../assets";
import './Footer.scss';

const Footer = ({footerText}: { footerText: string | undefined }) => {
    return (
        <section className="footer-layout">
            <a className="footer-layout__edm_link" href="https://edisonmarks.com/">
                <img className="footer-layout__image" src={EdisonMarksLogo} alt="test"/>
            </a>
            <p className="footer-layout__reach_out">{footerText}</p>
        </section>
    )
}

export default Footer;
export const ENDPOINTS = {
  TENANTS: {
    get_all_tenants: 'tenants/',
    get_current_tenant: `/tenants/current-tenant/info`,
    get_current_tenant_user: `/tenants/current-tenant-user/info`,
    get_members_names_by_tenant: (tenant: string) => `tenants/${tenant}/members/`,
    create_tenant: '/tenants/',
    create_tenant_admin: '/tenants/admin/',
    edit_tenant: (tenantId: string | undefined) => `tenants/${tenantId}/`,
    delete_tenants: (tenants: string) => `tenants/delete/?tenants=${tenants}`,
    create_tenant_user: (tenantId: string | undefined) => `tenants/${tenantId}/tenant-users/`,
    edit_tenant_user: (tenantId: string | undefined, tenantUserId: string | undefined) => `tenants/${tenantId}/tenant-users/${tenantUserId}/`,
    get_tenants_members: (tenants: string) => `tenants/members/?tenants=${tenants}`,
    get_tenant_email_export: (tenants: string) => `tenants/members/email_export/?tenants=${tenants}`,
    get_tenant_members_changed_scores: (tenants: string) => `tenants/members/changed-scores/?tenants=${tenants}`,
    get_tenant_members_data: '/tenants/members/score-to-pdf/',
    get_tenant_changed_members_data: 'tenants/members/changed/score-to-pdf/',
    invite_tenant_user: (tenantId: string | undefined) => `tenants/${tenantId}/tenant-users/invite-user/`,
    validate_invitation: 'tenant-users/validate-invitation',
    tenant_user_create: 'tenant-users/create/'
  },
  MEMBERS: {
    get_members_by_search_text: (searchText: string) => `/members/?search_text=${searchText}`,
    create_member_public_api: '/members/create/',
    get_or_create_member: '/members/',
    get_or_update_member: (memberId: string | number | undefined) => `/members/${memberId}/`,
    activate_member: 'activate-member/',
    edit_member_active: (memberId: string | undefined) => `members/${memberId}/active/`,
    edit_member_relationship: (memberId: string | undefined) => `members/${memberId}/relationship/`,
    get_member_users: `member-users/members/`,
    get_member_users_by_tenant: (tenant: string) => `member-users/members/?tenant=${tenant}`,
    get_member_user: (memberId: string | undefined) => `/members/${memberId}/users/`,
    create_member_user: (memberId: number) => `member/${memberId}/users/`,
    update_or_delete_member_user: (memberId: number, memberUserId: number) => `member/${memberId}/users/${memberUserId}/`,
    update_auth0_user_name: (memberId: string | undefined, memberUserId: number | undefined) => `/members/${memberId}/users/${memberUserId}/account`,
    post_csv_file: '/members/csv/',
    get_members_stats: 'members/stats/'
  },
  GOOGLE_ANALYTICS: {
    get_overview_charts_data: 'google-analytics/charts/overview/',
    get_details_charts_data: 'google-analytics/charts/details/',
    get_tables_data: 'google-analytics/tables/',get_or_create_blacklisted_ip: `google-analytics/ip-blacklist`,
    update_or_delete_blacklisted_ip: (ipId: number | undefined) => `google-analytics/ip-blacklist/${ipId}`
  },
  EMAILS: {
    get_campaign_history: 'campaign-history/',
    get_campaign_stats: 'email-messaging/campaigns/stats/',
    get_campaign_stats_by_id: (campaignId: number) => `email-messaging/campaigns/stats/?campaign=${campaignId}`,
    get_admin_campaign_stats: (tenant: string) => `email-messaging/campaigns/admin-stats/?tenant=${tenant}`,
    get_email_templates: (tenant: string) => `email-messaging/templates/?tenant=${tenant}`,
  },
  DOMAINS_SCORE: {
    get_domain_score_for_admin: 'em-domain-score/',
    get_domain_score_for_logged_user: 'member-domain-score/',
    get_domain_scan: (domainId: number | null) => `domain/${domainId}/scan/`,
    get_score_by_domain_id: (domainId: string) => `/domain/${domainId}/score/`,
    get_or_delete_domain: (domainId: string | number) => `/domains/${domainId}`,
    get_pdf_report_link: (domainId: number) => `domain/${domainId}/pdf-report/`,
    get_or_create_domain: (memberId: string | number) => `/members/${memberId}/domains`,
    get_all_domain_scores: (page: number | undefined, searchValue: string | undefined, riskStatus: string | undefined,
                            vulnerabilitiesFilterValue: string | undefined, dateFilterValue: string | undefined,
                            tenantFilterValue: string | undefined) => `/domain-scores?page=${page}&searchValue=${searchValue}&riskStatus=${riskStatus}&vulnerabilities=${vulnerabilitiesFilterValue}&date=${dateFilterValue}&tenant=${tenantFilterValue}`,
    rescan_domain: (domainId: number | undefined) => `domains/${domainId}/rescan/`,
    get_all_risk_statuses: 'domain-score/risk-statuses'
  },
  FEATURE_FLAGS: {
    get_all_flags: 'feature-flags',
    get_or_update_member_feature_flags: (memberId: string | number) => `/members/${memberId}/feature-flags`,
  },
  ACTIONS: {
    run_actions: '/actions',
  },
  TICKET_TRACKING: {
    get_tickets: (status: string, page: number) => `ticket-tracking/?status=${status}&page=${page}`,
    get_tickets_by_search_value: (status: string, searchValue: string, page: number) => `ticket-tracking/?status=${status}&searchValue=${searchValue}&page=${page}`,
    create_ticket: 'ticket-tracking/',
    change_ticket_status: (ticketID: number) => `ticket-tracking/${ticketID}`,
  },
  VIQ: {
    get_viq_recommendations_payload: (domainScoreId: number, step: number) => `domain-score/${domainScoreId}/viq-recommendation/?step=${step}`,
    get_recommendations_count: (domainScoreId: number) => `domain-score/${domainScoreId}/recommendations/count/`,
    trigger_recommendations_generation: (domainScoreId: number) => `domain-score/${domainScoreId}/viq-recommendation/`,
    update_viq_recommendation: (viqRecId: number | string) => `viq-recommendation/${viqRecId}/`,
  },
  ADMIN: {
    get_user_logins: (page: number | undefined, searchValue: string | undefined) => `admin/users_logins?page=${page}&searchValue=${searchValue}`
  }
}

export const ROUTES = {
  MAIN: {
    name: 'Main',
    route: '/',
  },
  ACCEPT_INVITATION_PAGE: {
        name: 'Accept Invitation',
        route: '/accept-invitation',
    },
  REPORTS: {
    name: 'Reports',
    route: '/reports/:domain_name'
  },
  MEMBER_SS: {
    name: 'Member Self Serve',
    route: "/members/*",
    subroutes: {
      ONBOARDING: {
        name: 'Onboarding',
        route: 'onboarding/'
      },
      ANALYZE: {
        name: 'Analyze',
        route: ':member_id/analyze/:domain_id/'
      },
      REPORTS: {
        name: 'Reports',
        route: ':member_id/reports/:domain_name/'
      },
      SETTINGS: {
        name: 'Settings',
        route: '/:member_id/settings/*',
        subroutes: {
          ACCOUNT: {
            name: 'Account',
            route: 'account/',
          },
          BILLING: {
            name: 'Billing',
            route: 'billing/',
          },
          ALERTS: {
            name: 'Alerts',
            route: 'alerts/',
          },
        }
      }
    }
  },
  TENANT_SS: {
    name: 'Tenant Self Serve',
    route: "/tenants/*",
    subroutes: {
      ONBOARDING: {
        name: 'Onboarding',
        route: 'onboarding/'
      },
      SETTINGS: {
        name: 'Settings',
        route: '/:tenant_id/settings/*',
        subroutes: {
          PROFILE: {
            name: 'Tenant User Account',
            route: '/:tenant_user_id/profile/',
          },
          ACCOUNT: {
            name: 'Tenant Account',
            route: 'account/',
          },
          BILLING: {
            name: 'Billing',
            route: 'billing/',
          },USERS: {
                        name: 'Users',
                        route: 'users/',
                    },
        INTERNALIPS: {
            name: 'Internal IPs',
            route: 'internal-ips/',
          }}
      }
    }
  },
  ADMIN: {
    name: 'Admin Dashboard',
    route: "/admin/*",
    subroutes: {
      ENGAGEMENT: {
        name: 'Engagement',
        route: 'engagement/',
      },
      MEMBER_MANAGEMENT: {
        name: 'Member Management',
        route: 'member-management/',
        subroutes: {
          SINGLE_MEMBER: {
            name: 'Single Member',
            route: ':member_id/',
          }
        }
      },
      LANDING_PAGE_STATS: {
        name: 'Landing Page Stats',
        route: 'landing-page-stats/',
      },
      TICKET_TRACKING: {
        name: 'Ticket Tracking',
        route: 'ticket-tracking/',
      },
      MEMBER_RESPONSE_STATS: {
        name: 'Member Response Stats',
        route: 'member-response-stats/',
      },
      CAMPAIGN_MANAGEMENT: {
        name: 'Campaign Management',
        route: 'campaign-management/',
      },
      CAMPAIGN_STATS: {
        name: 'Campaign Stats',
        route: 'campaign-stats/',
      },
      ADMIN_CAMPAIGN_STATS: {
        name: 'Admin Campaign Stats',
        route: 'admin-campaign-stats/',
      },
      FLAGS: {
        name: 'Flags',
        route: 'flags/',
      },
    }
  },
  EM_ADMIN: {
    name: 'EM Admin',
    route: '/em-admin/*',
    subroutes: {
      CLIENTS_USERS: {
        name: 'Clients & Users',
        route: '/users/*',
        subroutes: {
          USER_LOGINS: {
            name: 'User Logins',
            route: 'logins/',
          },
          TENANTS: {
            name: 'Clients (Tenants)',
            route: 'tenants/',
          },
        }
      },
      SCORES: {
        name: 'Scores',
        route: '/scores/*',
        subroutes: {
          DOMAIN_SCORES: {
            name: 'Domain Scores',
            route: 'domain-scores/',
          }
        }
      },
    }
  },
  STYLE_GUIDE: {
    name: 'Style Guide',
    route: '/style-guide/*',
    subroutes: {
      CONCEPTS: {
        name: 'Concepts',
        route: '/',
      },
      STRUCTURE: {
        name: 'Structure',
        route: 'structure/',
      },
      STYLES: {
        name: 'Styles',
        route: 'styles/',
      }
    }
  },
  ACCESS_DENIED_PAGE: {
    name: 'Access Denied Page',
    route: '/access-denied',
  },
  EXPIRED_LINK_PAGE: {
    name: 'Expired Link Page',
    route: '/expired-link'
  },
  INVALID_LINK_PAGE: {
    name: 'Invalid Link Page',
    route: '/invalid-link'
  },
  ERROR_PAGE: {
    name: 'ErrorPage',
    route: '*'
  },
}

export const permissionsCatalog = {
  read_dashboard: 'read:dashboard',
  read_domains: 'read:domains',
  create_domains: 'create:domains',
  delete_domains: 'delete:domains',
  read_members: 'read:members',
  create_members: 'create:members',
  update_members: 'update:members',
  read_tenants: 'read:tenants',
  read_users: 'read:users',
  read_ss_report: 'read:ss-report',
  create_member_users: 'create:member-users',
  create_ss_member_users: 'create:ss-member-users',
  create_tenants: 'create:tenants',
  update_tenant_users: 'update:tenant-users',
}

export const emUrl = 'https://edisonmarks.com'

export const FEATURE_FLAGS = {
  VIQ: 'VIQ',
  SCORE_EMAIL_ALERTS: 'SCORE_EMAIL_ALERTS'
}

export const endUserInitialData = {
  company_info: {
    city: '',
    company_name: '',
    contact_name: '',
    mailing_address: '',
    mailing_address_2: '',
    state: '',
    zip: 0
  },
  critical: 0,
  important: 0,
  domain: '',
  'low_risk_score': 0,
  'total_avg_score': 0,
  score_history: null,
  timestamp: '',
  risk_status: '',
  id: 0,
  domain_id: 0,
  member_id: 0,
  recommendations: [],
}

export enum REC_STATUTES {
  ALL_GOOD = 'All Good!',
  WORK_NEEDED = 'Needs Work!',
  MARKED_AS_DONE = 'Marked as done!',
  MARKED_AS_SKIPPED = 'Skipped!',
  POSTPONED = 'Postponed!'
}

export enum REC_RISKS {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}

export const REC_RECORDS = {
  dns: ['spf', 'dmarc', 'starttls'],
  ssl: ['ssl', 'tls', 'redirect']
}

export const initialBarChartData: { name: string, score: number }[] = [
  {
    name: 'Low Risk',
    score: 0,
  },
  {
    name: 'Average',
    score: 0,
  },
  {
    name: 'You',
    score: 0,
  }
];

export const accessDeniedConstants = [
  {
    className: 'not_found__oops',
    text: 'Sorry, the page you are trying to reach has restricted access.'
  }
]

export const expiredInvitationLinkConstants = [
  {
    className: 'not_found__oops',
    text: 'Sorry, the invitation link has timed out.'
  },
  {
    className: 'not_found__oops',
    text: 'Reach out to the person who invited you to get a new link.'
  }
]

export const invalidInvitationLinkConstants = [
  {
    className: 'not_found__oops',
    text: 'It seems that the invitation link you provided'
  },
  {
    className: 'not_found__oops',
    text: 'is invalid and cannot be verified.'
  }
]

export const pageNotFoundConstants = [
  {
    className: 'not_found__oops',
    text: 'Oops! Page not found.'
  },
  {
    className: 'not_found__oops',
    text: 'The page you\'re looking for doesn\'t exist.'
  }
]

export const somethingWentWrong = [
  {
    className: 'not_found__oops',
    text: 'Something went wrong, please try again later.'
  }
]

export const usaStates = [
  {
    "label": "Alabama",
    "value": "AL"
  },
  {
    "label": "Alaska",
    "value": "AK"
  },
  {
    "label": "American Samoa",
    "value": "AS"
  },
  {
    "label": "Arizona",
    "value": "AZ"
  },
  {
    "label": "Arkansas",
    "value": "AR"
  },
  {
    "label": "California",
    "value": "CA"
  },
  {
    "label": "Colorado",
    "value": "CO"
  },
  {
    "label": "Connecticut",
    "value": "CT"
  },
  {
    "label": "Delaware",
    "value": "DE"
  },
  {
    "label": "District Of Columbia",
    "value": "DC"
  },
  {
    "label": "Federated States Of Micronesia",
    "value": "FM"
  },
  {
    "label": "Florida",
    "value": "FL"
  },
  {
    "label": "Georgia",
    "value": "GA"
  },
  {
    "label": "Guam",
    "value": "GU"
  },
  {
    "label": "Hawaii",
    "value": "HI"
  },
  {
    "label": "Idaho",
    "value": "ID"
  },
  {
    "label": "Illinois",
    "value": "IL"
  },
  {
    "label": "Indiana",
    "value": "IN"
  },
  {
    "label": "Iowa",
    "value": "IA"
  },
  {
    "label": "Kansas",
    "value": "KS"
  },
  {
    "label": "Kentucky",
    "value": "KY"
  },
  {
    "label": "Louisiana",
    "value": "LA"
  },
  {
    "label": "Maine",
    "value": "ME"
  },
  {
    "label": "Marshall Islands",
    "value": "MH"
  },
  {
    "label": "Maryland",
    "value": "MD"
  },
  {
    "label": "Massachusetts",
    "value": "MA"
  },
  {
    "label": "Michigan",
    "value": "MI"
  },
  {
    "label": "Minnesota",
    "value": "MN"
  },
  {
    "label": "Mississippi",
    "value": "MS"
  },
  {
    "label": "Missouri",
    "value": "MO"
  },
  {
    "label": "Montana",
    "value": "MT"
  },
  {
    "label": "Nebraska",
    "value": "NE"
  },
  {
    "label": "Nevada",
    "value": "NV"
  },
  {
    "label": "New Hampshire",
    "value": "NH"
  },
  {
    "label": "New Jersey",
    "value": "NJ"
  },
  {
    "label": "New Mexico",
    "value": "NM"
  },
  {
    "label": "New York",
    "value": "NY"
  },
  {
    "label": "North Carolina",
    "value": "NC"
  },
  {
    "label": "North Dakota",
    "value": "ND"
  },
  {
    "label": "Northern Mariana Islands",
    "value": "MP"
  },
  {
    "label": "Ohio",
    "value": "OH"
  },
  {
    "label": "Oklahoma",
    "value": "OK"
  },
  {
    "label": "Oregon",
    "value": "OR"
  },
  {
    "label": "Palau",
    "value": "PW"
  },
  {
    "label": "Pennsylvania",
    "value": "PA"
  },
  {
    "label": "Puerto Rico",
    "value": "PR"
  },
  {
    "label": "Rhode Island",
    "value": "RI"
  },
  {
    "label": "South Carolina",
    "value": "SC"
  },
  {
    "label": "South Dakota",
    "value": "SD"
  },
  {
    "label": "Tennessee",
    "value": "TN"
  },
  {
    "label": "Texas",
    "value": "TX"
  },
  {
    "label": "Utah",
    "value": "UT"
  },
  {
    "label": "Vermont",
    "value": "VT"
  },
  {
    "label": "Virgin Islands",
    "value": "VI"
  },
  {
    "label": "Virginia",
    "value": "VA"
  },
  {
    "label": "Washington",
    "value": "WA"
  },
  {
    "label": "West Virginia",
    "value": "WV"
  },
  {
    "label": "Wisconsin",
    "value": "WI"
  },
  {
    "label": "Wyoming",
    "value": "WY"
  }
]

export const industries = [
  {
    'label': 'Other',
    'value': ""
  },
  {
    'label': 'Accounting',
    'value': 'Accounting'
  },
  {
    'label': 'Airlines/Aviation',
    'value': 'Airlines/Aviation'
  },
  {
    'label': 'Alternative Dispute Resolution',
    'value': 'Alternative Dispute Resolution'
  },
  {
    'label': 'Alternative Medicine',
    'value': 'Alternative Medicine'
  },
  {
    'label': 'Animation',
    'value': 'Animation'
  },
  {
    'label': 'Apparel & Fashion',
    'value': 'Apparel & Fashion'
  },
  {
    'label': 'Architecture & Planning',
    'value': 'Architecture & Planning'
  },
  {
    'label': 'Arts and Crafts',
    'value': 'Arts and Crafts'
  },
  {
    'label': 'Automotive',
    'value': 'Automotive'
  },
  {
    'label': 'Aviation & Aerospace',
    'value': 'Aviation & Aerospace'
  },
  {
    'label': 'Banking',
    'value': 'Banking'
  },
  {
    'label': 'Biotechnology',
    'value': 'Biotechnology'
  },
  {
    'label': 'Broadcast Media',
    'value': 'Broadcast Media'
  },
  {
    'label': 'Building Materials',
    'value': 'Building Materials'
  },
  {
    'label': 'Business Supplies and Equipment',
    'value': 'Business Supplies and Equipment'
  },
  {
    'label': 'Capital Markets',
    'value': 'Capital Markets'
  },
  {
    'label': 'Chemicals',
    'value': 'Chemicals'
  },
  {
    'label': 'Civil & Social Organization',
    'value': 'Civil & Social Organization'
  },
  {
    'label': 'Civil Engineering',
    'value': 'Civil Engineering'
  },
  {
    'label': 'Commercial Real Estate',
    'value': 'Commercial Real Estate'
  },
  {
    'label': 'Computer & Network Security',
    'value': 'Computer & Network Security'
  },
  {
    'label': 'Computer Games',
    'value': 'Computer Games'
  },
  {
    'label': 'Computer Hardware',
    'value': 'Computer Hardware'
  },
  {
    'label': 'Computer Networking',
    'value': 'Computer Networking'
  },
  {
    'label': 'Computer Software',
    'value': 'Computer Software'
  },
  {
    'label': 'Construction',
    'value': 'Construction'
  },
  {
    'label': 'Consumer Electronics',
    'value': 'Consumer Electronics'
  },
  {
    'label': 'Consumer Goods',
    'value': 'Consumer Goods'
  },
  {
    'label': 'Consumer Services',
    'value': 'Consumer Services'
  },
  {
    'label': 'Cosmetics',
    'value': 'Cosmetics'
  },
  {
    'label': 'Dairy',
    'value': 'Dairy'
  },
  {
    'label': 'Defense & Space',
    'value': 'Defense & Space'
  },
  {
    'label': 'Design',
    'value': 'Design'
  },
  {
    'label': 'E-Learning',
    'value': 'E-Learning'
  },
  {
    'label': 'Education Management',
    'value': 'Education Management'
  },
  {
    'label': 'Electrical/Electronic Manufacturing',
    'value': 'Electrical/Electronic Manufacturing'
  },
  {
    'label': 'Entertainment',
    'value': 'Entertainment'
  },
  {
    'label': 'Environmental Services',
    'value': 'Environmental Services'
  },
  {
    'label': 'Events Services',
    'value': 'Events Services'
  },
  {
    'label': 'Executive Office',
    'value': 'Executive Office'
  },
  {
    'label': 'Facilities Services',
    'value': 'Facilities Services'
  },
  {
    'label': 'Farming',
    'value': 'Farming'
  },
  {
    'label': 'Financial Services',
    'value': 'Financial Services'
  },
  {
    'label': 'Fine Art',
    'value': 'Fine Art'
  },
  {
    'label': 'Fishery',
    'value': 'Fishery'
  },
  {
    'label': 'Food & Beverages',
    'value': 'Food & Beverages'
  },
  {
    'label': 'Food Production',
    'value': 'Food Production'
  },
  {
    'label': 'Fund-Raising',
    'value': 'Fund-Raising'
  },
  {
    'label': 'Furniture',
    'value': 'Furniture'
  },
  {
    'label': 'Gambling & Casinos',
    'value': 'Gambling & Casinos'
  },
  {
    'label': 'Glass, Ceramics & Concrete',
    'value': 'Glass, Ceramics & Concrete'
  },
  {
    'label': 'Government Administration',
    'value': 'Government Administration'
  },
  {
    'label': 'Government Relations',
    'value': 'Government Relations'
  },
  {
    'label': 'Graphic Design',
    'value': 'Graphic Design'
  },
  {
    'label': 'Health, Wellness and Fitness',
    'value': 'Health, Wellness and Fitness'
  },
  {
    'label': 'Higher Education',
    'value': 'Higher Education'
  },
  {
    'label': 'Hospital & Health Care',
    'value': 'Hospital & Health Care'
  },
  {
    'label': 'Hospitality',
    'value': 'Hospitality'
  },
  {
    'label': 'Human Resources',
    'value': 'Human Resources'
  },
  {
    'label': 'Import and Export',
    'value': 'Import and Export'
  },
  {
    'label': 'Individual & Family Services',
    'value': 'Individual & Family Services'
  },
  {
    'label': 'Industrial Automation',
    'value': 'Industrial Automation'
  },
  {
    'label': 'Information Services',
    'value': 'Information Services'
  },
  {
    'label': 'Information Technology and Services',
    'value': 'Information Technology and Services'
  },
  {
    'label': 'Insurance',
    'value': 'Insurance'
  },
  {
    'label': 'International Affairs',
    'value': 'International Affairs'
  },
  {
    'label': 'International Trade and Development',
    'value': 'International Trade and Development'
  },
  {
    'label': 'Internet',
    'value': 'Internet'
  },
  {
    'label': 'Investment Banking',
    'value': 'Investment Banking'
  },
  {
    'label': 'Investment Management',
    'value': 'Investment Management'
  },
  {
    'label': 'Judiciary',
    'value': 'Judiciary'
  },
  {
    'label': 'Law Enforcement',
    'value': 'Law Enforcement'
  },
  {
    'label': 'Law Practice',
    'value': 'Law Practice'
  },
  {
    'label': 'Legal Services',
    'value': 'Legal Services'
  },
  {
    'label': 'Legislative Office',
    'value': 'Legislative Office'
  },
  {
    'label': 'Leisure, Travel & Tourism',
    'value': 'Leisure, Travel & Tourism'
  },
  {
    'label': 'Libraries',
    'value': 'Libraries'
  },
  {
    'label': 'Logistics and Supply Chain',
    'value': 'Logistics and Supply Chain'
  },
  {
    'label': 'Luxury Goods & Jewelry',
    'value': 'Luxury Goods & Jewelry'
  },
  {
    'label': 'Machinery',
    'value': 'Machinery'
  },
  {
    'label': 'Management Consulting',
    'value': 'Management Consulting'
  },
  {
    'label': 'Maritime',
    'value': 'Maritime'
  },
  {
    'label': 'Market Research',
    'value': 'Market Research'
  },
  {
    'label': 'Marketing and Advertising',
    'value': 'Marketing and Advertising'
  },
  {
    'label': 'Mechanical or Industrial Engineering',
    'value': 'Mechanical or Industrial Engineering'
  },
  {
    'label': 'Media Production',
    'value': 'Media Production'
  },
  {
    'label': 'Medical Devices',
    'value': 'Medical Devices'
  },
  {
    'label': 'Medical Practice',
    'value': 'Medical Practice'
  },
  {
    'label': 'Mental Health Care',
    'value': 'Mental Health Care'
  },
  {
    'label': 'Military',
    'value': 'Military'
  },
  {
    'label': 'Mining & Metals',
    'value': 'Mining & Metals'
  },
  {
    'label': 'Motion Pictures and Film',
    'value': 'Motion Pictures and Film'
  },
  {
    'label': 'Museums and Institutions',
    'value': 'Museums and Institutions'
  },
  {
    'label': 'Music',
    'value': 'Music'
  },
  {
    'label': 'Nanotechnology',
    'value': 'Nanotechnology'
  },
  {
    'label': 'Newspapers',
    'value': 'Newspapers'
  },
  {
    'label': 'Nonprofit Organization Management',
    'value': 'Nonprofit Organization Management'
  },
  {
    'label': 'Oil & Energy',
    'value': 'Oil & Energy'
  },
  {
    'label': 'Online Media',
    'value': 'Online Media'
  },
  {
    'label': 'Outsourcing/Offshoring',
    'value': 'Outsourcing/Offshoring'
  },
  {
    'label': 'Package/Freight Delivery',
    'value': 'Package/Freight Delivery'
  },
  {
    'label': 'Packaging and Containers',
    'value': 'Packaging and Containers'
  },
  {
    'label': 'Paper & Forest Products',
    'value': 'Paper & Forest Products'
  },
  {
    'label': 'Performing Arts',
    'value': 'Performing Arts'
  },
  {
    'label': 'Pharmaceuticals',
    'value': 'Pharmaceuticals'
  },
  {
    'label': 'Philanthropy',
    'value': 'Philanthropy'
  },
  {
    'label': 'Photography',
    'value': 'Photography'
  },
  {
    'label': 'Plastics',
    'value': 'Plastics'
  },
  {
    'label': 'Political Organization',
    'value': 'Political Organization'
  },
  {
    'label': 'Primary/Secondary Education',
    'value': 'Primary/Secondary Education'
  },
  {
    'label': 'Printing',
    'value': 'Printing'
  },
  {
    'label': 'Professional Training & Coaching',
    'value': 'Professional Training & Coaching'
  },
  {
    'label': 'Program Development',
    'value': 'Program Development'
  },
  {
    'label': 'Public Policy',
    'value': 'Public Policy'
  },
  {
    'label': 'Public Relations and Communications',
    'value': 'Public Relations and Communications'
  },
  {
    'label': 'Public Safety',
    'value': 'Public Safety'
  },
  {
    'label': 'Publishing',
    'value': 'Publishing'
  },
  {
    'label': 'Railroad Manufacture',
    'value': 'Railroad Manufacture'
  },
  {
    'label': 'Ranching',
    'value': 'Ranching'
  },
  {
    'label': 'Real Estate',
    'value': 'Real Estate'
  },
  {
    'label': 'Recreational Facilities and Services',
    'value': 'Recreational Facilities and Services'
  },
  {
    'label': 'Religious Institutions',
    'value': 'Religious Institutions'
  },
  {
    'label': 'Renewables & Environment',
    'value': 'Renewables & Environment'
  },
  {
    'label': 'Research',
    'value': 'Research'
  },
  {
    'label': 'Restaurants',
    'value': 'Restaurants'
  },
  {
    'label': 'Retail',
    'value': 'Retail'
  },
  {
    'label': 'Security and Investigations',
    'value': 'Security and Investigations'
  },
  {
    'label': 'Semiconductors',
    'value': 'Semiconductors'
  },
  {
    'label': 'Shipbuilding',
    'value': 'Shipbuilding'
  },
  {
    'label': 'Sporting Goods',
    'value': 'Sporting Goods'
  },
  {
    'label': 'Sports',
    'value': 'Sports'
  },
  {
    'label': 'Staffing and Recruiting',
    'value': 'Staffing and Recruiting'
  },
  {
    'label': 'Supermarkets',
    'value': 'Supermarkets'
  },
  {
    'label': 'Telecommunications',
    'value': 'Telecommunications'
  },
  {
    'label': 'Textiles',
    'value': 'Textiles'
  },
  {
    'label': 'Think Tanks',
    'value': 'Think Tanks'
  },
  {
    'label': 'Tobacco',
    'value': 'Tobacco'
  },
  {
    'label': 'Translation and Localization',
    'value': 'Translation and Localization'
  },
  {
    'label': 'Transportation/Trucking/Railroad',
    'value': 'Transportation/Trucking/Railroad'
  },
  {
    'label': 'Utilities',
    'value': 'Utilities'
  },
  {
    'label': 'Venture Capital & Private Equity',
    'value': 'Venture Capital & Private Equity'
  },
  {
    'label': 'Veterinary',
    'value': 'Veterinary'
  },
  {
    'label': 'Warehousing',
    'value': 'Warehousing'
  },
  {
    'label': 'Wholesale',
    'value': 'Wholesale'
  },
  {
    'label': 'Wine and Spirits',
    'value': 'Wine and Spirits'
  },
  {
    'label': 'Wireless',
    'value': 'Wireless'
  },
  {
    'label': 'Writing and Editing',
    'value': 'Writing and Editing'
  },
]

export const employeesCounts = [
  {
    'label': 'N/A',
    'value': ""
  },
  {
    'label': '2-10',
    'value': '2-10'
  },
  {
    'label': '11-50',
    'value': '11-50'
  },
  {
    'label': '51-200',
    'value': '51-200'
  },
  {
    'label': '201-500',
    'value': '201-500'
  },
  {
    'label': '501-1 000',
    'value': '501-1000'
  },
  {
    'label': '1 001-5 000',
    'value': '1001-5000'
  },
  {
    'label': '5 001-10 000',
    'value': '5001-10000'
  },
  {
    'label': 'Over 10 000',
    'value': 'Over 10000'
  },
]

export const revenues = [
  {
    'label': 'N/А',
    'value': ""
  },
  {
    'label': 'Under $100K',
    'value': 'Under 100K'
  },
  {
    'label': '$100K - $1M',
    'value': '100K-1M'
  },
  {
    'label': '$1M - $5M',
    'value': '1M-5M'
  },
  {
    'label': '$5M - $20M',
    'value': '5M-20M'
  },
  {
    'label': '$20M - $50M',
    'value': '20M-50M'
  },
  {
    'label': '$50M - $100M',
    'value': '50M-100M'
  },
  {
    'label': '$100M - $500M',
    'value': '100M-500M'
  },
  {
    'label': '$500M - $1B',
    'value': '500M-1B'
  },
  {
    'label': 'Over $1B',
    'value': 'Over 1B'
  },
]

export const countries = [
  {
    'label': 'Afghanistan',
    'value': 'Afghanistan'
  },
  {
    'label': 'Albania',
    'value': 'Albania'
  },
  {
    'label': 'Algeria',
    'value': 'Algeria'
  },
  {
    'label': 'Andorra',
    'value': 'Andorra'
  },
  {
    'label': 'Angola',
    'value': 'Angola'
  },
  {
    'label': 'Antigua & Deps',
    'value': 'Antigua & Deps'
  },
  {
    'label': 'Argentina',
    'value': 'Argentina'
  },
  {
    'label': 'Armenia',
    'value': 'Armenia'
  },
  {
    'label': 'Australia',
    'value': 'Australia'
  },
  {
    'label': 'Austria',
    'value': 'Austria'
  },
  {
    'label': 'Azerbaijan',
    'value': 'Azerbaijan'
  },
  {
    'label': 'Bahamas',
    'value': 'Bahamas'
  },
  {
    'label': 'Bahrain',
    'value': 'Bahrain'
  },
  {
    'label': 'Bangladesh',
    'value': 'Bangladesh'
  },
  {
    'label': 'Barbados',
    'value': 'Barbados'
  },
  {
    'label': 'Belarus',
    'value': 'Belarus'
  },
  {
    'label': 'Belgium',
    'value': 'Belgium'
  },
  {
    'label': 'Belize',
    'value': 'Belize'
  },
  {
    'label': 'Benin',
    'value': 'Benin'
  },
  {
    'label': 'Bhutan',
    'value': 'Bhutan'
  },
  {
    'label': 'Bolivia',
    'value': 'Bolivia'
  },
  {
    'label': 'Bosnia Herzegovina',
    'value': 'Bosnia Herzegovina'
  },
  {
    'label': 'Botswana',
    'value': 'Botswana'
  },
  {
    'label': 'Brazil',
    'value': 'Brazil'
  },
  {
    'label': 'Brunei',
    'value': 'Brunei'
  },
  {
    'label': 'Bulgaria',
    'value': 'Bulgaria'
  },
  {
    'label': 'Burkina',
    'value': 'Burkina'
  },
  {
    'label': 'Burundi',
    'value': 'Burundi'
  },
  {
    'label': 'Cambodia',
    'value': 'Cambodia'
  },
  {
    'label': 'Cameroon',
    'value': 'Cameroon'
  },
  {
    'label': 'Canada',
    'value': 'Canada'
  },
  {
    'label': 'Cape Verde',
    'value': 'Cape Verde'
  },
  {
    'label': 'Central African Rep',
    'value': 'Central African Rep'
  },
  {
    'label': 'Chad',
    'value': 'Chad'
  },
  {
    'label': 'Chile',
    'value': 'Chile'
  },
  {
    'label': 'China',
    'value': 'China'
  },
  {
    'label': 'Colombia',
    'value': 'Colombia'
  },
  {
    'label': 'Comoros',
    'value': 'Comoros'
  },
  {
    'label': 'Congo',
    'value': 'Congo'
  },
  {
    'label': 'Congo (Democratic Rep)',
    'value': 'Congo (Democratic Rep)'
  },
  {
    'label': 'Costa Rica',
    'value': 'Costa Rica'
  },
  {
    'label': 'Croatia',
    'value': 'Croatia'
  },
  {
    'label': 'Cuba',
    'value': 'Cuba'
  },
  {
    'label': 'Cyprus',
    'value': 'Cyprus'
  },
  {
    'label': 'Czech Republic',
    'value': 'Czech Republic'
  },
  {
    'label': 'Denmark',
    'value': 'Denmark'
  },
  {
    'label': 'Djibouti',
    'value': 'Djibouti'
  },
  {
    'label': 'Dominica',
    'value': 'Dominica'
  },
  {
    'label': 'Dominican Republic',
    'value': 'Dominican Republic'
  },
  {
    'label': 'East Timor',
    'value': 'East Timor'
  },
  {
    'label': 'Ecuador',
    'value': 'Ecuador'
  },
  {
    'label': 'Egypt',
    'value': 'Egypt'
  },
  {
    'label': 'El Salvador',
    'value': 'El Salvador'
  },
  {
    'label': 'Equatorial Guinea',
    'value': 'Equatorial Guinea'
  },
  {
    'label': 'Eritrea',
    'value': 'Eritrea'
  },
  {
    'label': 'Estonia',
    'value': 'Estonia'
  },
  {
    'label': 'Ethiopia',
    'value': 'Ethiopia'
  },
  {
    'label': 'Fiji',
    'value': 'Fiji'
  },
  {
    'label': 'Finland',
    'value': 'Finland'
  },
  {
    'label': 'France',
    'value': 'France'
  },
  {
    'label': 'Gabon',
    'value': 'Gabon'
  },
  {
    'label': 'Gambia',
    'value': 'Gambia'
  },
  {
    'label': 'Georgia',
    'value': 'Georgia'
  },
  {
    'label': 'Germany',
    'value': 'Germany'
  },
  {
    'label': 'Ghana',
    'value': 'Ghana'
  },
  {
    'label': 'Greece',
    'value': 'Greece'
  },
  {
    'label': 'Grenada',
    'value': 'Grenada'
  },
  {
    'label': 'Guatemala',
    'value': 'Guatemala'
  },
  {
    'label': 'Guinea',
    'value': 'Guinea'
  },
  {
    'label': 'Guinea-Bissau',
    'value': 'Guinea-Bissau'
  },
  {
    'label': 'Guyana',
    'value': 'Guyana'
  },
  {
    'label': 'Haiti',
    'value': 'Haiti'
  },
  {
    'label': 'Honduras',
    'value': 'Honduras'
  },
  {
    'label': 'Hungary',
    'value': 'Hungary'
  },
  {
    'label': 'Iceland',
    'value': 'Iceland'
  },
  {
    'label': 'India',
    'value': 'India'
  },
  {
    'label': 'Indonesia',
    'value': 'Indonesia'
  },
  {
    'label': 'Iran',
    'value': 'Iran'
  },
  {
    'label': 'Iraq',
    'value': 'Iraq'
  },
  {
    'label': 'Ireland',
    'value': 'Ireland'
  },
  {
    'label': 'Israel',
    'value': 'Israel'
  },
  {
    'label': 'Italy',
    'value': 'Italy'
  },
  {
    'label': 'Ivory Coast',
    'value': 'Ivory Coast'
  },
  {
    'label': 'Jamaica',
    'value': 'Jamaica'
  },
  {
    'label': 'Japan',
    'value': 'Japan'
  },
  {
    'label': 'Jordan',
    'value': 'Jordan'
  },
  {
    'label': 'Kazakhstan',
    'value': 'Kazakhstan'
  },
  {
    'label': 'Kenya',
    'value': 'Kenya'
  },
  {
    'label': 'Kiribati',
    'value': 'Kiribati'
  },
  {
    'label': 'Korea North',
    'value': 'Korea North'
  },
  {
    'label': 'Korea South',
    'value': 'Korea South'
  },
  {
    'label': 'Kosovo',
    'value': 'Kosovo'
  },
  {
    'label': 'Kuwait',
    'value': 'Kuwait'
  },
  {
    'label': 'Kyrgyzstan',
    'value': 'Kyrgyzstan'
  },
  {
    'label': 'Laos',
    'value': 'Laos'
  },
  {
    'label': 'Latvia',
    'value': 'Latvia'
  },
  {
    'label': 'Lebanon',
    'value': 'Lebanon'
  },
  {
    'label': 'Lesotho',
    'value': 'Lesotho'
  },
  {
    'label': 'Liberia',
    'value': 'Liberia'
  },
  {
    'label': 'Libya',
    'value': 'Libya'
  },
  {
    'label': 'Liechtenstein',
    'value': 'Liechtenstein'
  },
  {
    'label': 'Lithuania',
    'value': 'Lithuania'
  },
  {
    'label': 'Luxembourg',
    'value': 'Luxembourg'
  },
  {
    'label': 'Macedonia',
    'value': 'Macedonia'
  },
  {
    'label': 'Madagascar',
    'value': 'Madagascar'
  },
  {
    'label': 'Malawi',
    'value': 'Malawi'
  },
  {
    'label': 'Malaysia',
    'value': 'Malaysia'
  },
  {
    'label': 'Maldives',
    'value': 'Maldives'
  },
  {
    'label': 'Mali',
    'value': 'Mali'
  },
  {
    'label': 'Malta',
    'value': 'Malta'
  },
  {
    'label': 'Marshall Islands',
    'value': 'Marshall Islands'
  },
  {
    'label': 'Mauritania',
    'value': 'Mauritania'
  },
  {
    'label': 'Mauritius',
    'value': 'Mauritius'
  },
  {
    'label': 'Mexico',
    'value': 'Mexico'
  },
  {
    'label': 'Micronesia',
    'value': 'Micronesia'
  },
  {
    'label': 'Moldova',
    'value': 'Moldova'
  },
  {
    'label': 'Monaco',
    'value': 'Monaco'
  },
  {
    'label': 'Mongolia',
    'value': 'Mongolia'
  },
  {
    'label': 'Montenegro',
    'value': 'Montenegro'
  },
  {
    'label': 'Morocco',
    'value': 'Morocco'
  },
  {
    'label': 'Mozambique',
    'value': 'Mozambique'
  },
  {
    'label': 'Myanmar',
    'value': 'Myanmar'
  },
  {
    'label': 'Namibia',
    'value': 'Namibia'
  },
  {
    'label': 'Nauru',
    'value': 'Nauru'
  },
  {
    'label': 'Nepal',
    'value': 'Nepal'
  },
  {
    'label': 'Netherlands',
    'value': 'Netherlands'
  },
  {
    'label': 'New Zealand',
    'value': 'New Zealand'
  },
  {
    'label': 'Nicaragua',
    'value': 'Nicaragua'
  },
  {
    'label': 'Niger',
    'value': 'Niger'
  },
  {
    'label': 'Nigeria',
    'value': 'Nigeria'
  },
  {
    'label': 'Norway',
    'value': 'Norway'
  },
  {
    'label': 'Oman',
    'value': 'Oman'
  },
  {
    'label': 'Pakistan',
    'value': 'Pakistan'
  },
  {
    'label': 'Palau',
    'value': 'Palau'
  },
  {
    'label': 'Panama',
    'value': 'Panama'
  },
  {
    'label': 'Papua New Guinea',
    'value': 'Papua New Guinea'
  },
  {
    'label': 'Paraguay',
    'value': 'Paraguay'
  },
  {
    'label': 'Peru',
    'value': 'Peru'
  },
  {
    'label': 'Philippines',
    'value': 'Philippines'
  },
  {
    'label': 'Poland',
    'value': 'Poland'
  },
  {
    'label': 'Portugal',
    'value': 'Portugal'
  },
  {
    'label': 'Qatar',
    'value': 'Qatar'
  },
  {
    'label': 'Romania',
    'value': 'Romania'
  },
  {
    'label': 'Russian Federation',
    'value': 'Russian Federation'
  },
  {
    'label': 'Rwanda',
    'value': 'Rwanda'
  },
  {
    'label': 'St Kitts & Nevis',
    'value': 'St Kitts & Nevis'
  },
  {
    'label': 'St Lucia',
    'value': 'St Lucia'
  },
  {
    'label': 'Saint Vincent & the Grenadines',
    'value': 'Saint Vincent & the Grenadines'
  },
  {
    'label': 'Samoa',
    'value': 'Samoa'
  },
  {
    'label': 'San Marino',
    'value': 'San Marino'
  },
  {
    'label': 'Sao Tome & Principe',
    'value': 'Sao Tome & Principe'
  },
  {
    'label': 'Saudi Arabia',
    'value': 'Saudi Arabia'
  },
  {
    'label': 'Senegal',
    'value': 'Senegal'
  },
  {
    'label': 'Serbia',
    'value': 'Serbia'
  },
  {
    'label': 'Seychelles',
    'value': 'Seychelles'
  },
  {
    'label': 'Sierra Leone',
    'value': 'Sierra Leone'
  },
  {
    'label': 'Singapore',
    'value': 'Singapore'
  },
  {
    'label': 'Slovakia',
    'value': 'Slovakia'
  },
  {
    'label': 'Slovenia',
    'value': 'Slovenia'
  },
  {
    'label': 'Solomon Islands',
    'value': 'Solomon Islands'
  },
  {
    'label': 'Somalia',
    'value': 'Somalia'
  },
  {
    'label': 'South Africa',
    'value': 'South Africa'
  },
  {
    'label': 'South Sudan',
    'value': 'South Sudan'
  },
  {
    'label': 'Spain',
    'value': 'Spain'
  },
  {
    'label': 'Sri Lanka',
    'value': 'Sri Lanka'
  },
  {
    'label': 'Sudan',
    'value': 'Sudan'
  },
  {
    'label': 'Suriname',
    'value': 'Suriname'
  },
  {
    'label': 'Swaziland',
    'value': 'Swaziland'
  },
  {
    'label': 'Sweden',
    'value': 'Sweden'
  },
  {
    'label': 'Switzerland',
    'value': 'Switzerland'
  },
  {
    'label': 'Syria',
    'value': 'Syria'
  },
  {
    'label': 'Taiwan',
    'value': 'Taiwan'
  },
  {
    'label': 'Tajikistan',
    'value': 'Tajikistan'
  },
  {
    'label': 'Tanzania',
    'value': 'Tanzania'
  },
  {
    'label': 'Thailand',
    'value': 'Thailand'
  },
  {
    'label': 'Togo',
    'value': 'Togo'
  },
  {
    'label': 'Tonga',
    'value': 'Tonga'
  },
  {
    'label': 'Trinidad & Tobago',
    'value': 'Trinidad & Tobago'
  },
  {
    'label': 'Tunisia',
    'value': 'Tunisia'
  },
  {
    'label': 'Turkey',
    'value': 'Turkey'
  },
  {
    'label': 'Turkmenistan',
    'value': 'Turkmenistan'
  },
  {
    'label': 'Tuvalu',
    'value': 'Tuvalu'
  },
  {
    'label': 'Uganda',
    'value': 'Uganda'
  },
  {
    'label': 'Ukraine',
    'value': 'Ukraine'
  },
  {
    'label': 'United Arab Emirates',
    'value': 'United Arab Emirates'
  },
  {
    'label': 'United Kingdom',
    'value': 'United Kingdom'
  },
  {
    'label': 'United States',
    'value': 'United States'
  },
  {
    'label': 'Uruguay',
    'value': 'Uruguay'
  },
  {
    'label': 'Uzbekistan',
    'value': 'Uzbekistan'
  },
  {
    'label': 'Vanuatu',
    'value': 'Vanuatu'
  },
  {
    'label': 'Vatican City',
    'value': 'Vatican City'
  },
  {
    'label': 'Venezuela',
    'value': 'Venezuela'
  },
  {
    'label': 'Vietnam',
    'value': 'Vietnam'
  },
  {
    'label': 'Yemen',
    'value': 'Yemen'
  },
  {
    'label': 'Zambia',
    'value': 'Zambia'
  },
  {
    'label': 'Zimbabwe',
    'value': 'Zimbabwe'
  },
]

export const businessRelationships = [
  {
    'label': 'Prospect',
    'value': 'Prospect'
  },
  {
    'label': 'Customer',
    'value': 'Customer'
  },
]

export const auth0UserMock = {
  email: "johndoe@me.com",
  email_verified: true,
  sub: "google-oauth2|12345678901234",
};

export const howRUDoing = {
  great: 'Great',
  good: 'Good',
  atRisk: 'At Risk'
}

export const initialNavButtonsContext = [
  {
    name: 'Engagement',
    isClicked: false
  },
  {
    name: 'Ticket Tracking',
    isClicked: false
  },
  {
    name: 'Campaign Management',
    isClicked: false
  },
  {
    name: 'Campaign Stats',
    isClicked: false
  },
  {
    name: 'Admin Campaign Stats',
    isClicked: false
  },
  {
    name: 'Landing Page Stats',
    isClicked: false
  },
  {
    name: 'Member Response Stats',
    isClicked: false
  },
  {
    name: 'Flags',
    isClicked: false
  },
  {
    name: 'Member Management',
    isClicked: false
  }]

export const pathNamesDashboard = new Map<string, string>([
  ["member-management", "Member Management"],
  ["engagement", "Engagement"],
  ["ticket-tracking", "Ticket Tracking"],
  ["landing-page-stats", "Landing Page Stats"],
  ["member-response-stats", "Member Response Stats"],
  ["campaign-management", "Campaign Management"],
  ["campaign-stats", "Campaign Stats"],
  ["admin-campaign-stats", "Admin Campaign Stats"]
])

export const extraSmallScreen = {
  breakpoint: 450
}

export const VIQ_RECOMMENDATIONS_MOCKS = {
  "1": {
    "issue": {
      "title": "Your Email Security",
      "type": "dns",
      "items": [
        {
          "id": 7,
          "scan_type": "dmarc",
          "risk": "Medium",
          "details": "supports_starttls:true",
          "recommendation": "To set starttls, you need to enable it in your email or server settings. Check the documentation or contact your email provider for specific instructions.",
          "resolved_on": null
        },
        {
          "id": 9,
          "scan_type": "starttls",
          "risk": "Medium",
          "details": "supports_starttls:true",
          "recommendation": "To set starttls, you need to enable it in your email or server settings. Check the documentation or contact your email provider for specific instructions.",
          "resolved_on": null
        },
      ]
    },
    "total_steps": 3,
    "current_step": 1
  },
  "2": {
    "issue": {
      "title": "Your Web Security",
      "type": "ssl",
      "items": [
        {
          "id": 8,
          "scan_type": "ssl",
          "risk": "High",
          "details": "valid_cert_chain_order:true",
          "recommendation": "Your goal of having a valid SSL certificate is currently not met as it is showing as invalid.",
          "resolved_on": null
        }
      ]
    },
    "total_steps": 3,
    "current_step": 2
  },
  "3": {
    "issue": {
      "title": "Discovered Vulnerabilities",
      "type": "vulnerability",
      "items": [
        {
          "id": 11,
          "scan_type": "vulnerability",
          "risk": "High",
          "details": "A vulnerability scan failed.",
          "recommendation": "We could not test https://silenccio.com for vulnerabilities. Please try again, or contact us for more information.",
          "resolved_on": null
        },
        {
          "id": 10,
          "scan_type": "vulnerability",
          "risk": "High",
          "details": "The domain could not be resolved.",
          "recommendation": "We could not test your domain www.silenccio.com for vulnerabilities. Please try again, or contact us for more information.",
          "resolved_on": null
        }
      ]
    },
    "total_steps": 3,
    "current_step": 3
  }
}
export const smallScreen = {
  breakpoint: 600
}

export const mediumScreen = {
  breakpoint: 768
}

export const largeScreen = {
  breakpoint: 992
}

export const analyticsChartLineColors = new Map<string, string>([
  ['page_view', '#2FA84F'],
  ['scroll', '#FF7052'],
  ['session_start', '#2DAAE2'],
  ['first_visit', '#FFC90B'],

  ['button_1', '#2FA84F'],
  ['button_2', '#FF7052'],
  ['button_3', '#CC3366'],
  ['tech_report', '#2DAAE2'],
  ['get_started', '#7B61FF'],
  ['login', '#FFC90B'],
  ['learn_more', '#3DBDA0'],
  ['ticket_cancel', '#CC0000'],
  ['ticket_submit', '#1565C0'],
  ['user_engagement', '#EBB0E7'],
])

export const DAY_IN_SECONDS = 24 * 3600 * 1000;

export const MIN_DOMAIN_SCORE = 300;
export const MAX_DOMAIN_SCORE = 850;

export const DOMAIN_SCORE_RECOMMENDATION_MAX_CHARS_VISIBLE = 140;

export const HUBSPOT_CHAT = {
  src: "//js-na1.hs-scripts.com/20816716.js",
  id: "hs-script-loader"
}

export enum DOMAIN_SCORES_RISK_STATUS {
    AT_RISK = 653,
    GOOD = 800,
    GREAT = 850
}

export const RISK_STATUS_DISTRIBUTION_COLORS = {
    RED: 'bg-alert-red',
    YELLOW: 'bg-alert-yellow',
    GREEN: 'bg-green',
}
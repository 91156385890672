import React from 'react';
import { MenuItem, TextField } from "@mui/material";

type TenantSelectProps = {
	selectedTenant: string;
	handleTenantChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	tenantsResponse: { data: string[] } | undefined;
};

const TenantSelect: React.FC<TenantSelectProps> = ({ selectedTenant, handleTenantChange, tenantsResponse }) => {
	return (
		<TextField
			select
			label="Tenant"
			helperText="Please select your tenant"
			size="small"
			value={selectedTenant}
			onChange={handleTenantChange}
			style={{marginRight: 10}}
			sx={{width: 200}}
		>
			{tenantsResponse
				? tenantsResponse.data.map((tenant, index) => (
					<MenuItem key={index} value={tenant}>
						{tenant}
					</MenuItem>
				))
				: <span className="ms-2">No tenants yet</span>
			}
		</TextField>
	);
};

export default TenantSelect;
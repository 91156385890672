import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useAuth0} from "@auth0/auth0-react";
import moment from "moment";
import {AxiosError} from "axios";

import LoadingSpinner from "../../components/loading/LoadingSpinner";
import {endUserInitialData, HUBSPOT_CHAT, permissionsCatalog} from "../../constants";
import {useAPI} from "../../utils/hooks/useAPI";
import {useTitle} from "../../utils/hooks/useTitle";
import {EndUserData} from '../../interfaces';
import HeaderReport from "./HeaderReport/HeaderReport";
import SubheaderReport from "./SubheaderReport/SubheaderReport";
import {ReferenceBoard} from './ReferenceBoard/ReferenceBoard';
import CurrentYearScoreBoard from "./CurrentYearScoreBoard/CurrentYearScoreBoard";
import OpportunitiesBoard from "./OpportunitiesBoard/OpportunitiesBoard";
import SuggestionsBoards from "./SuggestionsBoards/SuggestionsBoards";
import FooterReports from "./FooterReports/FooterReports";
import PrintVersion from "./PrintVersion/PrintVersion";
import {getDomainScoreDataService} from "../../api/domain.service";
import PathContext from "../../store/context/path-context";
import {UserPermissionContext} from "../../store/context/user-permission-context";
import {ThemeContext} from "../../store/context/theme-context";
import {Modal} from "../../components/utils/Modal/Modal";
import './styles.scss'
import useScript from "../../utils/hooks/useScript";


const MainReports = ({fromSelfServe}: { fromSelfServe?: boolean }) => {
  const {domain_name} = useParams();
  const api = useAPI();
  const {apiUrlProps} = useContext(PathContext);
  const {userPermissions} = useContext(UserPermissionContext);
  const {isLoading, user} = useAuth0();
  const [endUserData, setEndUserData] = useState<EndUserData>(endUserInitialData);
  const componentRef = useRef<HTMLElement>(null);
  const [loading, setLoading] = useState<boolean>(true);
  //we need those two below for the Error Modal to appear and display the appropriate message
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const currentThemeProps = useContext(ThemeContext)

  useTitle(`Vulnerability IQ report by ${currentThemeProps?.name} for ${domain_name}`);
  useScript({shouldLoad: true, src: HUBSPOT_CHAT.src, id: HUBSPOT_CHAT.id});

  const fonts = {
    mainFont: currentThemeProps?.fonts.mainFont,
    subFont: currentThemeProps?.fonts.subFont
  }

  const dataReportProps = {
    id: endUserData.id,
    domain_id: endUserData.domain_id,
    member_id: endUserData.member_id,
    timestamp: endUserData.timestamp,
    domain: endUserData.domain,
    permissions: userPermissions,
  }

  const headerReportProps = {
    ...dataReportProps,
    company_info: endUserData.company_info,
    fromSelfServe,
  }

  const referenceBoardReportProps = {
    ...dataReportProps,
    permission: dataReportProps.permissions,
  }

  const opportunitiesBoardReportProps = {
    ...dataReportProps,
    permission: dataReportProps.permissions,
    critical: endUserData.critical,
    important: endUserData.important
  }

  const suggestionsBoardReportProps = {
    ...dataReportProps,
  }

  useEffect(() => {
    if (!isLoading) {
      fetchUser();
    }
    // eslint-disable-next-line
  }, [domain_name, apiUrlProps, api, userPermissions, isLoading])

  async function fetchUser(): Promise<void> {
    if (!domain_name) {
      return;
    }
    try {
      const response = await getDomainScoreDataService(domain_name, apiUrlProps, userPermissions, api);
      if (!response.data.score_history) {
        setIsError(true);
        setErrorMessage('It looks like we are still processing your report.');
      }
      const timestamp = moment(new Date(response.data.timestamp), 'DD/MM/YYYY').format('DD MMM YYYY');
      setEndUserData({...response.data, timestamp});
    } catch (e: unknown) {
      if (e instanceof Error) {
        if ((e as AxiosError).response?.status === 404) {
          navigate('/not-found');
          return;
        }
        if ((e as AxiosError).response?.status === 400) {
          setIsError(true);
          setErrorMessage(`${domain_name} is not a valid domain!`);
          return;
        }
        setIsError(true);
        setErrorMessage('Oops, something went wrong...');
      }
    } finally {
      setLoading(false);
    }
  }


  return (
    <>
      {loading
        ? <LoadingSpinner ref={componentRef}/>
        :
        <>
          {/*display component only if user is logged, and is a super admin*/}
          {/*<FeatureWrapper feature={FeatureFlag.VIQ}><div hidden={true}>some hidden text</div></FeatureWrapper>*/}
          {!isLoading && user && userPermissions.includes(permissionsCatalog.read_tenants) && !fromSelfServe &&
              <PrintVersion font={fonts.mainFont} printRef={componentRef}
                            buttonColor={currentThemeProps?.buttons.mainButtonsColor}
                            />
          }
          <section ref={componentRef}
                   style={{fontFamily: currentThemeProps?.fonts.all.join(', '), fontSize: currentThemeProps?.fontSize}}
                   className={fromSelfServe ? "app__self-serve__report" : "app__reports-wrapper"}
                   >
            <HeaderReport {...headerReportProps}
                          logo={currentThemeProps.logo}
                          font={fonts.subFont}
                          buttonColor={currentThemeProps.buttons.mainButtonsColor}
                          borderLineColor={currentThemeProps.borderLineColor}
                          />
            {/*display content if user is not a super admin*/}
            {!userPermissions.includes(permissionsCatalog.read_tenants) &&
                <SubheaderReport font={fonts.subFont}
                                 timestamp={endUserData.timestamp}
                />
            }
            <ReferenceBoard {...endUserData} {...referenceBoardReportProps}
                            font={fonts.subFont}
                            buttonColor={currentThemeProps.buttons.actionButtonsColor}
                            borderLineColor={currentThemeProps.borderLineColor}
                            arrowColor={currentThemeProps.borderCircle.urgentColor}
            />
            <CurrentYearScoreBoard scoreHistory={endUserData.score_history}
                                   underlineColor={currentThemeProps.borderCircle.urgentColor}
                                   borderLineColor={currentThemeProps.borderLineColor}
            />
            <OpportunitiesBoard {...opportunitiesBoardReportProps}
                                font={fonts.subFont}
                                buttonColor={currentThemeProps.buttons.actionButtonsColor}
                                borderLineColor={currentThemeProps.borderLineColor}
                                borderCircleUrgentColor={currentThemeProps.borderCircle.urgentColor}
                                borderCircleImportantColor={currentThemeProps.borderCircle.importantColor}

            />
            <SuggestionsBoards {...suggestionsBoardReportProps}
                               font={fonts.subFont}
                               qrColor={currentThemeProps.buttons.mainButtonsColor}
                               buttonColor={currentThemeProps.buttons.mainButtonsColor}
                               underlineColor={currentThemeProps.borderCircle.urgentColor}
                               borderLineColor={currentThemeProps.borderLineColor}
                               recommendations={currentThemeProps?.recommendations ? currentThemeProps?.recommendations
                                 : endUserData.recommendations}

            />
            <FooterReports logo={currentThemeProps.logo}
                           footer={currentThemeProps.footerContent}/>
            {isError && <Modal
                useHeader={false}
                useFooter={fromSelfServe}
                show={isError}
                hideConfirmButton={!fromSelfServe}
                confirmBtnText={fromSelfServe ? 'Refetch' : ''}
                hideCloseButton={!fromSelfServe}
                denyBtnText={fromSelfServe ? 'Back' : ''}
                onConfirm={() => window.location.reload()}
                onDeny={() => navigate(-1)}
                size="lg"
            >
                <div className="app__reports-modal">
                    <h3>{errorMessage}</h3>
                    <p>Please try again or contact us at support@edisonmarks.com</p>
                </div>
            </Modal>}
          </section>
        </>
      }
    </>
  )
}

export default MainReports;

import './Concepts.scss';

const Concepts = () => {
    return (
        <section className="concepts">
            <h2 className="concepts__center-headers">Concepts</h2>
            <h2 className="concepts__header">Bem</h2>
            <h4>Bem is a front-end naming method for organizing and naming CSS classes.</h4>
            <article>
                <h3 className="concepts__headers_green-color">Introduction</h3>
                <p>
                    BEM (Block, Element, Modifier) is a component-based approach to web development.
                    The idea behind it is to divide the user interface into independent blocks.
                    This makes interface development easy and fast even with a complex UI,
                    and it allows reuse of existing code without copying and pasting.
                </p>
            </article>
            <article>
                <h3 className="concepts__headers_green-color">Contents</h3>
                <p>
                    <ul>
                        <li>Block</li>
                        <li>Element</li>
                        <li>Modifier</li>
                        <li>Reference</li>
                    </ul>
                </p>
            </article>
            <article>
                <h3 className="concepts__headers_green-color">Block</h3>
                <p>
                    A functionally independent page component that can be reused.
                    In HTML, blocks are represented by the class attribute.
                </p>
                <p>
                    <ul>
                        Features:
                        <li>
                            The block name describes its purpose ("What is it?" — menu or button),
                            not its state ("What does it look like?" — red or big).
                        </li>
                    </ul>
                </p>
                <h5 className="concepts__headers_green-color">Example</h5>
                <div className="concepts__block-example">
                    <p>
                        &lt;!-- Correct. The `error` block is semantically meaningful --&gt;<br/>
                        &lt;div className="error"&gt;&lt;/div&gt;

                    </p>
                    <p>
                        &lt;!-- Incorrect. It describes the appearance --&gt;<br/>
                        &lt;div className="red-text"&gt;&lt;/div&gt;
                    </p>
                </div>
                <div>
                    <h3 className="concepts__headers_green-color">Elements</h3>
                    <p>A composite part of a block that can't be used separately from it.</p>
                    <ul>
                        Features:
                        <li>
                            The element name describes its purpose ("What is this?" — item, text, etc.),
                            not its state ("What type, or what does it look like?" — red, big, etc.).
                        </li>
                        <li>
                            The structure of an element's full name is block-name__element-name.
                            The element name is separated from the block name with a double underscore (__).
                        </li>
                    </ul>
                    <h4 className="concepts__headers_green-color">Nesting</h4>
                    <ul>
                        <li>Elements can be nested inside each other.</li>
                        <li>You can have any number of nesting levels.</li>
                        <li>An element is always part of a block, not another element.
                            This means that element names can't define a hierarchy such as block__elem1__elem2.
                        </li>
                    </ul>
                    <h5 className="concepts__headers_green-color">Example</h5>
                    <p className="concepts__block-example">
                        &lt;!--<br/>
                        &emsp;Correct. The structure of the full element name follows the pattern:<br/>
                        &emsp;`block-name__element-name`<br/>
                        --&gt;<br/>
                        &lt;form class="search-form"&gt;<br/>
                        &emsp;&lt;div class="search-form__content"&gt;<br/>
                        &emsp;&emsp;&lt;input class="search-form__input"&gt;<br/>

                        &emsp;&emsp;&lt;button class="search-form__button"&gt;Search&lt;/button&gt;<br/>
                        &emsp;&lt;/div&gt;<br/>
                        &lt;/form&gt;<br/><br/>

                        &lt;!--<br/>
                        &emsp;Incorrect. The structure of the full element name doesn't follow the<br/> &emsp;pattern:
                        `block-name__element-name`<br/>
                        --&gt;<br/>
                        &lt;form class="search-form"&gt;<br/>
                        &emsp;&lt;div class="search-form__content"&gt;<br/>
                        &emsp;&emsp;&lt;!-- Recommended: `search-form__input` or `search-form__content-input` --&gt;
                        <br/>
                        &emsp;&emsp;&lt;input class="search-form__content__input"&gt;<br/><br/>

                        &emsp;&emsp;&lt;!-- Recommended: `search-form__button` or `search-form__content-button` --&gt;
                        <br/>
                        &emsp;&emsp;&lt;button class="search-form__content__button"&gt;Search&lt;/button&gt;<br/>
                        &emsp;&lt;/div&gt;<br/>
                        &lt;/form&gt;
                    </p>
                </div>
                <div>
                    <h3 className="concepts__headers_green-color">Modifier</h3>
                    <p>An entity that defines the appearance, state, or behavior of a block or element.</p>
                    <ul>
                        <li>
                            Used when only the presence or absence of the modifier is important, and its value is
                            irrelevant.
                            For example, disabled. If a Boolean modifier is present, its value is assumed to be true.
                        </li>
                        <li>
                            The structure of the modifier's full name follows the pattern:<br/>
                            &emsp; -block-name_modifier-name<br/>
                            &emsp; -block-name__element-name_modifier-name
                        </li>
                    </ul>
                    <h5 className="concepts__headers_green-color">Example</h5>
                    <p className="concepts__block-example">
                        &lt;!-- The `search-form` block has the `focused` Boolean modifier --&gt;<br/>
                        &lt;form class="search-form search-form_focused"&gt;<br/>
                        &emsp;&lt;input class="search-form__input"&gt;<br/><br/>

                        &emsp;&lt;!-- The `button` element has the `disabled` Boolean modifier --&gt;<br/>
                        &emsp;&lt;button class="search-form__button
                        search-form__button_disabled"&gt;Search&lt;/button&gt;<br/>
                        &lt;/form&gt;
                    </p>
                </div>
            </article>
            <article className="concepts__reference concepts__headers_green-color">
                For future guidelines you can use-&emsp;
                <a href="https://en.bem.info/methodology/quick-start/">
                    https://en.bem.info/methodology/quick-start/
                </a>
                <br/>
            </article>
        </section>
    );
};

export default Concepts;
import React from "react";
import {AxiosError} from "axios";
import {Field, FormikProvider, useFormik} from "formik";
import {Button, Checkbox} from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import * as Yup from "yup";

import {notifyError, notifySuccess} from "../../../../../components/utils/ToastNotifications/Notifier";
import {ENDPOINTS} from "../../../../../constants";
import {MemberUsers} from "../../../../../interfaces";
import {FormElementInputField} from "../AddMember/AddMember";
import {useAPI} from "../../../../../utils/hooks/useAPI";
import {replaceEmptyStringsWithNullsInJSON} from "../../../../../utils/helpers";
import "./CreateEditContact.scss"

interface CreateEditContactProps {
    editedContact?: MemberUsers;
    setChangesMade: React.Dispatch<React.SetStateAction<boolean>>
    closeModal: Function;
    memberId: number;
}

const CreateEditContact: React.FC<CreateEditContactProps> = ({
                                                                 editedContact,
                                                                 memberId,
                                                                 closeModal,
                                                                 setChangesMade}) => {
    const api = useAPI()
    const formik = useFormik({
        initialValues: {
            email: (editedContact && editedContact.email) || "",
            contact_name: (editedContact && editedContact.contact_name) || "",
            contact_position: (editedContact && editedContact.contact_position) || "",
            contact_phone: (editedContact && editedContact.contact_phone) || "",
            is_primary: editedContact ? editedContact.is_primary : false
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email("You must enter a valid email.")
                .max(254, "This field must contain at most 254 characters.")
                .required("This field is required."),
            contact_name: Yup.string()
                .max(255, "This field must contain at most 255 characters.")
                .notRequired(),
            contact_position: Yup.string()
                .max(100, "This field must contain at most 100 characters.")
                .notRequired(),
            contact_phone: Yup.string()
                .notRequired()
                .min(8, "This field must contain at least 8 characters.")
                .max(16, "This field must contain at most 16 characters.")
        }),
        onSubmit: (formData, {setSubmitting}) => {
            const requestData = replaceEmptyStringsWithNullsInJSON(formData)
            const response = editedContact ?
                api.patch(ENDPOINTS.MEMBERS.update_or_delete_member_user(memberId, editedContact.id), requestData) :
                api.post(ENDPOINTS.MEMBERS.create_member_user(memberId), requestData);
            response.then(() => {
                setChangesMade(true);
                if (editedContact) {
                    notifySuccess('Contact updated.')
                } else {
                    notifySuccess('Contact added.')
                }
            }).catch((error) => {
                notifyError((error as AxiosError).response?.data[0])
            }).finally(() => {
                setSubmitting(false);
                closeModal()
            })
        }
    })

    const handleContactDelete = () => {
      api.delete(ENDPOINTS.MEMBERS.update_or_delete_member_user(memberId, editedContact!.id))
          .then(() => {
              setChangesMade(true);
              notifySuccess('Contact deleted.')
              closeModal()
          })
          .catch(() => {
              notifyError("We could not process your request. Please try again later.");
          })
    }

    return <section className="create-edit-contact">
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} className="create-edit-contact__form">
                <div className="create-edit-contact__header">{editedContact ? "Edit Contact" : "Create Contact"}</div>
                {
                    editedContact &&
                    <div className="create-edit-contact__title-div">
                        <span className="create-edit-contact__title-text">{editedContact.contact_name || editedContact.email}</span>
                        <DeleteOutlineIcon onClick={handleContactDelete} className="create-edit-contact__delete-icon"/>
                    </div>
                }
                <FormElementInputField name="email"
                                       label="Contact email"
                                       errors={formik.errors}
                                       touched={formik.touched}
                                       required/>
                <FormElementInputField name="contact_name" label="Contact name (optional)"
                                       errors={formik.errors} touched={formik.touched}/>
                <FormElementInputField name="contact_position"
                                       label="Contact position (optional)"
                                       errors={formik.errors}
                                       touched={formik.touched}/>
                <FormElementInputField name="contact_phone"
                                       label="Contact phone (optional)"
                                       errors={formik.errors}
                                       touched={formik.touched}
                                       isPhone={true}
                                       formik={formik}/>
                <div className="create-edit-contact__primary-section">
                    <Field as={Checkbox} name="is_primary"
                           style={{color: '#2DAAE2', padding: 0, backgroundColor: 'white'}}
                           checked={formik.values.is_primary}/>
                    <span className="create-edit-contact__checkbox-label">Primary</span>
                </div>

                <div className="create-edit-contact__save-div">
                    <Button
                        className="create-edit-contact__save-btn" variant={"contained"} type="submit"
                        disabled={formik.isSubmitting || !formik.isValid || !formik.dirty || !formik.touched}
                    >
                        Save
                    </Button>
                </div>
            </form>
        </FormikProvider>
    </section>
}

export default CreateEditContact;

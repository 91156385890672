import React, {useEffect, useState} from 'react';import {useAPI} from "../../../utils/hooks/useAPI";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import {DAY_IN_SECONDS, ENDPOINTS} from "../../../constants";
import moment from "moment/moment";
import {TextField} from "@mui/material";
import {dateFormatToUniversal} from "../../../utils/helpers";
import DateRangePicker from "../../../components/DatePickers/DateRangePicker";
import MultiLineChart from "../../../components/Charts/MultiLineChart";
import {notifyError} from "../../../components/utils/ToastNotifications/Notifier";
import Autocomplete from "@mui/material/Autocomplete";


const Details = () => {
	const api = useAPI();
	const queryClient = useQueryClient();
	const [startDate, setStartDate] = useState<Date | null>(new Date(Date.now() - 14 * DAY_IN_SECONDS));
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [selectedDomain, setSelectedDomain] = useState<string | null>();
	const [tenantDomains, setTenantDomains] = useState<Array<string>>();

	const query = useQuery({
		queryKey: ['landing-analytics-details'],
		queryFn: initAnalyticsReportData,
		refetchOnWindowFocus: false
	})

	function initAnalyticsReportData() {
		return api.post(ENDPOINTS.GOOGLE_ANALYTICS.get_details_charts_data, {
			start_date: moment(startDate).format("YYYY-MM-DD"),
			end_date: moment(endDate).format("YYYY-MM-DD"),
			...(selectedDomain && {domain: selectedDomain}),
		});
	}

	const refetchAnalyticsReportData = async () => {
		await query.refetch();
	}

	const getTenantDomains = () => {
		const getTenantDomainsQuery = queryClient.getQueryData(['get-tenant-domains']);
		// @ts-ignore
		let domains = tenantDomains || getTenantDomainsQuery?.data;
		if (!domains) {
			api.get('tenants/domains/').then(response => {
				if (response.status !== 200) {
					notifyError("Something went wrong, please try again or contact us at support@edisonmarks.com");
					return;
				}
				setTenantDomains(() => {
					const domainsWithData = [...query.data?.data.domains];
					for (const domain of response.data) {
						if (!domainsWithData.includes(domain)) {
							domainsWithData.push(domain);
						}
					}
					return domainsWithData;
				});
			});

			return;
		}

		setTenantDomains(() => {
			const domainsWithData = [...query.data?.data.domains];
			for (const domain of domains) {
				if (!domainsWithData.includes(domain)) {
					domainsWithData.push(domain);
				}
			}

			return domainsWithData;
		});
	}

	function wasEventRegistered(event: string, eventsData: Array<any>) {
		return eventsData.some(dayData => {
			return dayData[event] > 0;
		})
	}


	useEffect(() => {
		if (query.data?.data.domains) {
			getTenantDomains();
		}
	}, [query.data?.data.domains]);

	return (
		<section>
			<div className={'flex flex-col md:flex-row'}>
				<Autocomplete
					disablePortal
					disabled={query.isFetching || !query.data?.data}
					className={'me-2 mb-3 md:mb-0'}
					options={tenantDomains || []}
					sx={{ width: 300 }}
					renderInput={(params) => <TextField {...params} size={'small'} label={'Domain'}/>}
					onChange={(event, domain: string | null) => setSelectedDomain(domain)}
					renderOption={(props, option, { inputValue }) => {
						return (
							<li {...props} style={{
								fontWeight: query.data?.data.domains.includes(option) ? 700 : 400,
							}}>
								{option}
							</li>
						);
					}}
				/>
				<DateRangePicker startDate={startDate} endDate={endDate}
												 disabled={query.isFetching || !query.data?.data}
												 setStartDate={setStartDate} setEndDate={setEndDate}
												 onDateFilterChartData={refetchAnalyticsReportData}/>
			</div>
			{
				query.isFetching || !query.data?.data ? <LoadingSpinner/>
				:
				<div>
					<MultiLineChart title={'Report Visits'} subtitle={'types of visits'}
													data={dateFormatToUniversal(query.data?.data.detailed_report_visits)}
													wasEventRegistered={wasEventRegistered}/>
					<MultiLineChart title={'Report CTAs'} subtitle={'types of engagements'}
													data={dateFormatToUniversal(query.data?.data.detailed_report_engagement)}
													wasEventRegistered={wasEventRegistered}/>
				</div>
			}
		</section>
	);
};

export default Details;

import React, {useEffect, useState} from 'react';
import {Form, FormikProvider, FormikValues, useFormik} from "formik";
import {Button, Tooltip, Typography} from "@mui/material";

import {FormElementSelectField} from "../../Dashboard/MemberManagement/AddOrModifyMember/AddMember/AddMember";
import FormikMUISimpleInput from "../../../components/Formik/MUISimpleInput/FormikMUISimpleInput";
import {ENDPOINTS, industries} from "../../../constants";
import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useAPI} from "../../../utils/hooks/useAPI";
import {AxiosError, AxiosResponse} from "axios";
import {useAuth0} from "@auth0/auth0-react";
import * as yup from "yup";
import {replaceEmptyStringsWithNullsInJSON} from "../../../utils/helpers";
import {notifyError, notifySuccess} from "../../../components/utils/ToastNotifications/Notifier";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LoadingSpinner from "../../../components/loading/LoadingSpinner";

type SSAccountSettingsType = {
  company_name: string,
  industry: string
}

type MemberUserType = {
  id: number,
  email: string,
  member: number,
  is_primary: boolean,
  contact_name: string | null,
  contact_position: string | null,
  contact_phone: string | null,
}

export const updateUserNameValidationSchema = yup.object({
  name: yup.string()
    .required('This field is required')
})

export const updateCompanyInfoValidationSchema = yup.object({
  company_name: yup.string()
    .notRequired(),
  industry: yup.string()
    .notRequired(),
})


const Account = ({setUserName, userName}: {
  setUserName: React.Dispatch<React.SetStateAction<string | undefined>>,
  userName: string | undefined
}) => {
  const {member_id: memberId} = useParams()
  const api = useAPI();
  const {user} = useAuth0();
  const userProvider = user?.sub?.split('|')[0]
  const [companyInfo, setCompanyInfo] = useState<{ company_name: string, industry: string }>()

  const {data: memberData, isFetching: memberDataLoading} = useQuery({
    queryKey: ['ssMemberInfo'],
    queryFn: fetchSSMemberInfo,
    refetchOnWindowFocus: false,
  })

  const {data: memberUserProps} = useQuery({
    queryKey: ['fetchMemberUserId'],
    queryFn: fetchMemberUserId,
    refetchOnWindowFocus: false,
  })

  const {mutateAsync: mutateUserName} = useMutation({
    mutationFn: handleChangingUserName
  })

  const {mutateAsync: mutateCompanyInfo} = useMutation({
    mutationFn: handleSubmittingCompanyInfo
  })

  const updateUserNameFormik = useFormik({
    initialValues: {
      name: userName || ''
    },
    validationSchema: updateUserNameValidationSchema,
    enableReinitialize: true,
    onSubmit: (values: FormikValues) => mutateUserName(values)
  });

  const updateCompanyInfoFormik = useFormik({
    initialValues: {
      company_name: companyInfo?.company_name || '',
      industry: companyInfo?.industry || ''
    },
    validationSchema: updateCompanyInfoValidationSchema,
    enableReinitialize: true,
    onSubmit: (values: FormikValues) => mutateCompanyInfo(values)
  });

  useEffect(() => {
    if (memberData?.data) {
      setCompanyInfo({company_name: memberData.data.company_name, industry: memberData.data.industry})
    }
  }, [memberData?.data])


  async function handleChangingUserName(values: FormikValues) {
    updateUserNameFormik.setSubmitting(true)
    return api.patch(ENDPOINTS.MEMBERS.update_auth0_user_name(memberId, memberUserProps?.data.id), {name: values.name})
      .then((response) => {
        if (response.status === 200) {
          setUserName(values.name)
        }
      })
  }

  async function handleSubmittingCompanyInfo(values: FormikValues) {
    updateCompanyInfoFormik.setSubmitting(true)
    if (values.company_name === "") {
      if (updateCompanyInfoFormik.initialValues.company_name !== values.company_name) {
        values.company_name = 'N/A'
      } else {
        delete values.company_name
      }
    }
      return api.patch(ENDPOINTS.MEMBERS.get_or_update_member(memberId), replaceEmptyStringsWithNullsInJSON(values))
      .then((response) => {
        if (response.status === 200) {
          setCompanyInfo({company_name: response.data.company_name, industry: response.data.industry})
        }
      })
  }

  function handleSubmit() {
    const promises: Promise<void>[] = []
    updateUserNameFormik.dirty && promises.push(mutateUserName(updateUserNameFormik.values))
    updateCompanyInfoFormik.dirty && promises.push(mutateCompanyInfo(updateCompanyInfoFormik.values))
    Promise.all(promises)
      .then(() => notifySuccess('You have successfully changed your data.'))
      .catch((error) => notifyError((error as AxiosError).response?.data?.detail || 'Something went wrong please try again later or contact us.'))
      .finally(() => {
        updateUserNameFormik.setSubmitting(false)
        updateCompanyInfoFormik.setSubmitting(false)
      })
  }

  function fetchSSMemberInfo(): Promise<AxiosResponse<SSAccountSettingsType>> {
    return api.get(ENDPOINTS.MEMBERS.get_or_update_member(memberId));
  }

  function fetchMemberUserId(): Promise<AxiosResponse<MemberUserType>> {
    return api.get(ENDPOINTS.MEMBERS.get_member_user(memberId));
  }

  return (
    <section className="w-1/2">
      <h3>Account</h3>
      <p>Change your member details.</p>
      {memberDataLoading || !userName ? <LoadingSpinner/> :
        <div>
          <FormikProvider value={updateUserNameFormik}>
            <Form className="flex flex-col items-start mt-6">
              <div className="mb-4 mt-1 w-11/12 items-center relative">
                <FormikMUISimpleInput error={!!updateUserNameFormik.errors.name} label="Name" name="name"
                                      required={true}
                                      size="small" disabled={userProvider !== "email"}/>
                {userProvider !== "email" &&
                    <Tooltip className="absolute mt-2 ml-2"
                             title={<Typography fontSize={"0.9rem"}>You cannot change your name due to being logged
                               in
                               via social account (ex: google).</Typography>}>
                        <InfoOutlinedIcon className="app__ss-account-icon"/>
                    </Tooltip>}
              </div>
            </Form>
          </FormikProvider>
          <FormikProvider value={updateCompanyInfoFormik}>
            <Form className="flex flex-col items-start">
              <div className="mb-4 w-11/12">
                <FormikMUISimpleInput error={!!updateCompanyInfoFormik.errors.industry} label="Company name"
                                      name="company_name" size="small"/>
              </div>
              <div className="mb-5 w-11/12">
                <FormElementSelectField items={industries} name="industry" label="Industry"
                                        errors={updateUserNameFormik.errors} touched={updateUserNameFormik.touched}/>
              </div>
            </Form>
          </FormikProvider>
          <div className="mb-16 flex justify-center">
            <Button className="!font-bold !text-lg !px-7 !rounded-lg bg-orange mulish" onClick={handleSubmit}
                    size="large" variant="contained"
                    disabled={
                      memberDataLoading ||
                      (updateUserNameFormik.isSubmitting || !updateUserNameFormik.isValid || !updateUserNameFormik.dirty || !updateUserNameFormik.touched) &&
                      (updateCompanyInfoFormik.isSubmitting || !updateCompanyInfoFormik.isValid || !updateCompanyInfoFormik.dirty || !updateCompanyInfoFormik.touched)}
            >
              submit changes
            </Button>
          </div>
        </div>
      }
    </section>
  );
};

export default Account;

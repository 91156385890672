import React from "react";
import {Checkbox, TableCell, TableHead, TableRow} from "@mui/material";

import {Tenant} from "../../../../Dashboard/CampaignStats/AdminCampaignStats";

interface TenantsTableHeadProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

interface HeadCell {
  id: keyof Tenant;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Name',
  },
  {
    id: 'subdomain_prefix',
    numeric: false,
    label: 'Subdomain Prefix',
  },
  {
    id: 'active',
    numeric: false,
    label: 'Active',
  },
];

function TenantsTableHead(props: TenantsTableHeadProps) {
  const { onSelectAllClick, numSelected, rowCount} =
    props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all tenants',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding="none"
            className="italic"
            style={{fontFamily: "Mulish, Quicksand, sans-serif"}}
          >
              {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TenantsTableHead
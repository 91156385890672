import './DashboardFooter.scss';

const DashboardFooter = () => {
    return (
        <footer className="app__footer">
            <p>© {new Date().getFullYear()} Edison Marks, Inc.</p>
        </footer>
    )
}

export default DashboardFooter;
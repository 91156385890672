import Navigation from "./Navigation/Navigation";
import Main from "./Main/Main";
import './styles.scss';

const StyleGuide = () => {
    return (
        <section className="style_guide">
            <Navigation/>
            <Main/>
        </section>
    );
};

export default StyleGuide;
import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Collapse, List, ListItemButton, ListItemText} from "@mui/material"
import {ExpandLess, ExpandMore} from "@mui/icons-material"

import useNavSideBar from "../../../utils/hooks/useNavSideBar";
import './EmAdminNavigation.scss'

const navButtons = {
  'logins': {
    name: 'User Logins',
    path: 'logins'
  },
  'tenants': {
    name: 'Clients (Tenants)',
    path: 'tenants'
  },
  'domain-scores': {
    name: 'Domain Scores',
    path: 'domain-scores'
  },
}

const initialNavButtonsValues = Object.values(navButtons).map(({name: navButtonName}) => {
  return {
    name: navButtonName.toLowerCase(),
    isClicked: false
  }
})

const EmAdminNavigation = ({setIsDrawerToggled}: {
  setIsDrawerToggled?: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const navigate = useNavigate()
  const [openClients, setOpenClients] = useState(true);
  const [openScores, setOpenScores] = useState(false);
  const {pathname} = useLocation();
  const activeNavButton = React.useMemo(() => {
    return Object.values(navButtons).find(({path}) => pathname.includes(path))
  }, [pathname])
  const {
    filterNavButtons,
    updateNavButtons
  } = useNavSideBar(initialNavButtonsValues, activeNavButton?.name?.toLowerCase()! || 'user logins')

  return <nav className='nav-dash'>
    <div className='nav-dash__paths'>
      <List
        sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => setOpenClients(!openClients)}>
          <ListItemText primary="Clients & Users"/>
          {openClients ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={openClients} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{pl: 4}}
                            className={filterNavButtons('user logins') ? "nav-buttons__clicked-button" : ""}>
              <ListItemText primary="User Logins"
                            onClick={() => {
                              updateNavButtons('user logins');
                              setIsDrawerToggled && setIsDrawerToggled(false)
                              navigate("users/logins")
                            }}/>
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton sx={{pl: 4}}
                            className={filterNavButtons('clients (tenants)') ? "nav-buttons__clicked-button" : ""}>
              <ListItemText primary="Clients (Tenants)"
                            onClick={() => {
                              updateNavButtons('clients (tenants)');
                              setIsDrawerToggled && setIsDrawerToggled(false)
                              navigate("users/tenants")
                            }}/>
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton onClick={() => setOpenScores(!openScores)}>
          <ListItemText primary="Scores"/>
          {openScores || activeNavButton?.path === 'domain-scores' ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={openScores || activeNavButton?.path === 'domain-scores'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{pl: 4}}
                            className={filterNavButtons('domain scores') ? "nav-buttons__clicked-button" : ""}>
              <ListItemText primary="Domain Scores"
                            onClick={() => {
                              updateNavButtons('domain scores');
                              setIsDrawerToggled && setIsDrawerToggled(false)
                              navigate("scores/domain-scores")
                            }}/>
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </div>
  </nav>
}

export default EmAdminNavigation;
export const dataTestIds = {
	viq: {
		execute: 'exec-viq',
		ticketCreationHead: 'ticket-creation-head',
		ticketCreateHeadFlag: 'ticket-create-head-flag',
		ticketCreateFooter: 'ticket-create-footer',
		pageTitle: 'page-title',
		backBtn: 'back-button',
		recTitle: 'recommendation-title',
		accordion: 'accordion',
		skipRecBtn: 'skip-recommendation-button',
		nextRecBtn: 'next-recommendations-page-button',
		doneRecBtn: 'done-recommendation-button',
		recSubtitle: 'recommendation-subtitle',
		resolvedIssues: 'resolved-recommendation-issues',
		unresolvedIssues: 'unresolved-recommendation-issues'
	},
	techReport: {
		downloadBtn: 'download-tech-report'
	},
	newTicket: {
		emailSupport: 'email-support',
		submitTicket: 'submit-ticket'
	},
	modal: {
		modal: 'modal',
		cancelBtn: 'modal-cancel-btn',
		clearIcon: 'modal-clear-icon',
	}
}
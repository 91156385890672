import React from 'react';
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useAPI} from "../../../utils/hooks/useAPI";
import {useLocation, useNavigate} from "react-router-dom";
import LoaderWithFacts from "../../../components/loading/LoaderWithFacts/LoaderWithFacts";
import {ENDPOINTS} from "../../../constants";

const Analyze = () => {
  const api = useAPI();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const domain_id = pathname.split('/')[4];
  const member_id = pathname.split('/')[2];

  const {data: domainScoreData} = useQuery({
    queryKey: ['fetchDomainScore'],
    queryFn: fetchDomainScore,
    retryDelay: 10000,
    retry: 12
  })

  const {data: domainData} = useQuery({
    queryKey: ['fetchDomainName', domain_id],
    queryFn: fetchDomainName
  })

  React.useEffect(() => {
    if (domainScoreData?.data && domainData?.data) {
      queryClient.invalidateQueries(['fetch-ss-domains']);
      navigate(`/members/${member_id}/reports/${domainData.data.domain}`);
    }
  }, [domainScoreData]);

  function fetchDomainScore() {
    return api.get(ENDPOINTS.DOMAINS_SCORE.get_score_by_domain_id(domain_id))
  }

  function fetchDomainName() {
    return api.get(ENDPOINTS.DOMAINS_SCORE.get_or_delete_domain(domain_id));
  }

  return (
    <section className="mt-10 mb-24 md:w-1/3 text-center app_analyze">
      <LoaderWithFacts domain={domainData?.data?.domain} title={"Scanning can take between 30s and 2 min."}/>
    </section>
  );
};

export default Analyze;

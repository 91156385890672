import React, {useState, useEffect} from 'react';
import {useLocation} from "react-router-dom";

export const RedirectContext = React.createContext({
    pathname: ''
})

export const RedirectContextProvider: React.FC = ({children}) => {
    const [pathName, setPathName] = useState('');
    const path = useLocation().pathname

    useEffect(() => {
        setPathName(path);
    }, [path])

    return (
        <RedirectContext.Provider value={{pathname: pathName}}>{children}</ RedirectContext.Provider>
    )
}

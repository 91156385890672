import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import GoogleAnalyticsChartCustomTooltip from "../chart/tooltip/GoogleAnalyticsChartCustomTooltip";
import useIsMobile from "../../utils/hooks/useIsMobile";
import {mediumScreen, smallScreen} from "../../constants";

const ReportsChart = ({title, subtitle, data, xAxisData, yAxisData, lineData}
						  : {title: string, subtitle?: string, data: any, xAxisData: string, yAxisData: string, lineData: string}) => {
	const isMediumScreen = useIsMobile(mediumScreen);
	const isSmallScreen = useIsMobile(smallScreen);

	return (
		<>
			<h1 className="engagement__title-props">{title}</h1>
			<p className="engagement__subtitle-props">{subtitle}</p>
			<ResponsiveContainer  width="100%" height={250}>
					<LineChart
						// width={730}
						// height={250}
						data={data}
						margin={isMediumScreen? {top: 5, right: 5, left: 0, bottom: 5} : {top: 5, right: 30, left: 20, bottom: 5} }
					>
						<CartesianGrid strokeDasharray="3 4" stroke="#818A91" vertical={false} />
						<XAxis
							dataKey={xAxisData}
							axisLine={false}
							tick={{ fill: "#555555" }}
							tickLine={false}
							style={{ fontSize: 14, fontFamily: "Mulish", fontStyle: "italic" }}
						/>
						<YAxis
							dataKey={yAxisData}
							stroke="#818A91"
							tick={{ fill: "#555555" }}
							tickCount={4}
							tickLine={false}
							style={{ fontSize: 14, fontFamily: "Mulish", fontStyle: "italic" }}
						/>
						<Tooltip
							content={<GoogleAnalyticsChartCustomTooltip/>}
							isAnimationActive={false}
							allowEscapeViewBox={{ x: true, y: true }}
							cursor={{ fill: "transparent" }}
							labelStyle={{ display: "none" }}
						/>
						<Line
							type="linear"
							dataKey={lineData}
							stroke="#2fa847"
							strokeWidth={isSmallScreen? 2 : 3}
						/>
					</LineChart>
				</ResponsiveContainer>
		</>
	);
};

export default ReportsChart;

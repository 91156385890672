import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useMutation} from "@tanstack/react-query";
import {useAPI} from "../../utils/hooks/useAPI";
import {useSearchParams} from "react-router-dom";
import {ENDPOINTS} from "../../constants";

type Props = {
  children: JSX.Element,
}

const LoginActionsProvider = ({children}: Props) => {
  const {getAccessTokenSilently} = useAuth0();
  const api = useAPI()
  const [searchParams] = useSearchParams()
  const [areActionsCompleted, setAreActionsCompleted] = useState<boolean>(!searchParams.get("afterLogin"));

  const {mutate: triggerActions} = useMutation({
    mutationFn: triggerActionsFn,
    onSettled: async () => {
      await getAccessTokenSilently({ignoreCache: true});
      setAreActionsCompleted(true)
    }
  })

  function triggerActionsFn() {
    return api.post(ENDPOINTS.ACTIONS.run_actions, {'originURL': window.location.href})
  }

  useEffect(() => {
    if (areActionsCompleted) {
      return
    }
    triggerActions()
  }, [])

  return areActionsCompleted ? children : <div className={'text-center'}>Checking authentication</div>;
}

export default LoginActionsProvider;

import React, {useEffect, useMemo, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";

import {MemberFlags} from "../index";
import {useAPI} from "../../../../utils/hooks/useAPI";
import DisplayFlags from "./DisplayFlags";
import {Button} from "@mui/material";
import _ from 'lodash';
import {notifyError, notifySuccess} from "../../../../components/utils/ToastNotifications/Notifier";

type FlagsSelectProps = {
	selectedMember: { id: number, company_name: string }
	isMemberOrTenantLoading: boolean
};

export type SelectedFlags = {
	id: number,
	new_state: boolean,
	name: string,
	initialState: boolean
}[]

type AllFlags = {
	flags: { name: string, id: number }[]
}

const FlagsSelect: React.FC<FlagsSelectProps> = ({
													 selectedMember,
													 isMemberOrTenantLoading
												 }) => {
	const [selectedFlags, setSelectedFlags] = useState<SelectedFlags>([]);
	const api = useAPI();
	const queryClient = useQueryClient();

	const {data: flagsResponse, isLoading: isFlagsResponseLoading} = useQuery({
		queryKey: ['flags', selectedMember],
		refetchOnWindowFocus: false,
		enabled: !!selectedMember.id,
		queryFn: getAllFlags
	})

	const {data: memberFlagsResponse, isLoading: isMemberFlagsResponseLoading} = useQuery({
		queryKey: ['memberFlags', selectedMember],
		refetchOnWindowFocus: false,
		enabled: !!selectedMember.id,
		queryFn: () => getMemberFlags(selectedMember.id)
	})

	const {mutate: updateFlagsMutate, isLoading: isUpdatingFlags} = useMutation({
		mutationFn: updateMemberFlags,
		onSuccess: () => {
			notifySuccess('Successfully updated member');
			queryClient.invalidateQueries(['tenants-table']);
		},
		onError: (e) => e instanceof Error && notifyError('Something wrong happened, please try again later or contact us.')
	})

	const areFlagsLoading = useMemo(() => {
		return isFlagsResponseLoading || isMemberFlagsResponseLoading || isUpdatingFlags
	}, [isFlagsResponseLoading, isMemberFlagsResponseLoading, isUpdatingFlags])

	const selectedFlagsNames = useMemo(() => {
		return selectedFlags.filter(({new_state}) => new_state).map(({name}) => name);
	}, [selectedFlags])

	const isAllSelected = useMemo(() => {
		return (
			selectedFlags &&
			selectedFlags.length > 0 &&
			selectedFlags.filter(({new_state}) => new_state).length ===
			selectedFlags.length
		);
	}, [flagsResponse?.data, selectedFlags]);

	const isSelectedFlagStateInitial = useMemo(() => {
		return selectedFlags.filter(({new_state, initialState}) => new_state !== initialState).length === 0

	}, [selectedFlags])

	// Assign initial selected flags state
	useEffect(() => {
		if (memberFlagsResponse?.data && flagsResponse?.data) {
			setSelectedFlags(() => {
				return flagsResponse.data.flags.map(flag => {
					const isMemberFlag = memberFlagsResponse.data.flags.some(({id}) => id === flag.id);
					return {
						id: flag.id,
						name: flag.name,
						new_state: isMemberFlag,
						initialState: isMemberFlag,
					}
				})
			})
		}
	}, [memberFlagsResponse?.data, flagsResponse?.data]);

	//handlers
	// Function to handle the case where 'all' is selected
	const handleAllFlagsSelected = (checked: boolean) => {
		// If all flags are selected, set selectedFlags with new_state: true
		// Otherwise, set selectedFlags to all flags with new_state: false
		setSelectedFlags((prevState) => {
			const flags = prevState.map(flag => ({
				...flag,
				new_state: checked,
			})) || []
			return [...flags]
		});
	};

	// Function to handle the case where single flag is selected
	const handleSingleSelectedFlag = (new_state: boolean, id: number) => {
		setSelectedFlags((prevState) => {
			const flagIndex = prevState.findIndex(({id: flagId}) => flagId === id);
			prevState[flagIndex] = {...prevState[flagIndex], new_state}
			return [...prevState];
		})
	}

	const indeterminateSelect = () => {
		return flagsResponse?.data?.flags && selectedFlags.length < flagsResponse?.data?.flags.length;
	}

	function getAllFlags(): Promise<AxiosResponse<AllFlags>> {
		return api.get(`feature-flags`);
	}

	function getMemberFlags(memberId: number): Promise<AxiosResponse<MemberFlags>> {
		return api.get(`members/${memberId}/feature-flags`)
	}

	function updateMemberFlags({flags, memberId}: { flags: SelectedFlags, memberId: number }) {
		const updatedFlagsValues: { id: number, new_state: boolean }[] = flags
			.filter(({new_state, initialState}) => new_state !== initialState)
			.map(flag => _.omit(flag, ['name', 'initialState']))

		return api.post(`members/${memberId}/feature-flags`, updatedFlagsValues);
	}

	return (
		<>
			<DisplayFlags selectedFlagsNames={selectedFlagsNames} selectedFlags={selectedFlags}
						  handleSingleSelectedFlag={handleSingleSelectedFlag}
						  handleAllFlagsSelected={handleAllFlagsSelected} isAllSelected={isAllSelected}
						  indeterminate={indeterminateSelect()}
			/>

			<div>
				<Button disabled={isMemberOrTenantLoading || areFlagsLoading || isSelectedFlagStateInitial}
						onClick={() => updateFlagsMutate({memberId: selectedMember.id, flags: selectedFlags})}
						 size="large">
					<span>Update flags</span>
				</Button>
			</div>
		</>
	);
};

export default FlagsSelect;
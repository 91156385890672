import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {useAPI} from "../../../utils/hooks/useAPI";
import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import {Modal} from "../../../components/utils/Modal/Modal";

type FlagsTableType = {
	tenants: {
		company_names: string[]
		active_flags: string[]
		id: number
		tenant_name: string
	}[]
}

const FlagsTable = () => {
	const [isModalOpened, setIsModalOpened] = React.useState(false);
	const [modalContent, setModalContent] = React.useState<string>();
	const api = useAPI();

	const {data: tenantsTableData} = useQuery({
		queryKey: ['tenants-table'],
		refetchOnWindowFocus: false,
		queryFn: getTenantsTableData
	})

	//handlers
	const handleModalContent = (properties: string[]) => {
		setIsModalOpened(prevState => !prevState)
		setModalContent(properties.join(', '))
	}

	function getTenantsTableData(): Promise<AxiosResponse<FlagsTableType>> {
		return api.get('tenants/members/flags');
	}

	if (!tenantsTableData?.data) {
		return <LoadingSpinner/>
	}

	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{minWidth: 650}} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Tenant</TableCell>
							<TableCell align="center">Members</TableCell>
							<TableCell align="center">Flags</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tenantsTableData?.data.tenants.map((row) => (
							<TableRow
								key={row.id}
								sx={{'&:last-child td, &:last-child th': {border: 0}}}
							>
								<TableCell component="th" scope="row">
									{row.tenant_name}
								</TableCell>
								<TableCell align="center" onClick={() => handleModalContent(row.company_names)}>
									<span className="cursor-pointer">{row.company_names?.length}</span>
								</TableCell>
								<TableCell align="center" onClick={() => handleModalContent(row.active_flags)}>
									<span className="cursor-pointer">{row.active_flags?.length}</span>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Modal show={isModalOpened} onClose={() => setIsModalOpened(prevState => !prevState)}>
				<div className="p-5">{modalContent}</div>
			</Modal>
		</>
	);
};

export default FlagsTable;
import * as React from 'react';

import {notifyError, notifySuccess} from "../../../../components/utils/ToastNotifications/Notifier";
import {useAPI} from "../../../../utils/hooks/useAPI";
import "./ImportCSVModal.scss"
import "../MemberManagement.scss"
import InfoIcon from "@mui/icons-material/Info";
import {Button, Checkbox, FormControl, FormControlLabel, FormGroup, Typography} from "@mui/material";
import Downloader from "../../../../components/utils/Downloader";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {ENDPOINTS} from "../../../../constants";
import {AxiosError} from "axios";

interface componentProps {
    handleCSVSubmit: Function;
}

const info_icon_style = {
    fontSize: "1.88rem",
    color: "#2DAAE2",
    paddingRight: "0.3rem"
};

const upload_icon_style = {
    fontSize: "1.83rem",
    color: "#F3AA18",
    paddingRight: "0.3rem"
};

const checkBox_labels = {
    fontStyle: 'italic',
    fontWeight: '300',
    fontSize: '14px',
}

const span_checkbox_styles = {
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '18px',
    fontFamily: '"Mulish", sans-serif'
}

const sample_file_url = 'https://static-em-api-prod.s3.amazonaws.com/static/users_ingest_csv_info.xlsx'
const csv_template_url = 'https://static-em-api-prod.s3.amazonaws.com/static/users_ingest_csv_template.csv'

const CSVModal: React.FC<componentProps> = ({handleCSVSubmit}) => {
    const api = useAPI();
    const [checkBoxData, setCheckBoxData] = React.useState({
        sampleFile: true,
        csvTemplate: true,
    });
    const {sampleFile, csvTemplate} = checkBoxData;
    const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);
    const [fileNames, setFileNames] = React.useState([''])
    const [isClickedDownloadButton, setIsClickedDownloadButton] = React.useState(false)

    const clickInput = () => {
        // @ts-ignore
        hiddenFileInput.current.click()
    }

    async function handleFile(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();

        if (!event.target.files) {
            return;
        }
        const fileToUpload = event.target.files;
        let formData = new FormData();
        formData.append("file", fileToUpload[0]);

        await api.post(ENDPOINTS.MEMBERS.post_csv_file, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            if (response.status === 201) {
                notifySuccess('File successfully uploaded. We\'ll notify you over email when scanning has been completed.')
                handleCSVSubmit();
            }

            if (response.status === 422) {
                throw new Error('Network response was not ok');
            }
        }).catch((error) => {
            if (hiddenFileInput.current) {
                hiddenFileInput.current.value = ""
            }
            const responseError = error.response.data.message || error.response?.data?.detail
            if (!responseError['result']) {
                notifyError(responseError, {autoClose: false});
                return;
            }
            const keys = Object.keys(error.response.data.message.result)
            const result: Array<string> = []
            keys.forEach(key => {
                result.push(`Issue with field '${key}': '${responseError['result'][key]}' at row ${responseError['row_number']}`)
            })
            result.forEach(error => notifyError(error, {autoClose: false}))
        })
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckBoxData({
            ...checkBoxData,
            [event.target.name]: event.target.checked,
        });
    };

    const downloadFile = () => {
        if (fileNames.includes('')) {
            fileNames.shift()
        }
        if (checkBoxData.sampleFile && !checkBoxData.csvTemplate) {
            setFileNames([sample_file_url])
        }
        if (checkBoxData.csvTemplate && !checkBoxData.sampleFile) {
            setFileNames([csv_template_url])
        }
        if (checkBoxData.sampleFile && checkBoxData.csvTemplate) {
            setFileNames([sample_file_url, csv_template_url])
        }
        setIsClickedDownloadButton(true)
    }

    // TODO: style -> ?

    return (
        <section className="import-csv-wrapper">
        <div className="member-management__csv-modal">
            <h2 className="member-management__csv-modal-title">Import .CSV data file</h2>
            <div className="member-management__csv-modal-subtitle">
                <InfoIcon style={info_icon_style}/>
                <h2 className="member-management__csv-modal-subtitle-content">
                    Please, start with reviewing our sample file</h2>
            </div>
            <div className="member-management__csv-modal-content">
                <p className="member-management__csv-modal-content-text">Learn more about <span
                    style={{textDecoration: 'underline', fontWeight: '700'}}>required</span> and optional
                    fields
                    and potential values you can include in your file.</p>
            </div>
            <FormControl style={{display: 'flex', justifyContent: 'center', flexDirection: 'row'}} sx={{m: 2}}
                         component="fieldset" variant="standard">
                <FormGroup>
                    <FormControlLabel className={"csv-modal-form-control-label"}
                        control={
                            <Checkbox style={{color: '#2DAAE2', fill: 'white'}} checked={sampleFile}
                                      onChange={handleChange}
                                      name="sampleFile"/>
                        }
                        label={<Typography style={checkBox_labels}><span
                            style={span_checkbox_styles}>Sample File</span> learn how to format your data</Typography>}
                        //style={{fontFamily: '"Mulish",sans-serif', fontWeight: '300', fontSize: '18px', lineHeight: '22.59'}}
                    />
                    <FormControlLabel className={"csv-modal-form-control-label"}
                        control={
                            <Checkbox style={{color: '#2DAAE2', fill: 'white'}} checked={csvTemplate}
                                      onChange={handleChange}
                                      name="csvTemplate"/>
                        }
                        label={<Typography style={checkBox_labels}><span
                            style={span_checkbox_styles}>CSV Template</span> use our template to create your data
                            file</Typography>}
                    />
                </FormGroup>
            </FormControl>
            <div className="member-management__csv-modal-div">
                <Button className="member-management__csv-modal-download-btn" onClick={downloadFile}>
                    <span>DOWNLOAD</span>
                </Button>
                {isClickedDownloadButton && <Downloader fileNames={fileNames}/>}
            </div>
            <div className="member-management__csv-modal-subtitle member-management__csv-modal-subtitle-upload">
                <FileUploadIcon style={upload_icon_style}/>
                <h2 className="member-management__csv-modal-subtitle-content">
                    Upload your data</h2>
            </div>
            <div className="member-management__csv-modal-content" style={{marginBottom: '1.5rem'}}>
                <p className="member-management__csv-modal-content-text">Once you’ve formated your data, go
                    ahead and upload it.</p>
            </div>
            <div className="member-management__csv-modal-div member-modal__mobile-upload-margin">
                <Button className="member-management__csv-modal-upload-btn" onClick={clickInput}>
                    <span>UPLOAD</span>
                </Button>
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleFile}
                    style={{display: 'none'}}
                />
            </div>
        </div>
        </section>
    )
}


export default CSVModal;

import {useEffect, useState} from 'react';

const useScript = (props: { src: string; id: string; shouldLoad: number | undefined | boolean }) => {
    const [triggerLoad, setTriggerLoad] = useState<number | undefined | boolean>(false);

    useEffect(() => {
        if (props.shouldLoad || triggerLoad) {
            const script = document.createElement('script');

            script.src = props.src;
            script.id = props.id;
            script.async = true;
            script.defer = true;

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            }
        }
    }, [props.src, triggerLoad]);

    return setTriggerLoad;
};

export default useScript;
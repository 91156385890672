import React, {useContext} from 'react';
import {NavLink} from "react-router-dom";
import moment from "moment";
import {Modal as MUIModal, Pagination, Tooltip} from "@mui/material";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import MailIcon from "@mui/icons-material/Mail";
import Button from "@mui/material/Button";

import LoadingSpinner from "../loading/LoadingSpinner";
import {notifyError, notifySuccess} from "../utils/ToastNotifications/Notifier";
import {ColorNavPathsContext} from "../../store/context/color-nav-paths-context";
import {NavigationButtonsContextType} from "../../interfaces";
import {Modal} from "../utils/Modal/Modal";
import './styles.scss';

export interface Ticket {
	ticketID: number;
	companyName: string;
	memberID: number;
	domain: string;
	desc: string;
	phone: string;
	email: {
		email: string;
		is_primary: boolean;
	};
	src: string;
	score: number;
	status: string;
	date: Date;
}

type TicketTrackingProps = {
	tickets: Ticket[]
	activeTab: string,
	searchValue?: string,
	emitHandleButtonClick: (clickedTicket: Ticket) => void,
	getTickets: (status: string, searchValue?: string, page?: number) => Promise<void>,
	currentPage: number,
	pageCount: number,
	loadingModal: boolean
}

const TicketTrackingTab = ({tickets, activeTab, searchValue, emitHandleButtonClick,
							   getTickets, currentPage, pageCount, loadingModal}: TicketTrackingProps) => {
	const [loading, setLoading] = React.useState<boolean>(true);
	const [ticketDescription, setTicketDescription] = React.useState<string>("")
	const [ticketDetailsModal, setTicketDetailsModal] = React.useState<boolean>(false)
	const {updateNavButtons} = useContext(ColorNavPathsContext) as NavigationButtonsContextType;

	React.useEffect(() => {
		if (tickets) {
			setLoading(false)
		}
	}, [tickets])

	//handlers
	function showDescriptionModal(clickedTicket: Ticket) {
		if (clickedTicket.desc) {
			setTicketDescription(clickedTicket.desc)
			setTicketDetailsModal(true)
		}
	}

	function handleModalClose() {
		setTicketDetailsModal(false)
	}

	function getButtonTextByTicketStatus(ticketStatus: string): string {
		switch (ticketStatus) {
			case "new":
				return "engage";
			case "in progress":
				return "close";
			default:
				return "closed";
		}
	}

	const copyEmailToClickBoard = async (email: string) => {
		navigator.clipboard.writeText(email)
			.then(() => notifySuccess('Email copied to clipboard'))
			.catch(() => notifyError(('Failed to copy email to clipboard')))
	}

	const handlePaginationOnChange = (event: React.ChangeEvent<unknown>, page: number) => {
		if (page !== currentPage) {
			setLoading(true)
			getTickets(activeTab, searchValue, page).then(() => setLoading(false))
		}
	}

	return (
		<>
			{loading
				? <LoadingSpinner/>
				: <section className="tickets-tab">
					{
						tickets?.map((ticket, i) => {
							return <article
								key={i}
								className="ticket-tracking__ticket"
								style={{
									border: ticket.status === "new" ? '1px #F3AA18 solid' :
										ticket.status === "closed"
											? '2px #F7F7F7 solid'
											: ''
								}}
							>
								<article className="ticket-tracking--body-left">
									<div className="ticket-tracking__domain">
										<NavLink onClick={() => {
											updateNavButtons("Member Management");
										}
										} to={`/admin/member-management/${ticket.memberID}`}
												 className="ticket-tracking__link"
										>
                                                    <span
														className="ticket-tracking__companyName"
													>
														{ticket.companyName}
                                                    </span>
										</NavLink>
										<Tooltip title={ticket.domain}>
											<span
												className="ticket-tracking__domain-name"
											>
												{ticket.domain}
											</span>
										</Tooltip>
									</div>
									<p onClick={() => {
										showDescriptionModal(ticket);
									}}
									   style={{cursor: ticket.desc ? 'pointer' : 'auto'}}
									   className="ticket-tracking__description">
										{ticket.desc ? ticket.desc : "No description provided."}
									</p>
									<div
										style={{flexDirection: ticket.email.email.length > 30 ? "column" : "row"}}
										className="ticket-tracking__contacts"
									>
										<div className="ticket-tracking__phone-number-button_wrapper">
											<div
												style={{
													// width: ticket.email.email.length > 30 ? "fit-content" : "auto",
													width: "fit-content"
												}}
												className="ticket-tracking__button">
												<Tooltip title={ticket.phone || 'Not available'}>
														<span className="ticket-tracking__phone-number ticket-tracking__email-overflow"
														>
															<PermPhoneMsgIcon style={{
																height: '0.8em',
																color: '#555555',
																verticalAlign: 'bottom',
																marginRight: '0.5rem'
															}}/>
															{ticket.phone || 'Not available'}
														</span>
												</Tooltip>
											</div>
										</div>
										<div
											style={{
												// margin: ticket.email.email.length > 30 ? "1rem 0 0 0" : "0 0 0 2rem",
												width: ticket.email.email.length > 30 ? "fit-content" : "auto",
											}}
											className="ticket-tracking__button ticket-tracking--button-margin">
											<Tooltip title={ticket.email.email}>
													<span
														onClick={copyEmailToClickBoard.bind(this, ticket.email.email)}
														className="ticket-tracking__phone-number ticket-tracking__email-overflow"
													>
														<MailIcon style={{
															height: '0.8em',
															color: '#555555',
															verticalAlign: 'bottom',
															marginRight: '0.5rem'
														}}/>
														{ticket.email.email}
													</span>
											</Tooltip>
										</div>
									</div>
								</article>
								<article className="ticket-tracking__score">
									<p className="ticket-tracking__date">{"From: " + moment(ticket.date).format("DD MMMM YYYY")}</p>
									<div className="ticket-tracking__source-wrapper">
										<div>
											<p className="ticket-tracking__src ticket-tracking--font-size">
												Source:
											</p>
											<p className="ticket-tracking__src ticket-tracking__src--margin">
												{ticket.src.toUpperCase()}
											</p>
										</div>
										<div>
											<p className="ticket-tracking__score-text ticket-tracking--font-size">
												Score:
											</p>
											<p className="ticket-tracking__score-number">
												{ticket.score}
											</p>
										</div>
									</div>
									<div className="ticket-tracking__btn-wrapper">
										<Button disabled={activeTab === "closed"}
												style={{
													backgroundColor: activeTab === "new"
														? '#F3AA18'
														: activeTab === 'closed'
															? '#F4F4F4'
															: ''
												}}
												onClick={() => emitHandleButtonClick(ticket)}
												className="ticket-tracking__score-button"
												variant="contained"
												size="large"
										>
											<span>{getButtonTextByTicketStatus(ticket.status)}</span>
										</Button>
									</div>
								</article>
							</article>
						})
					}
					{ pageCount > 1 &&
						<article className="tickets-tab__pagination">
							<Pagination
								size="large"
								count={pageCount}
								color="primary"
								onChange={handlePaginationOnChange}
								page={currentPage}
							/>
						</article>
					}
					<Modal
						useFooter={false}
						onClose={handleModalClose}
						show={ticketDetailsModal}
						hideConfirmButton={true}
						hideCloseButton={true}
						onDeny={handleModalClose}
						size={"sm"}
					>
						<div className="ticket-tracking__ticket-description-modal">
							{ticketDescription}
						</div>
					</Modal>
					<MUIModal
						open={loadingModal}>
						<LoadingSpinner/>
					</MUIModal>
				</section>
			}
		</>
	);
};

export default TicketTrackingTab;

import React, {useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {useAPI} from "../../utils/hooks/useAPI";
import {Modal} from "../utils/Modal/Modal";
import {Button} from "@mui/material";
import {Form, Formik} from "formik";
import FormikMUISimpleInput from "../Formik/MUISimpleInput/FormikMUISimpleInput";
import MuiTextarea from "../Formik/MUITextarea/MUITextarea";
import * as yup from "yup";
import {dataTestIds} from "../../constants/data-test-ids";
import {ENDPOINTS} from "../../constants";

type TicketGeneratorType = {
	domain: string
	ticketData?: {
		btnType: string,
		scrollId: string
	}
}

const ticketValidation = yup.object().shape({
	email: yup.string().email('Wrong email format.').required('Email is required.'),
})

const GenerateTicket = ({domain, ticketData}: TicketGeneratorType) => {
	const [ticketModalOpened, setTicketModalOpened] = useState<boolean>(false);
	const [ticketSource, setTicketSource] = useState<string>('');
	const [confirmationModalOpened, setConfirmationModalOpened] = useState<boolean>(false);
	const [modalType, setModalType] = useState<string>("");
	const [loadingModal, setLoadingModal] = useState<boolean>(false);

	const api = useAPI();
	const {user} = useAuth0();

	React.useEffect(() => {
		buildTicketModalBody()
	}, [modalType])

	// handlers
	function submitTicket(ticketSource: string, ticketDescription: string, email: string): void {
		api.post(ENDPOINTS.TICKET_TRACKING.create_ticket, {
			domain,
			ticket_source: ticketSource,
			ticket_description: ticketDescription,
			email: email
		}).then((response) => {
			setLoadingModal(false)
			if (response.status === 201) {
				handleTicketModalClose("success")
			} else {
				handleTicketModalClose("failure")
			}
		})
			.catch((e) => {
				setLoadingModal(false)
				handleTicketModalClose("failure")
			})
	}

	function handleTicketModalClose(result: string): void {
		setModalType(result);
		setConfirmationModalOpened(true);
	}

	function handleModalClose(): void {
		if (ticketModalOpened) {
			setTicketModalOpened(false)
			localStorage.removeItem('isInputChanged')
		}
		if (confirmationModalOpened) {
			setConfirmationModalOpened(false);
			localStorage.removeItem('isInputChanged')
		}
	}

	async function handleEmailSupportClick(buttonID: string) {
		setModalType('submit');
		setTicketSource(buttonID);
		setTicketModalOpened(true);
		localStorage.removeItem('isInputChanged')
	}

	function buildTicketModalBody() {
		return modalType === "submit" ?
			<section className="mb-4">
				<p className="w-3/4 mb-3 m-auto text-xl text-center font-normal" data-testid={dataTestIds.viq.ticketCreateHeadFlag}>
					Do you need help starting your cybersecurity journey? Please, reach out:
				</p>
				<Formik onSubmit={(values) => {
					submitTicket(
						ticketSource,
						values.description ? values.description : '',
						values.email);
					setLoadingModal(true);
				}}
						initialValues={{email: user?.email ? user.email : '', description: ''}}
						validationSchema={ticketValidation}>
					{({errors}) => {
						// @ts-ignore
						return <Form>
							<div className="mb-3">
								<FormikMUISimpleInput error={!!errors.email} name="email" size="small"
													  label="Your email *"/>
							</div>
							<div className="max-w-full min-w-0 w-auto">
								<MuiTextarea name="description" placeholder="How can we help you?"
											 size="medium"
											 style={{width: '97.5%'}}/>
							</div>

							<div className="modal__footer">
								<Button
									id="ticket-cancel"
									data-testid={dataTestIds.modal.cancelBtn}
									onClick={() => setTicketModalOpened(prevState => !prevState)}
									className="modal__btn-cancel"
									variant="contained"
								>
									<span className="modal__btn-label">Cancel</span>
								</Button>
								<Button
									id="ticket-submit"
									data-testid={dataTestIds.newTicket.submitTicket}
									className="modal__btn-submit"
									type='submit'
									style={{
										backgroundColor: "inherit"
									}}
									variant="contained">
									<span className="modal__btn-label">Submit</span>
								</Button>
							</div>
						</Form>
					}
					}
				</Formik>
			</section>
			: modalType === "success"
				? <section className="modal-message">
					<h3>Thank you for submitting your request.</h3>
					<p>We will contact you shortly.</p>
				</section>
				: <section className="modal-message">
					<h3>Something went wrong with your request.</h3>
					<p>Please try again later.</p>
				</section>
	}

	return (
		<>
			<section data-testid={dataTestIds.viq.ticketCreateFooter} className="p-0.5 bg-light-grey rounded-b-2xl">
				<p className="ms-5">
					<span className="font-normal">Stuck? Reach out, and we’ll help you with this step.</span>
					<span
						data-testid={dataTestIds.newTicket.emailSupport}
						onClick={() => !!ticketData && handleEmailSupportClick(ticketData?.btnType)}
						className="pr-4 ms-2 underline cursor-pointer font-normal text-dark-purple"
					>
						Email Support.
					</span>
				</p>
			</section>
			<Modal
				onClose={handleModalClose}
				show={ticketModalOpened}
				hideConfirmButton={false}
				hideCloseButton={false}
				onDeny={handleModalClose}
				haveTextArea={true}
				confirmBtnText={"Submit"}
				confirmButtonDisabled={loadingModal}
				useFooter={false}
			>
				{buildTicketModalBody()}
			</Modal>
		</>
	);
};

export default GenerateTicket;
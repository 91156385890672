import React, {createContext, useState} from "react";
import {FeatureFlag} from "../../interfaces";

export type FeatureFlagContextType = {
  memberFlags?: Array<FeatureFlag>;
  setMemberFlags: (flag: Array<FeatureFlag>) => void;
}

export const FeatureFlagContext = createContext<FeatureFlagContextType>({
  memberFlags: [],
  setMemberFlags: () => {},
});


export const FeatureFlagProvider: React.FC = ({children}) => {
  const [memberFlags, setMemberFlags] = useState<Array<FeatureFlag>>();

  return <FeatureFlagContext.Provider value={{memberFlags, setMemberFlags}}>{children}</FeatureFlagContext.Provider>;
}

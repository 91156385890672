import DashboardComponent from "../../../../components/DashboardComponent/DashboardComponent";
import TenantsTable from "./Table/TenantsTable";
import '../UserLogins/UserLogins.scss';

const Tenants = () => {
  return (
    <DashboardComponent>
      <article className="user-logins">
        <TenantsTable/>
      </article>
    </DashboardComponent>
  )
}

export default Tenants;
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {MenuItem, TextField} from "@mui/material";

import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import DashboardComponent from "../../../components/DashboardComponent/DashboardComponent";
import {ENDPOINTS, permissionsCatalog} from "../../../constants";
import {CampaignStatsData} from "./CampaignStats";
import {UserPermissionContext} from "../../../store/context/user-permission-context";
import {useAPI} from "../../../utils/hooks/useAPI";

interface AdminCampaignStatsData extends CampaignStatsData{
    delivery_delays: number;
    complaints: number;
    rendering_failures: number;
    subscriptions: number;
}

export interface Tenant {
    id: number,
    name: string,
    subdomain_prefix: string,
    active: boolean
}

const AdminCampaignStats: React.FC = () => {
    const {userPermissions} = useContext(UserPermissionContext);
    const api = useAPI();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [campaignStats, setCampaignStats] = useState<AdminCampaignStatsData>();
    const [tenants, setTenants] = useState<Array<Tenant>>();
    const [selectedTenant, setSelectedTenant] = useState<string>("");

    useEffect(() => {
        if (!userPermissions.includes(permissionsCatalog.read_tenants)) {
            navigate("/access-denied");
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPermissions])

    useEffect(() => {
        if (!campaignStats) {
            initData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (selectedTenant !== "") {
            getDataForCurrentTenant()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTenant])

    const handleTenantChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        await setSelectedTenant(event.target.value);
    };

    async function initData() {
        setLoading(true);

        const {data} = await api.get(ENDPOINTS.TENANTS.get_all_tenants);
        await setTenants(data);

        setLoading(false);
    }

    async function getDataForCurrentTenant() {
        setLoading(true);

        const {data} = await api.get(ENDPOINTS.EMAILS.get_admin_campaign_stats(selectedTenant));
        await setCampaignStats(data);

        setLoading(false);
    }

    return (
        <DashboardComponent>
            <article className="campaign-stats">
                {
                    loading ?
                        <LoadingSpinner/>
                        :
                        <section className="admin-campaign-stats">
                            <TextField
                                select
                                label="Tenant"
                                helperText="Please select your tenant"
                                size={"small"}
                                value={selectedTenant}
                                onChange={handleTenantChange}
                            >
                                {tenants && tenants.map((tenant, index) => (
                                    <MenuItem key={index} value={tenant.name}>
                                        {tenant.name}
                                    </MenuItem>
                                ))}
                            </TextField><br/>
                            CampaignManagement
                            <p>Sent count: {campaignStats?.sent_count}</p>
                            <p>Clicked: {campaignStats?.clicked}</p>
                            <p>Bounced: {campaignStats?.bounced}</p>
                            <p>Opened: {campaignStats?.opened}</p>
                            <p>Delivered: {campaignStats?.deliveries}</p>
                            <p>Delivery Delays: {campaignStats?.delivery_delays}</p>
                            <p>Complaints: {campaignStats?.complaints}</p>
                            <p>Rejected: {campaignStats?.rejects}</p>
                            <p>Rendering failures: {campaignStats?.rendering_failures}</p>
                            <p>Unsubscribed: {campaignStats?.subscriptions}</p>
                        </section>
                }
            </article>
        </DashboardComponent>
    )
}

export default AdminCampaignStats

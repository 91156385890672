import {useContext, useEffect, useRef} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import axios, {CancelTokenSource} from 'axios';

import PathContext from "../../store/context/path-context";
import {RedirectContext} from "../../store/context/redirect-context";


export function useAPI(cancelSource?: CancelTokenSource) {
	const {apiUrlProps: {tenant, protocol, host}} = useContext(PathContext);
	const {getAccessTokenSilently, loginWithRedirect, user} = useAuth0();
	const {pathname} = useContext(RedirectContext);

	const API = useRef(
		axios.create({
			baseURL: `${protocol}${tenant}${host}/`,
			headers: {
				'Content-Type': 'application/json',
			},
			cancelToken: cancelSource?.token || undefined
		})
	);

	useEffect(() => {
		const currentAPI = API.current;

		const requestInterceptor = currentAPI.interceptors.request.use(
			async config => {
				if (user) {
					const token = await getAccessTokenSilently();
					if (config.headers) {
						config.headers.authorization = `Bearer ${token}`;
					}
				}
				return config;
			}
		);

		const responseInterceptor = currentAPI.interceptors.response.use(
			(response) => {
				return response;
			},
			async error => {
				if (error.config && error.response && error.response.status === 401) {
					await loginWithRedirect(
						{appState: {target: pathname}}
					);
				}

				return Promise.reject(error);
			}
		);

		return () => {
			currentAPI.interceptors.request.eject(requestInterceptor);
			currentAPI.interceptors.response.eject(responseInterceptor);
		};
	});

	return API.current;
}

import {Link} from 'react-router-dom';

import {EdisonMarksLogo} from "../../../assets";
import './Navigation.scss';


const Navigation = () => {
    return (
        <nav className="sg-nav">
            <img className="sg-nav__logo" src={EdisonMarksLogo} alt="Edison Marks"/>
            <h2>EM guide</h2>
            <article>
                <div className="sg-nav__getting_started">
                    <h3>Getting Started</h3>
                    <Link className="sg-nav__link sg-nav__link_hover" to="/style-guide">Concepts</Link>
                    <Link className="sg-nav__link sg-nav__link_hover" to="/style-guide/structure">Structure</Link>
                    <Link className="sg-nav__link sg-nav__link_hover" to="/style-guide/styles">Styles</Link>
                </div>
            </article>
        </nav>
    );
};

export default Navigation;
import React, {useContext, useEffect, useMemo} from "react";
import {useAuth0} from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import {useLocation, useNavigate} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {Avatar, Box, FormControl, IconButton, Menu, MenuItem, Select, Typography} from "@mui/material";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import {useAPI} from "../../../utils/hooks/useAPI";
import {SSActiveDomainContext} from "../../../store/context/self-serve-active-domain";
import {emUrl, ENDPOINTS, HUBSPOT_CHAT, pathNamesDashboard, permissionsCatalog, smallScreen} from "../../../constants";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import SideDrawer from "../../../components/Layout/SideDrawer/SideDrawer";
import DashboardNavigation from "../DashboardNavigation/DashboardNavigation";
import {TenantUserPropsContext} from "../../../store/context/tenant-user-props-context";
import {TenantPropsContext} from "../../../store/context/tenant-props-context";
import {UserPermissionContext} from "../../../store/context/user-permission-context";
import {capitalizeFirstLetter} from "../../../utils/helpers";
import useScript from "../../../utils/hooks/useScript";
import './DashboardHeader.scss';

type DashboardHeaderType = {
  fromSelfServe?: boolean,
  setAddDomainModal?: React.Dispatch<React.SetStateAction<boolean>>
  isUserOnboarded?: boolean;
}

const DashboardHeader = ({fromSelfServe, setAddDomainModal, isUserOnboarded}: DashboardHeaderType) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [isDrawerToggled, setIsDrawerToggled] = React.useState(false);
  const [pathName, setPathName] = React.useState<string | null>();
  const isMobile = useIsMobile();
  const isSmallScreen = useIsMobile(smallScreen);
  const {pathname} = useLocation();
  const adminHomePathname = '/admin/engagement/';
  const [_, , memberId, , domainName] = pathname.split('/');
  const {user, logout} = useAuth0()
  const navigate = useNavigate();
  const api = useAPI();
  const {activeDomain, setActiveDomain} = useContext(SSActiveDomainContext);
  const currentUrl = window.location.pathname;
  const {tenantProps} = useContext(TenantPropsContext);
  const {tenantUserProps} = useContext(TenantUserPropsContext);
  const triggerChatLoad = useScript({shouldLoad: tenantUserProps && tenantUserProps.id, src: HUBSPOT_CHAT.src, id: HUBSPOT_CHAT.id});
  const {userPermissions} = useContext(UserPermissionContext);
  const isSSOnboardingPath = useMemo(() => {
    return !['/members/onboarding', '/tenants/onboarding'].some((path) => pathname.includes(path))
  }, [pathname])

  const {data: response} = useQuery({
    queryKey: ['fetch-ss-domains'],
    queryFn: fetchSSDomains,
    enabled: isSSOnboardingPath && !!fromSelfServe,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (tenantUserProps && tenantUserProps.id) {
      triggerChatLoad(true);
    }
  }, [tenantUserProps]);

  React.useEffect(() => {
    if (response && response?.data.length > 0) {
      const activeDom = response?.data.find((domain: { domain: string; }) => domain.domain === domainName);
      if (activeDom) {
        setActiveDomain && setActiveDomain(activeDom.domain);
        return;
      }

      setActiveDomain && setActiveDomain(response?.data[0].domain);
    }
  }, [response?.data]);

  React.useEffect(() => {
    const path = currentUrl.replace("/admin/", "").match('^.*?(?=\\/)');
    if (path == null) {
      setPathName(null);
      return;
    }

    if (path.length && !path[0] && !fromSelfServe) {
      const currentUrlParts = currentUrl.split('/').reverse();
      let finalPath = currentUrl.includes('settings') ? 'Settings / ' : '';
      for (const part of currentUrlParts) {
        finalPath = finalPath + capitalizeFirstLetter(part);
        setPathName(finalPath);
        return;
      }
    }

    setPathName(pathNamesDashboard.get(path[0]));
  }, [currentUrl]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function fetchSSDomains() {
    return api.get(ENDPOINTS.DOMAINS_SCORE.get_or_create_domain(memberId));
  }

  const navigateToHome = () => {
    if (activeDomain) {
      navigate(`/members/${memberId}/reports/${activeDomain}`);
      return;
    }

    if (pathname !== adminHomePathname && userPermissions.includes(permissionsCatalog.read_dashboard)) {
      navigate(adminHomePathname);
    }
  }

  return <header className="dash-header">
    <div className="dash-header__img-wrapper">
      <img
        className={`dash-header__img ${(activeDomain || (pathname !== adminHomePathname && userPermissions.includes(permissionsCatalog.read_dashboard))) && 'cursor-pointer'}`}
        src="https://media-exp1.licdn.com/dms/image/C4D0BAQHusJQncW_zFA/company-logo_200_200/0/1628534469879?e=2159024400&v=beta&t=lYhp_JbOYwycaneTIwWk4bT7XL0SWqsCneq755G92y4"
        alt="Edison Marks"
        onClick={navigateToHome}
      />
      {
        !fromSelfServe && (isSmallScreen ? <div className="dash-header__title">
            <h3 className="dash-header__title__dashboard cursor-pointer" onClick={navigateToHome}>Dashboard</h3>
            <h3 className="dash-header__title__path">{isMobile && pathName != null && `${pathName}`}</h3>
          </div>
          :
          <h3 className="dash-header__title">
            <div className={'flex'}>
              <div
                className={`${(activeDomain || (pathname !== adminHomePathname && userPermissions.includes(permissionsCatalog.read_dashboard))) && 'cursor-pointer'} me-1`}
                onClick={navigateToHome}>
                Dashboard
              </div>
              <div>
                {isMobile && pathName != null && ` / ${pathName}`}
              </div>
            </div>
          </h3>
        )
      }
      {fromSelfServe && isUserOnboarded && activeDomain && <Box sx={{minWidth: 120, borderBottom: 'none'}}>
          <FormControl fullWidth>
              <Select
                  variant="standard"
                  className={`${isMobile ? '!text-xs' : '!text-lg'} text-dark-purple !font-bold dash-header__custom-select`}
                  value={activeDomain}
                  renderValue={() => activeDomain}
                  onChange={(event) => setActiveDomain && setActiveDomain((event.target.value) as string)}
                  inputProps={{
                    name: 'domain',
                    id: 'uncontrolled-native',
                  }}
              >
                {
                  response?.data.map(({id, domain, is_scored}: { id: number, domain: string, is_scored: boolean }) => {
                    return (
                      <MenuItem
                        key={id}
                        onClick={() => navigate(`${memberId}/reports/${domain}`)}
                        disabled={!is_scored}
                        className={`m-3 ${isMobile && '!text-xs'}`}
                        value={domain}>
                        {domain} {!is_scored && "- pending"}
                      </MenuItem>
                    )
                  })
                }
              </Select>
          </FormControl>
      </Box>}
    </div>
    <div className="dash-header__logout flex">
      {/*{*/}
      {/*  !fromSelfServe && !isMobile &&*/}
      {/*    <Button*/}
      {/*        variant="contained"*/}
      {/*        size="large"*/}
      {/*        className="header-rep__logout header-rep__logout--margin"*/}
      {/*        onClick={() => logout({*/}
      {/*          returnTo: window.location.origin*/}
      {/*        })}*/}
      {/*    >*/}
      {/*        Logout*/}
      {/*    </Button>*/}
      {/*}*/}
      {/*{!isMobile && !isUserOnboarded && tenantProps && tenantProps.id && tenantUserProps && tenantUserProps.id &&*/}
      {/*    <MenuItem onClick={() => {*/}
      {/*      handleCloseUserMenu()*/}
      {/*      navigate(`/tenants/${tenantProps?.id}/settings/${tenantUserProps?.id}/profile/`)*/}
      {/*    }}*/}
      {/*              key="Settings"*/}
      {/*    >*/}
      {/*        <SettingsIcon className="me-2"/>*/}
      {/*        <Typography textAlign="center">Settings</Typography>*/}
      {/*    </MenuItem>*/}
      {/*}*/}
      <div className="flex items-center">
        {fromSelfServe && isUserOnboarded && <p
            className="flex items-center text-dark-purple me-2.5 text-sm rounded-lg shadow-md p-2 cursor-pointer hover:border-gray-200"
            onClick={() => setAddDomainModal && setAddDomainModal(true)}
        >
            <AddCircleOutlineIcon className="!text-sm" fontSize="small"/>
            <span className="ms-1">
            Add Domain
          </span>
        </p>}
        {/*{fromSelfServe &&*/}
        <Box sx={{flexGrow: 0}}>
          <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
            <Avatar alt="Avatar"
                    src={user?.picture}/>
          </IconButton>
          <Menu
            sx={{mt: '45px'}}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >

            {(isUserOnboarded || (tenantProps && tenantProps.id && tenantUserProps && tenantUserProps.id)) &&
                <MenuItem onClick={() => {
                  handleCloseUserMenu()
                  navigate(
                    tenantProps && tenantProps.id && tenantUserProps && tenantUserProps.id ?
                      `/tenants/${tenantProps?.id}/settings/${tenantUserProps?.id}/profile/` :
                      `/members/${memberId}/settings/account`
                  )
                }}
                          key="Settings"
                >
                    <SettingsIcon className="me-2"/>
                    <Typography textAlign="center">Settings</Typography>
                </MenuItem>
            }

            <MenuItem onClick={() => {
              handleCloseUserMenu()
              logout({returnTo: emUrl})
            }}
                      key="Sign out"
            >
              <ExitToAppIcon className="me-2"/>
              <Typography textAlign="center">Sign out</Typography>
            </MenuItem>
          </Menu>
        </Box>
        {/*}*/}
      </div>
      {
        isMobile && !fromSelfServe &&
          <Button
              variant="outlined"
              style={{border: 0, minWidth: '45px', minHeight: '40px'}}
              sx={{padding: 1}}
              onClick={() => setIsDrawerToggled(prevState => !prevState)}
          >
              <MenuRoundedIcon sx={{color: '#2e3192'}}/>
          </Button>
      }
      {
        !fromSelfServe && <SideDrawer isDrawerToggled={isDrawerToggled} setIsDrawerToggled={setIsDrawerToggled}>
              <DashboardNavigation setIsDrawerToggled={setIsDrawerToggled} isDrawerToggled={isDrawerToggled}/>
          </SideDrawer>
      }
    </div>
  </header>
}

export default DashboardHeader;

import React, {useContext, useEffect} from "react";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";

import {ENDPOINTS, ROUTES} from "../../constants";
import EmAdminHeader from "./EmAdminHeader/EmAdminHeader";
import EmAdminNavigation from "./EmAdminNavigation/EmAdminNavigation";
import UserLogins from "./Users/UserLogins/UserLogins";
import Tenants from "./Users/Tenants/Tenants";
import DomainScores from "./Scores/DomainScores/DomainScores";
import DashboardFooter from "../Dashboard/DashboardFooter/DashboardFooter";
import PathContext from "../../store/context/path-context";
import {TenantPropsContext} from "../../store/context/tenant-props-context";
import {TenantUserPropsContext} from "../../store/context/tenant-user-props-context";
import {useAPI} from "../../utils/hooks/useAPI";
import useIsMobile from "../../utils/hooks/useIsMobile";
import './../Dashboard/styles.scss'

const EmAdmin = () => {
  const api = useAPI()
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const {apiUrlProps: {tenant}} = useContext(PathContext);
  const {setTenantProps} = useContext(TenantPropsContext);
  const {setTenantUserProps} = useContext(TenantUserPropsContext);

  useEffect(() => {
    if (tenant && tenant !== 'em') {
      navigate('/not-found');
      return;
    }
  }, [tenant])

  const {data: tenantData} = useQuery({
    queryKey: ['fetch-tenant-info'],
    queryFn: fetchTenantInfo,
    refetchOnWindowFocus: false
  })

  const {data: tenantUserData} = useQuery({
    queryKey: ['fetch-tenant-user-info'],
    queryFn: fetchTenantUserInfo,
    refetchOnWindowFocus: false
  })

  function fetchTenantInfo() {
    return api.get(ENDPOINTS.TENANTS.get_current_tenant);
  }

  function fetchTenantUserInfo() {
    return api.get(ENDPOINTS.TENANTS.get_current_tenant_user);
  }

  useEffect(() => {
    if (tenantData) {
      setTenantProps && setTenantProps(tenantData.data)
    }
  }, [tenantData])

  useEffect(() => {
    if (tenantUserData) {
      setTenantUserProps && setTenantUserProps(tenantUserData.data)
    }
  }, [tenantUserData])

  return (
    <section className="app__admin-dashboard">
      <EmAdminHeader/>
      <article className='app__admin-dashboard-main'>
        {!isMobile && <EmAdminNavigation/>}
        <Routes>
          <Route index element={<UserLogins/>}/>
          <Route path={ROUTES.EM_ADMIN.subroutes.CLIENTS_USERS.route}>
            <Route path={ROUTES.EM_ADMIN.subroutes.CLIENTS_USERS.subroutes.USER_LOGINS.route} element={<UserLogins/>}/>
            <Route path={ROUTES.EM_ADMIN.subroutes.CLIENTS_USERS.subroutes.TENANTS.route} element={<Tenants/>}/>
            <Route path={ROUTES.ERROR_PAGE.route} element={<Navigate replace to="/not-found"/>}/>
          </Route>
          <Route path={ROUTES.EM_ADMIN.subroutes.SCORES.route}>
            <Route path={ROUTES.EM_ADMIN.subroutes.SCORES.subroutes.DOMAIN_SCORES.route} element={<DomainScores/>}/>
            <Route path={ROUTES.ERROR_PAGE.route} element={<Navigate replace to="/not-found"/>}/>
          </Route>
          <Route path={ROUTES.ERROR_PAGE.route} element={<Navigate replace to="/not-found"/>}/>
        </Routes>
      </article>
      <DashboardFooter/>
    </section>
  )
}

export default EmAdmin;

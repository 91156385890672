import React, {useContext, useEffect, useState} from 'react';

import {tenantsThemes} from "../../utils/theme";
import PathContext from "./path-context";
import {TenantThemeInterface} from "../../interfaces";

export const CurrentThemeContext = React.createContext<TenantThemeInterface>(tenantsThemes['em']);

export const CurrentThemeContextProvider: React.FC = ({children}) => {
    const [currentTheme, setCurrentTheme] = useState<TenantThemeInterface>(tenantsThemes['em']);
    const {apiUrlProps: {tenant}} = useContext(PathContext);

    useEffect(() => {
        if (tenantsThemes[tenant]) {
            setCurrentTheme(tenantsThemes[tenant]);
            return;
        }
        setCurrentTheme(tenantsThemes['em']);
    }, [currentTheme, tenant])

    return (
        <CurrentThemeContext.Provider value={currentTheme}>{children}</CurrentThemeContext.Provider>
    )
}
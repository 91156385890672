import React, {useState} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import moment from "moment/moment";
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import * as yup from "yup";

import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import FormikMUISimpleInput from "../../../components/Formik/MUISimpleInput/FormikMUISimpleInput";
import {Modal} from "../../../components/utils/Modal/Modal";
import {ipAddressV4Validation, IpValuesEditAction} from "./NonTrackedIPs";
import './IPsTable.scss';
import '../../Dashboard/MemberManagement/SingleMember/SingleMemberInfo.scss';
import '../../Dashboard/MemberManagement/AddOrModifyMember/CreateEditContact/CreateEditContact.scss';
import '../../Reports/HeaderReport/HeaderReport.scss';

export interface IpAddress {
  id: number,
  ip_address: string,
  added_on: string,
  user_email: string
}

type IPsTableProps = {
  ips: IpAddress[] | undefined,
  setIpId: React.Dispatch<React.SetStateAction<number | undefined>>,
  onChange: (type: string, values?: IpValuesEditAction, selectedIpId?: number) => void,
}

export enum ipActionsTypes {
  editIp = 'Edit selected IP Address',
  deleteIp = 'Delete selected IP Address',
}


const IPsTable = ({ips, setIpId, onChange}: IPsTableProps) => {
  const [ipAddressInfo, setIpAddressInfo] = useState<IpAddress>();
  const [ipAddressInfoModal, setIpAddressInfoModal] = useState<boolean>(false);
  const [inputLabel, setInputLabel] = useState('');
  const [ipVersion, setIpVersion] = useState('')

  const formik = useFormik({
    initialValues: {
      ipId: (ipAddressInfo && ipAddressInfo.id) || 0,
      ip_address: (ipAddressInfo && ipAddressInfo.ip_address) || "",
    },
    enableReinitialize: true,
    validationSchema: ipVersion === 'ipv4' ? ipAddressV4Validation : ipVersion === 'ipv6' ? yup.object({
      ip_address: yup.string()
        .required('This field is required.')
        .matches(/^::(?:ffff:)?([0-9a-fA-F]{1,4}:){1,7}[0-9a-fA-F]{1,4}$/, 'You must enter a valid IPv6 address'),
    }) : '',
    onSubmit: (values) => {
      onChange(ipActionsTypes.editIp, values);
      setIpAddressInfoModal(false);
    }
  })

  if (!ips) {
    return <LoadingSpinner/>
  }

  const getIpVersion = (ip_address: string) => {
    const ipv4Regex = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
    const ipv6Regex = /^::(?:ffff:)?([0-9a-fA-F]{1,4}:){1,7}[0-9a-fA-F]{1,4}$/;
    if (ipv4Regex.test(ip_address)) {
      setIpVersion('ipv4')
      setInputLabel('IP Address v4')
    }
    if (ipv6Regex.test(ip_address)) {
      setIpVersion('ipv6')
      setInputLabel('IP Address v6')
    }
  }

  const handleModalClose = () => {
    setIpAddressInfoModal(false);
    formik.resetForm();
  }

  function showInfoModal(ipAddress: IpAddress) {
    if (ipAddress) {
      setIpAddressInfo(ipAddress)
      setIpAddressInfoModal(true)
      getIpVersion(ipAddress.ip_address)
    }
  }

  return (
    <div>
      {ips.length === 0 ? (
        <p>No IP addresses available.</p>
      ) : (
        <div className='ips-table__div-table'>
          <TableContainer>
            <Table sx={{minWidth: 800}} aria-label="simple table" className="table-fixed">
              <TableHead>
                <TableRow>
                  <TableCell className="ips-table__head">IP Address</TableCell>
                  <TableCell className="ips-table__head" align="center">Added on</TableCell>
                  <TableCell className="ips-table__head" align="center">Added by</TableCell>
                  <TableCell className="ips-table__head" align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ips.map((ip, i) => (
                  <TableRow
                    key={ip.id}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    hover
                  >
                    <TableCell component="th" scope="row" className="overflow-hidden text-ellipsis">
                      {ip.ip_address}
                    </TableCell>
                    <TableCell align="center">
                      {moment(ip.added_on).format('YYYY/MM/DD')}
                    </TableCell>
                    <TableCell align="center" className="ips-table__user-email">
                      {ip.user_email}
                    </TableCell>
                    <TableCell align="center">
                      <EditIcon style={{color: '#2DAAE2', cursor: 'pointer'}}
                                onClick={() => {
                                  setIpId(ip.id)
                                  showInfoModal(ip)
                                }}/>
                      <DeleteOutlineIcon style={{color: '#EA3D2F', cursor: 'pointer'}}
                                         onClick={() => {
                                           onChange(ipActionsTypes.deleteIp, undefined, ip.id)
                                         }}/>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            useFooter={false}
            onClose={handleModalClose}
            show={ipAddressInfoModal}
            hideConfirmButton={true}
            hideCloseButton={true}
            onDeny={handleModalClose}
            size="lg"
          >
            <FormikProvider value={formik}>
              <Form className="flex flex-col ips-table__form">
                <div className="mb-6">Edit IP Address</div>
                <div className="mb-4 flex">
                  <div className="w-full">
                    <FormikMUISimpleInput error={!!formik.errors.ip_address} label={inputLabel} name="ip_address"
                                          size="small" placeholder={inputLabel}/>
                  </div>
                </div>
                <div>
                  <Button
                    variant="contained"
                    size="large"
                    className={formik.isSubmitting || !formik.isValid || !formik.dirty || !formik.touched ? "!font-bold !text-base !px-7 !rounded-lg header-rep__buttons non-tracked-ips__convert-btn-disabled"
                      : "!font-bold !text-base !px-7 !rounded-lg header-rep__buttons non-tracked-ips__convert-btn"}
                    type="submit"
                    disabled={formik.isSubmitting || !formik.isValid || !formik.dirty || !formik.touched}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </FormikProvider>
          </Modal>
        </div>
      )}
    </div>
  )
}

export default IPsTable;
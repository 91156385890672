import React, {useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';

import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import ReportsChart from "../../../components/Charts/ReportsChart";
import {useAPI} from "../../../utils/hooks/useAPI";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {DAY_IN_SECONDS, ENDPOINTS} from "../../../constants";
import moment from "moment";
import {dateFormatToUniversal} from "../../../utils/helpers";
import DateRangePicker from "../../../components/DatePickers/DateRangePicker";
import {notifyError} from "../../../components/utils/ToastNotifications/Notifier";
import {TextField} from "@mui/material";


const Overview = () => {
	const api = useAPI();
	const queryClient = useQueryClient();
	const [startDate, setStartDate] = useState<Date | null>(new Date(Date.now() - 14 * DAY_IN_SECONDS));
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [selectedDomain, setSelectedDomain] = useState<string | null>();
	const [tenantDomains, setTenantDomains] = useState<Array<string>>();

	const query = useQuery({
		queryKey: ['landing-analytics-overview'],
		queryFn: initAnalyticsReportData,
		refetchOnWindowFocus: false
	})

	function initAnalyticsReportData() {
		return api.post(ENDPOINTS.GOOGLE_ANALYTICS.get_overview_charts_data, {
			start_date: moment(startDate).format("YYYY-MM-DD"),
			end_date: moment(endDate).format("YYYY-MM-DD"),
			...(selectedDomain && {domain: selectedDomain}),
		});
	}

	const refetchAnalyticsReportData = async () => {
		await query.refetch();
	}

	const getTenantDomains = () => {
		const getTenantDomainsQuery = queryClient.getQueryData(['get-tenant-domains']);
		// @ts-ignore
		let domains = tenantDomains || getTenantDomainsQuery?.data;
		if (!domains) {
			api.get('tenants/domains/').then(response => {
				if (response.status !== 200) {
					notifyError("Something went wrong, please try again or contact us at support@edisonmarks.com");
					return;
				}
				setTenantDomains(() => {
					const domainsWithData = [...query.data?.data.domains];
					for (const domain of response.data) {
						if (!domainsWithData.includes(domain)) {
							domainsWithData.push(domain);
						}
					}
					return domainsWithData;
				});
			});

			return;
		}

		setTenantDomains(() => {
			const domainsWithData = [...query.data?.data.domains];
			for (const domain of domains) {
				if (!domainsWithData.includes(domain)) {
					domainsWithData.push(domain);
				}
			}

			return domainsWithData;
		});
	}

	useEffect(() => {
		if (query.data?.data.domains) {
			getTenantDomains();
		}
	}, [query.data?.data.domains]);

	return (
			<section>
				<div className={'flex flex-col md:flex-row'}>
					<Autocomplete
						disablePortal
						disabled={query.isFetching || !query.data?.data}
						className={'me-2 mb-3 md:mb-0'}
						options={tenantDomains || []}
						sx={{ width: 300 }}
						renderInput={(params) => <TextField {...params} size={'small'} label={'Domain'}/>}
						renderOption={(props, option, { inputValue }) => {
							return (
								<li {...props} style={{
									fontWeight: query.data?.data.domains.includes(option) ? 700 : 400,
								}}>
									{option}
								</li>
							);
						}}
						onChange={(event, domain: string | null) => setSelectedDomain(domain)}
					/>
					<DateRangePicker startDate={startDate} endDate={endDate}
													 disabled={query.isFetching || !query.data?.data}
													 setStartDate={setStartDate} setEndDate={setEndDate}
													 onDateFilterChartData={refetchAnalyticsReportData}/>
				</div>
				{
					query.isFetching || !query.data?.data ? <LoadingSpinner/> :
					<div>
						<ReportsChart title={'Report Visited'}
										subtitle="visits per day"
										data={dateFormatToUniversal(query.data?.data.overview_report_visits)}
										xAxisData={'date'}
										yAxisData={'event_count'}
										lineData={'event_count'}
						/>
						<ReportsChart title={'Report CTAs'}
										subtitle="clicks per day"
										data={dateFormatToUniversal(query.data?.data.overview_report_engagement)}
										xAxisData={'date'}
										yAxisData={'event_count'}
										lineData={'event_count'}
						/>
					</div>
				}
			</section>
	);
};

export default Overview;

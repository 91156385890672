import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {AxiosResponse} from "axios";
import {useQuery} from "@tanstack/react-query";

import {UserPermissionContext} from "../../../store/context/user-permission-context";
import {permissionsCatalog} from "../../../constants";
import DashboardComponent from "../../../components/DashboardComponent/DashboardComponent";
import {useAPI} from "../../../utils/hooks/useAPI";
import TenantSelect from "./TenantSelect";
import MemberSelect from "./MemberFlags";
import FlagsSelect from "./FlagsSelect/FlagsSelect";

import './styles.scss';
import FlagsTable from "./FlagsTable";

type MembersType = {
	id: number,
	company_name: string
}[]

export type MemberFlags = {
	flags: { name: string, id: number }[]
}

type SelectedMember = { id: number, company_name: string };

const Flags = () => {
	const [selectedTenant, setSelectedTenant] = useState<string>("");
	const [selectedMember, setSelectedMember] = useState<SelectedMember>({
		id: 0,
		company_name: ''
	});

	const {userPermissions} = useContext(UserPermissionContext);
	const navigate = useNavigate();
	const api = useAPI();

	const {data: tenantsResponse, isLoading: tenantLoading} = useQuery({
		queryKey: ['tenantsFlags'],
		refetchOnWindowFocus: false,
		queryFn: getTenants
	})

	const {data: membersResponse, isLoading: memberLoading} = useQuery({
		queryKey: ['membersFlags', selectedTenant],
		refetchOnWindowFocus: false,
		enabled: !!selectedTenant,
		queryFn: getMembers
	})

	const isMemberOrTenantLoading = React.useMemo(() => {
		return tenantLoading || memberLoading;
	}, [tenantLoading, memberLoading])

	useEffect(() => {
		if (!userPermissions.includes(permissionsCatalog.read_tenants)) {
			navigate("/access-denied");
			return;
		}
	}, [userPermissions])

	// handlers
	const handleTenantChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// to handle the out of range warning we need to clear the select member value
		if (selectedMember) {
			setSelectedMember({id: 0, company_name: ''});
		}
		setSelectedTenant(event.target.value);
	};

	const handleMembersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const member = membersResponse?.data.find(({company_name}) => company_name === event.target.value)
		member && setSelectedMember(member);
	};

	function getTenants(): Promise<AxiosResponse<any>> {
		return api.get('tenants/');
	}

	function getMembers(): Promise<AxiosResponse<MembersType>> {
		return api.get(`tenants/${selectedTenant}/members/`);
	}


	return (
		<DashboardComponent>
			<section className="flags">
				<FlagsTable />

				<article className="flex mt-8">
					<TenantSelect
						selectedTenant={selectedTenant}
						handleTenantChange={handleTenantChange}
						tenantsResponse={tenantsResponse}
					/>
					<MemberSelect
						selectedMember={selectedMember}
						handleMembersChange={handleMembersChange}
						membersResponse={membersResponse}
					/>
					<FlagsSelect
						selectedMember={selectedMember}
						isMemberOrTenantLoading={isMemberOrTenantLoading}
					/>
				</article>
			</section>
		</DashboardComponent>
	);
};

export default Flags;
import React, {useState} from "react";
import moment from "moment/moment";
import {Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

import LoadingSpinner from "../../../../components/loading/LoadingSpinner";
import {Modal} from "../../../../components/utils/Modal/Modal";
import '../../../Dashboard/MemberManagement/SingleMember/SingleMemberInfo.scss'
import DomainScoreData from "./DomainScoreData";
import './DomainScoresTable.scss';

export interface DomainScore {
  id: number | undefined,
  domain_id: number,
  domain: string,
  score: number,
  timestamp: string,
  tenant: string | undefined,
  tenant_id: number | undefined
}

type DomainScoresTableProps = {
  domainScores: DomainScore[] | undefined,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  currentPage: number,
  pageCount: number,
}

const DomainScoresTable = ({domainScores, setCurrentPage, currentPage, pageCount}: DomainScoresTableProps) => {
  const [domainScoreInfoModal, setDomainScoreInfoModal] = React.useState<boolean>(false);
  const [domainId, setDomainId] = useState<number | null>(null);

  const handlePaginationOnChange = (event: React.ChangeEvent<unknown>, page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page)
    }
  }

  const handleModalClose = () => {
    setDomainScoreInfoModal(false);
  }

  const handleDomainScoreClicked = (selectedDomainId: number) => {
    if (domainId !== selectedDomainId) {
      setDomainId(selectedDomainId);
    }
    setDomainScoreInfoModal(true);
  }

  return (
    <>
      {domainScores && domainScores.length === 0 ? <LoadingSpinner/> :
        <TableContainer>
          <Table sx={{minWidth: 650}} aria-label="simple table" className="table-fixed">
            <TableHead>
              <TableRow>
                <TableCell className="domain-scores-table__table-head">Domain</TableCell>
                <TableCell className="domain-scores-table__table-head" align="center">Score</TableCell>
                <TableCell className="domain-scores-table__table-head" align="center">Date Calculated</TableCell>
                <TableCell className="domain-scores-table__table-head" align="center">Tenant</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {domainScores && domainScores.map((domainScore, i) => (
                <TableRow
                  hover
                  sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  onClick={(e) => handleDomainScoreClicked(domainScore.domain_id)}
                >
                  <TableCell component="th" scope="row" className="overflow-hidden text-ellipsis login-table__button">
                    {domainScore.domain}
                  </TableCell>
                  <TableCell align="center" className="overflow-hidden text-ellipsis login-table__button"
                             style={{padding: '12px 12px 12px 0'}}>
                    {domainScore.score}
                  </TableCell>
                  <TableCell align="center" style={{padding: '12px 30px 12px 0'}}>
                    {moment(domainScore.timestamp).format('YYYY/MM/DD')}
                  </TableCell>
                  <TableCell align="center" style={{padding: '12px 12px 12px 0'}}>
                    {domainScore.tenant}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
      {pageCount > 1 &&
          <article className="tickets-tab__pagination">
              <Pagination
                  size="large"
                  count={pageCount}
                  color="primary"
                  onChange={handlePaginationOnChange}
                  page={currentPage}
              />
          </article>
      }
      <Modal
        useFooter={false}
        onClose={handleModalClose}
        show={domainScoreInfoModal}
        hideConfirmButton={true}
        hideCloseButton={true}
        onDeny={handleModalClose}
        size="xxl"
      >
        <DomainScoreData domainId={domainId}/>
      </Modal>
    </>
  );
}

export default DomainScoresTable;
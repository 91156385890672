import React, {useContext, useEffect, useState} from "react";
import {Routes, Route, Navigate, useSearchParams} from "react-router-dom";
import {useMutation, useQuery} from "@tanstack/react-query";

import {useTitle} from "../../utils/hooks/useTitle";
import useIsMobile from "../../utils/hooks/useIsMobile";
import {useAPI} from "../../utils/hooks/useAPI";
import DashboardHeader from "./DashboardHeader/DashboardHeader";
import DashboardNavigation from "./DashboardNavigation/DashboardNavigation";
import DashboardFooter from "./DashboardFooter/DashboardFooter";
import Engagement from "./Engagement/Engagement";
import LandingPageStats from "./LandingPageStats";
import MemberManagement from "./MemberManagement/MemberManagement";
import SingleMember from "./MemberManagement/SingleMember/SingleMemberInfo";
import MemberResponseStats from "./MemberResponseStats/MemberResponseStats";
import TicketTracking from "./TicketTracking/TicketTracking";
import CampaignManagement from "./CampaignManagement/CampaignManagement";
import AdminCampaignStats from "./CampaignStats/AdminCampaignStats";
import CampaignStats from "./CampaignStats/CampaignStats";
import Flags from "./Flags";
import {TenantPropsContext} from "../../store/context/tenant-props-context";
import {TenantUserPropsContext} from "../../store/context/tenant-user-props-context";
import {ThemeContext} from "../../store/context/theme-context";
import {Auth0UserNameContext} from "../../store/context/auth0-user-name";
import {ENDPOINTS, ROUTES} from "../../constants";
import './styles.scss';

const AdminDashboard = () => {
  const currentThemeProps = useContext(ThemeContext)
  const {setTenantProps} = useContext(TenantPropsContext);
  const {setTenantUserProps} = useContext(TenantUserPropsContext);
  const {setAuth0UserNameProps} = useContext(Auth0UserNameContext)
  const isMobile = useIsMobile()
  useTitle(`${currentThemeProps?.name} - Engagement Dashboard by Edison Marks`);
  const api = useAPI()
  const [searchParams] = useSearchParams()
  const [isTenantUserCreated, setIsTenantUserCreated] = useState<boolean>(false)

  const {data: tenantData} = useQuery({
    queryKey: ['fetch-tenant-info'],
    queryFn: fetchTenantInfo,
    refetchOnWindowFocus: false
  })

  const {data: tenantUserData} = useQuery({
    queryKey: ['fetch-tenant-user-info'],
    queryFn: fetchTenantUserInfo,
    enabled: isTenantUserCreated || !searchParams.get('isInvited'),
    refetchOnWindowFocus: false,
  })

  const {mutate: createTenantUser} = useMutation({
    mutationFn: createTenantUserFn,
    onSuccess: async ({data: {user_name, is_user_name_changed, created_tenant_user}}) => {
      if(is_user_name_changed){
        setAuth0UserNameProps && setAuth0UserNameProps(user_name)
      }
      setIsTenantUserCreated(true)
    },
  })

  function fetchTenantInfo() {
    return api.get(ENDPOINTS.TENANTS.get_current_tenant);
  }

  function fetchTenantUserInfo() {
    return api.get(ENDPOINTS.TENANTS.get_current_tenant_user);
  }

  function createTenantUserFn() {
    return api.post(ENDPOINTS.TENANTS.create_tenant_user(tenantData?.data.id))
  }

  useEffect(() => {
    if (tenantData) {
      setTenantProps && setTenantProps(tenantData.data)
    }
  }, [tenantData])

  useEffect(() => {
    if (tenantUserData) {
      setTenantUserProps && setTenantUserProps(tenantUserData.data)
    }
  }, [tenantUserData])

  useEffect(() => {
    if (searchParams && searchParams.get('isInvited')) {
      createTenantUser()
    }
  }, [])

  return (
    <section className="app__admin-dashboard">
      <DashboardHeader/>
      <article className='app__admin-dashboard-main'>
        {!isMobile && <DashboardNavigation/>}
        <Routes>
          <Route index element={<Engagement/>}/>
          <Route path={ROUTES.ADMIN.subroutes.MEMBER_MANAGEMENT.route} element={<MemberManagement/>}>
            <Route path={ROUTES.ADMIN.subroutes.MEMBER_MANAGEMENT.subroutes.SINGLE_MEMBER.route}
                   element={<SingleMember/>}/>
            <Route path={ROUTES.ERROR_PAGE.route} element={<Navigate replace to="/not-found"/>}/>
          </Route>
          <Route path={ROUTES.ADMIN.subroutes.LANDING_PAGE_STATS.route} element={<LandingPageStats/>}/>
          <Route path={ROUTES.ADMIN.subroutes.TICKET_TRACKING.route} element={<TicketTracking/>}/>
          <Route path={ROUTES.ADMIN.subroutes.ENGAGEMENT.route} element={<Engagement/>}/>
          <Route path={ROUTES.ADMIN.subroutes.MEMBER_RESPONSE_STATS.route} element={<MemberResponseStats/>}/>
          <Route path={ROUTES.ADMIN.subroutes.CAMPAIGN_MANAGEMENT.route} element={<CampaignManagement/>}/>
          <Route path={ROUTES.ADMIN.subroutes.CAMPAIGN_STATS.route} element={<CampaignStats/>}/>
          <Route path={ROUTES.ADMIN.subroutes.ADMIN_CAMPAIGN_STATS.route} element={<AdminCampaignStats/>}/>
          <Route path={ROUTES.ADMIN.subroutes.FLAGS.route} element={<Flags/>}/>
          <Route path={ROUTES.ERROR_PAGE.route} element={<Navigate replace to={'/not-found'}/>}/>
        </Routes>
      </article>
      <DashboardFooter/>
    </section>
  )
}

export default AdminDashboard;

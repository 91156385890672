import {useEffect, useState} from "react";

type IsMobileType = {
    breakpoint?: number
}

const useIsMobile = ({breakpoint = 1200}: IsMobileType = {}) => {
    const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= breakpoint);
}

export default useIsMobile;
import axios, {AxiosInstance, AxiosResponse} from "axios";

import {ApiUrlProps, EndUserData} from "../interfaces";
import {ENDPOINTS} from "../constants";

export const getDomainScoreDataService = async (domain: string, {
    host,
    tenant,
    protocol
}: ApiUrlProps, permissions: string[], api: AxiosInstance): Promise<AxiosResponse<EndUserData>> => {
    if (permissions.length < 1) {
        return axios({
            method: "POST",
            url: `${protocol}${tenant}${host}/domain-score/`,
            data: {
                domain
            }
        })
    } else if (permissions.includes('read:tenants')) {
        return api.post(ENDPOINTS.DOMAINS_SCORE.get_domain_score_for_admin, {domain});
    } else {
        return api.post(ENDPOINTS.DOMAINS_SCORE.get_domain_score_for_logged_user, {domain})
    }
}
import React, {useState} from "react";
import {ErrorMessage, Field, FormikProvider, useFormik} from "formik";
import {IconButton, TextField} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddTaskIcon from '@mui/icons-material/AddTask';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import * as Yup from "yup";

import {notifyError, notifySuccess} from "../../../../../components/utils/ToastNotifications/Notifier";
import {ENDPOINTS} from "../../../../../constants";
import {Domain} from "../../../../../interfaces";
import {icon_style} from "../../MemberManagement";
import {useAPI} from "../../../../../utils/hooks/useAPI";
import "./EditAssets.scss"


interface EditAssetsProps {
    domains: Array<Domain>;
    memberId: number;
    setChangesMade: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditAssets: React.FC<EditAssetsProps> = ({domains, memberId, setChangesMade}) => {
    const [isAddingDomain, setIsAddingDomain] = useState<boolean>(false);
    const api = useAPI();
    const [domainsList, setDomainsList] = useState<Array<Domain>>([...domains]);

    const formik = useFormik({
        initialValues: {
            domain: ""
        },
        validationSchema: Yup.object().shape({
            domain: Yup.string()
                .required("This field is required.")
                .matches(/^[\w-]+([.]{1}[\w-]{0,61})*\.[\w-]{2,}$/,
                    "You must enter a valid domain."),
        }),
        onSubmit: (formData, {setSubmitting}) => {
            handleAddingDomain(formData.domain);
            setSubmitting(false);
        }
    })

    const handleDeleteDomain = (domain: Domain) => {
        api.delete(ENDPOINTS.DOMAINS_SCORE.get_or_delete_domain(domain.id))
            .then(() => {
                setChangesMade(true);
                const domainIndex = domainsList.indexOf(domain);
                domainsList.splice(domainIndex, 1);
                setDomainsList([...domainsList]);
                notifySuccess('Domain deleted successfully');
            })
            .catch(() => {
                notifyError('There was a problem deleting your domain. Please try again later.');
            })
    }

    const handleAddingDomain = (domain: string) => {
        api.post(ENDPOINTS.DOMAINS_SCORE.get_or_create_domain(memberId), {domain: domain})
            .then((response) => {
                setChangesMade(true);
                domainsList.push({
                    domain: response.data.domain, id: response.data.created_id, timestamp: response.data.timestamp,
                    report_link: `/reports/${domain}`, scanners_opportunities: [], score: 0, is_domain_reprocess: false,
                    reprocess_retry: 0
                });
                setDomainsList([...domainsList]);
                formik.resetForm();
                setIsAddingDomain(false);
            })
            .catch(() => {
                notifyError('There was a problem adding your domain. Please try again later.');
            })
    }

    return <section className="edit-assets">
        <div className="edit-assets__header">
            Edit Assets
        </div>
        <div className="edit-assets__domains-grid">
            {domainsList.map((domain, index) => {
                return <div key={index} className="edit-assets__domain-entry">
                    <div className="edit-assets__domain-data">
                        <div className="edit-assets__domain-name">{domain.domain}</div>
                        <div className="edit-assets__score-label">Current score</div>
                        <div className="edit-assets__score-value">
                            {domain.score ? domain.score : "Pending"}
                        </div>
                    </div>
                    <DeleteOutlineIcon className="edit-assets__domain-delete"
                                       onClick={() => handleDeleteDomain(domain)}/>
                </div>
            })}
            {isAddingDomain ?
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit} className='edit-assets__add-form'>
                        <Field as={TextField}
                               name={'domain'}
                               variant='standard'
                               required
                               autoComplete="off"
                               fullWidth
                               color={'success'}
                               helperText={<ErrorMessage name={'domain'}/>}
                               error={!!(formik.errors['domain'] && formik.touched['domain'])}
                               autoFocus/>
                        <IconButton className='edit-assets__cancel-button' onClick={() => {
                            formik.resetForm()
                            setIsAddingDomain(false)
                        }
                        }>
                            <CancelOutlinedIcon className='edit-assets__cancel-icon'/>
                        </IconButton>
                        <IconButton className='edit-assets__add-button' type='submit'
                                    disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
                            <AddTaskIcon className='edit-assets__add-icon'/>
                        </IconButton>
                    </form>
                </FormikProvider>
                :
                <button className="member-info__add-contact-btn" onClick={() => {
                    setIsAddingDomain(true)
                }}
                        style={{height: 'fit-content', padding: '4px 10px 4px 0px'}}>
                    <AddCircleOutlineIcon
                        style={icon_style}
                    />
                    <span>Add New Domain</span>
                </button>
            }
        </div>
    </section>
}

export default EditAssets;

import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Button, Typography} from '@mui/material';
import {REC_RISKS, REC_STATUTES,} from "../../constants";
import {dataTestIds} from "../../constants/data-test-ids";
import useIsMobile from "../../utils/hooks/useIsMobile";

interface AIRecommendationProps {
	id: number | string
	status: REC_STATUTES,
	title: string;
	risk: REC_RISKS;
	details: string;
	recommendation: string;
	onDoneClick: (id: number | string, action: typeof REC_STATUTES.MARKED_AS_DONE) => void
	onSkipClick: (id: number | string, action: typeof REC_STATUTES.MARKED_AS_SKIPPED) => void
}

function AIRecommendation(this: any, {
	id,
	status,
	title,
	risk,
	details,
	recommendation,
	onDoneClick,
	onSkipClick
}: AIRecommendationProps) {
	const [isExpanded, setIsExpanded] = React.useState<boolean>(status !== REC_STATUTES.ALL_GOOD && status !== REC_STATUTES.POSTPONED && status !== REC_STATUTES.MARKED_AS_DONE && status !== REC_STATUTES.MARKED_AS_SKIPPED);
	const [showMore, setShowMore] = React.useState(false);
	const isMobile = useIsMobile({breakpoint: 576})
	const maxShownRecChar = isMobile ? 60 : 115;

	React.useEffect(() => {
		// When a recommendation is marked as than this logic here triggers the next that needs work.
		if (status === REC_STATUTES.WORK_NEEDED) {
			setIsExpanded(true);
		}
	}, [status])

	// handlers
	const handleOnDoneClicked = (action: typeof REC_STATUTES.MARKED_AS_DONE | typeof REC_STATUTES.MARKED_AS_SKIPPED) => {
		setIsExpanded(prevState => !prevState);
		action === REC_STATUTES.MARKED_AS_DONE ? onDoneClick(id, REC_STATUTES.MARKED_AS_DONE) : onSkipClick(id, REC_STATUTES.MARKED_AS_SKIPPED);
	};

	const handleRiskStatusBg = (): string => {
		const riskColors: { [key in REC_RISKS]: string } = {
			[REC_RISKS.HIGH]: 'bg-pink2',
			[REC_RISKS.MEDIUM]: 'bg-light-yellow',
			[REC_RISKS.LOW]: 'bg-light-grey2',
		}

		return riskColors[risk];
	}

	const handleRecHeaderContent = () => {
		const statusMap: Partial<{ [key in REC_STATUTES]: JSX.Element }> = {
			[REC_STATUTES.ALL_GOOD]: (
				<span className="ms-4 mt-3 pt-4 align-middle">{REC_STATUTES.ALL_GOOD}</span>
			),
			[REC_STATUTES.MARKED_AS_DONE]: (
				<span className="ms-4 mt-3 pt-4 align-middle">{REC_STATUTES.MARKED_AS_DONE}</span>
			),
			[REC_STATUTES.MARKED_AS_SKIPPED]: (
				<span className="ms-4 mt-3 pt-5 align-middle">{REC_STATUTES.MARKED_AS_SKIPPED}</span>
			),
			[REC_STATUTES.POSTPONED]: (
				<div className="flex items-center ms-4 w-fit ">
					<p className="m-0 text-base italic font-light leading-5 text-grey font-lato">
						Risk:
					</p>
					<p className={`ms-2 m-0 py-1 px-2 leading-5 rounded-md text-base font-lato ${handleRiskStatusBg()}`}>
						{risk}
					</p>
				</div>
			),
		};

		return statusMap[status];
	};

	return (
		<article className="mb-4">
			<Accordion data-testid={dataTestIds.viq.accordion} expanded={isExpanded} className="rec-accordion">
				<AccordionSummary
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					sx={{
						cursor: 'initial !important',
						userSelect: 'text',
					}}
				>
					<Typography component={'span'} variant={'body2'}
								sx={{flexShrink: 0, maxWidth: isExpanded ? '100%' : '70%'}}>
						<h1 data-testid={dataTestIds.viq.recTitle} className="m-0">{title}</h1>
					</Typography>
					{!isExpanded &&
						<Typography component={'span'} variant={'body2'}
									sx={{flexShrink: 0, marginTop: status !== REC_STATUTES.POSTPONED ? '1.9px' : '0'}}
									data-testid={dataTestIds.viq.recSubtitle}
						>
							{handleRecHeaderContent()}
						</Typography>
					}
				</AccordionSummary>
				<AccordionDetails sx={{paddingTop: '0'}}>
					<Typography component={'span'} variant={'body2'}>
						<div className="flex justify-between mt-0">
							<div>
								<p className="mt-0 mb-0 text-start text-base italic font-light leading-5 text-grey font-lato">
									Risk:
								</p>
								<p className={`mt-2 py-2 text-start px-3 leading-5 rounded-md text-base font-lato ${handleRiskStatusBg()}`}>
									{risk}
								</p>
							</div>
							<div>
								<p className="mt-0 mb-0 text-start text-base italic font-light leading-5 text-grey font-lato">
									Details:
								</p>
								<p className="mt-0 text-start font-lato text-dark-grey-2">{details}</p>
							</div>
						</div>
					</Typography>
					<Typography component={'span'} variant={'body2'}>
						<div className="flex mt-0">
							<div className="basis-2/3 overflow-auto">
								<p className="mt-0 text-start mb-0 text-base italic font-light leading-5 text-grey font-lato">
									Recommendation:
								</p>
								<p
									data-testid="viq-rec-content"
									className="mt-0 text-start font-lato text-dark-grey-2 white-space-pre-wrap text-ellipsis text-grey">
									{showMore ? recommendation : `${recommendation.substring(0, maxShownRecChar) + '...'}`}
									<span className="btn cursor-pointer text-light-blue-3" onClick={() => setShowMore(!showMore)}>
										{showMore ? " Show less" : " Show more"}
									</span>
								</p>
							</div>
							<div className="flex justify-center items-center basis-1/3">
								<div className="pl-2 me-4 max-sm:me-2">
									<Button onClick={() => handleOnDoneClicked(REC_STATUTES.MARKED_AS_SKIPPED)}
											variant="contained"
											data-testid={dataTestIds.viq.skipRecBtn}
									>
										Skip
									</Button>
								</div>
								<div>
									<Button
										data-testid={dataTestIds.viq.doneRecBtn}
										variant="contained"
										color="warning"
										onClick={() => handleOnDoneClicked(REC_STATUTES.MARKED_AS_DONE)}
									>
										Done
									</Button>
								</div>
							</div>
						</div>
					</Typography>
				</AccordionDetails>
			</Accordion>
		</article>
	);
}

export default AIRecommendation;
import * as React from "react";
import {useEffect, useState} from "react";
import {AxiosResponse} from "axios";
import TextField from "@mui/material/TextField/TextField";
import {useQuery} from "@tanstack/react-query";

import DashboardComponent from "../../../../components/DashboardComponent/DashboardComponent";
import {ENDPOINTS} from "../../../../constants";
import DomainScoresTable, {DomainScore} from "./DomainScoresTable";
import '../../Users/UserLogins/UserLogins.scss';
import '../../Users/Tenants/Table/DropdownActions.scss'
import '../../Users/UserLogins/UserLogins.scss'
import Filters from "./Filters";
import {useAPI} from "../../../../utils/hooks/useAPI";
import {useDebounce} from "../../../../utils/hooks/useDebounce";

export type DomainScoresTableType = {
  current_page: number,
  total_pages: number,
  domain_scores: DomainScore[]
}

const DomainScores = () => {
  const api = useAPI();
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageCount, setPageCount] = useState<number>(1)
  const [domainScores, setDomainScores] = useState<Array<DomainScore>>([])
  const [searchValue, setSearchValue] = useState('')
  const debounceSearchValue = useDebounce(searchValue, 1000)
  const [riskStatusFilterValue, setRiskStatusFilterValue] = useState<string>('')
  const [vulnerabilitiesFilterValue, setVulnerabilitiesFilterValue] = useState<string>('')
  const [dateFilterValue, setDateFilterValue] = useState<string>('')
  const [tenantFilterValue, setTenantFilterValue] = useState<string>('')

  const {data: domainScoresData} = useQuery({
    queryKey: ['getDomainScoresData', currentPage, debounceSearchValue, riskStatusFilterValue, vulnerabilitiesFilterValue, dateFilterValue, tenantFilterValue],
    queryFn: getDomainScoresData,
    refetchOnWindowFocus: false,
  })

  function getDomainScoresData(): Promise<AxiosResponse<DomainScoresTableType>> {
    return api.get(ENDPOINTS.DOMAINS_SCORE.get_all_domain_scores(currentPage, debounceSearchValue, riskStatusFilterValue, vulnerabilitiesFilterValue, dateFilterValue, tenantFilterValue))
  }

  useEffect(() => {
    if (domainScoresData) {
      setDomainScores(domainScoresData.data.domain_scores)
      setCurrentPage(domainScoresData.data.current_page)
      setPageCount(domainScoresData.data.total_pages)
    }
  }, [domainScoresData?.data, currentPage])

  return (
    <DashboardComponent>
      <article className="user-logins">
        <div className="dropdown-actions">
          <div className="dropdown-actions__title">
            <p className="m-0">Domain Scores</p>
          </div>
          <div className="user-logins__search-field">
            <TextField className="user-logins__search-label"
                       id="outlined-basic" label="Enter domain to search" variant="outlined"
                       value={searchValue} size="small"
                       onChange={e => {
                         setSearchValue(e.target.value)
                       }}/>
          </div>
        </div>
        <Filters riskStatus={riskStatusFilterValue} setRiskStatus={setRiskStatusFilterValue}
                 vulnerabilitiesFilterValue={vulnerabilitiesFilterValue}
                 setVulnerabilitiesFilterValue={setVulnerabilitiesFilterValue}
                 dateFilterValue={dateFilterValue} setDateFilterValue={setDateFilterValue}
                 tenantFilterValue={tenantFilterValue} setTenantFilterValue={setTenantFilterValue}/>
        <DomainScoresTable
          domainScores={domainScores}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageCount={pageCount}
        />
      </article>
    </DashboardComponent>
  )
}

export default DomainScores;
import React, {useEffect, useState} from "react";
import {MemberInfo} from "../../../../../interfaces";
import {FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {replaceEmptyStringsWithNullsInJSON} from "../../../../../utils/helpers";
import {useAPI} from "../../../../../utils/hooks/useAPI";
import {FormElementInputField, FormElementSelectField} from "../AddMember/AddMember";
import {
    businessRelationships,
    countries,
    employeesCounts, ENDPOINTS,
    industries,
    revenues,
    usaStates
} from "../../../../../constants";
import "./EditMemberInfo.scss"
import {Button} from "@mui/material";
import {notifyError, notifySuccess} from "../../../../../components/utils/ToastNotifications/Notifier";

interface EditMemberInfoProps {
    member: MemberInfo;
    setChangesMade: React.Dispatch<React.SetStateAction<boolean>>;
    closeModal: Function;
}

const EditMemberInfo: React.FC<EditMemberInfoProps> = ({member, setChangesMade, closeModal}) => {
    const api = useAPI();
    const [selectedCountry, setSelectedCountry] = useState<string>(member.postal_address.country);
    const formik = useFormik({
        initialValues: {
            company_name: (member && member.company_name) || "",
            industry: (member && member.industry) || "",
            revenue: (member && member.revenue) || "",
            number_employees: (member && member.number_employees) || "",
            business_relationship: (member && member.business_relationship) || "",
            active: (member && member.active) || 'false',
            country: (member && member.postal_address.country) || "",
            state: (member && member.postal_address.state) || "",
            city: (member && member.postal_address.city) || "",
            zip: (member && member.postal_address.zip) || "",
            mailing_address: (member && member.postal_address.mailing_address) || "",
            mailing_address_2: (member && member.postal_address.mailing_address_2) || "",
        },
        validationSchema: Yup.object().shape({
            company_name: Yup.string()
                .max(255, "This field must contain at most 255 characters.")
                .required("This field is required."),
            industry: Yup.string()
                .notRequired(),
            revenue: Yup.string()
                .notRequired(),
            number_employees: Yup.string()
                .notRequired(),
            active: Yup.boolean()
                .default(false),
            country: Yup.string()
                .required("This field is required."),
            state: selectedCountry === "United States" ?
                Yup.string()
                    .required("This field is required.")
                    .max(50, "This field must contain at most 50 characters.")
                : Yup.string()
                    .notRequired()
                    .max(50, "This field must contain at most 50 characters."),
            city: Yup.string()
                .max(30, "This field must contain at most 30 characters.")
                .required("This field is required."),
            zip: selectedCountry === "United States" ?
                Yup.string()
                    .required("This field is required.")
                    .matches(/\b\d{5}\b/g, "You must enter a 5 digit ZIP code.") :
                Yup.string()
                    .required("This field is required.")
                    .max(10, "This field must contain at most 16 characters."),
            mailing_address: Yup.string()
                .max(255, "This field must contain at most 255 characters.")
                .required("This field is required."),
            mailing_address_2: Yup.string()
                .max(50, "This field must contain at most 50 characters.")
                .notRequired(),
        }),
        onSubmit: (formData, {setSubmitting}) => {
            const requestData = replaceEmptyStringsWithNullsInJSON(formData)
            api.patch(ENDPOINTS.MEMBERS.get_or_update_member(member.id), requestData)
                .then(() => {
                    setChangesMade(true)
                    notifySuccess('Information edited.')
                })
                .catch(() => {
                    notifyError("We could not process your request. Please try again later.");
                })
                .finally(() => {
                    setSubmitting(false)
                    closeModal()
                })
        }
    })

    useEffect(() => {
        setSelectedCountry((prevValues) => {
            if (prevValues !== formik.values.country) {
                formik.values.state = "";
            }
            return formik.values.country
        });
    }, [formik.values, formik.values.country])


    return <section className="edit-member-info">
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} className="edit-member-info__form">
                <div className="edit-member-info__header">Edit Information</div>
                <div className="edit-member-info__form-body">
                    <div className={"edit-member-info__section"}>
                        <FormElementInputField name="company_name" label="Company name"
                                               errors={formik.errors} touched={formik.touched}
                                               required/>
                        <FormElementSelectField items={industries} name="industry"
                                                label="Industry (optional)"
                                                errors={formik.errors} touched={formik.touched}/>
                        <FormElementSelectField items={revenues} name="revenue"
                                                label="Revenue (optional)"
                                                errors={formik.errors}
                                                touched={formik.touched}/>
                        <FormElementSelectField items={employeesCounts} name="number_employees"
                                                label="Employee count (optional)"
                                                errors={formik.errors} touched={formik.touched}/>
                        <FormElementSelectField items={businessRelationships}
                                                name="business_relationship"
                                                label="Business relationship"
                                                errors={formik.errors}
                                                touched={formik.touched} required/>
                        <FormElementSelectField items={[{'label': 'Active', 'value': 'true'},
                                                        {'label': 'Inactive', 'value': 'false'}]}
                                                name="active"
                                                label="Member status"
                                                errors={formik.errors}
                                                touched={formik.touched} required/>
                    </div>
                    <div className={"edit-member-info__section"}>
                        <FormElementInputField name="mailing_address" label="Mailing address"
                                               errors={formik.errors} touched={formik.touched}
                                               required/>
                        <FormElementInputField name="mailing_address_2"
                                               label="Mailing address 2 (optional)"
                                               errors={formik.errors} touched={formik.touched}/>
                        <FormElementInputField name="zip" label="ZIP code"
                                               errors={formik.errors} touched={formik.touched}
                                               required/>
                        <FormElementInputField name="city" label="City" errors={formik.errors}
                                               touched={formik.touched} required/>
                        {formik.values.country !== "United States" ?
                            <FormElementInputField name="state" label="State"
                                                   errors={formik.errors} touched={formik.touched}/>
                            :
                            <FormElementSelectField items={usaStates} name="state" label="State"
                                                    errors={formik.errors} touched={formik.touched}
                                                    required/>
                        }
                        <FormElementSelectField items={countries} name="country" label="Country"
                                                errors={formik.errors} touched={formik.touched}
                                                required/>
                    </div>
                </div>
                <div className="edit-member-info__save-div">
                    <Button
                        className="edit-member-info__save-btn" variant={"contained"} type="submit"
                        disabled={formik.isSubmitting || !formik.isValid || !formik.dirty || !formik.touched}
                    >
                        Save
                    </Button>
                </div>
            </form>
        </FormikProvider>
    </section>
}

export default EditMemberInfo;

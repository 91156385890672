import {Routes, Route} from 'react-router-dom';

import Concepts from "./GettingStarted/Concepts/Concepts";
import Structure from "./GettingStarted/Structure/Structure";
import Styles from "./GettingStarted/Styles/Styles";
import './Main.scss';
import {ROUTES} from "../../../constants";

const Main = () => {
    return (
        <section className="style_guide_main">
            <Routes>
                <Route path={ROUTES.STYLE_GUIDE.subroutes.CONCEPTS.route} element={<Concepts/>}/>
                <Route path={ROUTES.STYLE_GUIDE.subroutes.STRUCTURE.route} element={<Structure/>}/>
                <Route path={ROUTES.STYLE_GUIDE.subroutes.STYLES.route} element={<Styles/>}/>
            </Routes>
        </section>
    );
};

export default Main;